import "../src/App.css";
import React from "react";
import theme from "./state/theme/Theme";
import "../src/firebase/firebaseConfig.js";
import NotFound from "./layouts/NotFound.js";
import { StateProvider } from "./state/Store";
import { ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AuthLayout from "./layouts/auth/AuthLayout";
import MainLayout from "./layouts/main/MainLayout";
import { routesName } from "./config/routesName.js";
import PreviewSMS from "./layouts/customers/PreviewSMS.js";
import LandingLayout from "./layouts/landing/LandingLayout";
import CustomAlert from "./components/custom/alert/CustomAlert";
import CustomLoader from "./components/custom/loader/CustomLoader";
import MarketingLandingPage from "./components/pages/landing/marketingComponents/MarketingLandingPage.js";

import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";

import {
  PrivacyPolicy,
  TermsAndServices,
} from "./components/pages/userManagement/FinycsPolicy.js";
import NewMarketingLandingPage from "./components/pages/landing/marketingComponents/NewMarketingLandingPage.js";


const App = () => {
  

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <StateProvider>
          <CustomLoader />
          <CustomAlert />
          <ToastContainer />
          <Routes>
            <Route path={"/auth/*"} element={<AuthLayout />} />
            <Route path={"/*"} element={<MainLayout />} />
            <Route path={"/landing/*"} element={<LandingLayout />} />
            <Route path={"/"} element={<Navigate replace to="/landing" />} />
            <Route path={"/app"} element={<Navigate replace to="/landing" />} />
            <Route path="/app/web/" element={<Navigate replace to={routesName.privacyPolicy.path} />} />
            <Route path={routesName.privacyPolicy.path} element={<PrivacyPolicy />} />
            <Route path={routesName.termsAndServices.path} element={<TermsAndServices />} />
            <Route path={routesName.MarketingLandingPage.path} element={<MarketingLandingPage />} />
            <Route path={routesName.NewMarketingLandingPage.path} element={<NewMarketingLandingPage />} />


            {/* catch-all route for SMS links */}
            <Route path={routesName.customerInvoice.path} element={<PreviewSMS />} />

            {/* Global catch-all route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </StateProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
