import { apiAction } from "../../../../../../api/api";
import { getIsPurchaseScreen, isBusinessRegistered } from "../../../../../../utils/Utils";

import {
    setUserPreferences,
    getUserPreferences,
    setBusinessPreferences,
    getBusinessPreferences,
} from "../../../../../../config/cookiesInfo";

import {
    get_user_preference,
    update_user_preference,
    get_business_preference,
} from "../../../../../../api/urls";

export const getUserPreferenceData = async (navigate, dispatch) => {
    let res = await apiAction({
        method: 'get',
        navigate: navigate,
        dispatch: dispatch,
        url: get_user_preference(),
    })
    if (res?.success) {
        setUserPreferences(res?.result);
        return res
    } else {
    }
}

export const updateUserPreferencesData = async (id, navigate, dispatch) => {
    let res = await apiAction({
        method: "post",
        navigate: navigate,
        dispatch: dispatch,
        url: update_user_preference(),
        data: { ...getUserPreferences(), business_id: id }
    });
    if (res?.success) {
        setUserPreferences(res?.result);
    } else {
    }
}

export const getBusinessPreferenceData = async (id, navigate, dispatch) => {
    let res = await apiAction({
        method: 'get',
        navigate: navigate,
        dispatch: dispatch,
        url: get_business_preference(id),
    })
    if (res?.success) {
        setBusinessPreferences(res?.result);
    } else {

    }
}

export const getItemPreferences = () => {
    return getBusinessPreferences() && getBusinessPreferences().item
}

export const getGeneralPreferences = () => {
    return getBusinessPreferences() && getBusinessPreferences().general
}

export const getAmountArePreferences = () => {
    const preferences = getBusinessPreferences();
    const generalPreferences = preferences?.general;
    const taxOfItemRate = generalPreferences?.tax_of_item_rate;

    if (!isBusinessRegistered()) {
        return 'no_tax';
    } else if (getIsPurchaseScreen()) {
        return 'no_tax';
    }
    if (!taxOfItemRate) {
        return 'exclusive';
    }

    if (taxOfItemRate.no_tax) {
        return 'no_tax';
    }

    return taxOfItemRate.tax_inclusive ? 'inclusive' : 'exclusive';
};


export const getAttachmentPreferences = () => {
    let { pdf_attachments } = getBusinessPreferences() && getBusinessPreferences()?.general
    return pdf_attachments ? pdf_attachments?.attach_pdf_for_email : true;
}

export const getDiscountPreferences = () => {
    let { discount } = getBusinessPreferences() && getBusinessPreferences().general
    return discount && discount.at_line_item_level ? discount.at_line_item_level : false
}

export const getSalesPersonPreferences = () => {
    let { add_field_for_sales_person } = getBusinessPreferences() && getBusinessPreferences().general
    return add_field_for_sales_person
}

export const getMarkupPreferences = () => {
    let { default_markup_percentage } = getBusinessPreferences() && getBusinessPreferences().general
    return default_markup_percentage
}

export const getTermsAndConditionPreferences = (key) => {
    let { terms_and_condition_id } = getBusinessPreferences() && getBusinessPreferences()[key]
    return terms_and_condition_id
}

export const getPartyPreferences = (key) => {
    let { default_party_type } = getBusinessPreferences() && getBusinessPreferences()[key]
    return default_party_type
}
export const getNotesPreferences = (key) => {
    let { notes } = getBusinessPreferences() && getBusinessPreferences()[key]
    return notes !== '' ? notes : null
}