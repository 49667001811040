import React from 'react';
import Input from '../../../common/Input';
import * as Actions from "../../../../state/Actions";
import {
    isFinalAmountRangeCrossed,
} from '../sales/invoices/InvoiceManager';
import {
    isNumeric,
    stateChangeManager,
    limitDecimalPlaces,
} from '../../../../utils/Utils';

export const QuantityLabelComponent = () => {
    return (
        <div style={{ whiteSpace: 'nowrap' }}>
            Qty
        </div>
    );
};

export const QuantityInputComponent = ({ index, data, setData, quantityData, finalPayment, isFormSubmitted, lowInventoryAlert, ...otherProps }) => {
    const dispatch = Actions.getDispatch(React.useContext);
    const [editable, setEditable] = React.useState(false);

    const handleFocus = () => {
        setEditable(true);
    };

    const handleChange = (event) => {
        const inputValue = limitDecimalPlaces(event.target.value);
        if (inputValue > data[index]?.challan_quantity) {
            stateChangeManager(dispatch, Actions, true, "error", "Quantity recorded cannot be more than quantity ordered.");
        } else {
            if (isFinalAmountRangeCrossed({
                item: data[index],
                invoice: quantityData,
                rate: data[index]?.rate,
                quantity: inputValue,
                item_level_discount: data[index].item_level_discount,
                finalPayment: data.length <= 1 ? 0 : finalPayment - (data[index]?.amount || 0) - (data[index]?.tax_amount || 0),
            })) {
                stateChangeManager(dispatch, Actions, true, "error", "Amount range max limit reached");
            } else {
                data[index].quantity = inputValue;
                if (isNumeric(inputValue)) {
                    if (data[index]?.rate && isNumeric(data[index]?.rate)) {
                        data[index].amount = parseFloat((inputValue * parseFloat(data[index]?.rate).toFixed(2)).toFixed(2));
                    }
                } else {
                    if (data[index]?.rate && isNumeric(data[index]?.rate)) {
                        data[index].amount = parseFloat(data[index]?.rate).toFixed(2);
                    }
                }
                setData([...data]);
            }
        }
    };

    const handleBlur = (event) => {
        const inputValue = limitDecimalPlaces(event.target.value);
        if (isNumeric(inputValue)) {
            if (lowInventoryAlert) {
                lowInventoryAlert(data[index].item, inputValue);
            }
            data[index].quantity = inputValue;
            if (data[index]?.rate && isNumeric(data[index]?.rate)) {
                data[index].amount = parseFloat((inputValue * parseFloat(data[index]?.rate).toFixed(2)).toFixed(2));
            }
        } else {
            data[index].quantity = null;
            if (data[index]?.rate && isNumeric(data[index]?.rate)) {
                data[index].amount = parseFloat(data[index]?.rate).toFixed(2);
            }
        }
        setEditable(false);
        setData([...data]);
    };

    const handleKeyPress = (event) => {
        const restrictedKeys = ["-", "+", "e", "E"];
        if (restrictedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Input
            editable={editable}
            onFocus={handleFocus}
            isSearchableComponent={true}
            id={`input_quantity_${index}`}
            isShowCommaSeparatedValue={true}
            dataTestId={`input_quantity_${index}`}
            currency_code={quantityData.currency_code}
            item={{
                type: "number",
                required: true,
                placeholder: "",
                fullWidth: true,
                onBlur: handleBlur,
                onChange: handleChange,
                value: data[index]?.quantity,
                validation: isFormSubmitted && !data[index]?.quantity,
            }}
            InputProps={{
                inputProps: { min: 0 },
            }}
            onKeyPress={handleKeyPress}
            {...otherProps} // Spread all other props
        />
    );
};
