import React from 'react';
import moment from 'moment';

import * as Actions from '../../state/Actions';

import DateRange from './DateRange';
import DatePicker from './DatePicker';
import AsyncDropdown from './AsyncDropdown';
import { get_chart_of_account_nested_nodes } from '../../api/urls';
import CustomDateRangePicker from '../custom/Datepicker/CustomDateRangePicker';

import {
    Grid,
} from '@mui/material';



const CommonCustomDateRange = (props) => {
    const {
        toDate,
        setPage,
        fromDate,
        dateRange,
        setToDate,
        date_range,
        setFromDate,
        setDateRange,

        isAsOff,
        
        account_value,
        isAccountDropdown,
        onAccountChangeHandler,

        isDateRange,
        onDateRangeChange,
    } = props

    const dispatch = Actions.getDispatch(React.useContext)

    return (
        <React.Fragment>
            <Grid item xs={12} sm={isDateRange ? 4 : isAccountDropdown ? 4.5 : 3}>
                <DateRange
                    id={'date_range_dropdown'}
                    dataTestId={'date_range_dropdown'}

                    sx={{ }}
                    value={dateRange}
                    label='Date Range'
                    results={date_range}
                    disableClearable={true}
                    placeholder='select date range'
                    setValue={(event, newDateRange) => {
                        setToDate(moment());
                        setFromDate(moment());
                        if (setPage) { setPage(1) }
                        setDateRange(newDateRange);
                        if (newDateRange && newDateRange.name !== 'Custom') {
                            dispatch(Actions.stateChange("selectedDateRange", newDateRange.name));
                        }
                    }
                    }
                />
            </Grid>
            {isAccountDropdown ?
                <Grid item xs={12} sm={4.5}>
                    {/* <ChartAccountDropdown
                        id={'account_dropdown'}
                        dataTestId={'account_dropdown'}

                        placeholder='Select account'
                        sx={{ mb: 2 }}
                        item={{
                            title: 'Account',
                            results: accounts,
                            value: account_value,
                            onChange: (event, newInputValue) => { onAccountChangeHandler(newInputValue); if (setPage) { setPage(1) } },
                        }}
                    /> */}
                    <AsyncDropdown
            sx={{}}
            fullWidth={true}
            autoFocus={false}
            newResults={null}
            disabledCloseIcon={false}
            isGroupHeaderSticky={false}

            title={'Account'}
            id={'account_dropdown'}
            dataTestId={'account_dropdown'}
            validation={false}

            selectedValue={account_value}
            setSelectedValue={(value) => {
                onAccountChangeHandler(value)
            }}

            valueLabelKey='id'
            uniqueOptionKey='id'
            searchKey='account_name'
            optionLabelKey='account_name'
            placeholder='Select the Account'
            optionGroupLabelKey='account_type'
            URL={get_chart_of_account_nested_nodes(1)}
          />
                </Grid>
                : null
            }
            {isDateRange && dateRange && dateRange.name === 'Custom' ?
                <Grid item xs={12} sm={8} sx={{mt:1}}>
                    <CustomDateRangePicker disabledCloseIcon={false} onDateRangeChange={onDateRangeChange} />
                </Grid>
                : null
            }
            {isAsOff && dateRange && dateRange.name === 'Custom' ?
                <React.Fragment>
                    <Grid item xs={12} sm={3}>
                        <DatePicker
                            id={'to_date_select'}
                            dataTestId={'to_date_select'}

                            date={toDate}
                            title='To Date'
                            disabledCloseIcon={true}
                            titleVisibility='hidden'
                            setDate={(date) => { setToDate(date); if (setPage) { setPage(1) } }}
                        />
                    </Grid>
                </React.Fragment>
                : null
            }
            {!isAsOff && !isDateRange && dateRange && dateRange.name === 'Custom' ?
                <React.Fragment>
                    <Grid item xs={6} sm={isAccountDropdown ? 4.5 : 2.5}>
                        <DatePicker
                            id={'from_date_select'}
                            dataTestId={'from_date_select'}

                            title='From Date'
                            date={fromDate}
                            setDate={(date) => {
                                setFromDate(date)
                                if (setPage) { setPage(1) }
                                if (date > toDate) { setToDate(date) }
                            }
                            }

                            disabledCloseIcon={true}
                        />
                    </Grid>
                    <Grid item xs={6} sm={isAccountDropdown ? 4.5 : 2.5}>
                        <DatePicker
                            id={'to_date_select'}
                            dataTestId={'to_date_select'}

                            date={toDate}
                            title='To Date'
                            minDate={fromDate}
                            disabledCloseIcon={true}
                            setDate={(date) => { setToDate(date); if (setPage) { setPage(1) } }}
                        />
                    </Grid>

                </React.Fragment>
                : null
            }
        </React.Fragment>
    )
}

export default CommonCustomDateRange;