import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import HighchartsReact from 'highcharts-react-official';
import { routesName } from '../../../../config/routesName';
import { stateChangeManager } from '../../../../utils/Utils';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { amountFormatter, numberWithSuffix } from '../../../../utils/Utils';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { eventsNames,AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { CustomDataPickerPopover } from '../../../custom/Datepicker/CustomDataPickerPopover';


const IncomeExpenseComponents = (props) => {
    const { id, dataTestId, url, date_range, custom_date_range } = props
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);


    const DropdownRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dates, setDates] = React.useState(custom_date_range[4]?.dates);
    const [selectedDateRange, setSelectedDateRange] = React.useState(custom_date_range[4]);

    const [result, setResult] = React.useState();
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (url) {
            getApiResults(url)
        }
    }, [url, dates])


    const getApiResults = async (url) => {
        let res = await apiAction({
            url: url,
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            data: { business_id: getBusinessInfo().id, ...dates },
        })
        if (res?.success) {
            setResult(res?.result)
            setData(aggregateDataByPeriod(res?.result.income_and_expense, dates.from_date, dates.to_date))
        } else {
            stateChangeManager(dispatch, Actions, true, "error", res?.status);

        }
    }

    const aggregateDataByPeriod = (data, from_date, to_date) => {

        const aggregatedData = {};
        const fromDate = moment(from_date);
        const toDate = moment(to_date);

        // Determine the duration between from_date and to_date
        const duration = toDate.diff(fromDate, 'days');

        // Define the format for grouping based on the duration
        let format;
        if (duration <= 7) {
            format = { unit: "day", format: "DD MMM YY" };
        } else if (duration <= 31) {
            format = { unit: "day", format: "DD MMM" };
        } else if (duration <= 93) {
            format = { unit: "month", format: "MMM YY" };
        } else if (duration <= 365) {
            format = { unit: "month", format: "MMM YY" };
        } else {
            format = { unit: "year", format: "YYYY" };
        }

        data.forEach(entry => {
            const period = moment(entry.start_date).format(format.format);
            const groupKey = moment(entry.start_date).startOf(format.unit).format(format.format);

            if (aggregatedData[groupKey]) {
                aggregatedData[groupKey].income_amount += entry.income_amount ? parseFloat(entry.income_amount) : 0;
                aggregatedData[groupKey].expense_amount += entry.expense_amount ? parseFloat(entry.expense_amount) : 0;
            } else {
                aggregatedData[groupKey] = {
                    income_amount: entry.income_amount ? parseFloat(entry.income_amount) : 0,
                    expense_amount: entry.expense_amount ? parseFloat(entry.expense_amount) : 0,
                    start_date: entry.start_date,
                    end_date: entry.end_date,
                    period: period
                };
            }
        });
        return Object.values(aggregatedData);
    };

    let categories = data.map((expense_item) => {
        let temp = {};
        temp['end_date'] = expense_item.end_date;
        temp['start_date'] = expense_item.start_date;
        // temp['name'] = moment(expense_item.end_date).format("MMM") + "<br/>" + moment(expense_item.end_date).format("YY");
        temp['name'] = expense_item.period
        return temp;
    })

    let series_expense_amount = data.map((item) => item.expense_amount)
    let series_income_amount = data.map((item) => item.income_amount)

    let options = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: categories,
            labels: {
                formatter: function () {
                    return this.value.name;
                }
            }
        },
        yAxis: {
            title: {
                text: null
            },
            min: 0,
            labels: {
                formatter: function () {
                    return numberWithSuffix(this.value)
                }
            },
        },
        tooltip: {
            formatter: function () {
                var tooltip = `${this.x.name}`.replace('<br/>', ' ');
                // var tooltip = Highcharts.dateFormat('%b %e', new Date(this.x.name));
                tooltip += `<br><span style="font-family: 'Noto Sans';"><span style="color:${this.series.color}">${this.series.name} : </span>${amountFormatter(this.y)}</span>`;
                return tooltip;
            }
        },
        legend: {
            enabled: false
        },

        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            },
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            AnalyticsEvent(eventsNames.categories.DASHBOARD,{action:eventsNames.actions.CLICK,graph_name:eventsNames.actions.dashboard.graph_name.INCOME_AND_EXPENSE})
                            navigate(routesName.profitLoss.path,
                                {
                                    state: {
                                        dates: [],
                                        name: 'Custom',
                                        to_date: this.category.end_date,
                                        from_date: this.category.start_date,
                                    }
                                })
                        }
                    }
                }
            },
        },
        series: [{ data: series_income_amount, color: "#049735", showInLegend: true, name: 'Income', },{ data: series_expense_amount, color: "#ED0F1C", showInLegend: true, name: 'Expense', }]
    }

    return (
        <div>
            <CustomDataPickerPopover
                anchorEl={anchorEl}
                customDate={dates}
                setAnchorEl={setAnchorEl}
                setCustomDate={setDates}
            />
            <Box>
                <Box sx={{ pb: 2, borderBottom: '2px solid #F5F5F5' }}>
                    <Grid container style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        justifyContent: 'space-evenly',
                        alignContent: 'space-evenly',
                    }}>
                        <Grid item xs={4} sm={6}>
                            <CustomTypography
                                text={'Income and Expense'}
                                sx={{
                                    color: '#000000',
                                    display: 'inline',
                                    fontSize: '16px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} sx={{ textAlign: '-webkit-right' }}>
                            <DateRange
                                label=''
                                sx={{ width: 200 }}
                                id={id + '_dropdown'}
                                inputRef={DropdownRef}
                                disableClearable={true}
                                value={selectedDateRange}
                                results={custom_date_range}
                                dataTestId={dataTestId + '_dropdown'}
                                setValue={(event, newDateRange) => {
                                    if (newDateRange.dates) {
                                        setAnchorEl(null);
                                        setDates(newDateRange.dates);
                                        setSelectedDateRange(newDateRange);
                                    } else {
                                        setAnchorEl(DropdownRef.current);
                                        setSelectedDateRange(newDateRange);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {
                    result && (result.total.income_total || result.total.expense_total) ?
                        <>
                            <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
                                <Box sx={{ p: 1 }}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ p: 1, borderRight: '1px solid #EBEBEB' }}>

                                            <HighchartsReact highcharts={Highcharts}
                                                containerProps={{ style: { height: "346px" } }}
                                                options={options}
                                            />

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box>
                                <Grid container sx={{ pt: 2, pb: 2, }}>
                                    <Grid item xs={6} sx={{ borderRight: '1px solid #EBEBEB' }}>
                                        <Box sx={{ pl: 4 }}>
                                            <CustomTypography
                                                text={<span><CheckBoxOutlineBlankIcon sx={{ mr: 1.5, background: '#049735', fontSize: '12px' }} />Total Income</span>}
                                                sx={{
                                                    mb: 1,
                                                    color: '#049735',
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    fontStyle: 'normal',
                                                    lineHeight: '16.34px',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <CustomTypography
                                                text={<CurrencyFormatter amount={result ? result.total.income_total : 0} />}
                                                sx={{
                                                    ml: 3,
                                                    mt: 1,
                                                    color: '#000000',
                                                    fontSize: '16px',
                                                    fontWeight: '700',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sx={{}}>
                                        <Box sx={{ pl: 4 }}>
                                            <CustomTypography
                                                text={<span><CheckBoxOutlineBlankIcon sx={{ mr: 1.5, background: '#ED0F1C', fontSize: '12px' }} />Total Expense</span>}
                                                sx={{
                                                    mb: 1,
                                                    color: '#ED0F1C',
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    fontStyle: 'normal',
                                                    lineHeight: '16.34px',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                            <CustomTypography
                                                text={<CurrencyFormatter amount={result ? result.total.expense_total : 0} />}
                                                sx={{
                                                    ml: 3,
                                                    mt: 1,
                                                    color: '#000000',
                                                    fontSize: '16px',
                                                    fontWeight: '700',
                                                    lineHeight: '18px',
                                                    fontStyle: 'normal',
                                                    fontFamily: "Noto Sans",
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                        : <Box sx={{
                            height: "430px",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: "center",
                        }}>

                            <CustomTypography
                                text={<span>No transactions recorded for <span style={{ textTransform: 'lowercase' }}>{'selected date range'}</span></span>}
                                sx={{
                                    opacity: 0.6,
                                    color: '#000000',
                                    display: 'inline',
                                    fontSize: '14px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                        </Box>
                }
            </Box>
        </div>
    )
}



export default IncomeExpenseComponents