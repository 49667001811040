import React from 'react';
import moment from 'moment/moment';
import PaymentCreate from '../../payment/PaymentCreate';
import CustomTitle from '../../../../common/CustomTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { get_list_of_invoice } from '../../../../../api/urls';
import { routesName } from '../../../../../config/routesName';
import AdvancePaymentCreate from '../../payment/AdvancePaymentCreate';
import { CustomTitleContainer } from '../../../../custom/container/CustomContainer';
import { eventsNames,AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
  getQueryParams,
  getDefaultPaymentMethod,
} from '../../../../../utils/Utils';

import {
  Box,
} from '@mui/material';

const CreatePaymentsReceived = (props) => {
  let location = useLocation();
  const navigate = useNavigate();
  const params = getQueryParams(location.search);

  const [tab, setTab] = React.useState(0);
  const [tabs, setTabs] = React.useState(
    [
      { name: 'Invoice Payment', condition: 'invoice' },
      { name: 'Customer Advance', condition: 'advance' },
    ]
  );

  React.useEffect(() => {
    if (params.payment_id) {
      setTab(0);
      setTabs([{ name: 'Invoice Payment', condition: 'invoice' }])
    }
    if (params.advance_payment_id) {
      setTab(0);
      setTabs([{ name: 'Customer Advance', condition: 'advance' }])
    }
    if (location.state) {
      setTab(0);
      setTabs([{ name: 'Invoice Payment', condition: 'invoice' }])
    }
  }, [])

  const onClose = (id) => {
    if (location.state) {
      navigate(routesName.invoicingPaymentsReceivedView.path + "?payment_id=" + id);
    } else {
      navigate(routesName.invoicingPaymentsReceivedView.path + "?payment_id=" + id);
    }
  }

  return (
    <Box>
      <CustomTitleContainer>
        <CustomTitle title={'Payments Received'} />
      </CustomTitleContainer>
      <Box sx={{ background: '#FFFFFF', }}>
        <CustomTabs
          id={'tabs_'}
          dataTestId={'tabs_'}
          tabs={tabs}
          value={tab}
          onChange={(event, tab) => { setTab(tab) }}
        />
      </Box>
      {tabs[tab].condition === 'invoice' &&
        <PaymentCreate
        role='customer'
        title='Payments Received'
          dateText='Payment Date '
          accountText='Deposit To '
          nameText='Customer Name '
          amountText='Amount Received '
          checkBoxText='Received full amount '
          label={{
            dateText: 'Date',
            paymentText: 'Payment',
            amountDueText: 'Amount Due',
            amountText: 'Invoice Amount',
            numberText: 'Invoice Number',
            noteText: 'Notes (Internal use. Not visible to customer)',
            blurPopoverText: 'Amount changed to reflect the outstanding balance for this invoice..'
          }}
          apiData={{
            party_key: 'customer_id',
            url: get_list_of_invoice(1),
            date_key: 'invoice_date',
            payment_type: 'receive',
            reference_type: 'invoice',
            amountDue_key: 'due_amount',
            amount_key: 'total_amount',
            number_key: 'invoice_number',
            withholding_tax: 'tds_tcs_amount',
          }}
          party_disabled={location.state ? true : false}

          payment_type='receive'
          bank_charges={location.state ? location.state.bank_charges : 0}
          party_id={location.state ? location.state.customer_id : null}
          total_due_amount={location.state ? location.state.due_amount : 0}

          currency_id={location.state ? location.state.currency_id : null}
          currency_code={location.state ? location.state.currency_code : null}

          party_selected={location.state ? { name: location.state.customer_display_name } : null}
          total_withholding_tax={location.state ? location.state.tds_tcs_amount : 0}
          payment_method={location.state ? getDefaultPaymentMethod(location.state.payment_method) ? getDefaultPaymentMethod(location.state.payment_method).value : 'cash' : 'cash'}

          payment_for={location.state ? [{
            payment_amount: 0,
            reference_type: 'invoice',
            status:location.state.status,
            due_date: location.state.due_date,
            amount: location.state.total_amount,
            amount_due: location.state.due_amount,
            currency_code: location.state.currency_code,
            const_amount_due: location.state.due_amount,
            
            is_tcs_applied: location.state.tds_tcs_choice === 'tcs',
            withholding_tax: location.state.tds_tcs_choice === 'tcs' ? null : location.state.tds_tcs_amount,
            const_with_holding_tax: location.state.tds_tcs_choice === 'tcs' ? null : location.state.tds_tcs_amount,

            reference_id: location.state.id,
            reference_number: location.state.invoice_number,
            date: moment(location.state.invoice_date).format('YYYY-MM-DD'),
          }] : []}
          validation_data={[
            { key: "party_id", message: 'Please select customer' },
            { key: "total_amount", message: 'Amount received field has been left empty.' },
            { key: "payment_date", message: 'Please select the payment date' },
            { key: "payment_method", message: 'Please select the payment mode' },
            { key: "account_id", message: 'Please select the deposit to' },
          ]}
          onClose={onClose}
          AnalyticsEvent={(params)=>{AnalyticsEvent(eventsNames.categories.PAYMENTS_RECEIVED,params)}}
        />
      }
      {tabs[tab].condition === 'advance' &&
        <AdvancePaymentCreate
          role='customer'
          taxText='Tax '
          onClose={onClose}
          payment_type='receive'
          dateText='Payment Date '
          accountText='Deposit To '
          nameText='Customer Name '
          placeText='Place Of Supply '
          amountText='Amount Received '
          is_amount_tax_inclusive={true}

          validation_data={[
            { key: "party_id", message: 'Please select customer' },
            { key: "total_amount", message: 'Amount received field has been left empty.' },
            { key: "payment_date", message: 'Please select the payment date' },
            { key: "payment_method", message: 'Please select the payment mode' },
            { key: "account_id", message: 'Please select the deposit to' },
          ]}
          AnalyticsEvent={(params)=>{AnalyticsEvent(eventsNames.categories.PAYMENTS_RECEIVED,params)}}
        />
      }


    </Box>

  )
}

export default CreatePaymentsReceived