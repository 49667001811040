
export const CONSTANTS = {

  // ===================Categories=============================
  categories: {
    NAV_BAR: 'Nav_Bar',
    SIDEBAR: "Sidebar",
    BUSINESS: "Business",
    GLOBAL_SEARCH: "Global_Search",
    USER_AUTHENTICATION: "User_Authentication",

    INSIGHT: "Insight",
    DASHBOARD: "Dashboard",

    ITEMS: "Items",
    BARCODE_GENERATOR: "Barcode_Generator",

    INVOICES: "Invoices",
    CUSTOMERS: "Customers",
    ESTIMATES: "Estimates",
    CREDIT_NOTES: "Credit_Notes",
    SALES_ORDERS: "Sales_Orders",
    RECEIVEDINVOICES: "Received_Invoices",
    DELIVERY_CHALLANS: "Delivery_Challans",
    PAYMENTS_RECEIVED: "Payments_Received",
    RECURRING_INVOICES: "Recurring_Invoices",

    BILLS: "Bills",
    VENDORS: "Vendors",
    EXPENSES: "Expenses",
    DEBIT_NOTES: "Debit_Notes",
    PAYMENTS_MADE: "Payments_Made",
    PURCHASE_ORDERS: "purchase_orders",
    RECURRING_EXPENSES: "Recurring_Expenses",

    JOURNAL_ENTRY: "Journal_Entry",
    CHART_OF_ACCOUNTS: "Chart_Of_Accounts",

    REPORT: 'Reports',
    SETTINGS: 'Settings',
    DOWNLOAD: 'Downloads',
  },


  // ===================Actions=============================
  actions: {
    NEW: "new",
    ADD: "add",
    EDIT: "edit",
    SEND: "send",
    VIEW: "view",
    CLICK: "click",
    CLONE: "clone",
    PRINT: "print",
    IMPORT: 'import',
    EXPORT: 'export',
    REFUND: "refund",
    LOGOUT: "logout",
    DELETE: "delete",
    CREATE: "create",
    ADVANCE: "advance",
    APPROVE: 'approve',
    HISTORY: "history",
    SIGN_UP: "sign_up",
    PREVIEW: "preview",
    SIGN_IN: "sign_in",
    DOWNLOAD: "download",
    GENERATE: "generate",
    MARK_SENT: "mark_sent",
    REFUND_HISTORY: "refund_history",
    MAKE_RECURRING: "make_recurring",
    RECORD_PAYMENT: 'record_payment',
    REVERT_TO_OPEN: "revert_to_open",
    SOCIAL_CONNECT: "social_connect",
    RESET_PASSWORD: "reset_password",
    MARK_AS_RETURNED: "mark_as_returned",
    MARK_AS_DELIVERED: "mark_as_delivered",


userAuthentication:{
  action:{
    OPEN_EMAIL: "open_email",
    ACCOUNT_CREATION: "account_creation",
    EMAIL_VERIFICATION:'email_verification'
  },
method:{
  EMAIL:'email',
  GOOGLE:'google',
}
},
    dashboard: {
      graph_name: {
        CASH_FLOW: 'cash_flow',
        TOP_SALES: 'top_sales',
        TOP_EXPENSE: 'top_expenses',
        TOP_CUSTOMER: 'top_customers',
        INCOME_AND_EXPENSE: 'income_and_expenses',
      }
    },

    item: {
      filter_type: {
        ALL: 'all',
        GOODS: 'goods',
        SERVICES: 'service',
      },
      action: {
        SEARCH_HSN: 'search_hsn',
        SELECT_HSN: 'select_hsn',
        MANAGE_STOCK: 'manage_stock',
        STOCK_HISTORY: 'stock_history',
        ITEM_OVERVIEW: 'item_overview',
        ITEM_TRANSACTIONS: 'item_transactions',
      }
    },
    customer: {
      filter_type: {
        ALL: 'all',
        ACTIVE: 'active',
        INACTIVE: 'inactive',
      },
      action: {
        STATEMENT: 'statement',
        INFORMATION: 'information',
        TRANSACTION: 'transactions',
        CREATE_INVOICE: 'create_invoice',
      },
      switch: {
        MARK_ACTIVE: 'mark_active',
        MARK_INACTIVE: 'mark_inactive',
      }
    },
    vendor: {
      filter_type: {
        ALL: 'all',
        ACTIVE: 'active',
        INACTIVE: 'inactive',
      },
      action: {
        STATEMENT: 'statement',
        INFORMATION: 'information',
        CREATE_BILL: 'create_bill',
        TRANSACTION: 'transactions',
      },
      switch: {
        MARK_ACTIVE: 'mark_active',
        MARK_INACTIVE: 'mark_inactive',
      }
    },
    estimate: {
      action: {
        CONVERT_INVOICE: 'convert_to_invoice',
        CONVERT_SALES_ORDER: 'convert_to_sales_order',
      },
      filter_type: {
        ALL: 'all',
        SENT: 'sent',
        UNSENT: 'unsent',
        CLOSED: 'closed',
      }

    },
    salesOrder: {
      action: {
        CONVERT_INVOICE: 'convert_to_invoice',
      },
      filter_type: {
        ALL: 'all',
        SENT: 'sent',
        UNSENT: 'unsent',
        CLOSED: 'closed',
      }

    },
    deliveryChallan: {
      filter_type: {
        ALL: 'all',
        OPEN: 'open',
        CLOSED: 'closed',
        RETURNED: 'returned',
        DELIVERED: 'delivered',
      },
      action: {
        CONVERT_INVOICE: 'convert_to_invoice',
        RECORD_PARTIALLY_RETURNED: 'record_partially_returned',
        
      }
    },
    receivedInvoice: {
      state: {
        SAVE: 'save',
        DRAFT: 'draft'
      },
      action: {
        VIEW: 'view',
        CREDITS_APPLIED: 'credits_applied',
        PAYMENT_HISTORY: 'payment_history',
        INVOICE_REMINDER: 'invoice_reminder',
      },
      filter_type: {
        ALL: 'all',
        DRAFT: 'draft',
        UNPAID: 'unpaid',
        PRO_FORMA_INVOICE: 'pro_forma_invoice',
      },
      type: {
        INVOICE: 'invoice',
        PRO_FORMA_INVOICE: 'pro_forma_invoice',

      }
    },
    invoice: {
      state: {
        SAVE: 'save',
        DRAFT: 'draft'
      },
      action: {
        VIEW: 'view',
        CREDITS_APPLIED: 'credits_applied',
        PAYMENT_HISTORY: 'payment_history',
        INVOICE_REMINDER: 'invoice_reminder',
      },
      filter_type: {
        ALL: 'all',
        DRAFT: 'draft',
        UNPAID: 'unpaid',
        PRO_FORMA_INVOICE: 'pro_forma_invoice',
      },
      type: {
        INVOICE: 'invoice',
        PRO_FORMA_INVOICE: 'pro_forma_invoice',

      }
    },
    recurringInvoice: {
      filter_type: {
        ALL: 'all',
        ACTIVE: 'active',
        EXPIRED: 'expired',
        INACTIVE: 'inactive',
      },
      action: {
        ACTIVATE: 'activate',
        DEACTIVATE: 'deactivate',
      }
    },
    creditNote: {
      state: {
        SAVE: 'save',
        DRAFT: 'draft'
      },
      action: {
        CREDITS_APPLIED: 'credits_applied',
      },
      filter_type: {
        ALL: 'all',
        SENT: 'sent',
        DRAFT: 'draft',
        UNSENT: 'unsent',
        CLOSED: 'closed',
        PARTIALLY_UTILIZED: 'partially_utilized',
      }

    },
    expense: {
      action: {
        CONVERT_INVOICE: 'convert_to_invoice',
      },
      filter_type: {
        ALL: 'all',
        INVOICED: 'invoiced',
        UN_BILLED: 'unbilled',
        REIMBURSED: 'reimbursed',
        NON_BILLABLE: 'non_billable',
      }

    },
    recurringExpense: {
      filter_type: {
        ALL: 'all',
        ACTIVE: 'active',
        EXPIRED: 'expired',
        INACTIVE: 'inactive',
      },
      action: {
        ACTIVATE: 'activate',
        DEACTIVATE: 'deactivate',
      }
    },
    purchaseOrder: {
      action: {
        CONVERT_BILL: 'convert_to_bill',
      },
      filter_type: {
        ALL: 'all',
        SENT: 'sent',
        UNSENT: 'unsent',
        CLOSED: 'closed',
      }

    },
    bill: {
      state: {
        SAVE: 'save',
        DRAFT: 'draft'
      },
      action: {
        VIEW: 'view',
        CREDITS_APPLIED: 'credits_applied',
        PAYMENT_HISTORY: 'payment_history',
        INVOICE_REMINDER: 'invoice_reminder',
      },
      filter_type: {
        ALL: 'all',
        DRAFT: 'draft',
        UNPAID: 'unpaid',
      },

    },
    debitNote: {
      state: {
        SAVE: 'save',
        DRAFT: 'draft'
      },
      action: {
        CREDITS_APPLIED: 'credits_applied',
      },
      filter_type: {
        ALL: 'all',
        SENT: 'sent',
        DRAFT: 'draft',
        UNSENT: 'unsent',
        CLOSED: 'closed',
        PARTIALLY_UTILIZED: 'partially_utilized',
      }

    },
    journalEntry: {
      type: {
        ACCOUNT: 'account',
        DESCRIPTION: 'description',
      },
      filter_type: {
        ALL: 'all',
        DRAFT: 'draft',
        POSTED: 'posted',
      },
      state: {
        SAVE: 'save',
        DRAFT: 'draft'
      },

    },

    chartOfAccount: {
      type: {
        ACCOUNT: 'account',
        PARENT_ACCOUNT: 'parent_account'
      },
      filter_type: {
        ALL: 'all',
        ASSET: 'asset',
        EQUITY: 'equity',
        INCOME: 'income',
        EXPENSE: 'expense',
        LIABILITY: 'liability',
      },
      action: {
        VIEW_PARENT_ACCOUNT: 'view_parent_account',
        MANAGE_CHART_OF_ACCOUNTS: 'manage_chart_of_accounts',

      },
    },

    insight: {
      type: {
        TAB: 'tab',
        SECTION: 'section'
      }
    },
    reports: {
      // Financial Statements
      CASH_FLOW: "cash_flow",
      BALANCE_SHEET: "balance_sheet",
      PROFIT_AND_LOSS: "profit_and_loss",
      MOMENT_OF_EQUITY: "moment_of_equity",
      HORIZONTAL_BALANCE_SHEET: "horizontal_balance_sheet",
      HORIZONTAL_PROFIT_AND_LOSS: "horizontal_profit_and_loss",
      BUSINESS_PERFORMANCE_RATIOS: 'business_performance_ratios',

      // Taxes
      TAX_SUMMARY: 'tax_summary',
      TDS_SUMMARY: 'tds_summary',
      TCS_SUMMARY: 'tcs_summary',

      // Sales
      SALES_BY_ITEM: "sales_by_item",
      INVOICE_DETAILS: "invoice_details",
      AR_AGING_SUMMARY: "ar_aging_summary",
      AR_AGING_DETAILS: "ar_aging_details",
      ESTIMATE_DETAILS: "estimate_details",
      SALES_BY_CUSTOMER: "sales_by_customer",
      CUSTOMER_BALANCES: "customer_balances",
      INCOME_BY_CUSTOMER: "income_by_customer",
      SALES_ORDERS_DETAILS: "sales_orders_details",
      SALES_BY_SALES_PERSON: "sales_by_sales_person",
      DELIVERY_CHALLAN_DETAILS: "delivery_challan_details",
      RECURRING_INVOICE_DETAILS: "recurring_invoice_details",

      // Purchases
      BILL_DETAILS: "bill_details",
      VENDOR_BALANCES: "vendor_balances",
      EXPENSE_DETAILS: "expense_details",
      PURCHASE_BY_ITEM: "purchase_by_item",
      AP_AGING_SUMMARY: "ap_aging_summary",
      AP_AGING_DETAILS: "ap_aging_details",
      PURCHASE_BY_VENDOR: "purchase_by_vendor",
      PURCHASE_ORDERS_DETAILS: "purchase_orders_details",
      RECURRING_EXPENSE_DETAILS: "recurring_expense_details",

      // Payments
      PAYMENTS_MADE: "payments_made",
      VENDORS_REFUND: "vendors_refund",
      TIME_TO_GET_PAID: "time_to_get_paid",
      CUSTOMERS_REFUND: "customers_refund",
      PAYMENTS_RECEIVED: "payments_received",
      DEBIT_NOTE_DETAILS: "debit_note_details",
      CREDIT_NOTE_DETAILS: "credit_note_details",

      // Accountant
      DAY_BOOK: "day_book",
      TRIAL_BALANCE: "trial_balance",
      JOURNAL_REPORTS: "journal_reports",
      GENERAL_LEDGERS: "general_ledgers",
      ACCOUNT_TRANSACTIONS: "account_transactions",

      // Activity
      ACTIVITY_LOGS: 'activity_logs',

      // Inventory
      INVENTORY: "inventory",
    },
    setting: {
      REMINDERS: 'reminders',
      USER_PROFILE: 'user_profile',
      DELETE_ACCOUNT: 'delete_account',
      USER_MANAGEMENT: 'user_management',
      OPENING_BALANCE: 'opening_balance',
      BUSINESS_PROFILE: 'business_profile',



      ITEM_PREFERENCE: 'item_preference',
      VENDOR_PREFERENCE: 'vendor_preference',
      GENERAL_PREFERENCE: 'general_preference',
      CUSTOMER_PREFERENCE: 'customer_preference',
      VENDOR_NOTES_PREFERENCE: 'vendor_notes_preference',
      BANK_ACCOUNT_PREFERENCE: 'bank_account_preference',
      CUSTOMER_NOTES_PREFERENCE: 'customer_notes_preference',
      SALES_TERMS_AND_CONDITIONS_PREFERENCE: 'sales_terms_and_condition_preference',
      PURCHASE_TERMS_AND_CONDITIONS_PREFERENCE: 'purchase_terms_and_condition_preference',
    },

    sidebar: {
      ITEMS: 'items',
      INSIGHT: 'insight',
      REPORTS: 'reports',
      SETTINGS: 'settings',
      SUPPORT:'support',
      DOWNLOAD: 'downloads',
      DASHBOARD: 'dashboard',
      GST_FILING: 'gst_filing',
      STATEMENT_UPLOAD: 'statement_upload',
      SOURCE_TRANSACTIONS: 'source_transactions',


      SALES: {
        INVOICES: "invoices",
        CUSTOMERS: "customers",
        ESTIMATES: "estimates",
        CREDIT_NOTES: "credit_notes",
        SALES_ORDERS: "sales_orders",
        DELIVERY_CHALLANS: "delivery_challans",
        PAYMENTS_RECEIVED: "payments_received",
        RECURRING_INVOICES: "recurring_invoices",
      },
      PURCHASE: {
        BILLS: "bills",
        VENDORS: "vendors",
        EXPENSES: "expenses",
        DEBIT_NOTE: "debit_notes",
        PAYMENTS_MADE: "payments_made",
        PURCHASE_ORDERS: "purchase_orders",
        RECURRING_EXPENSES: "recurring_expenses",
      },
      ACCOUNTING: {
        JOURNAL_ENTRY: "journal_entry",
        CHART_OF_ACCOUNTS: "chart_of_accounts",
      },
      RECEIVEDDOCUMENT: {
        INVOICE: "invoice",
      },
    },

  },

};


