import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { AnalyticsEvent, eventsNames } from '../../../../firebase/firebaseAnalytics';
import { getBusinessPreferenceData, updateUserPreferencesData } from '../../setting/settings/Preferences/common/getPreferences';

import {
    setLoader,
    stateChangeManager,
    get_party_contact_name,
} from '../../../../utils/Utils';

import {
    getToken,
    clearCookie,
    setLoginStatus,
    getUserDetails,
    setBusinessInfo,
    removeSessionStorage,
    getSessionStorage,
} from '../../../../config/cookiesInfo';

import {
    get_list_of_business,
    retrieve_business,
    receive_invoice_in_business,
} from '../../../../api/urls';

import {
    Box,
    List,
    Modal,
    Button,
    Avatar,
    Divider,
    ListItem,
    ListItemText,
} from '@mui/material';


const initial_state = {
    title: "",
    open: false,
    condition: "",
    maxWidth: "lg",
    fullWidth: true,
}
export default function SelectBusiness({ show, data }) {
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [open, setOpen] = React.useState(false);
    const [businessList, setBusinessList] = React.useState([]);
    const [state, setState] = React.useState({ ...initial_state });

    const fetchBusiness = async () => {
        try {
            const response = await apiAction({ method: 'get', url: get_list_of_business() });
            if (response?.success) {
                setBusinessList(response.result || []);
            }
        } catch (error) {
            console.error('Failed to fetch businesses:', error);
        }
    };

    const addDocumentInBusiness = async (business_id, link_uid) => {
        try {
            const response = await apiAction({ method: 'post', url: receive_invoice_in_business(), data: { business_id: business_id, link_uid: link_uid } });
            if (response?.success) {
                let invoice_id = response?.result?.id;
                getBusinessInfo(business_id, invoice_id);
                updateUserPreferencesData(business_id, navigate, dispatch);
                if (response?.status) {
                    stateChangeManager(dispatch, Actions, true, 'success', response?.status);
                }
            } else {
                fetchBusiness();
                setState({ ...initial_state });
                setLoader(dispatch, Actions, false);
                stateChangeManager(dispatch, Actions, true, 'error', response?.status);

            }
        } catch (error) {
            console.error('Failed to fetch businesses:', error);
        }

    }

    const getBusinessInfo = async (id, invoice_id) => {
        const businessRes = await apiAction({
            method: 'get',
            url: retrieve_business(id),
        });

        if (businessRes?.success) {
            setLoginStatus('true');
            setLoader(dispatch, Actions, false);
            setBusinessInfo(businessRes?.result);
            removeSessionStorage('receivedInvoice');
            getBusinessPreferenceData(businessRes?.result?.id);
            navigate(routesName.receivedInvoiceView.path + "?id=" + invoice_id, { replace: true });
        }
    }

    React.useEffect(() => {
        if (show && getToken()) {
            setOpen(true);
            fetchBusiness();
        } else {
            navigate(-1)
        }
    }, [show]);

    const onClickAddBusiness = () => {
        setState({
            open: true,
            maxWidth: "sm",
            fullWidth: true,
            title: "Add New Business Account",
            is_receive_invoice_in_business: true,
            condition: "create_business_account",
            onSuccess: (data) => { handleBusinessSelect(data) }
        });
    };

    const handleBusinessSelect = (businessInfo) => {
        addDocumentInBusiness(businessInfo.id, data?.link_uid)
    };

    const handleSignout = () => {
        clearCookie(false)
        setLoader(dispatch, Actions, false);
        navigate(`/auth${routesName.signIn.path}`, { state: { link_uid: data?.link_uid }, replace: true })
    }
    return (
        <div>
            <CustomDialog state={state} setState={setState} />

            <Modal open={open} sx={{ border: 'none' }}>
                <Box sx={styles.modalContainer}>

                    <UserInfo handleSignout={handleSignout} />

                    <Divider />
                    <h2 style={styles.modalTitle}>Choose a Business</h2>
                    <Divider />

                    <List sx={{ height: 'calc(100% - 190px)', overflow: 'auto' }}>
                        {businessList.map((business, index) => (
                            <BusinessListItem
                                key={index}
                                index={index}
                                business={business}
                                onSelect={() => handleBusinessSelect(business)}
                            />
                        ))}
                    </List>

                    <Box sx={styles.footer} onClick={() => { onClickAddBusiness() }}>
                        <Divider />
                        <CustomTypography
                            sx={styles.addBusiness}
                            text={'+ Add New Business'}
                        />
                    </Box>
                </Box>
            </Modal>

        </div>
    );
}

// Business list item component
const BusinessListItem = ({ business, onSelect, index }) => (
    <>
        <ListItem
            alignItems="flex-start"
            sx={styles.listItem}
            onClick={onSelect}
            id={`legal_name_select_${index}`}
            dataTestId={`legal_name_select_${index}`}
        >
            <ListItemText
                primary={<strong>{business.legal_name}</strong>}
                secondary={`— ${business.industry_name || 'N/A'}`}
            />
        </ListItem>
        <Divider />
    </>
);

const UserInfo = ({ handleSignout }) => {
    const user = getUserDetails();
    const navigate = useNavigate();
    const sessionStorage = getSessionStorage('receivedInvoice');

    const userName = get_party_contact_name(user) || user?.name;
    const userEmail = user?.email;

    return (
        <Box sx={{ p: 1, pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar />
                <Box sx={{ ml: 1 }}>
                    <CustomTypography text={<span>{userName}</span>} sx={styles.userName} />
                    <CustomTypography text={userEmail} sx={styles.userEmail} />
                </Box>
            </Box>
            <Box>
                <Button id={'step_cancel_btn'} dataTestId={'step_cancel_btn'} onClick={() => { navigate(routesName.customerInvoice.path + `/?t=${sessionStorage?.link_uid}`, { replace: true }); removeSessionStorage('receivedInvoice') }} style={{ ':hover': { backgroundColor: '#e8f3ff', }, backgroundColor: 'rgb(232 243 255 / 25%)', marginRight: '10px', border: 'none', paddingLeft: '10px', paddingRight: '10px', borderRadius: 3, color: '#2464EB', textTransform: 'none', cursor: 'pointer', }}>Cancel</Button>
                <Button id={'log_out_btn'} dataTestId={'log_out_btn'} onClick={() => { handleSignout(); AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.LOGOUT }) }} style={{ ':hover': { backgroundColor: '#e8f3ff', }, backgroundColor: 'rgb(232 243 255 / 25%)', border: 'none', paddingLeft: '10px', paddingRight: '10px', borderRadius: 3, color: '#D93E06', textTransform: 'none', cursor: 'pointer', }}> Sign Out</Button>
            </Box>
        </Box>
    );
}
// Styles object
const styles = {
    modalContainer: {
        pt: 2,
        pb: 1,
        top: '50%',
        left: '50%',
        boxShadow: 24,
        width: '100%',
        border: 'none',
        height: '85vh',
        maxWidth: '600px',
        position: 'absolute',
        bgcolor: 'background.paper',
        transform: 'translate(-50%, -50%)',
    },
    modalTitle: {
        marginTop: '10px',
        textAlign: 'center',
    },
    listItem: {
        cursor: 'pointer',
        ':hover': { backgroundColor: '#e8f3ff', },
    },
    footer: {
        bottom: 0,
        left: '50%',
        width: '100%',
        cursor: 'pointer',
        textAlign: 'center',
        position: 'absolute',
        transform: 'translateX(-50%)',
        '&:hover': { backgroundColor: '#e8f3ff' }
    },
    addBusiness: {
        mt: 2,
        mb: 2,
        fontWeight: 700,
        color: '#2464EB',
        fontSize: '14px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        fontFamily: 'Noto Sans',
        textOverflow: 'ellipsis',
    },
};


const data1 = [
    {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    }, {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    },
    {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    }, {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    },
    {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    }, {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    },
    {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    }, {
        "id": 27,
        "legal_name": "Test Account",
        "created_at": "2024-11-25T09:29:40.076612Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "Interior Design",
        "logo_url": "",
        "is_default": true
    },
    {
        "id": 24,
        "legal_name": "Albedo",
        "created_at": "2024-11-14T09:58:21.765805Z",
        "fiscal_year": 3,
        "integration_mode": "numbers",
        "industry_name": "IT and Technology",
        "logo_url": "",
        "is_default": false
    },
]
