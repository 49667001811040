import React from "react";
import { amountFormatter, formatterWithoutSymbol } from "../../../utils/Utils";

export const CustomDivInput = ({
    id,
    onBlur,
    onFocus,
    onChange,
    onKeyPress,
    dataTestId,
    value = "",
    type = "text",
    currency_code,
    editable = true,
    is_currency_symbol,
    isShowCommaSeparatedValue,
}) => {
    const divRef = React.useRef(null);
    const [divWidth, setDivWidth] = React.useState(null);
    const [currentValue, setCurrentValue] = React.useState(value);

    const saveCursorPosition = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount || !divRef.current) return null;

        const range = selection.getRangeAt(0);
        const cursorOffset = range.startOffset;

        if (type === "number") {
            const numericContent = divRef.current.textContent.replace(/[^0-9.]/g, "");
            const offset = Math.min(cursorOffset, numericContent.length); // Prevent overflow
            return { start: offset, end: offset };
        }

        return { start: cursorOffset, end: range.endOffset };
    };

    const restoreCursorPosition = (start, end) => {
        const selection = window.getSelection();
        if (divRef.current && start != null && end != null) {
            const content = divRef.current.textContent;

            if (type === "number") {
                const numericContent = content.replace(/[^0-9.]/g, "");
                const adjustedStart = Math.min(start, numericContent.length);
                const adjustedEnd = Math.min(end, numericContent.length);
                start = adjustedStart;
                end = adjustedEnd;
            }

            const range = document.createRange();
            range.setStart(divRef.current.firstChild || divRef.current, start);
            range.setEnd(divRef.current.firstChild || divRef.current, end);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    const handleInput = () => {
        const cursorPosition = saveCursorPosition();
        const textContent = divRef.current.textContent;

        if (type === "number") {
            const numericValue = textContent.replace(/[^0-9.]/g, "");
            if (textContent !== numericValue) {
                divRef.current.textContent = numericValue;
            }
        }

        setCurrentValue(divRef.current.textContent);

        if (cursorPosition) {
            restoreCursorPosition(cursorPosition.start, cursorPosition.end);
        }
    };

    const handleEvent = (eventHandler) => {
        if (eventHandler) {
            eventHandler({ target: { value: divRef.current.textContent } });
        }
    };

    const formattedValue = React.useMemo(() => {
        if (isShowCommaSeparatedValue && !editable && value) {
            return is_currency_symbol
                ? amountFormatter(value, currency_code)
                : formatterWithoutSymbol(currency_code, value);
        }
        return currentValue;
    }, [editable, isShowCommaSeparatedValue, value, is_currency_symbol, currency_code, currentValue]);

    React.useEffect(() => {
        if (divRef.current && divRef.current.textContent !== formattedValue) {
            divRef.current.textContent = formattedValue;
        }
    }, [formattedValue]);

    React.useEffect(() => {
        const updateWidth = () => {
            if (divRef.current) {
                const computedStyle = getComputedStyle(divRef.current);
                setDivWidth(computedStyle.width);
            }
        };

        // Update width on mount and on window resize
        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);

    return (
        <div
            id={id}
            ref={divRef}
            onInput={handleInput}
            onFocus={() => handleEvent(onFocus)}
            onKeyPress={() => handleEvent(onKeyPress)}
            onBlur={() => {
                handleEvent(onBlur);
                handleEvent(onChange);
                // Scroll to start on blur
                if (divRef.current) {
                    divRef.current.scrollLeft = 0;
                }
            }}
            contentEditable={'true'}
            data-test-id={dataTestId}
            style={{
                padding: "8px",
                fontWeight: 700,
                display: 'flex',
                width: divWidth,
                overflow: 'auto',
                maxWidth: "100%",
                fontSize: "12px",
                minHeight: "16px",
                maxHeight: "16px",
                textAlign: "left",
                lineHeight: "16px",
                borderRadius: "4px",
                alignItems: 'center',
                color: "rgb(0, 0, 0)",
                fontFamily: "Noto Sans",
                border: "1px solid #ccc",
            }}
        />
    );
};
