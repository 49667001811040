import React, { useState } from 'react'
import { Button } from '@mui/material';
import { AnalyticsEvent } from '../../../firebase/firebaseAnalytics';
import { useLocation } from 'react-router-dom';
import { getUserDetails } from '../../../config/cookiesInfo';

const CustomButton = (
    {
        id,
        sx,
        icon,
        style,
        onClick,
        className,
        dataTestId,
        variant = '',
        onMouseDown,
        size = "small",
        timeoutValue = 1000,
        btnLabel = 'CustomButton',
        fullWidth = false,
        ...props }
) => {
    const location = useLocation()
    const [disabled, setDisabled] = useState(false)
    // console.log("location pathname", )
    let custom_btn_id = btnLabel && typeof (btnLabel) === "string" ? btnLabel.toLowerCase().replace(/ /g, "_") : "";
    return (
        <Button
            {...props}
            size={size}
            variant={variant}
            fullWidth={fullWidth}
            className={className}
            dataTestId={dataTestId}
            onMouseDown={onMouseDown}
            id={id ? id : custom_btn_id}
            disabled={props.disabled ? props.disabled : disabled}
            style={{ textTransform: 'none', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '700', fontSize: '12px', lineHeight: '16px', p: 1.3, '&:hover': { backgroundColor: variant === 'outlined' ? '#e8f3ff' : '' }, ...style, }}
            sx={{ textTransform: 'none', height: '38px', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '700', fontSize: '12px', lineHeight: '16px', p: 1.3, '&:hover': { backgroundColor: variant === 'outlined' ? '#e8f3ff' : '' }, ...sx, }}
            onClick={(event) => {
                setDisabled(true)
                if (onClick) {
                    onClick(event);
                }
                setTimeout(() => setDisabled(false), timeoutValue);
            }}
        >
            {btnLabel}
            {icon}
        </Button>
    )
}


export default CustomButton