import React from 'react';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import EvaluateDataPoint from './EvaluateDataPoint';
import ManageSection from '../sections/ManageSection';
import { useIsElementVisible } from '../common/Helper';
import * as Actions from '../../../../../state/Actions';
import { ViewDataPoint } from '../common/CommonComponents';
import { CustomSkeleton } from '../common/CustomComponents';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { HtmlTooltip } from '../../../../custom/tooltip/CustomTooltip';
import { FullViewModeLineChart } from '../sections/sectionTypes/LineChart';
import CustomTypography from "../../../../custom/typography/CustomTypography";
import { BootstrapDialog, BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";

import {
  get_data_point,
  update_insight_section,
  evaluate_data_point_range,
} from '../../../../../api/urls';

import {
  isNumber,
  setLoader,
  stateChangeManager,
  getUniqueObjectsByKey,
  getSelectedGranularityData,
  formattedDataBasedGranularity,
} from '../../../../../utils/Utils';

import {
  Box,
  Popover,
  Divider,
  MenuItem,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PieChartView from '../sections/sectionTypes/PieChart';


export const DataPointComponent = (props) => {
  const { data_point_id, from_date, to_date, sectionData, setSectionData, refreshDataPoint } = props;

  const navigate = useNavigate();
  const dispatch = Actions.getDispatch(React.useContext);

  const elementRef = React.useRef(null);
  const [data, setData] = React.useState();
  const [view, setView] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(true);
  const [dataPointData, setDataPointData] = React.useState();
  const [refreshView, setRefreshView] = React.useState(false);
  const [lineChartData, setLineChartData] = React.useState([]);
  const [isItemVisible, setIsItemVisible] = React.useState(false);
  const [viewLineChart, setViewLineChart] = React.useState(false);
  const [isGrowthGraph, setIsGrowthGraph] = React.useState(false);
  const [granularity, setGranularity] = React.useState(getSelectedGranularityData('', from_date, to_date)?.selected);

  const setRefreshDataPoint = () => { setRefresh(!refresh) }

  const getDataPoint = async () => {
    setIsLoader(true);
    const res = await apiAction({
      url: get_data_point(data_point_id)
    })
      .then((value) => {
        if (value.success) {
          setIsLoader(false);
          setData(value.result);
          setDataPointData(value.result);
        } else {
          setIsLoader(false);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log('==>error', error)
      })

  }
  const isElementVisible = useIsElementVisible(elementRef.current);

  React.useEffect(() => {
    if (isElementVisible) {
      setIsItemVisible(true)
    }

  }, [isElementVisible]);

  React.useEffect(() => {
    if (isItemVisible && data_point_id) {
      getDataPoint();
    }
  }, [data_point_id, isItemVisible])

  const onRemoveDataPointFromSection = async (id) => {
    const formData = {
      business_id: getBusinessInfo().id,

      name: sectionData.name,
      tab_id: sectionData.tab_id,
      section_id: sectionData.id,
      data_point_ids: sectionData.data_point_ids.filter(val => val !== id)
    }

    let res = await apiAction({
      method: 'post',
      navigate: navigate,
      dispatch: dispatch,
      data: { ...formData, },
      url: update_insight_section(),

    });
    if (res?.success) {
      setSectionData({ ...res?.result, id: formData.section_id });
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }

  const onEditDataPoint = (value) => {
    setOpen(true)
    setAction(value)
  }
  React.useEffect(() => {
    if (data && isElementVisible) {
      setGranularity(getSelectedGranularityData('', from_date, to_date)?.selected);
    }
  }, [from_date, to_date]);

  React.useEffect(() => {
    if (data && isElementVisible && lineChartData.length && viewLineChart) {
      onClickViewLineChart(data_point_id);
    };
  }, [granularity]);

  const onClickViewLineChart = async (data_point_id) => {
    try {
      const item = await evaluateDataPoint(data_point_id);
      if (item?.success) {
        setLoader(dispatch, Actions, false);
        data['lineChartData'] = formattedDataBasedGranularity(item.result, granularity);
      }
      setViewLineChart(true);
      setLineChartData((previous) => getUniqueObjectsByKey([...previous, data]));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const evaluateDataPoint = async (data_point_id, isRefresh = false) => {
    try {
      const value = await apiAction({
        url: isRefresh ? `${evaluate_data_point_range(data_point_id, from_date, to_date, granularity)}&is_refresh=${true}` : evaluate_data_point_range(data_point_id, from_date, to_date, granularity),
      });

      return value?.success ? value : null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  let dataPointName = data ? data.account_type === 'composite' ? `${data.name.replace(' - Composite', '')} - Composite` : data.name : ''

  return (
    <Box ref={elementRef} id={"list_item_" + data_point_id}>
      <ManageSection
        open={open}
        action={action}
        setOpen={setOpen}
        data={sectionData}
        dataPointData={dataPointData}
        setData={(dataNew) => {
          if (action === 'clone_data_point') {
            setSectionData({ ...dataNew })
          } else {
            if (data?.id === dataNew?.id) {
              setData(dataNew);
              setRefreshDataPoint();
            }
            setDataPointData(dataNew);
            setRefreshView(!refreshView);
          }
        }
        }
      />

      <FullViewModeLineChart
        isGrowthGraph={isGrowthGraph}
        setIsGrowthGraph={setIsGrowthGraph}

        color={'#2464eb'}
        open={viewLineChart}
        data={lineChartData}
        setOpen={setViewLineChart}

        to_date={to_date}
        from_date={from_date}
        title={dataPointName}
        granularity={granularity}
        setGranularity={setGranularity}
      />

      <ViewDataPoint
        open={view}
        item={data}
        to_date={to_date}
        isDetails={true}
        setOpen={setView}
        from_date={from_date}
        refreshView={refreshView}
        setDataPointData={setDataPointData}
        onEdit={(value) => { onEditDataPoint(value) }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {isLoader ?
          <CustomSkeleton variant="text" sx={{ mr: 3, height: 40, width: '100%' }} />
          :
          <CustomTypography
            text={dataPointName ? dataPointName : 'The reference data point has been deleted.'}
            sx={{
              pr: 2,
              fontSize: '12px',
              fontWeight: '700',
              fontStyle: 'normal',
              lineHeight: 'normal',
              fontFamily: 'Noto Sans',
              color: dataPointName ? '#7A7A7A' : 'red',

              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          />
        }
        <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
          <div style={{ cursor: 'pointer' }} onClick={() => { setViewLineChart(true); onClickViewLineChart(data_point_id) }}>
            <LineChartComponent
              data={lineChartData}
            />
          </div>
          {data &&
            <EvaluateDataPoint
              to_date={to_date}
              from_date={from_date}
              unit={data && data.unit}
              data_point_id={data_point_id}

              refreshDataPoint={refresh}
              refreshDataPointAll={refreshDataPoint}
            />
          }
          {isLoader ?
            <CustomSkeleton variant="circular" width={30} height={30} />
            :
            data ?
              <ActionComponent
                data={data}
                to_date={to_date}
                from_date={from_date}
                refreshDataPoint={refreshDataPoint}
                onView={(value) => setView(value)}
                onEdit={(value) => { onEditDataPoint(value) }}
                onRemove={() => { onRemoveDataPointFromSection(data_point_id) }}
              />
              :
              <HtmlTooltip title={'Remove Data point'} placement="top">
                <IconButton aria-label="delete" sx={{ ":hover": { color: '#2464eb' } }} onClick={() => { onRemoveDataPointFromSection(data_point_id) }}>
                  <DeleteIcon sx={{ fontSize: '18px' }} />
                </IconButton>
              </HtmlTooltip>
          }
        </Box>
      </Box>
    </Box>

  )
}

const ActionComponent = (props) => {
  const { data, onEdit, onRemove, onView, to_date, from_date, refreshDataPoint } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPieChart, setOpenPieChart] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const actionList = [
    { name: 'View', onClick: () => { handleClose(); onView(true) } },
    data.is_custom && { name: 'Edit', onClick: () => { onEdit('edit_data_point'); handleClose() } },
    { name: 'Clone', onClick: () => { onEdit('clone_data_point'); handleClose() } },
    // { name: 'Pie chart', onClick: () => { setOpenPieChart(true); handleClose() } },
    { name: 'Remove', onClick: () => { onRemove(); handleClose() }, showDivider: true },
  ]

  const action_style = {
    p: 1,
    color: '#141414',
    fontWeight: 500,
    display: 'block',
    fontSize: '14px',
    cursor: 'pointer',
    lineHeight: '12px',
    textDecoration: 'none',
    ":hover": { backgroundColor: "#f5f5f5", }
  }
  return (
    <React.Fragment>
      <PieChartComponent
        data={data}
        open={openPieChart}
        setOpen={setOpenPieChart}
        to_date={to_date}
        from_date={from_date}
        refreshDataPoint={refreshDataPoint}
      />
      <IconButton aria-label="manage-data-point" sx={{ ":hover": { color: '#2464eb' } }} onClick={(event) => { handleClick(event); console.log('===>clicked') }} >
        <MoreVertIcon sx={{ fontSize: '18px' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            minWidth: 100,
            // minHeight: 60,

          },
        }}
      >
        {
          actionList.map((action, index) => {
            return action ? <React.Fragment key={index}>
              {
                action.showDivider ? <>
                  <Divider /><MenuItem id={'list_action_item_' + index} dataTestId={'list_action_item_' + index} disabled={action.disabled} sx={{ ...action_style, mt: actionList[0] ? -1 : 0, color: 'red' }} onClick={() => { if (action.onClick) { action.onClick() }; handleClose() }}>{action.name}</MenuItem>
                </>
                  :
                  <MenuItem
                    sx={{ ...action_style, }}
                    id={'list_action_item_' + index}
                    dataTestId={'list_action_item_' + index}
                    disabled={action.disabled}
                    onClick={() => {
                      handleClose();
                      if (action.onClick) { action.onClick() };
                    }}
                  >
                    {action.name}
                  </MenuItem>
              }</React.Fragment> : null
          })
        }
      </Popover>
    </React.Fragment>
  )
}

const LineChartComponent = ({ data }) => {
  const chartRef = React.useRef(null);
  const chartOptions = {
    chart: {
      type: 'line',
      width: 100,
      height: 40,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: false, // Hide the x-axis
    },
    yAxis: {
      visible: false, // Hide the y-axis
    },
    legend: {
      enabled: false, // Hide the legend
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false, // Hide data points markers
        },
        animation: {
          duration: 1000, // Animation duration
        },

        lineWidth: data?.length ? 2 : 2, // Adjust line width based on data presence
        color: data?.length ? '#2464eb' : '#dddddd', // Adjust line color based on data presence
        states: {
          hover: {
            enabled: false // Disable hover effect
          }
        },
        opacity: 1, // Adjust opacity based on data presence
      }
    },
    series:
      data?.length ?
        data?.map(item => ({
          showInLegend: false,
          data: item?.lineChartData?.map(entry => isNumber(entry.result) ? parseFloat(entry.result) : 0),
        }))
        :
        [
          {
            showInLegend: false,
            data: [0, 0, 0, 0, 0, -30, 30, -30, 60, -60, 30, -30, 0, 0, 0, 0, 0, 0],
          }
        ]
  };

  return (
    <div style={{ position: 'relative' }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />
    </div>
  );
}

const PieChartComponent = ({ open, setOpen, title, data, to_date, from_date, refreshDataPoint }) => {
  return (
    open &&
    <PieChartView
      mb={0}
      to_date={to_date}
      from_date={from_date}
      isFromListView={true}
      onClose={() => setOpen(false)}
      refreshDataPoint={refreshDataPoint}
      data={{ ...data, data_point_ids: [data?.id], name: '' }}
    />
  )
}

