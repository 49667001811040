import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../api/api';
import DatePicker from '../../../common/DatePicker';
import * as Actions from '../../../../state/Actions';
import { getBusinessInfo } from '../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../common/CurrencyFormatter';
import { getDateFormat, setLoader } from '../../../../utils/Utils';
import CustomTypography from '../../../custom/typography/CustomTypography';
import CommaSeparatedInputField from '../../../custom/input/CommaSeparatedInputField';

import {
    groupByAccountType,
    calculateTotalBalances
} from './helper';

import {
    chart_of_account_for_opening_balance
} from '../../../../api/urls';

import {
    CustomTable,
    CustomTableRow,
    CustomTableHead,
    CustomTableBody,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer
} from '../../../custom/table/CustomTable';

import {
    Box,
    Alert,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { opening_balance_adjustments } from '../../../../utils/Constant';

const Setup = ({ accounts, setAccounts }) => {
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const migration_date = getBusinessInfo()?.migration_date

    React.useEffect(() => {
        const fetchApiData = async () => {
            setLoader(dispatch, Actions, true);
            const businessId = getBusinessInfo().id;
            const chartRes = await apiAction({
                method: 'post',
                navigate, dispatch,
                data: { business_id: businessId },
                url: chart_of_account_for_opening_balance(),
                onError: () => setLoader(dispatch, Actions, false),
            });

            if (chartRes.success) {
                let groupAccount = groupByAccountType(chartRes.result);
                setAccounts(groupAccount);
            }
            setLoader(dispatch, Actions, false);
        };

        if (accounts?.length === 0) {
            fetchApiData();
        }
    }, [dispatch, navigate]);

    return (
        <div>
            <MigrationDateSelector migration_date={migration_date} />
            <ParentAccountList migration_date={migration_date} parentAccounts={accounts} setParentAccounts={setAccounts} />
            <CalculationList accounts={accounts} setValidation={() => { }} />
        </div>
    );
};

export default Setup;

const MigrationDateSelector = ({ migration_date }) => (
    <div style={{ padding: '0 24px 20px' }}>
        <Box sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center' }}>
            <CustomTypography
                text={migration_date && `Migration Date: ${moment(migration_date).format(getDateFormat())}`}
                sx={{
                    color: '#000000',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '18px',
                    fontStyle: 'normal',
                    fontFamily: "Noto Sans",
                    whiteSpace: 'nowrap'
                }}
            />
            <Alert sx={{ ml: { xs: -3, sm: 1 }, opacity: 0.8, color: '#000', fontSize: '12px', fontWeight: 700, background: '#fff', fontFamily: 'Noto Sans', display: 'flex', alignItems: { xs: 'start', sm: 'center' } }} severity="info">
                The date on which you generated the Trial Balance report in your previous accounting software while migrating to finycs.
            </Alert>
        </Box>
    </div>
);

const ParentAccountList = ({ migration_date, parentAccounts, setParentAccounts }) => {
    const updateParentAccount = React.useCallback((index, updatedAccount) => {
        const updatedAccounts = [...parentAccounts];
        updatedAccounts[index] = updatedAccount;
        setParentAccounts(updatedAccounts);
    }, [parentAccounts, setParentAccounts]);

    return (
        <>
            {parentAccounts.map((account, index) => (
                <AccountAccordion
                    key={index}
                    index={index}
                    account={account}
                    migration_date={migration_date}
                    updateAccount={updateParentAccount}
                />
            ))}
        </>
    );
};

const AccountAccordion = ({ index, account, migration_date, updateAccount }) => (
    <Box style={{ border: '5px solid #F5F5F5' }}>
        <Accordion square elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <CustomTypography text={account.name.capitalize()} sx={{ fontWeight: 700, fontSize: '14px', fontFamily: 'Noto Sans' }} />
            </AccordionSummary>
            <AccordionDetails>
                <CustomTableContainer>
                    <CustomTable>
                        <OpeningBalanceHeader />
                        <OpeningBalanceBody
                            account={account}
                            parent_index={index}
                            updateAccount={updateAccount}
                            migration_date={migration_date}
                        />
                    </CustomTable>
                </CustomTableContainer>
            </AccordionDetails>
        </Accordion>
    </Box>
);

const CalculationList = ({ accounts }) => {
    if (!accounts.length) return null;

    // Update destructuring to match the keys returned from calculateTotalBalances
    const { totalDebit, totalCredit, totalAmount, adjustmentAmount } = calculateTotalBalances(accounts);

    // Define the calculation rows as an array of objects
    const calculationRows = [
        {
            align: 'right',
            title: 'Total',
            debit: <CurrencyFormatter amount={totalDebit} />,
            credit: <CurrencyFormatter amount={totalCredit} />,
        },
        {
            align: 'right',
            color: '#e24b70',
            borderBottom: '1px solid #FFE799',
            title: 'Opening Balance Adjustments',
            title2: 'This account will hold the difference in credit and debit.',
            debit: totalDebit < totalCredit ? <CurrencyFormatter amount={adjustmentAmount} /> : '-',
            credit: totalCredit < totalDebit ? <CurrencyFormatter amount={adjustmentAmount} /> : '-',
        },
        {
            align: 'right',
            title: 'Total Amount',
            title2: 'Includes Opening Balance Adjustment account.',
            debit: <CurrencyFormatter amount={totalAmount} />,
            credit: <CurrencyFormatter amount={totalAmount} />,
        },
    ];

    const renderCalculationRow = ({ title, title2, debit, credit, color = '#000000', borderBottom = 'none' }) => (
        <CustomTableRow key={title}>
            <Calculation
                color={color}
                title={title}
                debit={debit}
                credit={credit}
                title2={title2}
                borderBottom={borderBottom}
            />
        </CustomTableRow>
    );

    return (
        <CustomTableContainer sx={{ background: '#FFF7DC' }}>
            <CustomTable sx={{ mb: 3 }}>
                <CustomTableBody>
                    {calculationRows.map(renderCalculationRow)}
                </CustomTableBody>
            </CustomTable>
        </CustomTableContainer>
    );
};

const OpeningBalanceHeader = () => (
    <CustomTableHead>
        <CustomTableRow>
            <CustomTableHeadCell style={{ width: 300 }}>Accounts</CustomTableHeadCell>
            <CustomTableHeadCell style={{ width: 200 }} align="right">As of</CustomTableHeadCell>
            <CustomTableHeadCell style={{ width: 200 }} align="right">Debit</CustomTableHeadCell>
            <CustomTableHeadCell style={{ width: 200 }} align="right">Credit</CustomTableHeadCell>
        </CustomTableRow>
    </CustomTableHead>
);

const OpeningBalanceBody = ({ migration_date, account, parent_index, updateAccount }) => {
    const [localTransactions, setLocalTransactions] = React.useState(
        account?.transactions.map((item) => ({ ...item, debit: item.debit || '', credit: item.credit || '', opening_balance_date: item.opening_balance_date || null, }))
    );

    function hasTransactionChanged(transaction) {
        if (!transaction) return false;

        const { journal_entry_id, initial_adjusted_transaction_type, adjusted_transaction_type, adjusted_amount, debit, credit } = transaction;


        // Case 1: If journal_entry_id is present
        if (journal_entry_id) {

            // Return true if transaction types are different
            if (initial_adjusted_transaction_type !== adjusted_transaction_type) {
                return true;
            }

            // Check if the adjusted amount has changed based on the transaction type
            const isAdjustedAmountDifferent = initial_adjusted_transaction_type === adjusted_transaction_type
                ? Number(debit) !== adjusted_amount
                : Number(credit) !== adjusted_amount;

            return adjusted_amount ? isAdjustedAmountDifferent : true;
        }

        // Case 2: If journal_entry_id is not present, check if either credit or debit is non-empty
        return credit !== "" || debit !== "";
    }



    const handleBlur = React.useCallback((index, updated) => {
        const transaction = updated ? updated[index] : localTransactions[index];

        const hasChanged = hasTransactionChanged(transaction)

        console.log('===>hasChanged', hasChanged)
        const updatedAccount = { ...account };
        updatedAccount.transactions[index] = {
            ...updatedAccount.transactions[index],
            hasChanged: hasChanged,
            debit: transaction.debit,
            credit: transaction.credit,
            opening_balance_date: transaction.opening_balance_date,
            adjusted_transaction_type: transaction.debit ? 'DEBIT' : 'CREDIT',
        };
        updateAccount(parent_index, updatedAccount);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localTransactions, account, updateAccount]);

    return (
        <CustomTableBody>
            {account?.transactions.map((item, i) => item.account_name !== opening_balance_adjustments && (
                <TransactionRow
                    key={i}
                    index={i}
                    migration_date={migration_date}
                    accountName={item.account_name}
                    transaction={localTransactions[i]}
                    setTransaction={setLocalTransactions}
                    onBlur={(value) => value ? handleBlur(i, value) : handleBlur(i)}
                />
            ))}
        </CustomTableBody>
    );
};

const TransactionRow = ({ index, accountName, transaction, migration_date, onBlur, setTransaction }) => {
    return (
        <CustomTableRow>
            <CustomTableBodyCell>{accountName}</CustomTableBodyCell>
            <CustomTableBodyCell align="right">
                <DatePicker
                    disabledCloseIcon
                    no_minDate={false}
                    onBlur={() => onBlur()}
                    id={`open_bal_date_${index}`}
                    minDate={moment(migration_date)}
                    date={moment(transaction.opening_balance_date)}
                    disabledDatePicker={Boolean(transaction.journal_entry_id)}
                    setDate={(date) => {
                        setTransaction(trans => {
                            const updated = [...trans];
                            updated[index] = { ...updated[index], 'opening_balance_date': moment(date).format('YYYY-MM-DD') };
                            onBlur(updated);
                            return updated;
                        })
                    }}
                />
            </CustomTableBodyCell>
            <CustomTableBodyCell align="right">
                <CommaSeparatedInputField
                    onBlur={() => onBlur()}
                    id={`debit_${index}`}
                    value={transaction.debit}
                    onChange={(value) => {
                        setTransaction(trans => {
                            const updated = [...trans];
                            updated[index] = { ...updated[index], 'adjusted_transaction_type': 'DEBIT', 'debit': value, 'credit': '' };
                            return updated;
                        })
                    }}
                />
            </CustomTableBodyCell>
            <CustomTableBodyCell align="right">
                <CommaSeparatedInputField
                    onBlur={() => onBlur()}
                    id={`credit_${index}`}
                    value={transaction.credit}
                    onChange={(value) => {
                        setTransaction(trans => {
                            const updated = [...trans];
                            updated[index] = { ...updated[index], 'adjusted_transaction_type': 'CREDIT', 'credit': value, 'debit': '' };
                            return updated;
                        })
                    }}
                />
            </CustomTableBodyCell>
        </CustomTableRow>
    )
};

const Calculation = ({ title, title2, align = 'right', color = '#000000', borderBottom = 'none', debit, credit }) => {
    const cellStyles = {
        borderBottom,
    };

    const typographyStyles = {
        mr: 3,
        color,
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontFamily: "Noto Sans",
    };

    return (
        <>
            <CustomTableBodyCell align={align} style={cellStyles} width={300}>
                <CustomTypography
                    text={title}
                    sx={typographyStyles}
                />
                {title2 && (
                    <CustomTypography
                        text={title2}
                        sx={{
                            ...typographyStyles,
                            fontSize: '10px',
                            fontWeight: '500',
                            color: '#000000',
                        }}
                    />
                )}
            </CustomTableBodyCell>
            <CustomTableBodyCell align={align} style={cellStyles} width={200}>
                <CustomTypography
                    text={debit}
                    id="total_debit"
                    dataTestId="total_debit"
                    sx={typographyStyles}
                />
            </CustomTableBodyCell>
            <CustomTableBodyCell align={align} style={cellStyles} width={200}>
                <CustomTypography
                    text={credit}
                    id="total_credit"
                    sx={typographyStyles}
                    dataTestId="total_credit"
                />
            </CustomTableBodyCell>
        </>
    );
};

