import React from 'react'
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import DateRange from '../../../../common/DateRange';
import ManageSection from '../sections/ManageSection';
import * as Actions from '../../../../../state/Actions';
import { ViewDataPoint } from '../common/CommonComponents';
import CommonSearch from '../../../../common/CommonSearch';
import { DeleteComponent } from '../common/CommonComponents';
import ListItemActions from '../../../../common/ListItemActions';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { HtmlTooltip } from '../../../../custom/tooltip/CustomTooltip';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import { BootstrapDialog } from '../../../bulkStatementUpload/mappings/Transactions';
import { BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import {
    list_of_data_point,
} from '../../../../../api/urls';

import {
    CustomContainer,
} from '../../../../custom/container/CustomContainer';

import {
    CustomTable,
    CustomTableRow,
    CustomTableBody,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableBodyCell,
    CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Avatar,
    IconButton,
    DialogContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Dropdown = DateRange;
const ManageDataPoint = (props) => {
    const { open, setOpen, onDeleteDataPointTabAction } = props;

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [page, setPage] = React.useState(1);
    const [results, setResults] = React.useState([]);
    const [action, setAction] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [isDeleteDataPoint, setIsDeleteDataPoint] = React.useState(false);
    const [dataPointFilterOption, setDataPointFilterOption] = React.useState("");

    const dataPointsFiltersList = [
        { name: "Report", value: `data_point_type=Report` },
        { name: "Account", value: `data_point_type=Account` },
        { name: "Equation", value: `data_point_type=Equation` },
        { name: "Function", value: `data_point_type=Function` },
        { name: "Parent Account", value: `data_point_type=Parent Account` },
        { name: "User Defined", value: `is_custom=True` },
    ]

    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })
    React.useEffect(() => {
        fetchDefaultDataPoint()
    }, [page, searchTerm, dataPointFilterOption]);

    const fetchDefaultDataPoint = async () => {
        const filterQuery = dataPointFilterOption ? dataPointFilterOption.value : "";
        const res = await apiAction({
            url: `${list_of_data_point(getBusinessInfo().id, searchTerm)}&page=${page}&sort_by=data_point_type&sort_order=A&${filterQuery}`
        });
        if (res?.success) {
            setPagination({
                ...pagination,
                next: res?.result.next,
                count: res?.result.count,
                previous: res?.result.previous,
                number_of_pages: res?.result.number_of_pages,
            });
            setResults(res?.result.result);
        } else {
        }
    };

    return (
        <div>
            <ManageSection
                data={{}}
                action={action}
                isDataPointManage={true}
                setOpen={() => { setAction(false) }}
                open={action === 'add_new_data_point'}
                setData={(dataNew) => {
                    setAction(false)
                    fetchDefaultDataPoint()
                }
                }
            />
            <BootstrapDialog
                fullWidth
                open={open}
                maxWidth={'lg'}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {
                    setOpen(false)
                    if (isDeleteDataPoint && onDeleteDataPointTabAction) {
                        setIsDeleteDataPoint(false);
                        onDeleteDataPointTabAction();
                    }
                }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ flexGrow: .5, maxWidth: "max-content" }}>
                                <CustomTypography
                                    text={

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Manage Data Point
                                            <HtmlTooltip title="Add New Data Point" placement="top">
                                                <IconButton
                                                    aria-label="add"
                                                    onClick={(event) => {
                                                        setAction('add_new_data_point');
                                                    }}
                                                >
                                                    <Avatar
                                             
                                                        sx={{
                                                            height: '24px',
                                                            width: '24px',
                                                            bgcolor: '#2464eb',
                                                        }}
                                                    >
                                                        <AddIcon sx={{fontSize:'18px',fontWeight:700}}/>
                                                    </Avatar>
                                                </IconButton>
                                            </HtmlTooltip>
                                        </Box>

                                    }
                                    sx={{
                                        color: '#000000',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Dropdown
                                id={'data_point_filters_dropdown'}
                                dataTestId={'data_point_filters_dropdown'}
                                placeholder='Select Data Point type'
                                results={dataPointsFiltersList}
                                value={dataPointFilterOption}
                                setValue={(event, value) => setDataPointFilterOption(value || null)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CommonSearch autoFocus={true} id={'input_search_data_point'} dataTestId={'input_search_data_point'} isTitleVisibility={false} placeholder={'Search Data Point eg. ROE'} fullWidth={true} onSearchValue={searchTerm} onSearch={(search_text) => {
                                if (search_text) {
                                    setPage(1);
                                    setSearchTerm(search_text);
                                } else {
                                    setPage(1);
                                    setSearchTerm('')
                                }
                            }} />
                        </Grid>
                    </Grid>
                </BootstrapDialogTitle>
                <DialogContent dividers sx={{ overflow: '' }}>
                    <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 220px)', paddingTop: '0px !important' }}>
                        <CustomTableContainer sx={{}}>
                            <CustomTable>
                                <Header />
                                <Body results={results} fetchDefaultDataPoint={() => fetchDefaultDataPoint()} setIsDeleteDataPoint={setIsDeleteDataPoint} />
                            </CustomTable>
                        </CustomTableContainer>
                    </CustomContainer>
                    <CustomPagination
                        page={page}
                        count={pagination.number_of_pages}
                        onChange={(event, newValue) => setPage(newValue)}
                    />
                </DialogContent>
            </BootstrapDialog>
        </div >
    )
}

export default ManageDataPoint

const Header = () => {
    return (
        <CustomTableHead>
            <CustomTableRow>
                <CustomTableHeadCell style={{ width: 200 }} align='left' ><span>Name</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='left'><span>Account Type</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='left'><span>Unit</span></CustomTableHeadCell>
                <CustomTableHeadCell style={{ width: 140 }} align='right'><span>Actions</span></CustomTableHeadCell>
            </CustomTableRow>
        </CustomTableHead>
    )
}

const Body = (props) => {
    const { results, fetchDefaultDataPoint, setIsDeleteDataPoint } = props

    const [data, setData] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [view, setView] = React.useState(false);
    const [action, setAction] = React.useState('');
    const [openDelete, setOpenDelete] = React.useState(false);
    const [refreshView, setRefreshView] = React.useState(false);

    const unitList = [
        { name: `Base Currency - ${getBusinessInfo()?.currency?.currency_code} : (${getBusinessInfo()?.currency?.symbol})`, value: 'base_currency', symbol: getBusinessInfo()?.currency?.symbol },
        { name: 'Percentage - (%)', value: 'percentage', symbol: '%' },
        { name: '-', value: 'none', symbol: '' }
    ]

    const getUnit = (unit) => {
        let value = unitList.find((item) => item.value === unit)
        if (value) {
            return value.name
        } else {
            return '-'
        }

    }

    const getAccountType = (account) => {
        if (account === 'raw') {
            return 'Raw'
        } else if (account === 'composite') {
            return 'Composite'
        } else {
            return account
        }
    }

    return (
        <CustomTableBody>
            <DeleteComponent
                id={data?.id}
                action={action}
                openDelete={openDelete}
                setOpenDelete={() => { setOpenDelete(false); setAction('') }}
                onDeleteAction={() => { setOpenDelete(false); setAction(''); fetchDefaultDataPoint(); setIsDeleteDataPoint(true) }}
            />
            <ViewDataPoint
                open={view}
                item={data}
                isDetails={false}
                setOpen={setView}
                refreshView={refreshView}
                setDataPointData={setData}
                onEdit={(value) => { setAction(value); setOpen(true); }}
            />
            <ManageSection
                open={open}
                data={data}
                action={action}
                setOpen={setOpen}
                dataPointData={data}
                isDataPointManage={true}
                setData={(data) => {
                    if (data) {
                        setAction('')
                        setOpen(false)
                        setData(data);
                        fetchDefaultDataPoint()
                    }
                    if (action === 'edit_data_point') {
                        setRefreshView(!refreshView);
                    }
                }}
            />
            {
                results.map((item, index) => {
                    return (
                        <CustomTableRow key={index}>
                            <CustomTableBodyCell align='left'>{item.name}</CustomTableBodyCell>
                            <CustomTableBodyCell align='left'>{getAccountType(item.data_point_type)}</CustomTableBodyCell>
                            <CustomTableBodyCell align='left'>{getUnit(item.unit)}</CustomTableBodyCell>
                            <CustomTableBodyCell align='right'>
                                <ListItemActions
                                    index={index}
                                    actions={
                                        [
                                            { name: 'View', onClick: () => { setData(item); setView(true); } },
                                            item.is_custom && { name: 'Edit', onClick: () => { setData(item); setOpen(true); setAction('edit_data_point') } },
                                            { name: 'Clone', onClick: () => { setData(item); setOpen(true); setAction('clone_data_point') } },
                                            { name: 'Delete', disabled: !item.is_custom, showDivider: true, onClick: () => { setData(item); setOpenDelete(true); setAction('delete_data_point') } }
                                        ]
                                    }
                                />
                            </CustomTableBodyCell>
                        </CustomTableRow>
                    )
                })
            }

        </CustomTableBody>
    )
}
