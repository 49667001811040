import React from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";

// Set the worker source for PDF.js
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

export const PdfViewer = ({ pdfFile }) => {
    const containerRef = React.useRef(null);
    const [pdf, setPdf] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(0);

    React.useEffect(() => {
        const loadPdf = async () => {
            try {
                const loadingTask = getDocument(pdfFile);
                const loadedPdf = await loadingTask.promise;
                setPdf(loadedPdf);
                setPageCount(loadedPdf.numPages);
            } catch (error) {
                console.error("Error loading PDF:", error);
            }
        };

        loadPdf();
    }, [pdfFile]);

    const renderPage = async (pageNumber, canvas) => {
        if (!pdf) return;

        try {
            const page = await pdf.getPage(pageNumber);
            const context = canvas.getContext("2d");

            // Logical size of the canvas (CSS size)
            const cssWidth = canvas.clientWidth;
            const cssHeight = canvas.clientHeight;

            // Device pixel ratio for high-DPI displays
            const devicePixelRatio = window.devicePixelRatio || 1;

            // Calculate scale to match device resolution
            const scale = cssWidth / page.getViewport({ scale: 1 }).width;
            const viewport = page.getViewport({ scale: scale * devicePixelRatio });

            // Set the actual resolution of the canvas
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Reset the canvas before rendering
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Render the PDF page to the canvas
            const renderContext = {
                canvasContext: context,
                viewport,
            };

            await page.render(renderContext).promise;
        } catch (error) {
            console.error("Error rendering page:", error);
        }
    };

    const renderAllPages = () => {
        const container = containerRef.current;
        if (!container || !pdf || pageCount === 0) return;

        // Render each page into its own canvas
        for (let i = 1; i <= pageCount; i++) {
            const canvas = document.createElement("canvas");
            canvas.style.width = "100%";
            canvas.style.marginBottom = "20px";
            canvas.style.background = '#FFFFFF';
            canvas.style.boxShadow = 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px';
            container.appendChild(canvas);
            renderPage(i, canvas);
        }
    };

    React.useEffect(() => {
        renderAllPages();
    }, [pdf, pageCount]);

    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}
        />
    );
};
