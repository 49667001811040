import React from 'react';
import * as Pages from './pages/Pages';
import * as Actions from '../../../../state/Actions';
import { apiAction_social } from '../../../../api/api';
import { routesName } from '../../../../config/routesName';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmptyDict, getQueryParams } from '../../../../utils/Utils';
import { capture_marketing_page_metadata, get_webpage_context } from '../../../../api/urls';
import { setLoader } from '../../../../utils/Utils';

const NewMarketingLandingPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = getQueryParams(location.search);
    const [webPageContext, setWebPageContext] = React.useState(
        {
            "TopFold": {
                "id": 1,
                "name": "TopFold",
                "title": "Transform Your Books into Business Insights You Need",
                "subtitle": "See beyond the numbers. Finycs gives you insights that bring clarity to your business finances.",
                "page": "marketing",
                "other_section_data": {},
                "cards": [],
                "bulletins": []
            },
            "GSTCompliance": {
                "id": 2,
                "name": "GSTCompliance",
                "title": "Stay 100% GST and audit compliant",
                "subtitle": null,
                "page": "marketing",
                "other_section_data": {},
                "cards": [
                    {
                        "id": 1,
                        "section_id": 2,
                        "type": "CARD",
                        "title": "Easy GST Reports",
                        "subtitle": null,
                        "text": "Generate accurate GST reports with just a few clicks, keeping your business tax-ready without any hassle.",
                        "sub_section_key": null
                    },
                    {
                        "id": 2,
                        "section_id": 2,
                        "type": "CARD",
                        "title": "Easy Audit Trail",
                        "subtitle": null,
                        "text": "Keep track of all changes, so you’re always ready for an audit without any stress.",
                        "sub_section_key": null
                    },
                    {
                        "id": 3,
                        "section_id": 2,
                        "type": "CARD",
                        "title": "Simple GST Export",
                        "subtitle": null,
                        "text": "Quickly export your GST data in JSON format, making tax filing smooth and easy.",
                        "sub_section_key": null
                    }
                ],
                "bulletins": []
            },
            "In-AppPaymentTracking": {
                "id": 3,
                "name": "In-AppPaymentTracking",
                "title": "Reduce overdue payments and improve cashflow",
                "subtitle": "Send automatic payment reminders and track overdue invoices easily, helping you get paid faster without constant follow-ups.",
                "page": "marketing",
                "other_section_data": {
                    "finycs_free_text": {
                        "id": 8,
                        "section_id": 3,
                        "type": "TXT",
                        "title": null,
                        "subtitle": null,
                        "text": "Now, experience Finycs free for two months",
                        "sub_section_key": "finycs_free_text"
                    }
                },
                "cards": [],
                "bulletins": [
                    {
                        "id": 4,
                        "section_id": 3,
                        "type": "BULLETINS",
                        "title": null,
                        "subtitle": null,
                        "text": "Share invoices quickly on Whatsapp, Email, SMS or Print",
                        "sub_section_key": null
                    },
                    {
                        "id": 5,
                        "section_id": 3,
                        "type": "BULLETINS",
                        "title": null,
                        "subtitle": null,
                        "text": "Send due payment reminders (Automatic or Manual)",
                        "sub_section_key": null
                    },
                    {
                        "id": 6,
                        "section_id": 3,
                        "type": "BULLETINS",
                        "title": null,
                        "subtitle": null,
                        "text": "Track party-wise due payments with accurate reports",
                        "sub_section_key": null
                    },
                    {
                        "id": 7,
                        "section_id": 3,
                        "type": "BULLETINS",
                        "title": null,
                        "subtitle": null,
                        "text": "Recurring Invoices",
                        "sub_section_key": null
                    }
                ]
            },
            "FourFeatures": {
                "id": 4,
                "name": "FourFeatures",
                "title": null,
                "subtitle": null,
                "page": "marketing",
                "other_section_data": {},
                "cards": [
                    {
                        "id": 9,
                        "section_id": 4,
                        "type": "CARD",
                        "title": "Effortless Invoicing You’ll Love",
                        "subtitle": null,
                        "text": "Effortlessly create and send polished invoices that leave a lasting impression, track when clients view them, and easily resend invoices for repeat clients.",
                        "sub_section_key": null
                    },
                    {
                        "id": 10,
                        "section_id": 4,
                        "type": "CARD",
                        "title": "Instant Access to Payment Balances",
                        "subtitle": null,
                        "text": "Tired of waiting on the accounting team for payment details? Finycs lets you generate statements instantly, helping you track outstanding balances, send gentle reminders, and maintain better relationships with clients and vendors.",
                        "sub_section_key": null
                    },
                    {
                        "id": 11,
                        "section_id": 4,
                        "type": "CARD",
                        "title": "Your Books Always Tally with Double-Entry at the Core",
                        "subtitle": null,
                        "text": "Finycs ensures every transaction is correctly balanced, making your financial management both comprehensive and reliable. With an intuitive interface, understanding your books and quickly locating entries has never been easier.",
                        "sub_section_key": null
                    },
                    {
                        "id": 9,
                        "section_id": 4,
                        "type": "CARD",
                        "title": "Effortless Invoicing You’ll Love",
                        "subtitle": null,
                        "text": "Effortlessly create and send polished invoices that leave a lasting impression, track when clients view them, and easily resend invoices for repeat clients.",
                        "sub_section_key": null
                    },
                ],
                "bulletins": []
            },
            "KeyFeatures": {
                "id": 5,
                "name": "KeyFeatures",
                "title": null,
                "subtitle": null,
                "page": "marketing",
                "other_section_data": {},
                "cards": [
                    {
                        "id": 12,
                        "section_id": 5,
                        "type": "CARD",
                        "title": "Discover Financial Insights from Your Data",
                        "subtitle": null,
                        "text": "Imagine having years of financial data at your fingertips, yet never being able to truly leverage it. With Finycs, that changes. Instead of wasting hours sifting through spreadsheets, you can quickly drill down into past trends and see the story your numbers are telling. From uncovering seasonal patterns to spotting long-term opportunities, Finycs transforms your data into clear insights that empower smarter, faster decisions.",
                        "sub_section_key": null
                    },
                    {
                        "id": 13,
                        "section_id": 5,
                        "type": "CARD",
                        "title": "Instant Reports, On Your Schedule",
                        "subtitle": null,
                        "text": "Tired of the constant back-and-forth with your accounting team to get the reports you need? Finycs changes that by allowing both you and your accountants to customize and schedule reports effortlessly. This way, you reduce dependency, save valuable time, and always have timely insights for better, faster decision-making.",
                        "sub_section_key": null
                    },
                    {
                        "id": 14,
                        "section_id": 5,
                        "type": "CARD",
                        "title": "From Reactive to Proactive: Real-Time Monitoring",
                        "subtitle": null,
                        "text": "You may not require real-time monitoring at all times, but when you do—like during a sales campaign for New Year—having access to data timely is crucial. Whether it’s tracking sales trends, managing inventory levels, or monitoring expenses, Finycs keeps you updated multiple times a day. Even without a dedicated tracking system, Finycs leverages real-time accounting data to keep you informed and ensures that the right people have access to critical insights at the right time. This way, your team can respond proactively and make strategic adjustments to maximize returns or handle unexpected changes effectively.",
                        "sub_section_key": null
                    },
                    {
                        "id": 15,
                        "section_id": 5,
                        "type": "CARD",
                        "title": "Seamless Tally Integration",
                        "subtitle": null,
                        "text": "Keep your accounting on Tally while unlocking the powerful insights and analysis capabilities of Finycs. Our integration syncs your data effortlessly, allowing you to search, monitor, and analyze every detail. Your accounts team can continue working in the system they know best, while you leverage Finycs for advanced financial insights—accessible anytime, on any device, be it mobile or computer.",
                        "sub_section_key": null
                    }
                ],
                "bulletins": []
            },
            "PricingPlan": {
                "id": 6,
                "name": "PricingPlan",
                "title": "Pricing Plan",
                "subtitle": null,
                "page": "marketing",
                "other_section_data": {
                    "offer_premium_plan": {
                        "id": 18,
                        "section_id": 6,
                        "type": "TXT",
                        "title": null,
                        "subtitle": null,
                        "text": "PREMIUM PLAN FREE FOR 2 months till 31 October 2024",
                        "sub_section_key": "offer_premium_plan"
                    }
                },
                "cards": [
                    {
                        "id": 16,
                        "section_id": 6,
                        "type": "CARD",
                        "title": "Free",
                        "subtitle": "0 Rs",
                        "text": "1 business, upto 3 users\n10k Journal Entries / year\nManage Customers\nInvoices\nCredit / Debit Notes\nManage vendors\nPurchase orders\nLimited Financial Reports\nLimited Insights View",
                        "sub_section_key": null
                    },
                    {
                        "id": 17,
                        "section_id": 6,
                        "type": "CARD",
                        "title": "Paid",
                        "subtitle": "500 Rs/month",
                        "text": "1 Business, upto 5 users\nAll Features of Free Plan\nAutomated Journal Entries\nRecurring Invoices\nRecurring Expenses\nVarious Financial Reports\nCustomisable Insights\nSynchronise Data from Tally",
                        "sub_section_key": null
                    }
                ],
                "bulletins": []
            },
            "Review": {
                "id": 7,
                "name": "Review",
                "title": "Our Customers",
                "subtitle": null,
                "page": "marketing",
                "other_section_data": {},
                "cards": [
                    {
                        "id": 19,
                        "section_id": 7,
                        "type": "CARD",
                        "title": "Swathi R",
                        "subtitle": null,
                        "text": "Application has helped us reduce time spent on creating journal entries, their rule based entry creation is very simple to use and saves time.",
                        "sub_section_key": null
                    },
                    {
                        "id": 20,
                        "section_id": 7,
                        "type": "CARD",
                        "title": "Tejas Londhe - Business Owner",
                        "subtitle": null,
                        "text": "Finycs has helped us receive right information at right time to decide on matters related to business, we very rarely used to focused on financial indicators so much before we had this software. We are now loving this new discipline of keeping an eye on the fundamental ratios of our company.",
                        "sub_section_key": null
                    },
                    {
                        "id": 19,
                        "section_id": 7,
                        "type": "CARD",
                        "title": "Swathi R",
                        "subtitle": null,
                        "text": "Application has helped us reduce time spent on creating journal entries, their rule based entry creation is very simple to use and saves time.",
                        "sub_section_key": null
                    },
                ],
                "bulletins": []
            }
        }
    );

    const dispatch = Actions.getDispatch(React.useContext);
    // const [webPageContext, setWebPageContext] = React.useState(null);
    const scrollContainerRef = React.useRef(null);

    const captureParamsInformation = async () => {
        let res = await apiAction_social({
            method: 'post',
            url: capture_marketing_page_metadata(),
            data: { ...params },
        })
    }

    const getMarketingPageContext = async () => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction_social({
            method: 'get',
            url: get_webpage_context("marketing"),
        })
        if (res?.success) {
            setWebPageContext(res?.context);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    React.useEffect(() => {
        if (!isEmptyDict(params)) {
            captureParamsInformation();
        }
        // getMarketingPageContext();
    }, []);

    const navigateToLogin = () => { navigate("/auth" + routesName.signIn.path) }
    const navigateToSignup = () => { navigate("/auth" + routesName.signUp.path) }

    // return (
    //     <div style={{ height: '100%', background: '#FFFFFF' }}>
    //         <Pages.Header navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
    //         <Pages.Banner navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
    //         <Pages.KeyFeatures navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
    //         <Pages.AuditCompliant navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
    //         <Pages.OverduePayment navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
    //         <Pages.Benefits navigateToSignup={()=>{navigateToSignup()}} navigateToLogin={()=>{navigateToLogin()}}/>
    //         <Pages.Pricing navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
    //         <Pages.Reviews navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
    //         <Pages.Footer navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
    //     </div>
    // )

    return (
        <>
            {webPageContext && (
                <div style={{ height: '100vh', background: '#FFFFFF', overflow: "hidden", position: "relative" }} >
                    <Pages.NewHeader navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
                    <div
                        className='display_scroll_bar'
                        style={{ position: "absolute", top: "75px", height: "calc(100% - 75px)", overflowY: "auto", overflowX: "hidden", width: "100%", }}
                        ref={scrollContainerRef}
                    >
                        <Pages.NewBanner navigateToSignup={() => { navigateToSignup() }} textContent={webPageContext["TopFold"]} />
                        <Pages.NewAuditCompliant textContent={webPageContext["FourFeatures"]} />
                        <Pages.KeyFeatures />
                        <Pages.NewBenefits scrollContainerRef={scrollContainerRef} textContent={webPageContext["KeyFeatures"]} />
                        <Pages.NewReviews textContent={webPageContext["Review"]} />
                        <Pages.NewPricing navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} textContent={webPageContext["PricingPlan"]} />
                        <Pages.Footer navigateToSignup={() => { navigateToSignup() }} navigateToLogin={() => { navigateToLogin() }} />
                    </div>
                </div>
            )}
        </>
    )
}

export default NewMarketingLandingPage;
