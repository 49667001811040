import React from 'react';
import { Box } from '@mui/material';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { ReactComponent as EasyAuditTrailIcon } from '../assets/svg/easyAuditTrail.svg';
import { ReactComponent as EasyGstReportsIcon } from '../assets/svg/easyGstReports.svg';
import { ReactComponent as SimpleGstExportIcon } from '../assets/svg/simpleGstExport.svg';

const NewAuditCompliant = ({ textContent }) => {

    const [hoveredCard, setHoveredCard] = React.useState(null);

    const iconsList = [EasyGstReportsIcon, EasyAuditTrailIcon, SimpleGstExportIcon, EasyGstReportsIcon];
    const backgroundColorList = ['#FFF4EE', '#FEF1F3', '#F1F1FB', '#ECF8F3']

    const newCardsData = textContent?.cards.map((data, index) => {
        return ({
            ...data,
            icon: iconsList[index],
            background: backgroundColorList[index]
        })
    })

    // console.log("newCardsData", newCardsData)

    return (
        <Custom.Container sx={{ background: '#F9F9F9', mt: 3, mb: 3 }}>
            <Box sx={styles.container}>
                <Title text={textContent?.title} sx={styles.title} />
                <Box sx={styles.cardsContainer}>
                    {newCardsData.map((card, index) => (
                        <BannerCard
                            key={index}
                            icon={card.icon}
                            mainText={card.title}
                            subText={card.text}
                            background={card.background}
                            anchorText="Learn More"
                            hovered={hoveredCard === index}
                            onMouseEnter={() => setHoveredCard(index)}
                            onMouseLeave={() => setHoveredCard(null)}
                        />
                    ))}
                </Box>
            </Box>
        </Custom.Container>
    )
};


const Title = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.typography, ...sx }} variant='h2' />
);


const BannerCard = ({
    icon: Icon,
    background,
    mainText,
    subText,
    anchorText,
    onMouseEnter,
    onMouseLeave,
    hovered
}) => (
    <Box
        sx={{ ...styles.card, background }}
        className="banner-card"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <Box sx={styles.cardInner}>
            <Box sx={styles.iconWrapper} className="iconWrapper">
                <Icon />
            </Box>
            <Title text={mainText} sx={styles.cardText} />
            <Title text={subText} sx={styles.cardSubText} />
            <Box sx={styles.anchorTextContainer}>
                <Title
                    text={anchorText}
                    sx={{
                        ...styles.anchorText,
                        borderBottomColor: hovered ? 'transparent' : '#2550aa',
                    }}
                />
            </Box>
        </Box>
    </Box>
);

const styles = {
    container: {
        // pt: 4,
        pb: 8,
        height: '100%',
        borderRadius: '32px',
    },
    title: {
        pt: 2,
        pb: 8,
        textAlign: 'center',
    },
    typography: {
        fontWeight: 600,
        color: '#141414',
        fontSize: '22px',
        lineHeight: 1.4,
        letterSpacing: '0.96px',
    },
    cardsContainer: {
        display: 'grid',
        gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
        },
        gap: '30px',
        px: 3,
    },
    card: {
        width: '100%',
        borderRadius: 1,
        height: '100%',
        minHeight: '420px',
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: '450px', // use when displaying less then 4 cards
    },
    cardInner: {
        padding: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    iconWrapper: {
        width: '100%',
        height: 'auto',
        pt: 5,
        display: 'flex',
        justifyContent: 'center',
        mb: 3,
    },
    cardText: {
        paddingTop: '25px',
        margin: 0,
    },
    cardSubText: {
        paddingTop: '25px',
        fontWeight: 200,
        fontSize: '14px',
        lineHeight: 1.7,
        letterSpacing: '0.2px',
        textAlign: 'left',
        flex: 1,
    },
    anchorTextContainer: {
        paddingTop: '25px',
        marginTop: 'auto',
    },
    anchorText: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#2550aa',
        borderBottom: '2px solid #2550aa',
        width: 'fit-content',
        transition: 'all 0.3s ease',
    },
};

export default NewAuditCompliant;