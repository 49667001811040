import React from 'react';
import moment from 'moment';
import { apiAction } from "../../../../../api/api";
import ReportsTitle from '../../common/ReportsTitle';
import * as Actions from '../../../../../state/Actions';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';

import {
    export_horizontal_profit_and_loss,
    get_profit_and_loss_horizontal_statement,
} from "../../../../../api/urls";

import {
    setLoader,
    date_range,
    getDateFormat,
} from '../../../../../utils/Utils';

import {
    CustomTable,
} from '../../../../custom/table/CustomTable';

import {
    Box,
    Grid,
    Collapse,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



const HorizontalProfitAndLoss = () => {
    const stateAction = Actions.getState(React.useContext);
    const dispatch = Actions.getDispatch(React.useContext);

    const { selectedDateRange } = stateAction;
    let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
    const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

    const [results, setResults] = React.useState();
    const [isZero, setIsZero] = React.useState(false);
    const [toDate, setToDate] = React.useState(moment());
    const [fromDate, setFromDate] = React.useState(moment());
    const [isDetailed, setIsDetailed] = React.useState(false);
    const [dateRange, setDateRange] = React.useState(default_date_range);



    React.useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [dateRange, toDate, , fromDate]);

    const fetchData = () => {
        if (dateRange) {
            if (dateRange.name !== "Custom") {
                getApiResults(dateRange.dates.from_date, dateRange.dates.to_date)
            } else {
                if (toDate && fromDate) {
                    getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'))
                }
            }
        }
    }

    const getApiResults = async (from_date, to_date) => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            url: get_profit_and_loss_horizontal_statement(),
            data: {
                to_date: to_date,
                from_date: from_date,
                business_id: getBusinessInfo().id,
            },
        })

        if (res?.success) {
            setResults(res?.result.components);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const selectedDatesObject = { ...dateRange?.dates }
    const customDatesObject = { from_date: fromDate?.format('YYYY-MM-DD'), to_date: toDate?.format('YYYY-MM-DD') }
    return (
        results &&
        <div>
            <ReportsSettingButtons

                accountMappingData={{
                    refreshPage: () => fetchData(),
                    mapping_type: 'profit_and_loss',
                }}


                zeroData={{
                    value: isZero,
                    setValue: (value) => setIsZero(value),
                }}

                labelData={{
                    value: isDetailed,
                    labelName: 'Detailed',
                    title: 'Export Preference',
                    setValue: (value) => setIsDetailed(value),
                }}
                exportData={{ url: export_horizontal_profit_and_loss(), data: dateRange.dates ? { ...dateRange.dates, is_detailed: isDetailed, display_zero_balance_account: !isZero } : { to_date: toDate.format('YYYY-MM-DD'), from_date: null, is_detailed: isDetailed, display_zero_balance_account: !isZero } }}
            />


            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
                <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                    <Grid container spacing={0} sx={{ pb: 2 }} style={{
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'space-evenly',
                        justifyContent: 'space-evenly',
                    }}>
                        <Grid item xs={12} sm={4}>
                            <ReportsTitle
                                title={'Horizontal Profit and Loss'}
                                date={dateRange.dates ? `${moment(dateRange.dates.from_date).format(getDateFormat())} - ${moment(dateRange.dates.to_date).format(getDateFormat())}` : `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`}
                            />
                        </Grid>
                        <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
                            <CommonCustomDateRange
                                setPage={() => { }}
                                date_range={date_range()}

                                toDate={toDate}
                                setToDate={setToDate}

                                fromDate={fromDate}
                                setFromDate={setFromDate}

                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Grid>
                    </Grid>
                </div>
                <CustomTable>
                    <Body results={results} isZero={isZero} date={dateRange.name === "Custom" ? { ...customDatesObject } : { ...selectedDatesObject }} />
                    <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo().currency.currency_code}</span></caption>
                </CustomTable>
            </CustomContainer>

        </div>
    )
}

export default HorizontalProfitAndLoss;

const Body = (props) => {
    const { date, isZero, results } = props;

    return (
        <Box sx={{ p: 2 }}>
            <Grid container sx={{ border: '2px solid #F5F5F5' }}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5.9} sx={{ pl: 1, pr: 1, }}>
                            <Header title={`Expense`} />
                        </Grid>
                        <Grid item xs={6.1} sx={{ pl: 1, pr: 1, borderLeft: '2px solid #F5F5F5' }}>
                            <Header title={`Income`} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5.9} sx={{ pl: 1, pr: 1, }}>
                            <Content date={date} data={results.expense_components} isZero={isZero} />
                        </Grid>
                        <Grid item xs={6.1} sx={{ pl: 1, pr: 1, borderLeft: '2px solid #F5F5F5' }}>
                            <Content date={date} data={results.income_components} isZero={isZero} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={5.9} sx={{ pl: 1, pr: 1, }}>
                            <Footer total={{ name: 'Total Expense', value: results.expense_total }} />
                        </Grid>
                        <Grid item xs={6.1} sx={{ pl: 1, pr: 1, borderLeft: '2px solid #F5F5F5' }}>
                            <Footer total={{ name: 'Total Income', value: results.income_total }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const Header = (props) => {
    const { title } = props

    return (
        <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                    <CustomTypography
                        text={title}
                        sx={{
                            color: '#141414',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '27px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const Footer = (props) => {
    const { total } = props

    let custom_border = {
        background: '#FFF7DC',
        borderTop: '1px solid #FFF0C0',
        borderBottom: '1px solid #FFF0C0'
    }
    return (
        <Box sx={{ ...custom_border, borderTop: '2px solid #F5F5F5' }}>
            <Grid container sx={{ p: 2 }}>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                    <CustomTypography
                        text={total.name}
                        sx={{
                            color: '#141414',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '27px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <CustomTypography
                        text={<CurrencyFormatter amount={total.value} />}
                        sx={{
                            color: '#141414',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '27px',
                            fontStyle: 'normal',
                            textTransform: 'none',
                            fontFamily: "Noto Sans",
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const Content = (props) => {
    const { date, isZero, data } = props;
    return (
        data.map((valueItem, valueIndex) => {
            return (
                <React.Fragment key={valueIndex}>
                    <CollapseContentData date={date} valueItem={valueItem} valueIndex={valueIndex} isZero={isZero} />
                </React.Fragment>
            )
        })
    )
}

const CollapseContentData = (props) => {
    const { date, isZero, valueIndex, valueItem } = props;
    const [open, setOpen] = React.useState(false);
    const handleChange = () => {
        setOpen((prev) => !prev);
    };
    React.useEffect(() => {
        if (isZero) {
            setOpen(false)
        }
    }, [isZero])

    let condition = valueItem.component_details && valueItem.component_details.length ? true : false;

    let custom_border = {
        background: '#FFF7DC',
        borderTop: '1px solid #FFF0C0',
        borderBottom: '1px solid #FFF0C0'
    }
    return (
        <React.Fragment >
            <Box sx={{ borderTop: valueIndex ? '1px solid #F5F5F5' : '' }}>
                <Grid container sx={{ p: 2 }}>
                    <Grid item xs={6} sx={{ display: 'flex', textAlign: 'left' }} >
                        <CustomTypography
                            id={'handle_' + valueIndex}
                            dataTestId={'handle_' + valueIndex}
                            text={
                                <span onClick={() => {
                                    isZero ?
                                        (valueItem.component_balance !== 0 && condition) && handleChange()
                                        :
                                        condition && handleChange()
                                }}>
                                    {valueItem.component_name}
                                    {isZero ?
                                        (valueItem.component_balance !== 0 && condition) && <Icon open={open} />
                                        :
                                        condition && <Icon open={open} />
                                    }

                                </span>}
                            sx={{
                                color: '#141414',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '27px',
                                fontStyle: 'normal',
                                textTransform: 'none',
                                fontFamily: "Noto Sans",
                                cursor: isZero ? (valueItem.component_balance !== 0 && condition ? 'pointer' : '') : (condition ? 'pointer' : ''),
                            }}
                        />
                    </Grid>
                    {
                        !open ?
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <CustomTypography
                                    text={<CurrencyFormatter amount={valueItem.component_balance} />}
                                    sx={{
                                        color: '#141414',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '27px',
                                        fontStyle: 'normal',
                                        textTransform: 'none',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                            : null
                    }
                </Grid>
            </Box>
            <Collapse in={open}>
                {
                    condition ?
                        <>
                            {
                                valueItem.component_details.map((componentItem, componentIndex) => {
                                    return (
                                        <div>
                                            {
                                                isZero ?
                                                    componentItem.account_balance !== 0 &&
                                                    <Accounts date={date} componentItem={componentItem} componentIndex={componentIndex} />
                                                    :
                                                    <Accounts date={date} componentItem={componentItem} componentIndex={componentIndex} />

                                            }
                                        </div>
                                    )
                                })
                            }
                            <Box sx={{ ...custom_border, mt: 2, }}>
                                <Grid container sx={{ p: 2, pt: 0, pb: 0 }}>
                                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                        <CustomTypography
                                            text={'Total ' + valueItem.component_name}
                                            sx={{
                                                pt: 1,
                                                pb: .5,
                                                fontWeight: 700,
                                                color: '#141414',
                                                fontSize: '14px',
                                                lineHeight: '27px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                        <CustomTypography
                                            text={<CurrencyFormatter amount={valueItem.component_balance} />}
                                            sx={{
                                                pt: 1,
                                                pb: .5,
                                                fontWeight: 700,
                                                color: '#141414',
                                                fontSize: '14px',
                                                lineHeight: '27px',
                                                fontStyle: 'normal',
                                                textTransform: 'none',
                                                fontFamily: "Noto Sans",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                        : null

                }
            </Collapse>

        </React.Fragment >

    )
}

const Accounts = (props) => {
    const { date, componentIndex, componentItem } = props
    return (
        <Grid container style={{ backgroundColor: componentIndex % 2 === 0 ? "#f8f9fa" : "#ffffff", }} sx={{ mt: .5, p: 2, pt: 0, pb: 0, pl: 1 }} key={componentIndex}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
                <NavigationComponent
                    date={date}
                    account_id={componentItem.business_account_id}
                    account_name={'• ' + componentItem.business_account}
                />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                <NavigationAmountComponent
                    date={date}
                    account_id={componentItem.business_account_id}
                    account_name={componentItem.business_account}
                    account_balance={componentItem.account_balance}
                />

            </Grid>
        </Grid>
    )
}


const NavigationComponent = ({ date, account_name, account_id }) => {
    return (
        // <CustomLink
        //     id={account_name + '_link'}
        //     dataTestId={account_name + '_link'}
        //     style={{
        //         color: '#2464EB',
        //         overflow: 'hidden',
        //         whiteSpace: 'nowrap',
        //         textOverflow: 'ellipsis',
        //     }}
        // state={date ? { dateRange: { name: 'Custom', dates: 0 }, toDate: date.to_date, fromDate: date.from_date } : null}
        //     to={routesName.horizontalProfitLossTransaction.path + "?id=" + account_id}
        // >{<CustomTypography
        //     text={account_name}
        //     sx={{
        //         // pt: .5,
        //         pl: 2.5,
        //         color: 'inherit',
        //         fontSize: '14px',
        //         fontWeight: '400',
        //         lineHeight: '27px',
        //         fontStyle: 'normal',
        //         textTransform: 'none',
        //         fontFamily: "Noto Sans",
        //     }}
        // />}</CustomLink>

        <CustomTypography
            text={account_name}
            sx={{
                // pt: .5,
                pl: 2.5,
                color: '#000000',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '27px',
                fontStyle: 'normal',
                textTransform: 'none',
                fontFamily: "Noto Sans",

                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }}
        />
    )
}

const NavigationAmountComponent = ({ date, account_name, account_balance, account_id }) => {
    return (
        <CustomLink
            id={account_name + '_link'}
            dataTestId={account_name + '_link'}
            style={{
                color: '#2464EB',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            }}
            state={date ? { dateRange: { name: 'Custom', dates: 0 }, toDate: date.to_date, fromDate: date.from_date } : null}
            to={routesName.horizontalProfitLossTransaction.path + "?id=" + account_id}
        >
            <CustomTypography
                text={<CurrencyFormatter amount={account_balance} />}
                sx={{
                    color: 'inherit',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '27px',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontFamily: "Noto Sans",

                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            />
        </CustomLink>
        // <span>{account_balance}</span> 
    )
}

const Icon = (props) => {
    const { open } = props;
    return (
        open ?
            <ArrowDropUpIcon fontSize='small' sx={{ mb: -0.8, }} />
            :
            <ArrowDropDownIcon fontSize='small' sx={{ mb: -0.8, }} />
    )
}