
import moment from 'moment';
import * as React from 'react';
import { apiAction } from '../../../api/api';
import { HOST } from '../../../config/config';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../../state/Actions';
import CustomTitle from '../../common/CustomTitle';
import CommonShareFile from '../../common/CommonShareFile';
import { getBusinessInfo } from '../../../config/cookiesInfo';
import { NoDataComponent } from '../../common/NoDataComponent';
import CustomTypography from '../../custom/typography/CustomTypography';
import CustomPagination from '../../custom/pagination/CustomPagination';
import { eventsNames, AnalyticsEvent } from '../../../firebase/firebaseAnalytics';
import { ReactComponent as PdfFileIcon } from '../../../assets/svg/pdfFileIcon.svg';
import { ReactComponent as ZipFileIcon } from '../../../assets/svg/zipFileIcon.svg';
import { ReactComponent as CsvFileIcon } from '../../../assets/svg/csvFileIcon.svg';


import {
    CustomContainer,
    CustomTitleContainer,
} from '../../custom/container/CustomContainer';

import {
    delete_exported_file,
    get_list_of_exported_files,
} from '../../../api/urls';

import {
    setLoader,
    getDateFormat,
    convertSlugToString,
} from '../../../utils/Utils';

import {
    Box,
    Grid,
    IconButton
} from '@mui/material';

import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CustomDialog from '../../custom/dialog/CustomDialog';

const DownloadLists = () => {
    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [results, setResults] = React.useState([])
    const [state, setState] = React.useState({
        id: '',
        title: '',
        open: false,
        condition: '',
        maxWidth: 'lg',
        fullWidth: true,
    })
    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({
        next: undefined,
        count: undefined,
        previous: undefined,
        number_of_pages: undefined,
    })

    React.useEffect(() => {
        if (!state.open) {
            getApiResults()
        }
        // eslint-disable-next-line
    }, [state.open, page]);

    const getApiResults = async () => {
        setLoader(dispatch, Actions, true)
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: get_list_of_exported_files(page),
            data: { business_id: getBusinessInfo().id },
        })

        // console.log('res', res)
        if (res?.success) {
            let result = res?.result
            setPagination({
                ...pagination,
                next: result.next,
                count: result.count,
                previous: result.previous,
                number_of_pages: result.number_of_pages,
            });
            setResults(result.result);
            setLoader(dispatch, Actions, false);
        } else {
            setLoader(dispatch, Actions, false);
        }
    }


    const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage, id) => {
        setState({
            id: id,
            url: url,
            open: true,
            title: title,
            fullWidth: true,
            maxWidth: maxWidth,
            condition: condition,
            deleteMessage: deleteMessage,
        })
    }

    const [sharedata, setShareData] = React.useState({
        id: null,
        open: false
    })
    return (
        <div>
            <CustomDialog
                state={state}
                setState={setState}
            />
            <CommonShareFile
                title='Send File'
                id={sharedata.id}
                open={sharedata.open}
                setOpen={(value) => setShareData({ ...sharedata, open: value })}
            />
            <CustomTitleContainer>
                <CustomTitle title={'Downloads'} />
            </CustomTitleContainer>
            <CustomContainer maxWidth={"400"} sx={{ pb: 2, height: 'calc(100vh - 180px)', maxHeight: { xs: 'calc(100vh - 225px)', sm: '100%' }, background: '#FAFAFA' }}>
                <Grid container spacing={3}>
                    {results.map((item, index) => {

                        return (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{}}>
                                <DownloadsData
                                    id={'action_item_' + index}
                                    dataTestId={'action_item_' + index}
                                    data={item}
                                    onShare={() => {
                                        setShareData({ ...sharedata, id: item.id, open: true })
                                    }}
                                    onDelete={() => {
                                        stateChangeHandler('Delete File', 'delete', 'sm', delete_exported_file(item.id), `The selected file will be deleted and can not be retrieved later. Are you sure about deleting it?`)
                                    }}
                                />
                            </Grid>
                        )
                    })

                    }
                </Grid>

                <Box sx={{ pt: !results.length ? 10 : 0 }} >
                    <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
                </Box>
            </CustomContainer>
            {
                results.length ?
                    <CustomPagination
                        page={page}
                        count={pagination.number_of_pages}
                        onChange={(event, newValue) => setPage(newValue)}
                    />
                    : null
            }
        </div>
    );
}

export default DownloadLists;

const DownloadsData = (props) => {
    const { data, onDelete, onShare, id, dataTestId } = props;

    const fileUrl = HOST + `/media/${data.document}`;
    const fileName = data.document.split("/")[data.document.split("/").length - 1];
    const extension = fileName.split(".")[fileName.split(".").length - 1];

    const title = convertSlugToString(data.document_type, '_').capitalize();
    const generated_on = `${moment(data.created_at).format(getDateFormat('MMM DD, YYYY'))}`;
    const duration = data.durations_start_date ? `${data.durations_start_date ? moment(data.durations_start_date).format(getDateFormat('MMM DD, YYYY')) : ''} - ${moment(data.duration_end_date).format(getDateFormat('MMM DD, YYYY'))}` : "All";

    const handleView = () => {
        const link = document.createElement("a");
        link.download = fileName;
        link.href = fileUrl;
        window.open(link, '_blank');
    };
    const handleDownload = async () => {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const renderFileIcon = (extension) => {
        switch (extension) {
            case 'pdf':
                return <PdfFileIcon style={{ height: '30px', width: '30px' }} />;
            case 'csv':
                return <CsvFileIcon style={{ height: '30px', width: '30px' }} />;
            case 'zip':
                return <ZipFileIcon style={{ height: '30px', width: '30px' }} />;
            default:
                return null;
        }
    };
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                minHeight: '180px',
                position: 'relative',
                background: '#FFFFFF',
                boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.04)',
            }}
        >
            {/* Content Section */}
            <Box sx={{ p: 2, mb: 5 }}>
                <CustomTypography
                    text={title}
                    sx={{
                        mb: 2,
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
                <CustomTypography
                    text={`Duration: ${duration}`}
                    sx={{
                        mb: 1,
                        opacity: 0.6,
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
                <Box sx={{ display: 'flex' }}>
                    <CustomTypography
                        text={`Generated on: ${generated_on}`}
                        sx={{
                            mr: 1.5,
                            opacity: 0.6,
                            color: '#000000',
                            fontSize: '12px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                    <CustomTypography
                        text="View"
                        id={`${id}_view`}
                        dataTestId={`${dataTestId}_view`}
                        sx={{
                            color: '#2464EB',
                            fontSize: '13px',
                            cursor: 'pointer',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                            ':hover': { textDecoration: 'underline' },
                        }}
                        onClick={() => {
                            handleView();
                            AnalyticsEvent(eventsNames.categories.DOWNLOAD, {
                                action: eventsNames.actions.VIEW,
                                type: data?.document_type,
                            });
                        }}
                    />
                </Box>
            </Box>

            {/* Footer Section */}
            <Box
                sx={{
                    p: 2,
                    pb: 1,
                    bottom: 0,
                    height: '45px',
                    display: 'flex',
                    position: 'absolute',
                    alignItems: 'center',
                    width: 'calc(100% - 30px)',
                    justifyContent: 'space-between',
                }}
            >
                {/* File Icon */}
                <Box>
                    {renderFileIcon(extension)}
                </Box>

                {/* Action Buttons */}
                <Box>
                    <IconButton
                        onClick={() => {
                            onShare();
                            AnalyticsEvent(eventsNames.categories.DOWNLOAD, {
                                action: eventsNames.actions.SEND,
                            });
                        }}
                        sx={{ color: '#2464EB' }}
                        id={`${id}_share`}
                        dataTestId={`${dataTestId}_share`}
                    >
                        <ShareIcon fontSize="medium" />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            handleDownload();
                            AnalyticsEvent(eventsNames.categories.DOWNLOAD, {
                                action: eventsNames.actions.DOWNLOAD,
                            });
                        }}
                        sx={{ color: '#2464EB' }}
                        id={`${id}_download`}
                        dataTestId={`${dataTestId}_download`}
                    >
                        <FileDownloadIcon fontSize="medium" />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            onDelete();
                            AnalyticsEvent(eventsNames.categories.DOWNLOAD, {
                                action: eventsNames.actions.DELETE,
                            });
                        }}
                        id={`${id}_delete`}
                        sx={{}}
                        dataTestId={`${dataTestId}_delete`}
                    >
                        <DeleteIcon fontSize="medium" />
                    </IconButton>
                </Box>
            </Box>
        </Box>

    )
}

