import { routesName } from "./routesName";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { eventsNames } from "../firebase/firebaseAnalytics";
import SignupForm from "../components/pages/userManagement/onBoarding/SignupForm";

import CreateCompany from "../components/pages/businessManagement/createBusiness/CreateCompany";

import Mappings from "../components/pages/bulkStatementUpload/mappings/Mappings";
import FileUpload from "../components/pages/bulkStatementUpload/mappings/FileUpload";
import SourceTransactions from "../components/pages/bulkStatementUpload/sourceTransaction/SourceTransactions";


import Support from "../components/pages/support/Support";
import ChartofAccount from "../components/pages/accounting/accounts/ChartofAccount";
import GeneralLedger from "../components/pages/accounting/ledger/generalLedger/GeneralLedger";
import ListGeneralLedger from "../components/pages/accounting/ledger/listGeneralLedger/ListGeneralLedger";
import CreateJournalEntry from "../components/pages/accounting/journal/createJournalEntry/CreateJournalEntry";
import GetListJournalEntry from "../components/pages/accounting/journal/getJournalEntry/GetListJournalEntry";


import ItemsList from "../components/pages/invoicing/items/ItemsList";
import CreateItems from "../components/pages/invoicing/items/CreateItems";
import BillsList from "../components/pages/invoicing/purchase/bills/BillsList";
import InvoicesList from "../components/pages/invoicing/sales/invoices/InvoicesList";
import VendorsList from "../components/pages/invoicing/purchase/vendors/VendorsList";
import CustomersList from "../components/pages/invoicing/sales/customers/CustomersList";
import CreateNewInvoice from "../components/pages/invoicing/sales/invoices/CreateNewInvoice";
import CreateNewCustomer from "../components/pages/invoicing/sales/customers/CreateNewCustomer";




import PostAddIcon from '@mui/icons-material/PostAdd';
import InsightsIcon from '@mui/icons-material/Insights';
import LayersIcon from '@mui/icons-material/Layers';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import DownloadIcon from '@mui/icons-material/Download';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import ReceiptIcon from '@mui/icons-material/Receipt';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ListEstimate from "../components/pages/invoicing/sales/estimate/ListEstimate";
import ListSalesOrder from "../components/pages/invoicing/sales/order/ListSalesOrder";
import CreateNewBill from "../components/pages/invoicing/purchase/bills/CreateNewBill";
import CreateEstimate from "../components/pages/invoicing/sales/estimate/CreateEstimate";
import Transactions from "../components/pages/bulkStatementUpload/mappings/Transactions";
import CreateSalesOrder from "../components/pages/invoicing/sales/order/CreateSalesOrder";
import VendorViewpage from "../components/pages/invoicing/purchase/vendors/VendorViewpage";
import CreateNewVendor from "../components/pages/invoicing/purchase/vendors/CreateNewVendor";
import CustomerViewpage from "../components/pages/invoicing/sales/customers/CustomerViewpage";
import ListPurchaseOrder from "../components/pages/invoicing/purchase/order/ListPurchaseOrder";
import CreatePurchaseOrder from "../components/pages/invoicing/purchase/order/CreatePurchaseOrder";
import ListPaymentsMade from "../components/pages/invoicing/purchase/paymentsMade/ListPaymentsMade";
import CreatePaymentsMade from "../components/pages/invoicing/purchase/paymentsMade/CreatePaymentsMade";
import ListPaymentsReceived from "../components/pages/invoicing/sales/paymentsReceived/ListPaymentsReceived";
import ListRecurringInvoice from "../components/pages/invoicing/sales/recurringinvoices/ListRecurringInvoice";
import CreatePaymentsReceived from "../components/pages/invoicing/sales/paymentsReceived/CreatePaymentsReceived";
import CreateRecurringInvoice from "../components/pages/invoicing/sales/recurringinvoices/CreateRecurringInvoice";
import OpeningBalanceMapping from "../components/pages/accounting/openingBalanceAdjustment/OpeningBalanceFileMapping";
import RecurringInvoiceViewpage from "../components/pages/invoicing/sales/recurringinvoices/RecurringInvoiceViewpage";
import OpeningBalanceAdjustment from "../components/pages/accounting/openingBalanceAdjustment/OpeningBalanceAdjustment";
import ImportOpeningBalanceFile from "../components/pages/accounting/openingBalanceAdjustment/ImportOpeningBalanceFile";
import OpeningBalanceMappingPreview from "../components/pages/accounting/openingBalanceAdjustment/OpeningBalanceMappingPreview";

import VendorsMapping from "../components/pages/invoicing/purchase/vendors/importVendors/VendorsMapping";
import VendorsPreview from "../components/pages/invoicing/purchase/vendors/importVendors/VendorsPreview";
import CustomersMapping from "../components/pages/invoicing/sales/customers/importCustomers/CustomersMapping";
import CustomersPreview from "../components/pages/invoicing/sales/customers/importCustomers/CustomersPreview";
import VendorsImportFile from "../components/pages/invoicing/purchase/vendors/importVendors/VendorFileImport";
import CustomersImportFile from "../components/pages/invoicing/sales/customers/importCustomers/CustomersFileImport";
import CreateCreditNote from "../components/pages/invoicing/sales/creditNote/CreateCreditNote";
import ListCreditNote from "../components/pages/invoicing/sales/creditNote/ListCreditNote";
import CreateDebitNote from "../components/pages/invoicing/purchase/debitNote/CreateDebitNote";
import ListDebitNote from "../components/pages/invoicing/purchase/debitNote/ListDebitNote";
import RefundCreditNote from "../components/pages/invoicing/sales/creditNote/RefundCreditNote";
import RefundDebitNote from "../components/pages/invoicing/purchase/debitNote/RefundDebitNote";
import CreditNoteViewpage from "../components/pages/invoicing/sales/creditNote/CreditNoteViewpage";
import DebitNoteViewpage from "../components/pages/invoicing/purchase/debitNote/DebitNoteViewpage";
import ItemViewpage from "../components/pages/invoicing/items/ItemViewpage";
import InvoiceViewpage from "../components/pages/invoicing/sales/invoices/InvoiceViewpage";
import BillViewpage from "../components/pages/invoicing/purchase/bills/BillViewpage";
import ViewParentAccount from "../components/pages/accounting/accounts/ViewParentAccount";
import CreateParentAccount from "../components/pages/accounting/accounts/CreateParentAccount";
import CreateMultipleAccounts from "../components/pages/accounting/accounts/CreateMultipleAccounts";
import ViewProfile from "../components/pages/setting/userProfile/ViewProfile";
import ListBusiness from "../components/pages/setting/businessProfile/ListBusiness";
import ViewBusinessProfile from "../components/pages/setting/businessProfile/ViewBusinessProfile";
import MyAccount from "../components/pages/setting/MyAccount";
import PaymentReceivedViewpage from "../components/pages/invoicing/sales/paymentsReceived/PaymentReceivedViewpage";
import PaymentMadeViewpage from "../components/pages/invoicing/purchase/paymentsMade/PaymentMadeViewpage";
import RefundPaymentReceived from "../components/pages/invoicing/sales/paymentsReceived/RefundPaymentReceived";
import RefundPaymentMade from "../components/pages/invoicing/purchase/paymentsMade/RefundPaymentMade";
import DownloadLists from "../components/pages/downloads/DownloadLists";
import CreateDeliveryChallan from "../components/pages/invoicing/sales/deliveryChallan/CreateDeliveryChallan";
import ListDeliveryChallan from "../components/pages/invoicing/sales/deliveryChallan/ListDeliveryChallan";
import DeliveryChallanViewpage from "../components/pages/invoicing/sales/deliveryChallan/DeliveryChallanViewpage";
import VerifyEmail from "../components/pages/userManagement/VerifyEmail";
import ResetPassword from "../components/pages/userManagement/onBoarding/ResetPassword";
import EstimateViewpage from "../components/pages/invoicing/sales/estimate/EstimateViewpage";
import SalesOrderViewpage from "../components/pages/invoicing/sales/order/SalesOrderViewpage";
import PurchaseOrderViewpage from "../components/pages/invoicing/purchase/order/PurchaseOrderViewpage";
import OpenEmailScreen from "../components/pages/userManagement/OpenEmailScreen";
import ConnectAccountWithSocialAccount from "../components/pages/userManagement/ConnectAccountWithSocialAccount";



import ReportsList from "../components/pages/reports/ReportsList";
import TaxSummary from "../components/pages/reports/taxReports/TaxSummary";
import SalesByItem from "../components/pages/reports/salesReports/SalesByItem";
import CashFlow from "../components/pages/reports/financialReports/cashFlow/CashFlow";
import TrialBalance from "../components/pages/reports/accountantReports/TrialBalance";
import SalesByCustomer from "../components/pages/reports/salesReports/SalesByCustomer";
import PurchaseByItem from "../components/pages/reports/PurchaseReports/PurchaseByItem";
import IncomeByCustomer from "../components/pages/reports/salesReports/IncomeByCustomer";
import JournalReports from "../components/pages/reports/accountantReports/JournalReports";
import ARDetailedReports from "../components/pages/reports/salesReports/ARDetailedReports";
import PurchaseByVendor from "../components/pages/reports/PurchaseReports/PurchaseByVendor";
import SalesBySalesPerson from "../components/pages/reports/salesReports/SalesBySalesPerson";
import APDetailedReports from "../components/pages/reports/PurchaseReports/APDetailedReports";
import PaymentsMadeReports from "../components/pages/reports/paymentReports/PaymentsMadeReports";
import ProfitAndLoss from "../components/pages/reports/financialReports/profitLoss/ProfitAndLoss";
import BalanceSheet from "../components/pages/reports/financialReports/balanceSheet/BalanceSheet";
import VendorsRefundReports from "../components/pages/reports/paymentReports/VendorsRefundReports";
import ARAgingSummaryReports from "../components/pages/reports/salesReports/ARAgingSummaryReports";
import TimeToGetPaidReports from "../components/pages/reports/paymentReports/TimeToGetPaidReports";
import AccountTransactions from "../components/pages/reports/accountantReports/AccountTransactions";
import APAgingSummaryReports from "../components/pages/reports/PurchaseReports/APAgingSummaryReports";
import VendorBalancesReports from "../components/pages/reports/PurchaseReports/VendorBalancesReports";
import CustomersRefundReports from "../components/pages/reports/paymentReports/CustomersRefundReports";
import CustomerBalancesReports from "../components/pages/reports/salesReports/CustomerBalancesReports";
import PaymentsReceivedReports from "../components/pages/reports/paymentReports/PaymentsReceivedReports";
import DebitNoteDetailsReports from "../components/pages/reports/paymentReports/DebitNoteDetailsReports";
import CreditNoteDetailsReports from "../components/pages/reports/paymentReports/CreditNoteDetailsReports";
import HorizontalBalanceSheet from "../components/pages/reports/financialReports/balanceSheet/HorizontalBalanceSheet";
import HorizontalProfitAndLoss from "../components/pages/reports/financialReports/profitLoss/HorizontalProfitAndLoss";

import MovementEquityReports from "../components/pages/reports/financialReports/businessPerformance/MovementEquityReports";
import BusinessPerformanceRatios from "../components/pages/reports/financialReports/businessPerformance/BusinessPerformanceRatios";
import TDSSummaryReports from "../components/pages/reports/taxReports/TDSSummaryReports";
import TDSDetailsSectionReports from "../components/pages/reports/taxReports/TDSDetailsSectionReports";
import TCSSummaryReports from "../components/pages/reports/taxReports/TCSSummaryReports";
import SalesOrderDetailsReports from "../components/pages/reports/salesReports/SalesOrderDetailsReports";
import InvoiceDetailsReports from "../components/pages/reports/salesReports/InvoiceDetailsReports";
import RecurringInvoiceDetailsReports from "../components/pages/reports/salesReports/RecurringInvoiceDetailsReports";
import DeliveryChallanDetailsReports from "../components/pages/reports/salesReports/DeliveryChallanDetailsReports";
import EstimateDetailsReports from "../components/pages/reports/salesReports/EstimateDetailsReports";
import PurchaseOrderDetailsReports from "../components/pages/reports/PurchaseReports/PurchaseOrderDetailsReports";
import BillDetailsReports from "../components/pages/reports/PurchaseReports/BillDetailsReports";
import ActivityLogsAndAuditTrailReports from "../components/pages/reports/activityReports/ActivityLogsAndAuditTrailReports";
import GSTFilingLandingpage from "../components/pages/GSTFiling/GSTFilingLandingpage";
import GSTR3B from "../components/pages/GSTFiling/GSTR3B/GSTR3B";
import GSTR1 from "../components/pages/GSTFiling/GSTR1/GSTR1";
import DayBookReports from "../components/pages/reports/accountantReports/DayBookReports";
import RemindersPage from "../components/pages/setting/reminders/RemindersPage";
import CreateNewReminder from "../components/pages/setting/reminders/CreateNewReminder";
import ExpenseList from "../components/pages/invoicing/purchase/expense/ExpenseList";
import CreateNewExpense from "../components/pages/invoicing/purchase/expense/CreateNewExpense";
import ExpenseViewpage from "../components/pages/invoicing/purchase/expense/ExpenseViewpage";
import ItemsImportFile from "../components/pages/invoicing/items/importItems/ItemsFileImport";
import ItemsMapping from "../components/pages/invoicing/items/importItems/ItemsMapping";
import ItemsPreview from "../components/pages/invoicing/items/importItems/ItemsPreview";
import ItemStockReports from "../components/pages/reports/inventoryReports/ItemStockReports";
import Settings from "../components/pages/setting/settings/Settings";
import CreateInvitedUser from "../components/pages/userManagement/CreateInvitedUser";
import ExpenseReports from "../components/pages/reports/PurchaseReports/ExpenseReports";
import LandingPage from "../components/pages/landing/LandingPage";
import BookDemo from "../components/pages/landing/pages/BookDemo";
import ListRecurringExpense from "../components/pages/invoicing/purchase/recurringExpense/ListRecurringExpense";
import CreateRecurringExpense from "../components/pages/invoicing/purchase/recurringExpense/CreateRecurringExpense";
import ViewRecurringExpense from "../components/pages/invoicing/purchase/recurringExpense/ViewRecurringExpense";
import SalesBySalesPersonDetailReport from "../components/pages/reports/salesReports/SalesBySalesPersonDetailReport";
import RecurringExpenseDetailsReports from "../components/pages/reports/PurchaseReports/RecurringExpenseDetailsReports";

import Dashboard from "../components/pages/home/dashboard/Dashboard";
import Insight from "../components/pages/home/insight/Insight";
import Barcode from "../components/pages/invoicing/barcode/Barcode";
import VerifyOtp from "../components/pages/userManagement/onBoarding/VerifyOtp";
import OtpBasedSignup from "../components/pages/userManagement/onBoarding/OtpBasedSignup";
import SigninForm from "../components/pages/userManagement/onBoarding/SigninForm";
import ForgotPassword from "../components/pages/userManagement/onBoarding/ForgotPassword";

import ReceivedInvoiceList from "../components/pages/receivedDocument/invoice/ReceivedInvoiceList";
import ReceivedInvoiceView from "../components/pages/receivedDocument/invoice/ReceivedInvoiceView";


const TrialBalanceTransaction = GeneralLedger;
const ChartsAccountTransaction = GeneralLedger;
const JournalEntryTransaction = GeneralLedger;
const GeneralLedgerTransaction = GeneralLedger;
const ProfitAndLossTransaction = GeneralLedger;
const BalanceSheetTransaction = GeneralLedger;
const CashFlowTransaction = GeneralLedger;

export const landingRoutes = [
    {
        path: routesName.landingPage.path,
        name: routesName.landingPage.name,
        component: <LandingPage />,
    },
    {
        path: routesName.demoPage.path,
        name: routesName.demoPage.name,
        component: <BookDemo />,
    },
]

export const authRoutes = [

    {
        path: routesName.verify.path,
        name: routesName.verify.name,
        component: <VerifyEmail />,
    },
    {
        path: routesName.connect.path,
        name: routesName.connect.name,
        component: <ConnectAccountWithSocialAccount />,
    },
    {
        path: routesName.openEmailAccount.path,
        name: routesName.openEmailAccount.name,
        component: <OpenEmailScreen />,
    },
    {
        path: routesName.forgotPassword.path,
        name: routesName.forgotPassword.name,
        component: <ForgotPassword />,
    },
    {
        path: routesName.restPassword.path,
        name: routesName.restPassword.name,
        component: <ResetPassword />,
    },
    {
        path: routesName.otpBasedSignup.path,
        name: routesName.otpBasedSignup.name,
        component: <OtpBasedSignup />,
    },
    {
        path: routesName.signUp.path,
        name: routesName.signUp.name,
        component: <SignupForm />,
    },
    {
        path: routesName.verifyOtp.path,
        name: routesName.verifyOtp.name,
        component: <VerifyOtp />,
    }, {
        path: routesName.signIn.path,
        name: routesName.signIn.name,
        component: <SigninForm />,
    }, {
        path: routesName.businessCreate.path,
        name: routesName.businessCreate.name,
        component: <CreateCompany />,
    }, {
        path: routesName.invitedUser.path,
        name: routesName.invitedUser.name,
        component: <CreateInvitedUser />,
    },
];


export const mainRoutes = [
    {
        path: routesName.myAccount.path,
        name: routesName.myAccount.name,
        component: <MyAccount />,
    },
    {
        path: routesName.settings.path,
        name: routesName.settings.name,
        component: <Settings />,
    },
    {
        path: routesName.myProfile.path,
        name: routesName.myProfile.name,
        component: <ViewProfile />,
    },
    {
        path: routesName.myBusiness.path,
        name: routesName.myBusiness.name,
        component: <ListBusiness />,
    },
    {
        path: routesName.myBusinessView.path,
        name: routesName.myBusinessView.name,
        component: <ViewBusinessProfile />,
    },
    {
        path: routesName.businessCreate.path,
        name: routesName.businessCreate.name,
        component: <CreateCompany />,
    }, {
        path: routesName.invitedUser.path,
        name: routesName.invitedUser.name,
        component: <CreateInvitedUser />,
    },
    {
        path: routesName.chartsAccountList.path,
        name: routesName.chartsAccountList.name,
        component: <ChartofAccount />,
    }, {
        path: routesName.chartsAccountAdd.path,
        name: routesName.chartsAccountAdd.name,
        component: <CreateMultipleAccounts />
    }, {
        path: routesName.chartsAccountTransaction.path,
        name: routesName.chartsAccountTransaction.name,
        component: <ChartsAccountTransaction />,
    },
    {
        path: routesName.journalEntryList.path,
        name: routesName.journalEntryList.name,
        component: <GetListJournalEntry />,
    }, {
        path: routesName.journalEntryTransaction.path,
        name: routesName.journalEntryTransaction.name,
        component: <JournalEntryTransaction />,
    }, {
        path: routesName.journalEntryCreate.path,
        name: routesName.journalEntryCreate.name,
        component: <CreateJournalEntry />,
    }, {
        path: routesName.generalLedgerList.path,
        name: routesName.generalLedgerList.name,
        component: <ListGeneralLedger />,
    }, {
        path: routesName.generalLedgerTransaction.path,
        name: routesName.generalLedgerTransaction.name,
        component: <GeneralLedgerTransaction />,
    }, {
        path: routesName.profitLoss.path,
        name: routesName.profitLoss.name,
        component: <ProfitAndLoss />,
    },
    {
        path: routesName.profitLossTransaction.path,
        name: routesName.profitLossTransaction.name,
        component: <ProfitAndLossTransaction />,
    },
    {
        path: routesName.horizontalProfitLossTransaction.path,
        name: routesName.horizontalProfitLossTransaction.name,
        component: <ProfitAndLossTransaction />,
    },
    {
        path: routesName.horizontalProfitLoss.path,
        name: routesName.horizontalProfitLoss.name,
        component: <HorizontalProfitAndLoss />,
    }, {
        path: routesName.mappings.path,
        name: routesName.mappings.name,
        component: <DndProvider backend={HTML5Backend}><Mappings /></DndProvider>,
    }, {
        path: routesName.fileUpload.path,
        name: routesName.fileUpload.name,
        component: <FileUpload />,
    }, {
        path: routesName.transactions.path,
        name: routesName.transactions.name,
        component: <Transactions />,
    }, {
        path: routesName.sourceTransactions.path,
        name: routesName.sourceTransactions.name,
        component: <SourceTransactions />,
    }, {
        path: routesName.itemStockReports.path,
        name: routesName.itemStockReports.name,
        component: <ItemStockReports />,
    },
    {
        path: routesName.reportsList.path,
        name: routesName.reportsList.name,
        component: <ReportsList />,
    },
    {
        path: routesName.balanceSheet.path,
        name: routesName.balanceSheet.name,
        component: <BalanceSheet />,
    },
    {
        path: routesName.balanceSheetTransaction.path,
        name: routesName.balanceSheetTransaction.name,
        component: <BalanceSheetTransaction />,
    },

    {
        path: routesName.horizontalBalanceSheetTransaction.path,
        name: routesName.horizontalBalanceSheetTransaction.name,
        component: <BalanceSheetTransaction />,
    },
    {
        path: routesName.horizontalBalanceSheet.path,
        name: routesName.horizontalBalanceSheet.name,
        component: <HorizontalBalanceSheet />,
    },
    {
        path: routesName.movementEquityReports.path,
        name: routesName.movementEquityReports.name,
        component: <MovementEquityReports />,
    },
    {
        path: routesName.businessPerformanceRatiosReports.path,
        name: routesName.businessPerformanceRatiosReports.name,
        component: <BusinessPerformanceRatios />,
    },
    {
        path: routesName.journalReports.path,
        name: routesName.journalReports.name,
        component: <JournalReports />,
    },
    {
        path: routesName.timeToGetPaidReports.path,
        name: routesName.timeToGetPaidReports.name,
        component: <TimeToGetPaidReports />,
    },
    {
        path: routesName.cashFlowTransaction.path,
        name: routesName.cashFlowTransaction.name,
        component: <CashFlowTransaction />,
    },
    {
        path: routesName.cashFlow.path,
        name: routesName.cashFlow.name,
        component: <CashFlow />,
    },
    {
        path: routesName.trialBalance.path,
        name: routesName.trialBalance.name,
        component: <TrialBalance />,
    }, {
        path: routesName.trialBalanceTransaction.path,
        name: routesName.trialBalanceTransaction.name,
        component: <TrialBalanceTransaction />,
    },
    {
        path: routesName.accountTransactionReports.path,
        name: routesName.accountTransactionReports.name,
        component: <AccountTransactions />,
    },
    {
        path: routesName.dayBookReports.path,
        name: routesName.dayBookReports.name,
        component: <DayBookReports />,
    },
    {
        path: routesName.salesByCustomer.path,
        name: routesName.salesByCustomer.name,
        component: <SalesByCustomer />,
    },
    {
        path: routesName.customerBalancesReports.path,
        name: routesName.customerBalancesReports.name,
        component: <CustomerBalancesReports />,
    },
    {
        path: routesName.arAgingSummaryReports.path,
        name: routesName.arAgingSummaryReports.name,
        component: <ARAgingSummaryReports />,
    },
    {
        path: routesName.apAgingSummaryReports.path,
        name: routesName.apAgingSummaryReports.name,
        component: <APAgingSummaryReports />,
    },
    {
        path: routesName.arDetailedReports.path,
        name: routesName.arDetailedReports.name,
        component: <ARDetailedReports />,
    },
    {
        path: routesName.apDetailedReports.path,
        name: routesName.apDetailedReports.name,
        component: <APDetailedReports />,
    },
    {
        path: routesName.estimateDetailsReports.path,
        name: routesName.estimateDetailsReports.name,
        component: <EstimateDetailsReports />,
    },
    {
        path: routesName.salesOrderDetailsReports.path,
        name: routesName.salesOrderDetailsReports.name,
        component: <SalesOrderDetailsReports />,
    },
    {
        path: routesName.invoiceDetailsReports.path,
        name: routesName.invoiceDetailsReports.name,
        component: <InvoiceDetailsReports />,
    },
    {
        path: routesName.recurringInvoiceDetailsReports.path,
        name: routesName.recurringInvoiceDetailsReports.name,
        component: <RecurringInvoiceDetailsReports />,
    },
    {
        path: routesName.deliveryChallanDetailsReports.path,
        name: routesName.deliveryChallanDetailsReports.name,
        component: <DeliveryChallanDetailsReports />,
    },
    {
        path: routesName.purchaseOrderDetailsReports.path,
        name: routesName.purchaseOrderDetailsReports.name,
        component: <PurchaseOrderDetailsReports />,
    },
    {
        path: routesName.billDetailsReports.path,
        name: routesName.billDetailsReports.name,
        component: <BillDetailsReports />,
    },
    {
        path: routesName.vendorBalancesReports.path,
        name: routesName.vendorBalancesReports.name,
        component: <VendorBalancesReports />,
    }, {
        path: routesName.expenseDetailsReports.path,
        name: routesName.expenseDetailsReports.name,
        component: <ExpenseReports />,
    },
    {
        path: routesName.recurringExpenseDetailsReports.path,
        name: routesName.recurringExpenseDetailsReports.name,
        component: <RecurringExpenseDetailsReports />,
    },
    {
        path: routesName.salesByItem.path,
        name: routesName.salesByItem.name,
        component: <SalesByItem />,
    },
    {
        path: routesName.salesBySalesPerson.path,
        name: routesName.salesBySalesPerson.name,
        component: <SalesBySalesPerson />,
    },
    {
        path: routesName.salesBySalesPersonView.path,
        name: routesName.salesBySalesPersonView.name,
        component: <SalesBySalesPersonDetailReport />,
    },
    {
        path: routesName.incomeByCustomer.path,
        name: routesName.incomeByCustomer.name,
        component: <IncomeByCustomer />,
    },
    {
        path: routesName.purchaseByVendor.path,
        name: routesName.purchaseByVendor.name,
        component: <PurchaseByVendor />,
    },
    {
        path: routesName.purchaseByItem.path,
        name: routesName.purchaseByItem.name,
        component: <PurchaseByItem />,
    },
    {
        path: routesName.taxSummary.path,
        name: routesName.taxSummary.name,
        component: <TaxSummary />,
    },
    {
        path: routesName.tdsSummaryReports.path,
        name: routesName.tdsSummaryReports.name,
        component: <TDSSummaryReports />,
    },
    {
        path: routesName.tcsSummaryReports.path,
        name: routesName.tcsSummaryReports.name,
        component: <TCSSummaryReports />,
    },
    {
        path: routesName.tdsDetailsSectionReports.path,
        name: routesName.tdsDetailsSectionReports.name,
        component: <TDSDetailsSectionReports />,
    },
    {
        path: routesName.paymentsReceivedReports.path,
        name: routesName.paymentsReceivedReports.name,
        component: <PaymentsReceivedReports />,
    },
    {
        path: routesName.paymentsMadeReports.path,
        name: routesName.paymentsMadeReports.name,
        component: <PaymentsMadeReports />,
    },
    {
        path: routesName.creditNoteDetailsReports.path,
        name: routesName.creditNoteDetailsReports.name,
        component: <CreditNoteDetailsReports />,
    },
    {
        path: routesName.debitNoteDetailsReports.path,
        name: routesName.debitNoteDetailsReports.name,
        component: <DebitNoteDetailsReports />,
    },
    {
        path: routesName.customersRefundReports.path,
        name: routesName.customersRefundReports.name,
        component: <CustomersRefundReports />,
    },
    {
        path: routesName.vendorsRefundReports.path,
        name: routesName.vendorsRefundReports.name,
        component: <VendorsRefundReports />,
    },
    {
        path: routesName.downloadList.path,
        name: routesName.downloadList.name,
        component: <DownloadLists />,
    },
    {
        path: routesName.invoicingItems.path,
        name: routesName.invoicingItems.name,
        component: <ItemsList />,
    },
    {
        path: routesName.invoicingItemsBarcode.path,
        name: routesName.invoicingItemsBarcode.name,
        component: <Barcode />,
    },
    {
        path: routesName.invoicingItemsAdd.path,
        name: routesName.invoicingItemsAdd.name,
        component: <CreateItems />,
    }, {
        path: routesName.invoicingItemsView.path,
        name: routesName.invoicingItemsView.name,
        component: <ItemViewpage />,
    }, {
        path: routesName.invoicingItemsFileUpload.path,
        name: routesName.invoicingItemsFileUpload.name,
        component: <ItemsImportFile />,
    }, {
        path: routesName.invoicingItemsMapping.path,
        name: routesName.invoicingItemsMapping.name,
        component: <DndProvider backend={HTML5Backend}><ItemsMapping /></DndProvider>,
    }, {
        path: routesName.invoicingItemsPreview.path,
        name: routesName.invoicingItemsPreview.name,
        component: <ItemsPreview />,
    }, {
        path: routesName.invoicingSalesCustomer.path,
        name: routesName.invoicingSalesCustomer.name,
        component: <CustomersList />,
    }, {
        path: routesName.invoicingSalesCustomerAdd.path,
        name: routesName.invoicingSalesCustomerAdd.name,
        component: <CreateNewCustomer />,
    }, {
        path: routesName.invoicingSalesCustomerView.path,
        name: routesName.invoicingSalesCustomerView.name,
        component: <CustomerViewpage />,
    }, {
        path: routesName.invoicingSalesEstimateAdd.path,
        name: routesName.invoicingSalesEstimateAdd.name,
        component: <CreateEstimate />,
    }, {
        path: routesName.invoicingSalesEstimateView.path,
        name: routesName.invoicingSalesEstimateView.name,
        component: <EstimateViewpage />,
    }, {
        path: routesName.invoicingSalesEstimate.path,
        name: routesName.invoicingSalesEstimate.name,
        component: <ListEstimate />,
    }, {
        path: routesName.invoicingSalesInvoiceAdd.path,
        name: routesName.invoicingSalesInvoiceAdd.name,
        component: <CreateNewInvoice />,
    }, {
        path: routesName.invoicingSalesInvoice.path,
        name: routesName.invoicingSalesInvoice.name,
        component: <InvoicesList />,
    },
    {
        path: routesName.invoicingSalesInvoiceView.path,
        name: routesName.invoicingSalesInvoiceView.name,
        component: <InvoiceViewpage />,
    }, {
        path: routesName.invoicingSalesRecurringInvoiceAdd.path,
        name: routesName.invoicingSalesRecurringInvoiceAdd.name,
        component: <CreateRecurringInvoice />,
    },
    {
        path: routesName.invoicingSalesRecurringInvoiceView.path,
        name: routesName.invoicingSalesRecurringInvoiceView.name,
        component: <RecurringInvoiceViewpage />,
    },
    {
        path: routesName.invoicingSalesRecurringInvoice.path,
        name: routesName.invoicingSalesRecurringInvoice.name,
        component: <ListRecurringInvoice />,
    },
    {
        path: routesName.invoicingPurchaseVendor.path,
        name: routesName.invoicingPurchaseVendor.name,
        component: <VendorsList />,
    },
    {
        path: routesName.invoicingPurchaseVendorAdd.path,
        name: routesName.invoicingPurchaseVendorAdd.name,
        component: <CreateNewVendor />,
    },
    {
        path: routesName.invoicingPurchaseVendorView.path,
        name: routesName.invoicingPurchaseVendorView.name,
        component: <VendorViewpage />,
    },
    {
        path: routesName.invoicingPurchaseBill.path,
        name: routesName.invoicingPurchaseBill.name,
        component: <BillsList />,
    },
    {
        path: routesName.invoicingPurchaseBillAdd.path,
        name: routesName.invoicingPurchaseBillAdd.name,
        component: <CreateNewBill />
    },
    {
        path: routesName.invoicingPurchaseBillView.path,
        name: routesName.invoicingPurchaseBillView.name,
        component: <BillViewpage />
    },
    {
        path: routesName.invoicingSalesOrder.path,
        name: routesName.invoicingSalesOrder.name,
        component: <ListSalesOrder />
    },
    {
        path: routesName.invoicingSalesOrderAdd.path,
        name: routesName.invoicingSalesOrderAdd.name,
        component: <CreateSalesOrder />
    },
    {
        path: routesName.activityAuditTrailReports.path,
        name: routesName.activityAuditTrailReports.name,
        component: <ActivityLogsAndAuditTrailReports />
    },
    {
        path: routesName.invoicingSalesOrderView.path,
        name: routesName.invoicingSalesOrderView.name,
        component: <SalesOrderViewpage />
    },
    {
        path: routesName.invoicingDeliveryChallan.path,
        name: routesName.invoicingDeliveryChallan.name,
        component: <ListDeliveryChallan />
    },
    {
        path: routesName.invoicingDeliveryChallanAdd.path,
        name: routesName.invoicingDeliveryChallanAdd.name,
        component: <CreateDeliveryChallan />
    },
    {
        path: routesName.invoicingDeliveryChallanView.path,
        name: routesName.invoicingDeliveryChallanView.name,
        component: <DeliveryChallanViewpage />
    },
    {
        path: routesName.invoicingPaymentsReceived.path,
        name: routesName.invoicingPaymentsReceived.name,
        component: <CreatePaymentsReceived />
    },
    {
        path: routesName.invoicingPaymentsReceivedView.path,
        name: routesName.invoicingPaymentsReceivedView.name,
        component: <PaymentReceivedViewpage />
    },
    {
        path: routesName.invoicingPaymentsReceivedRefund.path,
        name: routesName.invoicingPaymentsReceivedRefund.name,
        component: <RefundPaymentReceived />
    },
    {
        path: routesName.invoicingPaymentsReceivedList.path,
        name: routesName.invoicingPaymentsReceivedList.name,
        component: <ListPaymentsReceived />
    },
    {
        path: routesName.invoicingExpense.path,
        name: routesName.invoicingExpense.name,
        component: <ExpenseList />
    },
    {
        path: routesName.invoicingExpenseAdd.path,
        name: routesName.invoicingExpenseAdd.name,
        component: <CreateNewExpense />
    },
    {
        path: routesName.invoicingExpenseView.path,
        name: routesName.invoicingExpenseView.name,
        component: <ExpenseViewpage />
    },
    {
        path: routesName.invoicingRecurringExpense.path,
        name: routesName.invoicingRecurringExpense.name,
        component: <ListRecurringExpense />
    },
    {
        path: routesName.invoicingRecurringExpenseAdd.path,
        name: routesName.invoicingRecurringExpenseAdd.name,
        component: <CreateRecurringExpense />
    },
    {
        path: routesName.invoicingRecurringExpenseView.path,
        name: routesName.invoicingRecurringExpenseView.name,
        component: <ViewRecurringExpense />
    },
    {
        path: routesName.invoicingPurchaseOrder.path,
        name: routesName.invoicingPurchaseOrder.name,
        component: <ListPurchaseOrder />
    },
    {
        path: routesName.invoicingPurchaseOrderAdd.path,
        name: routesName.invoicingPurchaseOrderAdd.name,
        component: <CreatePurchaseOrder />
    },
    {
        path: routesName.invoicingPurchaseOrderView.path,
        name: routesName.invoicingPurchaseOrderView.name,
        component: <PurchaseOrderViewpage />
    },
    {
        path: routesName.invoicingPaymentsMade.path,
        name: routesName.invoicingPaymentsMade.name,
        component: <CreatePaymentsMade />
    },
    {
        path: routesName.invoicingPaymentsMadeView.path,
        name: routesName.invoicingPaymentsMadeView.name,
        component: <PaymentMadeViewpage />
    },
    {
        path: routesName.invoicingPaymentsMadeRefund.path,
        name: routesName.invoicingPaymentsMadeRefund.name,
        component: <RefundPaymentMade />
    },
    {
        path: routesName.invoicingPaymentsMadeList.path,
        name: routesName.invoicingPaymentsMadeList.name,
        component: <ListPaymentsMade />
    },
    {
        path: routesName.dashboard.path,
        name: routesName.dashboard.name,
        component: <Dashboard />
    },
    {
        path: routesName.insight.path,
        name: routesName.insight.name,
        component: <Insight />
    },
    {
        path: routesName.openingBalanceAdjustment.path,
        name: routesName.openingBalanceAdjustment.name,
        component: <OpeningBalanceAdjustment />
    },
    {
        path: routesName.openingBalanceImport.path,
        name: routesName.openingBalanceImport.name,
        component: <ImportOpeningBalanceFile />
    },
    {
        path: routesName.openingBalanceMapping.path,
        name: routesName.openingBalanceMapping.name,
        component: <DndProvider backend={HTML5Backend}><OpeningBalanceMapping /></DndProvider>
    },
    {
        path: routesName.openingBalancePreview.path,
        name: routesName.openingBalancePreview.name,
        component: <OpeningBalanceMappingPreview />
    },

    {
        path: routesName.invoicingSalesCustomersImportFile.path,
        name: routesName.invoicingSalesCustomersImportFile.name,
        component: <CustomersImportFile />
    },

    {
        path: routesName.invoicingSalesCustomersImportMapping.path,
        name: routesName.invoicingSalesCustomersImportMapping.name,
        component: <DndProvider backend={HTML5Backend}><CustomersMapping /></DndProvider>
    }, {
        path: routesName.invoicingSalesCustomersImportPreview.path,
        name: routesName.invoicingSalesCustomersImportPreview.name,
        component: <CustomersPreview />
    }, {
        path: routesName.invoicingPurchaseVendorImportFile.path,
        name: routesName.invoicingSalesCustomersImportFile.name,
        component: <VendorsImportFile />
    }, {
        path: routesName.invoicingPurchaseVendorImportMapping.path,
        name: routesName.invoicingPurchaseVendorImportMapping.name,
        component: <DndProvider backend={HTML5Backend}><VendorsMapping /></DndProvider>
    }, {
        path: routesName.invoicingPurchaseVendorImportPreview.path,
        name: routesName.invoicingPurchaseVendorImportPreview.name,
        component: <VendorsPreview />
    }, {
        path: routesName.invoicingCreditNotesAdd.path,
        name: routesName.invoicingCreditNotesAdd.name,
        component: <CreateCreditNote />
    }, {
        path: routesName.invoicingCreditNotesList.path,
        name: routesName.invoicingCreditNotesList.name,
        component: <ListCreditNote />
    }, {
        path: routesName.invoicingCreditNotesView.path,
        name: routesName.invoicingCreditNotesView.name,
        component: <CreditNoteViewpage />
    }, {
        path: routesName.invoicingCreditNotesRefund.path,
        name: routesName.invoicingCreditNotesRefund.name,
        component: <RefundCreditNote />
    }, {
        path: routesName.invoicingDebitNotesRefund.path,
        name: routesName.invoicingDebitNotesRefund.name,
        component: <RefundDebitNote />
    }, {
        path: routesName.invoicingDebitNotesAdd.path,
        name: routesName.invoicingDebitNotesAdd.name,
        component: <CreateDebitNote />
    }, {
        path: routesName.invoicingDebitNotesView.path,
        name: routesName.invoicingDebitNotesView.name,
        component: <DebitNoteViewpage />
    }, {
        path: routesName.invoicingDebitNotesList.path,
        name: routesName.invoicingDebitNotesList.name,
        component: <ListDebitNote />
    },
    {
        path: routesName.parentAccount.path,
        name: routesName.parentAccount.name,
        component: <ViewParentAccount />
    },
    {
        path: routesName.parentAccountAdd.path,
        name: routesName.parentAccountAdd.name,
        component: <CreateParentAccount />
    },
    {
        path: routesName.gstFilingComponent.path,
        name: routesName.gstFilingComponent.name,
        component: <GSTFilingLandingpage />
    },
    {
        path: routesName.gstr3BFilingComponent.path,
        name: routesName.gstr3BFilingComponent.name,
        component: <GSTR3B />
    },
    {
        path: routesName.gstr1FilingComponent.path,
        name: routesName.gstr1FilingComponent.name,
        component: <GSTR1 />
    },
    {
        path: routesName.remindersComponent.path,
        name: routesName.remindersComponent.name,
        component: <RemindersPage />
    },
    {
        path: routesName.remindersComponentAdd.path,
        name: routesName.remindersComponentAdd.name,
        component: <CreateNewReminder />
    },
    {
        path: routesName.support.path,
        name: routesName.support.name,
        component: <Support />
    },
    {
        path: routesName.receivedInvoiceList.path,
        name: routesName.receivedInvoiceList.name,
        component: <ReceivedInvoiceList />
    },
    {
        path: routesName.receivedInvoiceView.path,
        name: routesName.receivedInvoiceView.name,
        component: <ReceivedInvoiceView />
    },


];



export const sidebarMenu = [
    {
        items: [],
        ml: 1, mt: 1,
        icon: <DashboardIcon />,
        to: routesName.dashboard.path,
        title: routesName.dashboard.name,
        eventName: eventsNames.actions.sidebar.DASHBOARD,

    },
    {
        ml: 1, mt: 1,
        items: [],
        icon: <InsightsIcon />,
        to: routesName.insight.path,
        title: routesName.insight.name,
        eventName: eventsNames.actions.sidebar.INSIGHT,


    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <UploadFileIcon />,
        to: routesName.fileUpload.path,
        title: routesName.fileUpload.name,
        eventName: eventsNames.actions.sidebar.STATEMENT_UPLOAD,

    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <LayersIcon />,
        to: routesName.sourceTransactions.path,
        title: routesName.sourceTransactions.name,
        eventName: eventsNames.actions.sidebar.SOURCE_TRANSACTIONS,

    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <InventoryIcon />,
        to: routesName.invoicingItems.path,
        title: routesName.invoicingItems.name,
        eventName: eventsNames.actions.sidebar.ITEMS,

    },
    {
        ml: 1, mt: 1,
        title: 'Sales',
        icon: <ReceiptIcon />,
        items: [
            { eventName: eventsNames.actions.sidebar.SALES.CUSTOMERS, title: routesName.invoicingSalesCustomer.name, to: routesName.invoicingSalesCustomer.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.SALES.ESTIMATES, title: routesName.invoicingSalesEstimate.name, to: routesName.invoicingSalesEstimate.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.SALES.SALES_ORDERS, title: routesName.invoicingSalesOrder.name, to: routesName.invoicingSalesOrder.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.SALES.DELIVERY_CHALLANS, title: routesName.invoicingDeliveryChallan.name, to: routesName.invoicingDeliveryChallan.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.SALES.INVOICES, title: routesName.invoicingSalesInvoice.name, to: routesName.invoicingSalesInvoice.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.SALES.RECURRING_INVOICES, title: routesName.invoicingSalesRecurringInvoice.name, to: routesName.invoicingSalesRecurringInvoice.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.SALES.PAYMENTS_RECEIVED, title: routesName.invoicingPaymentsReceivedList.name, to: routesName.invoicingPaymentsReceivedList.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.SALES.CREDIT_NOTES, title: routesName.invoicingCreditNotesList.name, to: routesName.invoicingCreditNotesList.path, ml: 3.5, mt: 0 },
        ]
    },
    {
        ml: 1, mt: 1,
        title: 'Purchase',
        icon: <ShoppingBagIcon />,
        items: [
            { eventName: eventsNames.actions.sidebar.PURCHASE.VENDORS, title: routesName.invoicingPurchaseVendor.name, to: routesName.invoicingPurchaseVendor.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.PURCHASE.EXPENSES, title: routesName.invoicingExpense.name, to: routesName.invoicingExpense.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.PURCHASE.RECURRING_EXPENSES, title: routesName.invoicingRecurringExpense.name, to: routesName.invoicingRecurringExpense.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.PURCHASE.PURCHASE_ORDERS, title: routesName.invoicingPurchaseOrder.name, to: routesName.invoicingPurchaseOrder.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.PURCHASE.BILLS, title: routesName.invoicingPurchaseBill.name, to: routesName.invoicingPurchaseBill.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.PURCHASE.PAYMENTS_MADE, title: routesName.invoicingPaymentsMadeList.name, to: routesName.invoicingPaymentsMadeList.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.PURCHASE.DEBIT_NOTE, title: routesName.invoicingDebitNotesList.name, to: routesName.invoicingDebitNotesList.path, ml: 3.5, mt: 0 },

        ]
    },
    // {
    //     ml: 1, mt: 1,
    //     title: 'Received Document',
    //     icon: <PostAddIcon />,
    //     items: [
    //         { eventName: eventsNames.actions.sidebar.RECEIVEDDOCUMENT.INVOICE, title: routesName.receivedInvoiceList.name, to: routesName.receivedInvoiceList.path, ml: 3.5, mt: 0 },
    //     ]
    // },
    {
        ml: 1, mt: 1,
        title: 'Accounting',
        icon: <AccountBalanceIcon />,
        items: [
            { eventName: eventsNames.actions.sidebar.ACCOUNTING.JOURNAL_ENTRY, title: routesName.journalEntryList.name, to: routesName.journalEntryList.path, ml: 3.5, mt: 0 },
            { eventName: eventsNames.actions.sidebar.ACCOUNTING.CHART_OF_ACCOUNTS, title: routesName.chartsAccountList.name, to: routesName.chartsAccountList.path, ml: 3.5, mt: 0 },
        ]
    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <ArticleIcon />,
        to: routesName.gstFilingComponent.path,
        title: routesName.gstFilingComponent.name,
        eventName: eventsNames.actions.sidebar.GST_FILING,

    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <AssessmentIcon />,
        to: routesName.reportsList.path,
        title: routesName.reportsList.name,
        eventName: eventsNames.actions.sidebar.REPORTS,

    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <DownloadIcon />,
        to: routesName.downloadList.path,
        title: routesName.downloadList.name,
        eventName: eventsNames.actions.sidebar.DOWNLOAD,
    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <SettingsIcon />,
        title: routesName.settings.name,
        eventName: eventsNames.actions.sidebar.SETTINGS,
        to: routesName.settings.path + '?action=personal_information',


    },
    {
        items: [],
        ml: 1, mt: 1,
        icon: <SupportAgentIcon />,
        to: routesName.support.path,
        title: routesName.support.name,
        eventName: eventsNames.actions.sidebar.SUPPORT,


    },

]

