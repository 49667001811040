import React from 'react';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import ModalUpdateUserDetails from './onBoarding/ModalUpdateUserDetails';
import SelectBusiness from '../businessManagement/selectBusiness/SelectBusiness';

import {
    getUserPreferenceData,
    updateUserPreferencesData,
    getBusinessPreferenceData,
} from '../setting/settings/Preferences/common/getPreferences';

import {
    apiAction,
    apiAction_social,
} from '../../../api/api';

import {
    google_login,
    retrieve_user,
    google_connect,
    get_google_login,
    retrieve_business,
    get_list_of_business,
} from '../../../api/urls';

import {
    setLoader,
    getQueryParams,
    stateChangeManager,
} from '../../../utils/Utils';

import {
    setToken,
    getToken,
    clearCookie,
    setLoginStatus,
    setUserDetails,
    setBusinessInfo,
    getSessionStorage,
} from '../../../config/cookiesInfo';

import { Button } from '@mui/material';
import { Google } from '@mui/icons-material';

const SocialLogin = ({ fullWidth = true, AnalyticsEvent, btnText = 'Login with Google' }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = getQueryParams(location.search);
    const sessionStorage = getSessionStorage('receivedInvoice')

    const [show, setShow] = React.useState(false);
    const [isMissingData, setIsMissingData] = React.useState(false);
    const dispatch = Actions.getDispatch(React.useContext);


    React.useEffect(() => {
        if (params.code) {
            if (getToken()) {
                connectWithGoogle(params.code);
            } else {
                loginWithGoogle(params.code);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.code]);

    const loginWithGoogle = async (code) => {
        setLoader(dispatch, Actions, true);
        const res = await apiAction_social({
            method: 'post',
            url: google_login(),
            data: { code, id_token: '', access_token: '' },
        });

        if (res?.data?.access_token) {
            setUserDetails(res?.data?.user);
            setToken(res?.data?.access_token);

            handleMissingInfoRedirect({ is_mobile_number_empty: res?.is_mobile_number_empty, is_email_empty: res?.is_email_empty });
        } else {
            handleLoginError();
        }
    };

    const connectWithGoogle = async (code) => {
        setLoader(dispatch, Actions, true);

        const res = await apiAction({
            method: 'post',
            url: google_connect(),
            data: { code, id_token: '', access_token: '' },
        });

        if (res?.data?.access_token) {
            setUserDetails(res?.data?.user);
            setToken(res?.data?.access_token);

            handleMissingInfoRedirect({ is_mobile_number_empty: res?.is_mobile_number_empty, is_email_empty: res?.is_email_empty });
        } else {
            handleLoginError(true);
        }
    };

    const handleLoginError = (isConnect = false) => {
        setLoader(dispatch, Actions, false);
        navigate(`/auth${routesName.connect.path}`);
        if (isConnect) {
            stateChangeManager(dispatch, Actions, true, 'error', `We've found an existing account with the email address associated with your social account. To link the two accounts, please login with the email and password associated with your existing account.`);
        }
    };

    const handleMissingInfoRedirect = async ({ is_email_empty, is_mobile_number_empty }) => {
        setLoader(dispatch, Actions, false);

        if (is_mobile_number_empty) {
            setIsMissingData('is_mobile_number_empty');
        } else if (is_email_empty) {
            setIsMissingData('is_email_empty');
        } else if (!(is_mobile_number_empty && is_email_empty)) {
            fetchBusinessList();
        }
    };

    const fetchBusinessList = async () => {
        const res = await apiAction({ method: 'get', url: get_list_of_business() });
        if (res?.success) {
            const businessList = res?.result;
            if (businessList?.length) {
                const res = await getUserPreferenceData(navigate, dispatch);
                if (res?.success) {
                    const userInfo = res?.result;
                    fetchUserProfile(userInfo?.business_id, businessList);
                }

            } else {
                redirectToBusinessCreation();
            }

        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const fetchUserProfile = async (businessId, businessList) => {
        const res = await apiAction({ method: 'get', url: retrieve_user() });

        if (res?.success) {
            setUserDetails(res.result);
            fetchBusinessDetails(businessId, businessList);
        }
    };

    const fetchBusinessDetails = async (businessId, businessList) => {
        if (sessionStorage && sessionStorage?.link_uid) {
            setShow(true);
        } else {
            if (businessList.length) {
                const selectedBusinessId = businessId || businessList?.[0]?.id;
                const businessRes = await apiAction({
                    method: 'get',
                    url: retrieve_business(selectedBusinessId),
                });

                if (businessRes?.success) {
                    if (!businessId) {
                        updateUserPreferencesData(selectedBusinessId, navigate, dispatch);
                    }
                    setLoginStatus('true');
                    setBusinessInfo(businessRes?.result);
                    getBusinessPreferenceData(businessRes?.result?.id);

                    navigate(routesName.dashboard.path);
                    setLoader(dispatch, Actions, false);
                }
            } else {
                redirectToBusinessCreation();
                setLoader(dispatch, Actions, false);
            }
        }

    };

    const redirectToBusinessCreation = () => {
        setLoader(dispatch, Actions, false);
        navigate(`/auth${routesName.businessCreate.path}`);
    };


    const handleBusinessRedirect = () => {
        fetchBusinessList();
    };


    if (isMissingData) {
        return (
            <ModalUpdateUserDetails
                open={isMissingData}
                setOpen={() => {
                    setIsMissingData(false);
                    handleBusinessRedirect();
                }}
            />
        );
    }

    if (show) {
        return <SelectBusiness show={show} data={sessionStorage} />
    }

    return (
        !params.code && (
            <Button
                id="get_google_login_btn"
                dataTestId="get_google_login_btn"
                fullWidth={fullWidth}
                variant="contained"
                href={get_google_login()}
                onClick={() => {
                    clearCookie();
                    setLoader(dispatch, Actions, true);
                    AnalyticsEvent?.();
                }}
                sx={{
                    mt: 3,
                    color: 'white',
                    background: '#dd4b39',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#dd4b39',
                        opacity: 1,
                    },
                }}
            >
                <Google sx={{ mr: 1.5 }} />
                {btnText}
            </Button>
        )
    );
};

export default SocialLogin;
