import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { Box } from '@mui/material';

const NewBanner = ({ navigateToSignup, textContent }) => {
    const bannerText = <span>{textContent?.title}</span>;
    const subText = <span><p>{textContent?.subtitle}</p></span>;

    return (
        <Box sx={styles.root} className='banner-root'>
            <Custom.BackgroundImageContainer sx={styles.bannerContainer}>
                <Custom.Container>
                    <Box sx={styles.contentBox}>
                        {/* Left SVG */}
                        <Box sx={styles.svgContainer}>
                            <svg width="100%" height="100%" viewBox="0 0 275 190" preserveAspectRatio="xMidYMid meet" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.687866 123.244L148.324 162.424L184.689 16.9596L0.687866 123.244Z" fill="#5D92FF"></path>
                                <path d="M260.026 75.9958C253.409 74.2231 251.207 99.1988 242.077 96.7555C232.947 94.3121 232.722 68.6813 223.597 66.2244C214.473 63.7674 214.776 89.4293 205.65 86.986C196.524 84.5427 196.293 58.9099 187.159 56.4626C178.025 54.0154 178.34 79.6657 169.214 77.2223C160.088 74.779 159.857 49.1462 150.732 46.6892C141.608 44.2323 141.913 69.8923 132.785 67.4509C123.657 65.0095 122.759 39.2154 114.278 36.9431" stroke="black"></path>
                            </svg>
                        </Box>

                        {/* Center Content */}
                        <Box sx={styles.centerContent}>
                            <BannerText text={bannerText} />
                            <SubTitleText text={subText} />
                            <BannerButton 
                                text="Signup now for free" 
                                variant="gradient" 
                                navigateToSignup={navigateToSignup} 
                            />
                            <BannerText 
                                text={<span>Plan starting from <strong>₹ 500/month</strong></span>} 
                                sx={styles.subText} 
                            />
                        </Box>

                        {/* Right SVG */}
                        <Box sx={styles.svgContainer}>
                            <svg width="100%" height="100%" viewBox="0 0 324 150" preserveAspectRatio="xMidYMid meet" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M103 55.2724L196.845 0.713867L251 95.2587L157.155 149.817L103 55.2724Z" fill="#1EC7B8"></path>
                                <path d="M1.93308 121.656C0.218971 115.211 3.0164 105.161 14.2646 108.841C29.6007 113.858 37.5075 150.858 55.6556 141.894C76.9622 131.37 57.4971 50.5088 80.3544 44.3385C103.213 38.1678 102.813 129.164 120.826 124.302C138.837 119.439 141.27 70.7832 159.28 65.9215C177.293 61.059 174.864 109.714 192.875 104.852C210.888 99.9896 217.322 66.3828 235.331 61.5212C253.344 56.6586 246.913 90.2649 264.924 85.4028C282.937 80.5402 296.959 41.4818 307.55 31.2717C314.611 24.4657 319.84 25.9107 323.239 35.6051" stroke="black" stroke-width="1.3"></path>
                            </svg>
                        </Box>
                    </Box>

                    <Box sx={styles.cardsContainer}>
                        <Box sx={styles.cardsWrapper} />
                    </Box>
                </Custom.Container>
            </Custom.BackgroundImageContainer>
        </Box>
    );
};

const BannerText = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.typography, ...sx }} />
);

const SubTitleText = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.subTitleTypography, ...sx }} />
);

const BannerButton = ({ text, variant, navigateToSignup }) => {
    const buttonStyle = variant === 'gradient' ? styles.gradientButton : styles.plainButton;
    return (
        <Box sx={{ ...styles.button, ...buttonStyle }} onClick={navigateToSignup}>
            {text}
        </Box>
    );
};

const styles = {
    root: {
        maxHeight: '600px',
        position: 'relative',
        height: { 
            xs: 'calc(100vh - 500px)', 
            sm: 'calc(100vh - 0px)' 
        }
    },
    bannerContainer: {
        width: '100%',
        maxHeight: '800px',
        position: 'absolute',
        backgroundSize: 'cover',
        height: 'calc(100% - 100px)',
    },
    contentBox: {
        left: '50%',
        width: '100%',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
        transform: 'translate(-50%, -50%)',
        top: {
            xs: '220px',
            sm: '300px'
        },
        px: {
            xs: 2,
            sm: 4,
            md: 6
        },
        gap: {
            xs: 2,
            sm: 4
        }
    },
    svgContainer: {
        display: { 
            xs: 'none', 
            md: 'block' 
        },
        width: {
            md: '20%',
            lg: '25%'
        },
        maxWidth: '275px',
        '& svg': {
            maxWidth: '100%',
            height: 'auto'
        }
    },
    centerContent: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: {
            xs: '100%',
            md: '60%',
            lg: '50%'
        },
        px: {
            xs: 2,
            sm: 4
        }
    },
    cardsContainer: {
        zIndex: 1,
        left: '0px',
        bottom: '-90px',
        overflow: 'auto',
        position: 'absolute',
        width: '100%',
    },
    cardsWrapper: {
        gap: '20px',
        width: '100%',
        padding: '10px',
        display: 'flex',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: { 
            xs: 'space-evenly', 
            sm: 'center' 
        },
    },
    typography: {
        m: 1,
        fontWeight: 700,
        color: '#141414',
        letterSpacing: '1.6px',
        textAlign: 'center',
        fontSize: {
            xs: '24px',
            sm: '32px',
            md: '40px'
        },
        lineHeight: 1.4,
    },
    subTitleTypography: {
        m: 1,
        fontWeight: 400,
        color: '#141414',
        letterSpacing: '.64px',
        textAlign: 'center',
        fontSize: {
            xs: '17px',
            sm: '19px',
            md: '21px'
        },
        lineHeight: 1.7,
        fontFamily: "Inter, sans-serif"
    },
    subText: {
        fontWeight: 400,
        fontSize: {
            xs: '14px',
            sm: '15px',
            md: '16px'
        },
        lineHeight: 'auto',
        letterSpacing: '0.2px',
    },
    button: {
        margin: 1,
        width: '100%',
        height: '56px',
        display: 'flex',
        maxWidth: '290px',
        cursor: 'pointer',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: {
            xs: '18px',
            sm: '20px',
            md: '22px'
        },
        fontFamily: 'Noto Sans',
    },
    gradientButton: {
        color: '#ffffff',
        background: "#2464EB",
        boxShadow: '6px 6px 8px rgba(0, 0, 0, 0.2)',
    },
    plainButton: {
        color: '#000000',
        backgroundColor: '#ffffff',
    },
};

export default NewBanner;