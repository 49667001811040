import React from 'react';
import Status from '../../../common/Status';
import HTMLViewer from '../../../common/HTMLViewer';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { routesName } from '../../../../config/routesName';
import { useLocation, useNavigate } from 'react-router-dom';
import ViewPageActionButtons from '../../../common/ViewPageActionButtons';
import { apiAction, apiBlobResponse, apiHandleDownload } from '../../../../api/api';
import { AnalyticsEvent, eventsNames } from '../../../../firebase/firebaseAnalytics';

import {
  setLoader,
  getQueryParams,
  stateChangeManager,
  breadCrumbsStateChangeManager,
} from '../../../../utils/Utils';

import {
  export_received_invoice,
  retrieve_received_invoice
} from '../../../../api/urls';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
  Box,
  Grid,
} from '@mui/material';



const ReceivedInvoiceView = () => {
  let location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);

  const [invoiceResults, setInvoiceResults] = React.useState();

  const getInvoice = async () => {
    setLoader(dispatch, Actions, true);

    let res = await apiAction({
      method: 'get',
      url: retrieve_received_invoice(params.id),
    })
    if (res?.success) {
      setInvoiceResults(res?.result);
      setLoader(dispatch, Actions, false);
      breadCrumbsStateChangeManager(dispatch, res?.result.invoice_number);

    } else {
      setLoader(dispatch, Actions, false)
    }
  }


  React.useEffect(() => {
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    invoiceResults &&
    <div>
      <CustomTitleContainer>
        <Grid container>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CustomTitle title={invoiceResults.invoice_number} />
              <Status status={invoiceResults.has_billed ? "closed" : "unbilled"} sx={{ ml: 1, mr: 1 }} />
              <Status status={invoiceResults.payment_status} />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <FilterActions
              data={invoiceResults}
            />
          </Grid>
        </Grid>

      </CustomTitleContainer>
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)', }}>
        <Preview basicInformation={invoiceResults} />
      </CustomContainer>
    </div>
  )
}

export default ReceivedInvoiceView;

const Preview = ({ basicInformation }) => {
  const dispatch = Actions.getDispatch(React.useContext);
  const [previewData, setPreviewData] = React.useState();


  const fetchDocumentData = (data = {}) => {
    apiAction({
      method: 'post',
      exportType: 'html',
      url: export_received_invoice(),
      data: { ...data, place_of_supply_id: data.place_of_supply ? data.place_of_supply.id : null, terms_and_conditions_id: data.terms_and_conditions, payment_term_id: data.payment_term, export_type: 'html' },
    })
      .then(handleDocumentResponse)
  };

  const handleDocumentResponse = (response) => {
    if (response?.success) {
      setPreviewData(response?.result);
      setLoader(dispatch, Actions, false);
    } else {
      setLoader(dispatch, Actions, false);

      stateChangeManager(dispatch, Actions, true, 'error', response.status);
    }
  };

  React.useEffect(() => {
    if (basicInformation) {
      fetchDocumentData(basicInformation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInformation]);

  React.useEffect(() => {
    AnalyticsEvent(eventsNames.categories.RECEIVEDINVOICES, { action: eventsNames.actions.receivedInvoice.action.VIEW })
  }, [])

  return (
    previewData &&
    <React.Fragment>
      <Box sx={{ pt: 2 }}>
        <HTMLViewer html_string={previewData.html_string} />
      </Box>
    </React.Fragment>
  )
}

const FilterActions = ({ data }) => {
  let navigate = useNavigate()
  const dispatch = Actions.getDispatch(React.useContext);

  const getQuickActions = (item) => {
    const actions = [];
    actions.push(
      !item.has_billed && { name: 'Convert to Bill', onClick: () => { onSelectAction("convert", item) } },

      {
        name: 'Delete',
        showDivider: true,
        onClick: () => { onSelectAction("delete", item) }
      }
    );
    return actions;


  }

  const onSelectAction = (action, item) => {
    setLoader(dispatch, Actions, true);
    if (action === "view") {
      navigate(routesName.receivedInvoiceView.path + "?id=" + item.id)
    }
    if (action === "print") {
      printInvoice(item)
      AnalyticsEvent(eventsNames.categories.RECEIVEDINVOICES, { action: eventsNames.actions.PRINT })
    }
    if (action === "download") {
      downloadInvoice(item)
      AnalyticsEvent(eventsNames.categories.RECEIVEDINVOICES, { action: eventsNames.actions.DOWNLOAD })
    }
    if (action === "convert") {
      setLoader(dispatch, Actions, false);
    }
    if (action === "delete") {
      setLoader(dispatch, Actions, false);
    }
  }

  const printInvoice = async (item) => {
    let data = await apiAction({
      method: 'get',
      url: retrieve_received_invoice(item.id),
    })
    if (data) {
      apiBlobResponse({
        url: export_received_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term, export_type: 'pdf' }, onSuccess: () => {
          setLoader(dispatch, Actions, false)
        }
      })
    }
  }

  const downloadInvoice = async (item) => {
    let data = await apiAction({
      method: 'get',
      url: retrieve_received_invoice(item.id),
    })
    if (data) {
      apiHandleDownload({
        url: export_received_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term, export_type: 'pdf' }, filename: data.result.invoice_number, onSuccess: () => {
          setLoader(dispatch, Actions, false)
        }
      })
    }
  }
  return (
    <ViewPageActionButtons
      print={() => onSelectAction("print", data)}
      download={() => onSelectAction("download", data)}
      actionsList={getQuickActions(data)}
    />
  )
}