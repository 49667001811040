import React from 'react';
import * as Custom from '../Custom/Custom';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AppLogo } from '../../../../common/AppLogo';
import { routesName } from '../../../../../config/routesName';
import NewDrawerComponent from '../../common/NewDrawerComponent';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomTypography from '../../../../custom/typography/CustomTypography';

const NewHeader = ({
    navigateToLogin,
    navigateToSignup,
}) => {
    const navigate = useNavigate();

    const [openDrawer, setOpenDrawer] = React.useState(false);

    return (
        <div>
            <AppBar sx={{ bgcolor: "#fafafa", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' }} elevation={0}>
                <Custom.Container>
                    <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                        <Box sx={{ display: 'flex' }} onClick={() => { navigate(routesName.landingPage.path); }}>
                            <CustomLogo />
                        </Box>

                        <Box sx={{ alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
                            <NavMenuItem />
                        </Box>

                        <Box sx={{ alignItems: 'center', pl: 5, display: { xs: 'none', md: 'flex' } }}>
                            <Buttons
                                navigateToLogin={navigateToLogin}
                                navigateToSignup={navigateToSignup}
                            />
                        </Box>

                        <Box sx={{ display: { xs: 'flex', md: 'none', } }}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setOpenDrawer(true)}
                            >
                                <MenuIcon sx={{ color: '#2464EB', fontSize: 30 }} />
                            </IconButton>
                            <NewDrawerComponent
                                openDrawer={openDrawer}
                                setOpenDrawer={setOpenDrawer}
                            />
                        </Box>
                    </Toolbar>
                </Custom.Container>
            </AppBar>
        </div>
    )
}

export default NewHeader;


const CustomLogo = () => {

    return (
        <Box style={{ padding: 4, cursor: 'pointer' }} onClick={() => { }}>
            <AppLogo />
        </Box>
    )
}

const Buttons = ({
    navigateToLogin,
    navigateToSignup,
}) => {
    return (
        <Box>
            <CustomButton
                btnLabel='Login'
                variant="outlined"
                id={'landing_page_sign_in'}
                dataTestId={'landing_page_sign_in'}
                onClick={() => { navigateToLogin() }}
                sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '14px !important',
                    width: { xs: 'auto', sm: '120px' },
                    textTransform: 'uppercase !important',
                }}
            />
            <CustomButton
                btnLabel='Sign Up'
                variant="contained"
                id={'landing_page_sign_up'}
                dataTestId={'landing_page_sign_up'}
                onClick={() => { navigateToSignup() }}
                sx={{
                    ml: 1,
                    whiteSpace: 'nowrap',
                    fontSize: '14px !important',
                    width: { xs: 'auto', sm: '120px' },
                    textTransform: 'uppercase !important',
                }}
            />
        </Box>
    )
}


const NavMenuItem = () => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <CustomTypography
                text={'Home'}
                onClick={() => { navigate(routesName.landingPage.path); }}
                sx={{
                    mr: 3,
                    fontWeight: 700,
                    cursor: 'pointer',
                    lineHeight: '26px',
                    letterSpacing: '0px',
                    fontSize: '16px !important',
                    color: "#2464EB",
                    '&:hover': {
                        color: '#2464EB',
                    },
                }}
            />
        </React.Fragment>
    )
}