import React, { useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as KeyFeaturesIcon } from '../assets/svg/Group 59565.svg';
import { ReactComponent as OverduePaymentIcon } from '../assets/svg/overduePayment.svg';

const NewBenefits = ({ textContent, scrollContainerRef }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeSection, setActiveSection] = useState(0);
  const sectionRef = useRef(null);

  const rightSectionLogos = [
    "https://landingi.com/wp-content/uploads/2024/02/event_tracker.svg",
    "https://landingi.com/wp-content/uploads/2024/02/smart_sections-1.svg",
    "https://landingi.com/wp-content/uploads/2024/02/landing_page.svg",
    "https://landingi.com/wp-content/uploads/2024/02/ai_assistant.svg",
  ]
  const leftSections = textContent?.cards?.map((data, index) => {
    return (
      {
        ...data,
        className: `left-section-${index + 1}`,
        logo: rightSectionLogos[index]
      })
  })

  const rightSections = [
    { title: "Effortless Accounting", content: <KeyFeaturesIcon />, className: "right-section-1" },
    { title: "Smart Invoicing", content: <OverduePaymentIcon />, className: "right-section-2" },
    { title: "Real-time Analytics", content: <KeyFeaturesIcon />, className: "right-section-3" },
    { title: "Synchronise Data from Tally", content: <OverduePaymentIcon />, className: "right-section-4" }
  ];

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (sectionRef.current && scrollContainerRef.current) {
            const sectionTop = sectionRef.current.offsetTop;
            const sectionHeight = sectionRef.current.offsetHeight;
            const scrollPosition = scrollContainerRef.current.scrollTop - sectionTop;

            const sectionIndex = Math.min(
              Math.floor(scrollPosition / (sectionHeight / leftSections.length)),
              leftSections.length - 1
            );
            setActiveSection(Math.max(0, sectionIndex));
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    const container = scrollContainerRef.current;
    if (container) container.addEventListener('scroll', handleScroll);

    return () => {
      if (container) container.removeEventListener('scroll', handleScroll);
    };
  }, [leftSections.length, scrollContainerRef]);

  return (
    <Box sx={{ width: '100%', background: '#F9F9F9' }}>
      {isMobile ? (
        <MobileSection
          leftSections={leftSections}
          rightSections={rightSections}
        />
      ) : (
        <DesktopSection
          leftSections={leftSections}
          rightSections={rightSections}
          activeSection={activeSection}
          sectionRef={sectionRef}
        />
      )}
    </Box>
  );
};

const DesktopSection = ({ leftSections, rightSections, activeSection, sectionRef }) => {
  return (
    <Box
      ref={sectionRef}
      className="sections-container"
      sx={{
        height: `${leftSections.length * 100}vh`,
      }}
    >
      <Box
        className="fixed-sections"
        sx={{
          position: 'sticky',
          top: 0,
          height: '100vh',
          display: 'flex'
        }}
      >
        {/* Left side */}
        <Box sx={{ width: '50%', position: 'relative', overflow: 'hidden' }}>
          {leftSections.map((section, index) => (
            <Box
              key={index}
              className={`section-content ${section.className}`}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                opacity: activeSection === index ? 1 : 0,
                transform: `translateY(${(index - activeSection) * 100}%)`,
                transition: 'transform 0.6s ease-in-out, opacity 0.6s ease-in-out',
              }}
            >
              <div className="content-wrapper">
                <div class="b-icon-box__image-wrapper">
                  <img decoding="async" loading="lazyload" height="30" width="30" src={section.logo} data-src={section.logo} alt="" />
                </div>
                <h2 className="section-title">{section.title}</h2>
                <p className="section-text">{section.text}</p>
              </div>
            </Box>
          ))}
        </Box>

        {/* Right side */}
        <Box sx={{ width: '50%', position: 'relative', overflow: 'hidden' }}>
          {rightSections.map((section, index) => (
            <Box
              key={index}
              className={`section-content ${section.className}`}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                opacity: activeSection === index ? 1 : 0,
                transform: `translateY(${(index - activeSection) * 100}%)`,
                transition: 'transform 0.6s ease-in-out, opacity 0.6s ease-in-out',
              }}
            >
              <div className="content-wrapper">
                {section.content}
              </div>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const MobileSection = ({ leftSections, rightSections }) => {
  return (
    leftSections?.map((leftSection, index) => (
      <Box
        key={index}
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          borderBottom: '2px solid #eaeaea',
          margin: "16px",
          background: "#FFF",
        }}>
        <Box
          className="mobile-section"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            py: 4,
            px: 2,
          }}
        >
          <Box className="mobile-content">
            <img decoding="async" loading="lazyload" height="30" width="30" src={leftSection.logo} data-src={leftSection.logo} alt="" />
            <h2 className="section-title">{leftSection.title}</h2>
            <p className="section-text">{leftSection.text}</p>
          </Box>
          <Box
            className="mobile-image"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              py: 2
            }}
          >
            {rightSections[index].content}
          </Box>
        </Box>
      </Box>
    ))
  );
};

export default NewBenefits;