

export const hasChannel = (channels, targetChannel) => channels.some(item => item.channel === targetChannel);

export const transformPayloadData = (data) => {
    const sendThroughChannels = data.send_through || [];

    const hasSms = hasChannel(sendThroughChannels, "sms");
    const hasEmail = hasChannel(sendThroughChannels, "email");

    const sendThroughIds = sendThroughChannels?.map(channel => channel?.id);

    return {
        ...data,
        send_through: sendThroughIds,
        mobile: hasEmail && !hasSms ? null : data.mobile,
        other_email: hasSms && !hasEmail ? { to: [], bcc: [], cc: [] } : data.other_email,
    };
};