import React from "react";
import NotFound from "../NotFound";
import { authRoutes } from "../../config/routes";
import { routesName } from "../../config/routesName";
import { getSessionStorage, isAuthenticated } from "../../config/cookiesInfo";
import SelectBusiness from "../../components/pages/businessManagement/selectBusiness/SelectBusiness";

import {
  getQueryParams,
  parseStateFromLocation,
} from "../../utils/Utils";

import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import StepAlert from "../customers/StepAlert";

export default function AuthLayout() {
  const location = useLocation();
  const params = getQueryParams(location.search);
  const sessionStorage = getSessionStorage('receivedInvoice');

  const ProtectedRoute = ({ children }) => {
    if (sessionStorage && isAuthenticated()) {
      return <SelectBusiness show={true} data={sessionStorage} />
    } else if (!params.token && isAuthenticated()) {
      return <Navigate to={routesName.dashboard.path} replace />;
    } else {
      return children;
    }
  };
  parseStateFromLocation(location, true);
  console.log('==>location_auth',location)

  return (
    <React.Fragment>
      <StepAlert />
      <Routes>
        {
          authRoutes.map((route, index) =>
            <Route key={index}
              path={route.path}
              element={
                <ProtectedRoute>
                  {route.component}
                </ProtectedRoute>
              }
            />
          )
        }
        {/* Catch-all route for auth */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
}


