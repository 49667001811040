import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomButton from '../../../../custom/button/CustomButton';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { ReactComponent as MicIcon } from '../assets/svg/megaphone-dynamic-color.svg';
import { ReactComponent as PlayStoreIcon } from '../../../../../assets/svg/Play_store.svg';
import { Box, Divider } from '@mui/material';
import { IOSSwitch } from '../../../../custom/switch/customSwitch';
import { ReactComponent as CheckIcon } from '../../../../../assets/svg/outline_check_icon.svg';


const NewPricing = ({ navigateToSignup, textContent }) => {

    const freePlanText = textContent?.cards[0]?.text?.split("\n")
    const premiumPlanText = textContent?.cards[1]?.text?.split("\n")

    const featuresData = getFeaturesData(freePlanText, premiumPlanText);

    return (
        <Box sx={styles.container}>
            <Box sx={{ alignItems: "center", textAlign: "center" }}>
                <SectionTitle text="Pricing Plans" />
            </Box>
            {/* <Box className="pricelist-switcher">
                <span class="pricelist-switcher__text pricelist-switcher__text--left">
                    Billed monthly
                </span>
                <div class="b-pricelist-switcher__toggle" data-left="group1" data-right="group3">
                    <IOSSwitch />
                </div>
                <span class="pricelist-switcher__text pricelist-switcher__text--right">
                    Billed annually
                </span>
            </Box> */}
            <Box sx={styles.cardWrapper}>
                {featuresData.map((item, index) => (
                    <PricingCard features={item} key={index} navigateToSignup={navigateToSignup} />
                ))}
            </Box>

            <Box sx={styles.cardWrapper}>
                <Box sx={{ ...styles.gradientColor }}>
                    <Box sx={{ ...styles.micIconWrapper }}>
                        <MicIcon />
                    </Box>
                    <Box sx={{ pl: { xs: '130px', sm: 4 }, }}>
                        <CustomTypography
                            text={'PREMIUM PLAN FREE FOR'}
                            sx={{ fontWeight: 400, color: '#ffffff', fontSize: { xs: '14px', sm: '24px' } }}
                        />
                        <CustomTypography
                            text={'2 months till 31 October 2024'}
                            sx={{ mt: 2, color: '#ffffff', fontSize: { xs: '18px', sm: '32px', lineHeight: '20px', } }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.cardWrapper}>
                <PromoSection />
            </Box>
        </Box>
    );
};

export default NewPricing;

const PricingCard = ({ features, navigateToSignup }) => {
    const { icon: Icon, isCrown, title, pricing, details, btnText, buttonStyle, cardStyle, textStyle } = features;

    return (
        <Box sx={{ ...styles.pricingCard, ...cardStyle }}>
            <CustomTypography text={title} sx={{ ...styles.cardTitle, color: '#049735' }} />
            <h1>{pricing}</h1>

            {details.map((detail, index) => (
                <span class='pricing-list-items'>
                    <Icon style={styles.iconSpacing} />
                    <p key={`detail-${index}`} class="features-text" style={styles.featureIconWrapper}><strong>{detail}</strong></p>
                </span>
            ))}

            <ActionButton text={btnText} buttonStyle={buttonStyle} navigateToSignup={navigateToSignup} />
        </Box>
    );
};

const SectionTitle = ({ text }) => (
    <CustomTypography text={text} sx={styles.sectionTitle} />
);

const PromoSection = () => (
    <Box sx={styles.promoSection}>
        <Box sx={styles.promoTextContainer}>
            <CustomTypography
                text={
                    <span>
                        <span>Finycs now available on<br /></span>
                        <strong>Web and Android</strong>
                    </span>
                }
                sx={styles.promoText}
            />
        </Box>
        <Box sx={styles.promoIcons}>
            <CustomButton
                sx={{
                    mb: 3,
                    '&:hover svg': {
                        transform: 'scale(1.1)',
                        transition: 'transform 0.3s ease-in-out',
                    }
                }}
                variant="text"
                target='_blank'
                btnLabel={<PlayStoreIcon />}
                id="landing_create_free_account"
                dataTestId="landing_create_free_account"
                href='https://play.google.com/store/apps/details?id=com.anctech.finycs'
            />
            {/* <AppleStoreIcon style={styles.iconSpacing} /> */}
        </Box>
    </Box>
);


const ActionButton = ({ text, buttonStyle, navigateToSignup }) => (
    <Box onClick={() => { navigateToSignup() }} sx={{ ...styles.buttonBase, ...buttonStyle, mt: 2 }}>
        {text}
    </Box>
);


const getFeaturesData = (freePlanText, premiumPlanText) => [
    {
        icon: CheckIcon,
        btnText: 'GET STARTED NOW',
        textStyle: { color: '#141414' },
        cardStyle: { background: '#FFFF' },
        buttonStyle: { background: '#2550AA', color: '#FFFFFF' },
        title: <span style={{ color: '#2550AA' }}>FREE PLAN</span>,
        pricing: <span style={{ ...styles.perMonth }}><sup>₹</sup><strong>0</strong></span>,
        details: freePlanText
    },
    {
        isCrown: true,
        icon: CheckIcon,
        btnText: 'GET STARTED NOW',
        textStyle: { color: '#FFFF' },
        cardStyle: { background: '#E9EDF7' },
        buttonStyle: { background: '#2550AA', color: '#FFFFFF' },
        title: <span style={{ color: '#2550AA' }}>PREMIUM PLAN</span>,
        pricing: <span style={{ ...styles.perMonth }}><sup>₹</sup><strong>500 / month</strong></span>,
        details: premiumPlanText
    },
];


const styles = {
    container: {
        mt: { xs: 2, sm: 0 },
        p: { xs: 4, sm: 0 },
        background: '#F9F9F9',
        pb: 4,
    },
    gradientColor: {
        mt: 3,
        p: '24px',
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        borderRadius: 2,
        color: '#ffffff',
        minHeight: '90px',
        maxWidth: '760px',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'center',
        // background: 'linear-gradient(to right, #F08E8E, #CA4747)',
        background: "#2550AA"
    },
    micIconWrapper: { left: { xs: '-10px', sm: '15px' }, top: '-50px', position: 'absolute' },

    sectionTitle: {
        fontWeight: 300,
        color: '#141414',
        fontSize: '40px',
        lineHeight: '66px',
        textAlign: 'center',
        pb: 2
    },
    cardWrapper: {
        mt: 4,
        gap: '30px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    pricingCard: {
        padding: 4,
        width: '100%',
        borderRadius: 0,
        maxWidth: '320px',
        textAlign: 'start',
        position: 'relative',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        border: "1px solid #707070",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '1.6px',
    },
    price: {
        fontSize: '60px',
        lineHeight: '65px',
    },
    perMonth: {
        fontSize: '32px',
        color: '#2550AA',
        lineHeight: '29px',
    },
    featureText: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#141414',
    },
    buttonBase: {
        mt: 'auto',
        width: '100%',
        fontWeight: 700,
        display: 'flex',
        height: '50px',
        borderRadius: 1,
        fontSize: '16px',
        cursor: 'pointer',
        lineHeight: '32px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    promoSection: {
        mt: 4,
        mb: 4,
        flexGrow: 1,
        gap: '10px',
        flexWrap: 'wrap',
        display: 'flex',
        maxWidth: '800px',
        alignItems: 'center',
        borderTop: '1px solid #E0E0E0',
        textAlign: { xs: 'center', sm: 'start' },
        justifyContent: { xs: 'center', sm: 'space-between' },
    },
    promoTextContainer: { pt: 4, },
    promoText: { whiteSpace: 'nowrap', fontWeight: 500, },
    promoIcons: { pt: 4, textAlign: 'center' },
    iconSpacing: { paddingRight: '10px', marginTop: "9px" },
    featureIconWrapper: { display: 'flex', alignItems: 'center' },
    crownIconWrapper: { top: '-40px', right: '20px', position: 'absolute' },
};
