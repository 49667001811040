import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomButton from '../../../../custom/button/CustomButton';
import { ReactComponent as CrownIcon } from '../assets/svg/Free.svg';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { ReactComponent as CheckWhiteIcon } from '../assets/svg/checkWhite.svg';
import { ReactComponent as CheckGreenIcon } from '../assets/svg/checkGreen.svg';
import { ReactComponent as MicIcon } from '../assets/svg/megaphone-dynamic-color.svg';
import { ReactComponent as PlayStoreIcon } from '../../../../../assets/svg/Play_store.svg';

import { Box, Divider } from '@mui/material';

// Main Pricing Component
const Pricing = ({ navigateToSignup }) => {
    const featuresData = getFeaturesData();

    return (
        <Custom.Container>
            <Box sx={styles.container}>
                <SectionTitle text="Pricing Plans" />
                <Box sx={styles.cardWrapper}>
                    {featuresData.map((item, index) => (
                        <PricingCard features={item} key={index} navigateToSignup={navigateToSignup} />
                    ))}
                </Box>
                <Box sx={styles.cardWrapper}>
                    <Box sx={{ ...styles.gradientColor }}>
                        <Box sx={{ ...styles.micIconWrapper }}>
                            <MicIcon />
                        </Box>
                        <Box sx={{ pl: { xs: '130px', sm: 4 }, }}>
                            <CustomTypography
                                text={'PREMIUM PLAN FREE FOR'}
                                sx={{ fontWeight: 400, color: '#ffffff', fontSize: { xs: '14px', sm: '24px' } }}
                            />
                            <CustomTypography
                                text={'2 months till 31 October 2024'}
                                sx={{ mt: 2, color: '#ffffff', fontSize: { xs: '18px', sm: '32px', lineHeight: '20px', } }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.cardWrapper}>
                    <PromoSection />
                </Box>
            </Box>
        </Custom.Container>
    );
};

export default Pricing;

// PricingCard Component
const PricingCard = ({ features, navigateToSignup }) => {
    const { icon: Icon, isCrown, title, pricing, details, btnText, buttonStyle, cardStyle, textStyle } = features;

    return (
        <Box sx={{ ...styles.pricingCard, ...cardStyle }}>
            {/* {isCrown && <CrownIconWrapper />} */}
            <CustomTypography text={title} sx={{ ...styles.cardTitle, color: '#049735' }} />
            <CustomTypography sx={{ mt: 2, mb: 2, ...styles.price, ...textStyle }} text={pricing} />
            <Divider flexItem style={{ background: textStyle.color, opacity: 0.2 }} />

            {details.map((detail, index) => (
                <FeatureDetail key={index} detail={detail} icon={Icon} textStyle={textStyle} />
            ))}

            <ActionButton text={btnText} buttonStyle={buttonStyle} navigateToSignup={navigateToSignup} />
        </Box>
    );
};

// SectionTitle Component
const SectionTitle = ({ text }) => (
    <CustomTypography text={text} sx={styles.sectionTitle} />
);

// PromoSection Component
const PromoSection = () => (
    <Box sx={styles.promoSection}>
        <Box sx={styles.promoTextContainer}>
            <CustomTypography
                text={
                    <span>
                        <span>Finycs now available on<br /></span>
                        <strong>Web and Android</strong>
                    </span>
                }
                sx={styles.promoText}
            />
        </Box>
        <Box sx={styles.promoIcons}>
            <CustomButton
                sx={{
                    mb: 3,
                    '&:hover svg': {
                        transform: 'scale(1.1)',
                        transition: 'transform 0.3s ease-in-out',
                    }
                }}
                variant="text"
                target='_blank'
                btnLabel={<PlayStoreIcon />}
                id="landing_create_free_account"
                dataTestId="landing_create_free_account"
                href='https://play.google.com/store/apps/details?id=com.anctech.finycs'
            />
            {/* <AppleStoreIcon style={styles.iconSpacing} /> */}
        </Box>
    </Box>
);

// CrownIconWrapper Component
const CrownIconWrapper = () => (
    <Box sx={styles.crownIconWrapper}>
        <CrownIcon />
    </Box>
);

// FeatureDetail Component
const FeatureDetail = ({ detail, icon: Icon, textStyle }) => (
    <>
        <CustomTypography
            sx={{ mt: 3, mb: 3, ...styles.featureText, ...textStyle, visibility: detail?.isIconHidden ? 'hidden' : 'visible' }}
            text={<span style={styles.featureIconWrapper}>{!detail?.isIconHidden && (<Icon style={styles.iconSpacing} />)}{detail.text}</span>}
        />
        {detail.isDivider && <Divider flexItem style={{ background: textStyle.color, opacity: 0.2, visibility: detail?.isIconHidden ? 'hidden' : 'visible' }} />}
    </>
);

// ActionButton Component
const ActionButton = ({ text, buttonStyle, navigateToSignup }) => (
    <Box onClick={() => { navigateToSignup() }} sx={{ ...styles.buttonBase, ...buttonStyle }}>
        {text}
    </Box>
);

// Helper function to get features data
const getFeaturesData = () => [
    {
        icon: CheckGreenIcon,
        btnText: 'GET STARTED NOW',
        textStyle: { color: '#141414' },
        cardStyle: { background: '#FFFF' },
        buttonStyle: { background: '#049735', color: '#FFFFFF' },
        title: <span style={{ color: '#049735' }}>FREE PLAN</span>,
        pricing: <span><strong>₹0</strong></span>,
        // pricing: <span><strong>₹0</strong><span style={styles.perMonth}> / for 2 months</span></span>,
        details: [
            { isDivider: true, text: 'Free' },
            { isDivider: true, text: '1 business, upto 3 users' },
            { isDivider: true, text: '10k Journal Entries / year' },
            { isDivider: true, text: 'Manage Customers' },
            { isDivider: true, text: 'Invoices' },
            { isDivider: true, text: 'Credit / Debit Notes' },
            { isDivider: true, text: 'Manage vendors' },
            { isDivider: true, text: 'Purchase orders ' },
            { isDivider: true, text: 'Limited Financial Reports' },
            { isDivider: false, text: 'Limited Insights View' },
        ]
    },
    {
        isCrown: true,
        icon: CheckWhiteIcon,
        btnText: 'GET STARTED NOW',
        textStyle: { color: '#FFFF' },
        cardStyle: { background: '#2464EB' },
        buttonStyle: { background: '#FFFF', color: '#2464EB' },
        title: <span style={{ color: '#FFFF' }}>PREMIUM PLAN</span>,
        pricing: <span><strong>₹500</strong><span style={{ ...styles.perMonth, color: '#FFFF' }}> / month</span></span>,
        details: [
            { isDivider: true, text: '1 business, upto 5 users' },
            { isDivider: true, text: 'All Features of Free Plan' },
            { isDivider: true, text: 'Automated Journal Entries' },
            { isDivider: true, text: 'Recurring Invoices' },
            { isDivider: true, text: 'Recurring Expenses' },
            { isDivider: true, text: 'Various Financial Reports' },
            { isDivider: true, text: 'Customisable Insights' },
            { isDivider: false, text: 'Synchronise Data from Tally' },
            { isIconHidden: true, isDivider: true, text: 'Empty' },
            { isIconHidden: true, isDivider: true, text: 'Empty' },
        ]
    }
];

// Reusable styles
const styles = {
    container: {
        mt: { xs: 2, sm: 0 },
        p: { xs: 4, sm: 0 },
        background: '#FAFAFA',
        borderRadius: '32px',
        pb: 4,
    },
    gradientColor: {
        mt: 3,
        p: '24px',
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        borderRadius: 2,
        color: '#ffffff',
        minHeight: '90px',
        maxWidth: '760px',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'center',
        background: 'linear-gradient(to right, #F08E8E, #CA4747)',
    },
    micIconWrapper: { left: { xs: '-10px', sm: '15px' }, top: '-50px', position: 'absolute' },

    sectionTitle: {
        fontWeight: 700,
        color: '#141414',
        fontSize: '40px',
        lineHeight: '66px',
        textAlign: 'center',
    },
    cardWrapper: {
        mt: 4,
        gap: '30px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    pricingCard: {
        padding: 4,
        width: '100%',
        borderRadius: 2,
        maxWidth: '320px',
        textAlign: 'start',
        position: 'relative',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '1.6px',
    },
    price: {
        fontSize: '60px',
        lineHeight: '65px',
    },
    perMonth: {
        fontSize: '17px',
        color: '#666666',
        lineHeight: '29px',
    },
    featureText: {
        fontWeight: 400,
        fontSize: '16px',
        color: '#141414',
    },
    buttonBase: {
        mt: 2,
        width: '100%',
        fontWeight: 700,
        display: 'flex',
        height: '50px',
        borderRadius: 1,
        fontSize: '16px',
        cursor: 'pointer',
        lineHeight: '32px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    promoSection: {
        mt: 4,
        mb: 4,
        flexGrow: 1,
        gap: '10px',
        flexWrap: 'wrap',
        display: 'flex',
        maxWidth: '800px',
        alignItems: 'center',
        borderTop: '1px solid #E0E0E0',
        textAlign: { xs: 'center', sm: 'start' },
        justifyContent: { xs: 'center', sm: 'space-between' },
    },
    promoTextContainer: { pt: 4, },
    promoText: { whiteSpace: 'nowrap', fontWeight: 500, },
    promoIcons: { pt: 4, textAlign: 'center' },
    iconSpacing: { paddingRight: '10px' },
    featureIconWrapper: { display: 'flex', alignItems: 'center' },
    crownIconWrapper: { top: '-40px', right: '20px', position: 'absolute' },
};
