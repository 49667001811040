import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

export const CustomLink = styled(({ to, state, ...props }) => {
    // Dynamically construct the URL with query parameters
    const queryParams = new URLSearchParams({
        'state%-%query%-%params': JSON.stringify(state),
    }).toString();

    // Determine whether to append `?` or `&`
    const separator = to.includes('?') ? '&' : '?';
    const link = state ? `${to}${separator}${queryParams}` : to;

    return <Link {...props} to={link} />;
})({
    fontSize: '12px',
    cursor: 'pointer',
    color: '#2464EB',
    fontWeight: '500',
    lineHeight: '16px',
    fontStyle: 'normal',
    textDecoration: 'none',
    fontFamily: 'Noto Sans',
    ":hover": { textDecoration: 'underline' }
});
