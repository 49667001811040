import React from 'react';
import UserList from './userManagement/UserList';
import DataBackup from './DataBackup/DataBackup';
import ViewProfile from '../userProfile/ViewProfile';
import RemindersPage from '../reminders/RemindersPage';
import { getQueryParams } from '../../../../utils/Utils';
import ListBusiness from '../businessProfile/ListBusiness';
import { routesName } from '../../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import VerticalTabs from '../../../custom/tabs/VerticalTabs';
import DeleteAccount from '../../userManagement/DeleteAccount';
import PreferencesWrapper from './Preferences/common/PreferencesWrapper';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import OpeningBalanceAdjustment from '../../accounting/openingBalanceAdjustment/OpeningBalanceAdjustment';

import {
    Box,
    IconButton,
    useMediaQuery,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Settings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = getQueryParams(location.search);

    // Check for mobile view
    const isMobile = useMediaQuery('(max-width:600px)');

    const tabs = React.useMemo(() => [
        {
            name: 'Account', disabled: false,
            child: [
                { eventName: 'USER_PROFILE', name: 'User Profile', condition: 'personal_information' },
                { eventName: 'BUSINESS_PROFILE', name: 'Organization Profile', condition: 'organizations_information' },
            ]
        },
        {
            name: 'User Management', disabled: false,
            child: [
                { eventName: 'USER_MANAGEMENT', name: 'Users', condition: 'user_information' },
            ]
        },
        { eventName: 'OPENING_BALANCE', name: 'Opening Balances', condition: 'opening_balances' },
        { eventName: 'REMINDERS', name: 'Reminders', condition: 'reminders' },
        {
            name: 'Preferences', disabled: false,
            child: [
                { eventName: 'GENERAL_PREFERENCE', name: 'General', condition: 'general_preference' },
                { name: 'ITEMS', disabled: true },
                { eventName: 'ITEM_PREFERENCE', name: 'Items', condition: 'items_preference' },

                { name: 'SALES', disabled: true },
                { eventName: 'CUSTOMER_PREFERENCE', name: 'Customers', condition: 'customers_preference' },
                { eventName: 'SALES_TERMS_AND_CONDITIONS_PREFERENCE', name: 'Terms and Conditions', condition: 'sales_terms_condition_preference' },
                { eventName: 'CUSTOMER_NOTES_PREFERENCE', name: 'Customers Notes', condition: 'sales_notes_preference' },
                { eventName: 'BANK_ACCOUNT_PREFERENCE', name: 'Bank Accounts', condition: 'bank_accounts_preference' },

                { name: 'PURCHASES', disabled: true },
                { eventName: 'VENDOR_PREFERENCE', name: 'Vendors', condition: 'vendors_preference' },
                { eventName: 'PURCHASE_TERMS_AND_CONDITIONS_PREFERENCE', name: 'Terms and Conditions', condition: 'purchase_terms_condition_preference' },
                { eventName: 'VENDOR_NOTES_PREFERENCE', name: 'Vendors Notes', condition: 'purchase_notes_preference' },
            ]
        },
        { eventName: 'DATA_BACKUP', name: 'Data Backup', condition: 'data_backup' },
        { eventName: 'DELETE_ACCOUNT', name: 'Delete Account', condition: 'delete_account' },
    ], []);

    const [tab, setTab] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [filter, setFilter] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState('personal_information');

    const analyticsEventActions = (selectedSetting) => {
        let item = eventsNames.actions.setting[selectedSetting];
        AnalyticsEvent(eventsNames.categories.SETTINGS, { action: eventsNames.actions.CLICK, item: item })
    };

    // Close sidebar if mobile on component load
    React.useEffect(() => {
        if (isMobile) {
            setOpen(false); // Close sidebar by default on mobile view
        } else {
            setOpen(true);
        }
    }, [isMobile]);

    React.useEffect(() => {
        if (params?.action) {
            let tabIndex = -1;
            let subTab = '';

            tabs.forEach((tab, index) => {
                if (tab.condition === params.action) {
                    tabIndex = index;
                    subTab = tab.condition;
                    analyticsEventActions(tab.eventName);
                }
                if (tab.child) {
                    tab.child.forEach(child => {
                        if (child.condition === params.action) {
                            tabIndex = index;
                            subTab = child.condition;
                            analyticsEventActions(child.eventName);
                        }
                    });
                }
            });

            if (tabIndex >= 0) {
                setTab(tabIndex);
                setSelectedTab(subTab);
                setFilter(prev => !prev);
            }
        }
    }, [params.action]);


    React.useEffect(() => {
        if (location.state) {
            const { selected_tab, selected_value } = location.state;
            if (selected_tab !== undefined && selected_value !== undefined) {
                setTab(selected_tab);
                setSelectedTab(selected_value);
            }
        }
    }, [location.state]);

    const handleTabChange = (value) => {

        setTab(value);
        setSelectedTab(tabs[value]?.condition || tabs[value]?.child?.[0]?.condition || 'personal_information');
        setFilter(prev => !prev);
        setOpen(false); // Close sidebar on tab change for mobile view
        navigate(`${routesName.settings.path}?action=${tabs[value]?.condition || tabs[value]?.child?.[0]?.condition}`);
    };

    const handleSubTabChange = (tab, value) => {

        setSelectedTab(value);
        setOpen(false); // Close sidebar on sub-tab change for mobile view
        navigate(`${routesName.settings.path}?action=${value}`);
    };

    const handleClose = () => {
        setTab(0);
        setSelectedTab('personal_information');
        navigate(`${routesName.settings.path}?action=${'personal_information'}`);
    };

    const SettingsSidebar = React.memo(({ open, setOpen, tabs, tab, selectedTab, handleTabChange, handleSubTabChange }) => (
        <Box sx={{ display: open ? 'block' : 'none', width: 250, borderRight: '1px solid #e8e8e8', background: '#FFFFFF', position: { xs: 'absolute', sm: 'relative' }, zIndex: 10 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '2px solid #F2F2F2', }}>
                <Box>
                    <CustomTypography
                        text={'Settings'}
                        sx={{
                            pb: 2,
                            pl: 2,
                            pt: 2.5,
                            color: '#000000',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: 'Noto Sans',
                        }}
                    />
                </Box>
                <Box sx={{ pr: 2, display: { xs: 'block', sm: 'none' }, }}>
                    <IconButton onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ height: 'calc(100vh - 124px)', overflowY: 'scroll', display: open ? 'block' : 'none' }}>
                <VerticalTabs
                    tabs={tabs}
                    value={tab}
                    id={'tabs_'}
                    dataTestId={'tabs_'}
                    selectedTab={selectedTab}
                    onChange={(value) => { setOpen(false); handleTabChange(value); }}
                    setSelectedTab={(tab, value) => { setOpen(false); handleSubTabChange(tab, value); }}
                />
            </Box>
        </Box>
    ));

    return (
        <Box sx={{ marginTop: { xs: '-3px', sm: '5px' }, display: { xs: 'block', sm: 'flex' }, height: '100vh', width: '100%', position: 'relative' }}>
            <Box
                onClick={() => { setOpen(true); }}
                sx={{
                    p: 2,
                    pl: '5px',
                    zIndex: 10,
                    top: '20%',
                    width: '5px',
                    left: '-20px',
                    height: '10px',
                    color: '#2464EB',
                    borderRadius: '10px',
                    position: 'absolute',
                    alignItems: 'center',
                    background: '#EBF1FF ',
                    display: open ? 'none' : { xs: 'flex', sm: 'none' },
                }}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </Box>

            <SettingsSidebar
                open={open}
                setOpen={setOpen}
                tabs={tabs}
                tab={tab}
                selectedTab={selectedTab}
                handleTabChange={handleTabChange}
                handleSubTabChange={handleSubTabChange}
            />

            <Box sx={{ pl: { xs: 0, sm: 2 }, width: '100%' }}>
                {React.useMemo(() => {
                    if (params.action === 'reminders') return <RemindersPage initial_state={filter} />;
                    if (params.action === 'personal_information') return <ViewProfile initial_state={filter} />;
                    if (params.action === 'organizations_information') return <ListBusiness initial_state={filter} />;
                    if (params.action === 'opening_balances') return <OpeningBalanceAdjustment initial_state={filter} />;
                    if (params.action === 'user_information') return <UserList initial_state={filter} onManageProfile={handleClose} />;
                    if (params.action === 'data_backup') return <DataBackup />;
                    if (tabs[tab]?.name === 'Preferences') return <PreferencesWrapper selectedTab={selectedTab} initial_state={filter} />;
                    if (params.action === 'delete_account') return <DeleteAccount selectedTab={selectedTab} initial_state={filter} onClose={handleClose} />;
                    return null;
                }, [params.action, filter, selectedTab, tab])}
            </Box>

        </Box>
    );
};

export default Settings;
