import React from 'react';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import { routesName } from '../../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { input_css_style } from '../../../../utils/Constant';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { getBusinessInfo, getUserDetails } from '../../../../config/cookiesInfo';

import {
    hasChannel,
    transformPayloadData,
} from './helper';

import {
    CustomContainer,
    CustomTitleContainer,
    CustomButtonContainer,
} from '../../../custom/container/CustomContainer';

import {
    isFormValid,
    stateChangeManager,
    get_party_contact_name,
} from '../../../../utils/Utils';

import {
    update_bill_reminder,
    delete_bill_reminder,
    create_bill_reminder,
    get_reminder_channels,
    update_invoice_reminder,
    delete_invoice_reminder,
    create_invoice_reminder,
} from '../../../../api/urls';

import {
    Box,
    Grid,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

const Dropdown = DateRange;
const CreateNewReminder = (props) => {
    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const { onCancel, locationState = location.state } = props;

    let url = {
        update_bill_reminder,
        delete_bill_reminder,
        create_bill_reminder,
        update_invoice_reminder,
        delete_invoice_reminder,
        create_invoice_reminder,
    }

    const initial_data = {
        days: 0,
        name: null,
        is_active: true,
        relative_due: "after",
        remind_to: "customer",
        business_id: getBusinessInfo().id,
        from_email: getUserDetails().email,
        other_email: locationState.data && locationState.data.to_email ? locationState.data.to_email : { to: [], bcc: [], cc: [] },
        mobile: locationState.data && locationState.data.mobile ? locationState.data.mobile : { mobile: null },
        send_through: locationState.data && locationState.data.send_through ? locationState.data.send_through : [
            {
                "id": 1,
                "name": "Email",
                "channel": "email"
            }
        ]
    }
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);
    const [data, setData] = React.useState({ ...initial_data, ...locationState.data })



    const onSave = async () => {
        setFormSubmitted(true);
        let validation_data = [
            locationState.type === 'invoice' && { key: "from_email", message: `Please Select the From Email` },
            { key: "name", message: 'Please Enter the Reminder Name.' },
            { key: "remind_to", message: 'Please Select the From Remind' },
            { key: "relative_due", message: 'Please Select the Due Date' },
            (locationState.type === 'bill' && !hasChannel(data.send_through, "sms")) && { key: '', validation: data.other_email.to.length === 0, message: 'Please enter valid email.' },
            (locationState.type === 'bill' && !hasChannel(data.send_through, "email")) && { key: "mobile", message: 'Please Enter Phone Number' },
        ]
        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {
            const payloadData = transformPayloadData(data);
            let res = await apiAction({
                method: 'post',
                data: { ...payloadData, to_email: payloadData.other_email },
                url: data.id ? url[locationState.updateURL](data.id) : url[locationState.createURL](),
            });

            if (res?.success) {
                onClose();
                setFormSubmitted(false);
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    }

    const onClose = () => {
        if (onCancel) {
            onCancel()
        } else {
            navigate(routesName.remindersComponent.path)
        }
    }

    const onDelete = async () => {
        let res = await apiAction({
            method: 'delete',
            url: url[locationState.deleteURL](data.id),
            data: { business_id: getBusinessInfo().id }
        })
        if (res?.success) {
            onClose();
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    // console.log("Send Through", data.send_through)
    return (
        <div>
            <CustomTitleContainer sx={{ pt: 1.48, pb: 1 }}>
                <Grid container spacing={0} style={{}}>
                    <Grid item xs={12} sx={{}}>
                        <Box sx={{ mt: -0.9, display: 'flex', alignItems: 'center' }}>
                            <CustomTitle title={<span>{'Automated Reminders'}</span>} />
                            <FormControlLabel
                                id={'automated_reminder_checkbox'}
                                dataTestId={'automated_reminder_checkbox'}
                                sx={{ paddingLeft: '20px', }}
                                control={<Checkbox size='small' checked={data.is_active} onClick={() => { setData({ ...data, is_active: !data.is_active }) }} sx={{ color: '#9b9898' }} />}
                                label={<span style={{ color: '#000000', fontFamily: 'Noto Sans', fontStyle: 'normal', fontWeight: '400', lineHeight: '16px', fontSize: '14px' }}>Is active</span>}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </CustomTitleContainer>

            <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 202px)', }}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '48px' }}>
                    <Information
                        data={data}
                        setData={setData}
                        locationState={locationState}
                        isFormSubmitted={isFormSubmitted}
                    />
                </div>
            </CustomContainer>

            <CustomButtonContainer>
                <Buttons
                    isDelete={data.id}
                    onSave={() => { onSave() }}
                    onDelete={() => { onDelete() }}
                    onCancel={() => { onClose() }}
                />
            </CustomButtonContainer>
        </div>
    )
}

export default CreateNewReminder;

const Buttons = (props) => {
    const { onSave, onCancel, onDelete, isDelete } = props
    return (
        <Box component="form" noValidate  >
            <Grid container>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                    <CustomButton id={'cancel_btn'} dataTestId={'cancel_btn'} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Cancel' onClick={onCancel} />
                    {isDelete &&
                        <CustomButton id={'delete_btn'} dataTestId={'delete_btn'} variant='outlined' sx={{ ml: 1, textTransform: 'none', '&:hover': { backgroundColor: '#e8f3ff' } }} btnLabel='Delete this reminder' onClick={onDelete} />
                    }
                </Grid>
                <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                    <CustomButton id={'save_btn'} dataTestId={'save_btn'} variant='contained' sx={{ ml: 1, textTransform: 'none' }} btnLabel={'Save'} onClick={onSave} />
                </Grid>
            </Grid>
        </Box>
    )
}

const Information = (props) => {
    const { data, setData, locationState, isFormSubmitted } = props;

    let is_user_present = [...getBusinessInfo().business_contact].find((item) => item.email === getUserDetails().email)
    const from_email_list = is_user_present ? [...getBusinessInfo().business_contact,] : [...getBusinessInfo().business_contact, { email: getUserDetails().email }];
    const remind_list = [
        { name: `Me (${getUserDetails().email})`, value: `me` },
        { name: `Customer`, value: `customer` },
        { name: `Customer and Copy Me`, value: `both` },
    ];
    const due_date_list = [
        { name: `After`, value: `after` },
        { name: `Before`, value: `before` },
    ]

    const myRef = React.useRef();
    const [clickedOutsideTO, setClickedOutsideTO] = React.useState(true);
    const [clickedOutsideCC, setClickedOutsideCC] = React.useState(true);
    const [clickedOutsideBCC, setClickedOutsideBCC] = React.useState(true);

    const handleClickInside_TO = () => { setClickedOutsideTO(false); setClickedOutsideBCC(true); setClickedOutsideCC(true) };
    const handleClickInside_CC = () => { setClickedOutsideCC(false); setClickedOutsideBCC(true); setClickedOutsideTO(true) };
    const handleClickInside_BCC = () => { setClickedOutsideBCC(false); setClickedOutsideCC(true); setClickedOutsideTO(true) };

    const handleClickOutside_TO = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideTO(true);
        }
    };
    const handleClickOutside_CC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideCC(true);
        }
    };
    const handleClickOutside_BCC = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutsideBCC(true);
        }
    };
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_TO);
        return () => document.removeEventListener('mousedown', handleClickOutside_TO);
    });
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_CC);
        return () => document.removeEventListener('mousedown', handleClickOutside_CC);
    });
    React.useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside_BCC);
        return () => document.removeEventListener('mousedown', handleClickOutside_BCC);
    });



    let user_details = getUserDetails();
    let user_details_name = get_party_contact_name(getUserDetails());
    return (
        <Grid container>
            <Grid container item xs={12} sm={8} spacing={3}>
                {/* // once sms template is approved the we enabled it */}
                {/* <SendThroughOptions
                    data={data}
                    setData={setData}
                /> */}


                {
                    locationState.type === 'invoice' &&
                    <Grid item xs={12} sm={6}>
                        <Input
                            id={'input_user_name'}
                            dataTestId={'input_user_name'}
                            validation={false}
                            autoFocus={false}
                            item={{
                                type: 'text',
                                disabled: true,
                                fullWidth: true,
                                onChange: (event) => { },
                                placeholder: 'Enter the Reminder Name',
                                title: <LabelWithAsteriskMark label={'From'} />,
                                value: user_details_name ? user_details_name : user_details?.name,
                            }}
                        />
                        {/* <Dropdown
                            autoFocus={true}
                            optionLabel='email'
                            id={'email_dropdown'}
                            disableClearable={false}
                            dataTestId={'email_dropdown'}
                            placeholder='Select From Email'
                            label={<LabelWithAsteriskMark label={'From'} />}
                            validation={isFormSubmitted && !data.from_email}
                            results={getUserDetails().email ? from_email_list : []}
                            value={data.from_email ? from_email_list.find((item) => item.email === data.from_email) : null}
                            setValue={(event, selected) => {
                                if (selected) {
                                    data.from_email = selected.email;
                                } else {
                                    data.from_email = null;
                                }
                                setData({ ...data, })
                            }}
                        /> */}
                    </Grid>
                }

                <Grid item xs={12} sm={locationState.type === 'bill' ? 12 : 6}>
                    <Input
                        id={'input_reminder_name'}
                        dataTestId={'input_reminder_name'}
                        validation={isFormSubmitted && !data.name}
                        autoFocus={locationState.type === 'bill'}
                        item={{
                            type: 'text',
                            fullWidth: true,
                            value: data.name ? data.name : '',
                            placeholder: 'Enter the Reminder Name',
                            title: <LabelWithAsteriskMark label={'Reminder Name'} />,
                            onChange: (event) => {
                                setData({ ...data, name: event.target.value })
                            }
                        }}
                    />
                </Grid>
                {
                    locationState.type === 'invoice' &&
                    <Grid item xs={12} sm={6}>
                        <Dropdown
                            optionLabel='name'
                            results={remind_list}
                            disableClearable={false}
                            id={'reminder_dropdown'}
                            placeholder='Select Remind'
                            dataTestId={'reminder_dropdown'}
                            validation={isFormSubmitted && !data.remind_to}
                            label={<LabelWithAsteriskMark label={'Remind'} />}
                            value={data.remind_to ? remind_list.find((item) => item.value === data.remind_to) : null}
                            setValue={(event, selected) => {
                                if (selected) {
                                    data.remind_to = selected.value;
                                } else {
                                    data.remind_to = null;
                                }
                                setData({ ...data, })
                            }}
                        />
                    </Grid>
                }

                <Grid item xs={12} sm={locationState.type === 'bill' ? 12 : 6} container spacing={3}>
                    <Grid item xs={4}>
                        <Input
                            id={'input_number_day'}
                            isSearchableComponent={true}
                            dataTestId={'input_number_day'}
                            validation={isFormSubmitted && data.days === null}
                            item={{
                                type: 'number',
                                fullWidth: true,
                                placeholder: 'Enter the days',
                                value: data.days !== null ? data.days : '',
                                title: <LabelWithAsteriskMark label={'Day(s)'} />,
                                onChange: (event) => {
                                    let value = event.target.value;
                                    // Ensure value is a number and no more than 3 digits
                                    if (value === '' || (value.length <= 3 && /^[0-9]+$/.test(value))) {
                                        data.days = value ? Number(value) : null;
                                    }
                                    setData({ ...data });
                                },
                                onBlur: (event) => {
                                    let value = event.target.value;
                                    // Convert to number on blur, default to null if empty
                                    data.days = value ? Number(value) : null;
                                    setData({ ...data });
                                }
                            }}
                            onKeyPress={(event) => {
                                // Block non-numeric keys like `+`, `-`, `.`, `e`, and `E`
                                if (event.key === '-' || event.key === '+' || event.key === '.' || event.key === 'e' || event.key === 'E') {
                                    event.preventDefault();
                                }
                            }}
                        />

                    </Grid>
                    <Grid item xs={8}>
                        <Dropdown
                            optionLabel='name'
                            results={due_date_list}
                            id={'due_date_dropdown'}
                            disableClearable={false}
                            placeholder='Select Due Date'
                            dataTestId={'due_date_dropdown'}
                            validation={isFormSubmitted && !data.relative_due}
                            label={<LabelWithAsteriskMark label={'Due Date'} />}
                            value={data.relative_due ? due_date_list.find((item) => item.value === data.relative_due) : null}
                            setValue={(event, selected) => {
                                if (selected) {
                                    data.relative_due = selected.value;
                                } else {
                                    data.relative_due = null;
                                }
                                setData({ ...data, })
                            }}
                        />
                    </Grid>
                </Grid>
                {(hasChannel(data.send_through, "sms") && locationState.type === "bill") &&
                    <Grid item xs={12}>
                        <Input
                            id={'input_mobile'}
                            dataTestId={'input_mobile'}
                            validation={isFormSubmitted && !data.mobile}
                            autoFocus={locationState.type === 'bill'}
                            item={{
                                type: 'number',
                                fullWidth: true,
                                value: data.mobile ? data.mobile : '',
                                placeholder: 'Enter Phone Number',
                                title: <LabelWithAsteriskMark label={'Phone Number'} />,
                                onChange: (event) => {
                                    setData({ ...data, mobile: event.target.value })
                                }
                            }}
                        />
                    </Grid>
                }
                {
                    (hasChannel(data.send_through, "email") && locationState.type === 'bill') &&
                    <Grid item xs={12}>
                        <CustomTypography
                            text={<LabelWithAsteriskMark label={'Email To'} />}
                            sx={{
                                mb: 1,
                                fontSize: '14px',
                                color: '#000000',
                                fontWeight: '700',
                                lineHeight: '18px',
                                fontStyle: 'normal',
                                fontFamily: "Noto Sans",
                            }}
                        />
                        <div onClick={handleClickInside_TO} ref={myRef}>
                            <ReactMultiEmail
                                id={'input_other_email_to_email'}
                                dataTestId={'input_other_email_to_email'}
                                emails={data.other_email.to ? data.other_email.to : []}
                                onChange={(valueEmails) => {
                                    data.other_email.to = valueEmails
                                    setData({ ...data })
                                }}
                                style={{
                                    marginTop: '0px',
                                    maxHeight: 20,
                                    textAlign: 'center',
                                    borderRadius: "4px",
                                    overflowY: 'scroll',
                                    borderWidth: clickedOutsideTO ? 1.5 : 1.8,
                                    borderColor: (clickedOutsideTO) ? isFormSubmitted && data.other_email.to.length === 0
                                        ? "#d32f2f" :
                                        '#c7c7c7' :
                                        "#2464EB",
                                }}

                                getLabel={(
                                    email,
                                    index,
                                    removeEmail
                                ) => {

                                    return (
                                        <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                        </div>
                                    );
                                }}
                                validateEmail={email => {
                                    return email ? isEmail(email) : false; // return boolean
                                }}
                            />
                        </div>
                    </Grid>
                }
                {hasChannel(data.send_through, "email") &&
                    <>
                        <Grid item xs={12}>
                            <CustomTypography
                                text={'Cc'}
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <div onClick={handleClickInside_CC} ref={myRef}>
                                <ReactMultiEmail
                                    id={'input_other_email_cc_email'}
                                    dataTestId={'input_other_email_cc_email'}
                                    emails={data.other_email.cc ? data.other_email.cc : []}
                                    onChange={(valueEmails) => {
                                        data.other_email.cc = valueEmails
                                        setData({ ...data })
                                    }}
                                    style={{ marginTop: '0px', maxHeight: 20, textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideCC) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideCC ? 1.5 : 1.8 }}

                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail
                                    ) => {

                                        return (
                                            <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}
                                    validateEmail={email => {
                                        return email ? isEmail(email) : false; // return boolean
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTypography
                                text={'Bcc'}
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontWeight: '700',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                }}
                            />
                            <div onClick={handleClickInside_BCC} ref={myRef}>
                                <ReactMultiEmail
                                    id={'input_other_email_bcc_email'}
                                    dataTestId={'input_other_email_bcc_email'}
                                    emails={data.other_email.bcc ? data.other_email.bcc : []}
                                    onChange={(valueEmails) => {
                                        data.other_email.bcc = valueEmails
                                        setData({ ...data })
                                    }}
                                    style={{ marginTop: '0px', maxHeight: 20, textAlign: 'center', borderRadius: "4px", overflowY: 'scroll', borderColor: (clickedOutsideBCC) ? '#c7c7c7' : "#2464EB", borderWidth: clickedOutsideBCC ? 1.5 : 1.8 }}

                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail
                                    ) => {

                                        return (
                                            <div data-tag key={index} style={{ ...input_css_style }}>{email}<span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}
                                    validateEmail={email => {
                                        return email ? isEmail(email) : false; // return boolean
                                    }}
                                />
                            </div>
                        </Grid>
                        {
                            locationState.type === 'invoice' &&
                            <Grid item xs={12}>
                                <Input
                                    id={'input_subject'}
                                    dataTestId={'input_subject'}
                                    item={{
                                        type: 'text',
                                        disabled: true,
                                        placeholder: '',
                                        fullWidth: true,
                                        title: 'Subject',
                                        onChange: (event) => { },
                                        value: `Payment of %Balance% is outstanding for %InvoiceNumber%`,
                                    }}
                                />
                            </Grid>
                        }
                    </>
                }

            </Grid>
        </Grid>
    )
}

const SendThroughOptions = ({ data, setData }) => {
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const [options, setOptions] = React.useState([]);

    const getApiResults = async () => {
        const res = await apiAction({
            method: 'get',
            navigate,
            dispatch,
            url: get_reminder_channels()
        });
        const fetchedOptions = res.result || [];

        // Try to find the "email" channel (assuming the channel name or id matches "email")
        const emailOption = fetchedOptions.find(option => option.channel === "email");

        // Map existing send_through data to ensure it's properly aligned with fetched options
        const updatedSendThrough = (data.send_through || []).map((selectedOption) => {
            const matchedOption = fetchedOptions.find(option => option.id === selectedOption.id);
            return matchedOption || selectedOption;
        });

        // Set options state
        setOptions(fetchedOptions);

        // If no send_through data, set default to "email" or first available option
        if (updatedSendThrough.length === 0) {
            setData({
                ...data,
                send_through: emailOption ? [emailOption] : [fetchedOptions[0]]
            });
        } else {
            // If send_through data exists, update it
            setData({ ...data, send_through: updatedSendThrough });
        }

    };

    React.useEffect(() => {
        getApiResults();
    }, []);

    const handleCheckboxChange = (selectedOption) => {
        const currentSelection = data.send_through || [];
        let updatedSelection;

        const exists = currentSelection.some(item => item.id === selectedOption.id);

        if (exists) {
            // Remove the object if it already exists
            updatedSelection = currentSelection.filter(item => item.id !== selectedOption.id);
        } else {
            // Add the object if it does not exist
            updatedSelection = [...currentSelection, selectedOption];
        }

        // Ensure at least one option is selected
        if (updatedSelection.length === 0) {
            return;
        }

        setData({ ...data, send_through: updatedSelection });
    };

    return (
        <Grid
            item
            xs={12}
            sm={12}
            sx={{
                mb: 2,
                display: { sm: "block", xs: "flex" },
                flexDirection: "column",
            }}
        >
            <Grid xs={12} sm={12}>
                <CustomTypography
                    text={'Send Through'}
                    sx={{
                        mr: 2,
                        mb: 1,
                        mt: 0.5,
                        fontSize: '14px',
                        color: '#000000',
                        fontWeight: '700',
                        lineHeight: '18px',
                        fontStyle: 'normal',
                        fontFamily: "Noto Sans",
                    }}
                />
            </Grid>
            <Grid>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.id}
                        id={`${option.id}_checkbox`}
                        control={
                            <Checkbox
                                size="small"
                                checked={(data.send_through || []).some(item => item.id === option.id)}
                                onChange={() => handleCheckboxChange(option)}
                            />
                        }
                        label={
                            <span
                                style={{
                                    color: '#000000',
                                    fontSize: '14px',
                                    display: 'inline',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    fontFamily: "Noto Sans",
                                    fontWeight: (data.send_through || []).some(item => item.id === option.id) ? '600' : '400',
                                }}
                            >
                                {option.name}
                            </span>
                        }
                    />
                ))}
            </Grid>
        </Grid>
    );
};
