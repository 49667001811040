import React from 'react';
import validator from 'validator';
import { Resend } from './VerifyOtp';
import SocialLogin from '../SocialLogin';
import Input from '../../../common/Input';
import ModalUpdateUserDetails from './ModalUpdateUserDetails';
import CustomButton from '../../../custom/button/CustomButton';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';

import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import SelectBusiness from '../../businessManagement/selectBusiness/SelectBusiness';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { AuthPagesLeftSideUI } from '../../home/insight/common/CommonComponents';

import {
    send_otp,
    userSignIn,
    retrieve_user,
    retrieve_business,
    get_list_of_business,

} from '../../../../api/urls';

import {
    setLoader,
    isFormValid,
    getQueryParams,
    stateChangeManager,
    isValidPhoneNumber,
} from '../../../../utils/Utils';

import {
    getUserPreferenceData,
    getBusinessPreferenceData,
    updateUserPreferencesData
} from '../../setting/settings/Preferences/common/getPreferences';

import {
    setToken,
    setLoginStatus,
    setUserDetails,
    setBusinessInfo,
    getSessionStorage
} from '../../../../config/cookiesInfo';

import {
    Box,
    Grid,
    Divider,
    Checkbox,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const SigninForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);
    const sessionStorage = getSessionStorage('receivedInvoice');

    const [show, setShow] = React.useState(false);
    const [agree, setAgree] = React.useState(true);
    const [isResend, setIsResend] = React.useState(false);
    const [nextStep, setNextStep] = React.useState(false);
    const [isMissingData, setIsMissingData] = React.useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const [data, setData] = React.useState({ loginInputValue: '', loginPasswordValue: '' });

    const isEmailInput = validator.isEmail(data.loginInputValue);

    React.useState(() => {
        if (sessionStorage && sessionStorage?.link_uid) {
            setData((previous) => ({
                ...previous,
                link_uid: sessionStorage?.link_uid,
                loginInputValue: sessionStorage?.email || sessionStorage?.mobile_number || "",
            }));
        }


    }, [sessionStorage]);

    const handleInputChange = (field) => (event) => {
        setData((prev) => ({ ...prev, [field]: event.target.value }));
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    const handleSubmitNext = async (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        let validation_data = [
            { key: 'loginInputValue', message: 'Email/Mobile Number field cannot be empty' },
            {
                key: 'loginInputValue',
                message: 'Please enter a valid Email/Mobile Number',
                validation: data.loginInputValue && !(isValidPhoneNumber(data.loginInputValue) || validator.isEmail(data.loginInputValue)),
            },

        ];

        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {
            setIsFormSubmitted(false);
            if (validator.isEmail(data.loginInputValue)) {
                setNextStep(true);
            }
            if (isValidPhoneNumber(data.loginInputValue)) {
                await getOTP(data.loginInputValue);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsFormSubmitted(true);

        let validation_data = [
            { key: 'loginPasswordValue', message: `${isEmailInput ? 'Password' : 'OTP'} field cannot be empty` },
            { key: '', validation: !agree, message: 'Please accept the terms and conditions.' }
        ];

        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {
            signInHandler();
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };

    const signInHandler = async () => {

        let additionData;
        if (validator.isEmail(data.loginInputValue)) {
            additionData = { is_otp_based_auth: false, email: data.loginInputValue, password: data.loginPasswordValue };
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'EMAIL' });
        } else {
            additionData = { is_otp_based_auth: true, mobile_number: data.loginInputValue, otp: data.loginPasswordValue, otp_through: 'mobile_number' };
            AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'MOBILE' });
        }
        const res = await apiAction({
            method: 'post',
            url: userSignIn(),
            data: { ...data, ...additionData, is_privacy_policy_and_terms_accepted: agree }
        });

        if (res?.success) {
            setUserDetails(res?.data?.user);
            setToken(res?.data?.access_token);
            handleMissingInfoRedirect({ is_mobile_number_empty: res?.is_mobile_number_empty, is_email_empty: res?.is_email_empty });
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };

    const handleMissingInfoRedirect = async ({ is_email_empty, is_mobile_number_empty }) => {
        setLoader(dispatch, Actions, false);

        if (is_mobile_number_empty) {
            setIsMissingData('is_mobile_number_empty');
        } else if (is_email_empty) {
            setIsMissingData('is_email_empty');
        } else if (!(is_mobile_number_empty && is_email_empty)) {
            fetchBusinessList();
        }
    };

    const fetchBusinessList = async () => {
        const res = await apiAction({ method: 'get', url: get_list_of_business() });
        if (res?.success) {
            const businessList = res?.result;
            if (businessList?.length) {
                const res = await getUserPreferenceData(navigate, dispatch);
                if (res?.success) {
                    const userInfo = res?.result;
                    fetchUserProfile(userInfo?.business_id, businessList);
                }

            } else {
                redirectToBusinessCreation();
            }

        } else {
            setLoader(dispatch, Actions, false);
        }
    }

    const fetchUserProfile = async (businessId, businessList) => {
        const res = await apiAction({ method: 'get', url: retrieve_user() });

        if (res?.success) {
            setUserDetails(res.result);
            fetchBusinessDetails(businessId, businessList);
        }
    };

    const fetchBusinessDetails = async (businessId, businessList) => {
        if (sessionStorage && sessionStorage?.link_uid) {
            setShow(true);
        } else {
            if (businessList.length) {
                const selectedBusinessId = businessId || businessList?.[0]?.id;
                const businessRes = await apiAction({
                    method: 'get',
                    url: retrieve_business(selectedBusinessId),
                });

                if (businessRes?.success) {
                    
                    if (!businessId) {
                        updateUserPreferencesData(selectedBusinessId, navigate, dispatch);
                    }
                    setLoginStatus('true');
                    setBusinessInfo(businessRes?.result);
                    getBusinessPreferenceData(businessRes?.result?.id);
                    navigate(routesName.dashboard.path);
                    setLoader(dispatch, Actions, false);
                }
            } else {
                redirectToBusinessCreation();
                setLoader(dispatch, Actions, false);
            }
        }

    };

    const redirectToBusinessCreation = () => {
        setLoader(dispatch, Actions, false);
        navigate(`/auth${routesName.businessCreate.path}`);
    };


    const handleBusinessRedirect = () => {
        fetchBusinessList();
    };


    const getOTP = async (mobileNumber) => {
        const res = await apiAction({
            method: 'post',
            url: send_otp(),
            data: { otp_for: 'login', link_uid: sessionStorage?.link_uid, mobile_number: mobileNumber, is_privacy_policy_and_terms_accepted: true },
        });
        if (res?.success) {
            setNextStep(true);
            setIsResend(!isResend);
            setIsFormSubmitted(false);
            setData((prev) => ({ ...prev, loginPasswordValue: '', session_id: res?.session_id }));
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    };


    if (params.code) {
        return <SocialLogin AnalyticsEvent={() => AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'GOOGLE' })} />;
    }
    if (isMissingData) {
        return (
            <ModalUpdateUserDetails
                open={isMissingData}
                setOpen={() => {
                    setIsMissingData(false);
                    handleBusinessRedirect();
                }}
            />
        );
    }
    if (show) {
        return <SelectBusiness show={show} data={sessionStorage} />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            height: '100vh',
            alignItems: "center",
        }}>

            <AuthPagesLeftSideUI />

            {/* Right Section */}

            <Box
                sx={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#fafafa',
                    p: 2
                }}
            >
                <Box
                    className='m_login_container'
                    sx={{
                        p: 4,
                        flexGrow: 1,
                        maxHeight: "676px",
                        maxWidth: "424px",
                        backgroundColor: '#f0f0f0',
                        borderRadius: '12px',
                    }}
                >
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>
                        <Box component="form" onSubmit={nextStep ? handleSubmit : handleSubmitNext} noValidate sx={{ width: "100%" }}>
                            <CustomTypography text="Account Login" sx={{ mb: 4, fontSize: '20px', fontWeight: 700, color: '#000', textAlign: 'start', lineHeight: "auto", }} />

                            {/* Login Input */}
                            <Box sx={{ mt: 2, }}>
                                <Input
                                    sx={{ backgroundColor: "#f6f6f6" }}
                                    autoFocus
                                    id="login_input"
                                    dataTestId="login_input"
                                    inputHeight={"27px"}
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        disabled: nextStep,
                                        value: data.loginInputValue,
                                        onChange: handleInputChange('loginInputValue'),
                                        placeholder: 'Enter Your Email Address or Mobile number',
                                        title: <LabelWithAsteriskMark
                                            label={'Email/Mobile Number'}
                                            style={{ lineHeight: "24px", fontSize: "13px" }} />,
                                        validation: isFormSubmitted && data.loginInputValue && !(isValidPhoneNumber(data.loginInputValue) || validator.isEmail(data.loginInputValue)),
                                    }}
                                    InputProps={{
                                        endAdornment: nextStep && (
                                            <InputAdornment position="end">
                                                <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Edit mode"
                                                    onClick={() => { setNextStep(false) }}
                                                >
                                                    {<Edit fontSize='small' sx={{ color: '#2464EB' }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            {nextStep && (

                                <Box sx={{ mt: 2, maxWidth: "424px" }}>
                                    <PasswordInput
                                        isFormSubmitted={isFormSubmitted}
                                        password={data.loginPasswordValue}
                                        isPasswordVisible={isPasswordVisible}
                                        label={isEmailInput ? 'Password' : 'OTP'}
                                        togglePasswordVisibility={togglePasswordVisibility}
                                        handleInputChange={handleInputChange('loginPasswordValue')}
                                    />
                                    {!isEmailInput && (
                                        <Resend handleResend={() => getOTP(data.loginInputValue)} />
                                    )}
                                    {isEmailInput && (
                                        <Box sx={{ mt: "10px", fontSize: "14px", lineHeight: "24px" }}>
                                            <Link style={{ color: '#2465EB' }} id='forgot_password_link' dataTestId='forgot_password_link' state={{ ...location.state, email: data?.loginInputValue }} to={{ pathname: "/auth" + routesName.forgotPassword.path }}>Forgot password?</Link>
                                        </Box>
                                    )}

                                    <AgreeTerms agree={agree} setAgree={setAgree} />
                                </Box>
                            )}

                            <CustomButton
                                disabled={!data.loginInputValue}
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{ mt: 4, mb: 3 }}
                                timeoutValue={nextStep ? 1000 : 0}
                                btnLabel={nextStep ? "SIGN IN" : 'Next'}
                                id={nextStep ? "sign_in_btn" : 'next_btn'}
                                dataTestId={nextStep ? "sign_in_btn" : 'next_btn'}
                                onClick={nextStep ? handleSubmit : handleSubmitNext}
                            />

                            <Divider>
                                <CustomTypography text="OR" sx={{ fontSize: '14px', fontWeight: 700 }} />
                            </Divider>

                            <SocialLogin btnText="SIGN IN WITH GOOGLE" AnalyticsEvent={() => AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: 'SIGN_IN', method: 'GOOGLE' })} />

                            <Grid container spacing={2} sx={{ pt: 3, fontSize: '14px', lineHeight: "auto", justifyContent: "center", }}>
                                <Grid item>
                                    <b style={{ fontSize: 14, paddingRight: "3px" }}>Don't have an account?</b>
                                    <Link style={{ color: '#2465EB' }} id='sign_up_link' dataTestId='sign_up_link' state={{ ...location.state }} to={{ pathname: "/auth" + routesName.signUp.path }} > Sign Up</Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default SigninForm;

export const PasswordInput = ({ label, password, isPasswordVisible, togglePasswordVisibility, handleInputChange, isFormSubmitted }) => (
    <Input
        sx={{ backgroundColor: "#f6f6f6" }}
        autoFocus
        id="password"
        dataTestId="password"
        inputHeight={"27px"}
        isKeyPressEnter={false}
        item={{
            required: true,
            fullWidth: true,
            value: password,
            onChange: handleInputChange,
            placeholder: 'Enter Your ' + label,
            validation: isFormSubmitted && (!password),
            type: isPasswordVisible ? 'text' : 'password',
            title: <LabelWithAsteriskMark label={label} style={{ lineHeight: "24px", fontSize: "13px" }} />,
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                    <IconButton
                        edge="end"
                        onClick={togglePasswordVisibility}
                        aria-label="toggle password visibility"
                    >
                        {isPasswordVisible ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
);


export const AgreeTerms = ({ agree, setAgree }) => (
    <Box sx={{ mt: 2, mb: 2 }}>
        <CustomTypography
            text={
                <div style={{ display: "flex", marginTop: "40px" }}>
                    <div style={{ marginTop: "-10px", marginLeft: "-10px" }}>
                        <Checkbox size="small" checked={agree} onChange={() => setAgree(!agree)} />
                    </div>
                    <div>
                        By signing in, I agree to the finycs{' '}
                        <Link style={{ color: '#2465EB' }} to={{ pathname: routesName.privacyPolicy.path }} target="_blank" rel="noreferrer">Privacy Policy</Link> and{' '}
                        <Link style={{ color: '#2465EB' }} to={{ pathname: routesName.termsAndServices.path }} target="_blank" rel="noreferrer">Terms and Services</Link>.
                    </div>
                </div>
            }
            sx={{ fontSize: '12px', color: '#000', textAlign: 'start', lineHeight: "19px", fontWeight: 600 }}
        />
    </Box>
);

