import React from 'react'
import { Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getSessionStorage, removeSessionStorage } from '../../config/cookiesInfo';
import CustomTypography from '../../components/custom/typography/CustomTypography';
import { routesName } from '../../config/routesName';

const StepAlert = () => {
    const navigate = useNavigate();

    const sessionStorage = getSessionStorage('receivedInvoice');

    return (
        sessionStorage && sessionStorage?.link_uid && (
            <Box sx={{ position: 'fixed', right: 0, top: 0, zIndex: 1, width: '100%', fontFamily: 'Noto Sans', }}>
                <Alert severity="info" sx={{}}><div style={{ display: 'flex', alignItems: 'center' }}>You are currently adding the received document in your received section.Don't want to continue
                    <CustomTypography
                        text="Click Here."
                        id="step_cancel_btn"
                        dataTestId="step_cancel_btn"
                        onClick={() => { navigate(routesName.customerInvoice.path + `/?t=${sessionStorage?.link_uid}`, { replace: true }); removeSessionStorage('receivedInvoice') }}
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            cursor: 'pointer',
                            fontWeight: '700',
                            color: '#2464EB',
                            lineHeight: '16px',
                            ":hover": { textDecoration: 'underline' },
                        }}
                    />
                </div>
                </Alert>
            </Box>
        )
    )
}

export default StepAlert