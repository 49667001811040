import React from 'react';
import validator from 'validator';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import * as Actions from '../../../../state/Actions';
import { signup_with_otp } from '../../../../api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { routesName } from '../../../../config/routesName';
import CustomButton from '../../../custom/button/CustomButton';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { AuthPagesLeftSideUI } from '../../home/insight/common/CommonComponents';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';

import {
    setLoader,
    isFormValid,
    stateChangeManager,
    isValidPhoneNumber,
} from '../../../../utils/Utils';


import {
    setToken,
    setUserDetails,
} from '../../../../config/cookiesInfo';

import {
    Box,
} from '@mui/material';


const OtpBasedSignup = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = Actions.getDispatch(React.useContext);

    const { state } = location;

    const [data, setData] = React.useState({});
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    const [isShow, setIsShow] = React.useState({
        is_password: false,
        is_confirmed_password: false
    });

    const onPasswordVisibility = (data) => {
        if (data === 'is_password') {
            isShow.is_password = !isShow.is_password;
        } else {
            isShow.is_confirmed_password = !isShow.is_confirmed_password;
        }
        setIsShow({ ...isShow })
    }

    const handleSubmit = async () => {
        setFormSubmitted(true)
        let validation_data = [
            { key: "first_name", message: 'Please enter a first name' },
            { key: "last_name", message: 'Please enter a last name' },
            { key: "email", message: 'Please enter a email' },
            { key: '', validation: (!data.email || !validator.isEmail(data.email)), message: 'Please enter a valid email address.' },
            { key: "mobile_number", message: "Please enter a mobile number" },
            { key: '', validation: data.mobile_number && !(data.mobile_number && isValidPhoneNumber(data.mobile_number)), message: 'Please enter a valid mobile number' },
        ]
        const { isValid, message } = isFormValid(data, validation_data);

        if (isValid) {
            signUpHandler();
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }

    }

    const signUpHandler = async () => {
        setLoader(dispatch, Actions, true)
        AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.userAuthentication.action.ACCOUNT_CREATION, method: eventsNames.actions.userAuthentication.method.EMAIL });
        let res = await apiAction({
            method: 'post',
            url: signup_with_otp(),
            data: { ...data, name: data.first_name + ' ' + data.last_name },
        })
        if (res?.success) {
            setUserDetails(res?.data.user);
            setToken(res?.data?.access_token);
            setLoader(dispatch, Actions, false);
            navigate("/auth" + routesName.businessCreate.path, { state: { ...location.state }, replace: true });
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', res?.status)
        }
    }

    React.useEffect(() => {
        if (state) {
            setData(state);
        }
    }, [state])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            height: '100vh',
            alignItems: "center",
        }}>
            <AuthPagesLeftSideUI />

            <Box
                sx={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#fafafa',
                    p: 2
                }}
            >
                <Box
                    className='m_login_container'
                    sx={{
                        p: 4,
                        flexGrow: 1,
                        maxWidth: "424px",
                        alignItems: "center",
                        borderRadius: '12px',
                        justifyContent: "center",
                        backgroundColor: '#f0f0f0',
                    }}
                >
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>
                        <Box noValidate component="form" sx={{ width: "100%" }}>
                            <CustomTypography
                                text={'Welcome to Finycs!'}
                                sx={{
                                    mb: 2,
                                    color: '#000000',
                                    fontSize: '30px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '40.86px',
                                    fontFamily: 'Noto Sans',
                                }}
                            />

                            <Box sx={{ mt: 3, }} >
                                <Input
                                    sx={{ backgroundColor: "#f6f6f6" }}
                                    autoFocus={true}
                                    id='input_first_name'
                                    dataTestId='input_first_name'
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        disabled: false,
                                        placeholder: 'Please Enter your first name',
                                        validation: isFormSubmitted && !data.first_name,
                                        title: <LabelWithAsteriskMark label={'First Name'} />,
                                        value: data.first_name ? data.first_name : '',
                                        onChange: (e) => setData({ ...data, first_name: e.target.value })
                                    }}
                                />
                            </Box>

                            <Box sx={{ mt: 2, }} >
                                <Input
                                    sx={{ backgroundColor: "#f6f6f6" }}
                                    id='input_last_name'
                                    dataTestId='input_last_name'
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        disabled: false,
                                        placeholder: 'Please Enter your last name',
                                        value: data.last_name ? data.last_name : '',
                                        validation: isFormSubmitted && !data.last_name,
                                        title: <LabelWithAsteriskMark label={'Last Name'} />,
                                        onChange: (e) => setData({ ...data, last_name: e.target.value })
                                    }}
                                />
                            </Box>

                            <Box sx={{ mt: 2, }}>
                                <Input
                                    autoFocus={false}
                                    id={'input_email_address'}
                                    dataTestId={'input_email_address'}
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        value: data.email || '',
                                        disabled: state?.email,
                                        placeholder: 'Enter Your Email Address',
                                        title: <LabelWithAsteriskMark label={'Email'} />,
                                        onChange: (e) => setData({ ...data, email: e.target.value }),
                                        validation: isFormSubmitted && (!data.email || !validator.isEmail(data.email)),
                                    }}
                                />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <Input
                                    autoFocus={false}
                                    id='input_mobile_number'
                                    dataTestId='input_mobile_number'
                                    item={{
                                        type: 'number',
                                        required: true,
                                        fullWidth: true,
                                        value: data.mobile_number || '',
                                        disabled: state?.mobile_number,
                                        placeholder: 'Enter Your Mobile Number',
                                        title: <LabelWithAsteriskMark label={'Mobile Number'} />,
                                        onChange: (e) => setData({ ...data, mobile_number: e.target.value }),
                                        validation: isFormSubmitted && !data.mobile_number && !(data.mobile_number && isValidPhoneNumber(data.mobile_number)),
                                    }}
                                />
                            </Box>

                            <CustomButton
                                fullWidth
                                type='Continue'
                                id='continue_btn'
                                btnLabel='Sign up'
                                variant='contained'
                                onClick={handleSubmit}
                                sx={{ mt: 3, mb: 3, }}
                                dataTestId='continue_btn'
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

export default OtpBasedSignup;