import React from 'react';
import moment from 'moment';
import { ApiActions } from './Helper';
import { useLocation } from 'react-router-dom';
import ReportsTitle from '../../common/ReportsTitle';
import * as Actions from '../../../../../state/Actions';
import { routesName } from '../../../../../config/routesName';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { CustomLink } from '../../../../custom/link/CustomLink';
import { export_profit_and_loss } from "../../../../../api/urls";
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';

import {
  setLoader,
  getDateFormat,
  multiple_date_range,
  special_character_check,
} from '../../../../../utils/Utils';

import {
  Box,
  Grid,
  Collapse,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';

const date_range = multiple_date_range;
const ProfitAndLoss = () => {
  let location = useLocation();
  // const current_financial_year = `${moment().year()}-${moment(getBusinessInfo() ? getBusinessInfo().financial_year_last_day : undefined).format('MM-DD')}`;

  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [results, setResults] = React.useState([]);
  const [isZero, setIsZero] = React.useState(false);
  const [dateRange, setDateRange] = React.useState();
  const [toDate, setToDate] = React.useState(moment());
  const [fromDate, setFromDate] = React.useState(moment());
  const [isDetailed, setIsDetailed] = React.useState(false);


  async function fetchData() {
    if (dateRange) {
      if (dateRange.dates.length) {
        try {
          Promise.all(dateRange.dates.map(async (data) => {
            // console.log('====>data.date_range',dateRange,data.date_range)
            let response = ApiActions(data.from_date, data.to_date, data.date_range_dates, dispatch, Actions, setLoader)
            return response
          }
          )).then(response => {
            setResults(response)
          })
        } catch {
          throw Error("Promise failed");
        }
      } else {
        if (toDate && fromDate) {
          let response = ApiActions(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`, dispatch, Actions, setLoader)
          return response
            .then(response => {
              setResults([response])
            })
        }
      }
    }
  }

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange, toDate, fromDate])


  React.useEffect(() => {

    if (location.state) {
      let data = location.state;
      setToDate(moment(data.to_date));
      setFromDate(moment(data.from_date));
      setDateRange({ name: data.name, dates: data.dates });
    } else {
      setDateRange(default_date_range);
    }
    // eslint-disable-next-line
  }, []);

  // console.log('=====>',results.length&&results.length===1?`${fromDate.format(getDateFormat())} | ${toDate.format(getDateFormat())}` : `${results[1].date_range} | ${results[0].date_range}`)

  return (
    <div>
      <ReportsSettingButtons

        scheduleData={{
          report_name: 'profit_and_loss',
          report_title: 'Profit and Loss',

        }}

        accountMappingData={{
          refreshPage: () => fetchData(),
          mapping_type: 'profit_and_loss',
        }}


        zeroData={{
          value: isZero,
          setValue: (value) => setIsZero(value),
        }}

        labelData={{
          value: isDetailed,
          labelName: 'Detailed',
          title: 'Export Preference',
          setValue: (value) => setIsDetailed(value),
        }}

        exportData={{ url: export_profit_and_loss(), data: dateRange && dateRange?.dates?.length ? { ...dateRange.dates[0], is_detailed: isDetailed, display_zero_balance_account: !isZero } : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD'), is_detailed: isDetailed, display_zero_balance_account: !isZero } }}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: { xs: 'calc(100vh - 200px)', sm: 'calc(100vh - 134px)' } }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'space-evenly',
            alignContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                title={'Profit and Loss'}
              // date={results.length ? results.length === 1 ? `${fromDate.format(getDateFormat())} | ${toDate.format(getDateFormat())}` : `${results[1].date_range} | ${results[0].date_range}` : null}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={() => { }}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={fromDate}
                setFromDate={setFromDate}

                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTableContainer>
          <Box sx={{ p: 2, pt: 0 }}>
            <CustomTable >
              <Header data={results} />
              <Body data={results} isZero={isZero} dates={dateRange?.dates?.length ? { ...dateRange.dates } : [{ from_date: fromDate?.format('YYYY-MM-DD'), to_date: toDate?.format('YYYY-MM-DD') }]} />
              <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo() && getBusinessInfo().currency && getBusinessInfo().currency.currency_code}</span></caption>
            </CustomTable>
          </Box>

        </CustomTableContainer>
      </CustomContainer>
    </div>
  )
}

export default ProfitAndLoss;


const Header = (props) => {
  const { data } = props;
  let custom_border = {
    padding: '16px',
    background: '#FFF',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  }
  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell sx={{ p: 0, border: 'none' }}>
          <div style={{ ...custom_border, marginLeft: '-16px', }} >
            <span>Particulars</span>
          </div>
        </CustomTableHeadCell>
        {data?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <CustomTableHeadCell align='right' sx={{ p: 0, border: 'none' }}>
                <div style={{ ...custom_border, marginRight: '-16px' }}>
                  <span style={{ whiteSpace: "nowrap", }}>{item.date_range}</span>
                </div>
              </CustomTableHeadCell>
            </React.Fragment>
          )
        })}
      </CustomTableRow>
    </CustomTableHead>
  )
}

const Body = (props) => {
  const { data, dates, isZero } = props

  let custom_border = {
    marginLeft: '-16px',
    marginRight: '-16px',
    background: '#FFF7DC',
    borderTop: '1px solid #FFF0C0',
    borderBottom: '1px solid #FFF0C0'
  }

  const [open, setOpen] = React.useState([]);
  const [nonZeroAccounts, setNonZeroAccounts] = React.useState();



  const addOrRemove = (array, item) => {
    const exists = array.includes(item)

    if (exists) {
      return array.filter((c) => { return c !== item })
    } else {
      const result = array
      result.push(item)
      return result
    }
  }

  let non_zero_accounts = {}
  React.useEffect(() => {
    if (isZero) {
      setOpen([]);
      setNonZeroAccounts({ ...non_zero_accounts });
    } else {
      setNonZeroAccounts({})
    }
  }, [isZero, data])

  return (
    <CustomTableBody>
      {data?.map((item, index) => {
        if (!index) {
          return (
            item?.data?.components?.map((values, index) => {

              let has_account = values.component_details && values.component_details.length !== 0;
              let hide_icons = nonZeroAccounts[values.component_name] ? true : false

              return (
                special_character_check(values.component_name) ?
                  <TotalComponent key={index} component_name={values.component_name} data={data} />
                  :
                  <CustomTableRow key={index} >
                    <CustomTableBodyCell style={special_character_check(values.component_name) ? custom_border : {}} sx={{ pl: 0, pr: 0 }}>

                      <CustomTypography
                        id={'handle_' + values.component_name}
                        dataTestId={'handle_' + values.component_name}
                        text={
                          <span style={{ cursor: isZero ? (hide_icons ? 'pointer' : '') : (has_account ? 'pointer' : ''), }} onClick={() => { if (has_account) { setOpen([...addOrRemove(open, values.component_name)]) } }}>
                            {values.component_name.replaceAll('*', ' ')}
                            {isZero ?
                              hide_icons &&
                              <Icon is_has_account={has_account} open={open.includes(values.component_name)} />
                              :
                              <Icon is_has_account={has_account} open={open.includes(values.component_name)} />
                            }
                          </span>}
                        sx={{
                          color: '#141414',
                          fontSize: '12px',
                          fontWeight: '700',
                          lineHeight: '27px',
                          fontStyle: 'normal',
                          textTransform: 'none',
                          fontFamily: "Noto Sans",
                        }}
                      />

                      {
                        has_account &&
                        <Collapse in={open.includes(values.component_name)}>
                          {
                            values?.component_details?.map((value, index) => {

                              return (
                                <Box key={index} style={{ minWidth: 300, }} sx={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#ffffff", }}>
                                  {isZero ?
                                    nonZeroAccounts[values.component_name] && nonZeroAccounts[values.component_name].includes(value.business_account) &&
                                    <NavigationComponent
                                      dates={dates}
                                      account_name={value.business_account}
                                      account_id={value.business_account_id}
                                    />
                                    :
                                    <NavigationComponent
                                      dates={dates}
                                      account_name={value.business_account}
                                      account_id={value.business_account_id}
                                    />
                                  }
                                </Box>
                              )
                            })
                          }
                        </Collapse>

                      }
                    </CustomTableBodyCell>

                    {data?.map((top_item, top_index) => {
                      return (
                        <React.Fragment key={top_index}>
                          {
                            top_item?.data?.components?.map((item, index) => {
                              if (values.component_name === item.component_name) {
                                return (
                                  <React.Fragment key={index}>
                                    <CustomTableBodyCell align='right' style={special_character_check(item.component_name) ? custom_border : {}} sx={{ pl: 0, pr: 0 }}>
                                      <CustomTypography
                                        text={<CurrencyFormatter amount={item.component_balance} />}
                                        sx={{
                                          color: '#141414',
                                          fontSize: '12px',
                                          fontWeight: '700',
                                          lineHeight: '27px',
                                          fontStyle: 'normal',
                                          textTransform: 'none',
                                          fontFamily: "Noto Sans",
                                        }}
                                      />
                                      {
                                        has_account &&
                                        <Collapse in={open.includes(values.component_name)}>
                                          {
                                            item?.component_details?.map((item, index) => {
                                              if (item.account_balance) {
                                                if (!non_zero_accounts[values.component_name]) {
                                                  non_zero_accounts[values.component_name] = [];
                                                }
                                                non_zero_accounts[values.component_name].push(item.business_account);
                                              }
                                              return (
                                                <Box key={index} sx={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#ffffff", }}>
                                                  {isZero ?
                                                    nonZeroAccounts[values.component_name] && nonZeroAccounts[values.component_name].includes(item.business_account) &&
                                                    <NavigationAmountComponent
                                                      date={dates[top_index]}
                                                      account_balance={item.account_balance}
                                                      account_name={item.business_account}
                                                      account_id={item.business_account_id}
                                                    />

                                                    :
                                                    <NavigationAmountComponent
                                                      date={dates[top_index]}
                                                      account_balance={item.account_balance}
                                                      account_name={item.business_account}
                                                      account_id={item.business_account_id}
                                                    />
                                                  }
                                                </Box>
                                              )
                                            })
                                          }
                                        </Collapse>

                                      }
                                    </CustomTableBodyCell>
                                  </React.Fragment>
                                )
                              }

                            })
                          }
                        </React.Fragment>
                      )
                    })}
                  </CustomTableRow>
              )
            })
          )
        }
      })
      }
    </CustomTableBody>
  )
}

const NavigationComponent = ({ dates, account_name, account_id }) => {
  let date = dates[0];
  return (
    <p style={{ paddingLeft: '10px', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500', whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: "hidden", }}>
      {/* <CustomLink
        id={account_name + '_link'}
        dataTestId={account_name + '_link'}
        style={{
          color: '#2464EB',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        state={dates.length === 1 ? { dateRange: { name: 'Custom', dates: 0 }, toDate: date.to_date, fromDate: date.from_date } : null}

        to={routesName.profitLossTransaction.path + "?id=" + account_id}
      >{account_name}</CustomLink> */}
      <span>{account_name}</span>

    </p>
  )
}

const NavigationAmountComponent = ({ date, account_name, account_balance, account_id }) => {
  // console.log('===>date', date)
  return (
    <p style={{ display: 'block', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500', whiteSpace: "nowrap", }}>
      {account_id ?
        <CustomLink
          id={account_name + '_link'}
          dataTestId={account_name + '_link'}
          style={{
            color: '#2464EB',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          state={date ? { dateRange: { name: 'Custom', dates: 0 }, toDate: date.to_date, fromDate: date.from_date } : null}
          to={routesName.profitLossTransaction.path + "?id=" + account_id}
        >{<CurrencyFormatter amount={account_balance} />}</CustomLink>
        : <CurrencyFormatter amount={account_balance} />
      }

    </p>
  )
}

const TotalComponent = ({ component_name, data = [] }) => {
  let custom_border = {
    padding: '16px',
    background: '#FFF7DC',
    borderTop: '1px solid #FFF0C0',
    borderBottom: '1px solid #FFF0C0'
  }
  return (
    <CustomTableRow>
      <CustomTableBodyCell sx={{ p: 0 }}>
        <div style={{ ...custom_border, marginLeft: '-16px' }}>

          <CustomTypography
            id={'handle_' + component_name}
            dataTestId={'handle_' + component_name}
            text={component_name.replaceAll('*', ' ')}
            sx={{
              color: '#141414',
              fontSize: '12px',
              fontWeight: '700',
              lineHeight: '27px',
              fontStyle: 'normal',
              textTransform: 'none',
              fontFamily: "Noto Sans",
            }}
          />
        </div>
      </CustomTableBodyCell>

      {data?.map((top_item, top_index) => {
        return (
          <React.Fragment key={top_index}>
            {
              top_item?.data?.components?.map((item, index) => {
                if (component_name === item.component_name) {
                  return (
                    <React.Fragment key={index}>
                      <CustomTableBodyCell align='right' sx={{ p: 0 }}>
                        <div style={{ ...custom_border, marginRight: '-16px' }}>

                          <CustomTypography
                            text={<CurrencyFormatter amount={item.component_balance} />}
                            sx={{
                              color: '#141414',
                              fontSize: '12px',
                              fontWeight: '700',
                              lineHeight: '27px',
                              fontStyle: 'normal',
                              textTransform: 'none',
                              fontFamily: "Noto Sans",
                            }}
                          />
                        </div>
                      </CustomTableBodyCell>
                    </React.Fragment>
                  )
                }

              })
            }
          </React.Fragment>
        )
      })}
    </CustomTableRow>
  )

}
const Icon = (props) => {
  const { open, is_has_account } = props;
  return (
    is_has_account ? open ?
      <ArrowDropUpIcon fontSize='small' sx={{ mb: -0.8, }} />
      :
      <ArrowDropDownIcon fontSize='small' sx={{ mb: -0.8, }} />
      : null
  )
}

