import Header from "./Header";
import Footer from "./Footer";
import Banner from "./Banner";
import Reviews from "./Reviews";
import Pricing from "./Pricing";
import Benefits from "./Benefits";
import KeyFeatures from "./KeyFeatures";
import AuditCompliant from "./AuditCompliant";
import OverduePayment from "./OverduePayment";
import NewAuditCompliant from "./NewAuditCompliant";
import NewBanner from "./NewBanner";
import NewBenefits from "./NewBenefits";
import NewReviews from "./NewReviews";
import NewOverDuePayment from "./NewOverduePayment";
import NewPricing from "./NewPricing";
import NewHeader from "./NewHeader";

export {
    Header,
    Footer,
    Banner,
    Pricing,
    Reviews,
    Benefits,
    KeyFeatures,
    AuditCompliant,
    OverduePayment,
    NewAuditCompliant,
    NewBenefits,
    NewBanner,
    NewReviews,
    NewOverDuePayment,
    NewPricing,
    NewHeader
}