import React from 'react';
import moment from 'moment';
import { ApiActions } from './Helper';
import ReportsTitle from '../../common/ReportsTitle';
import * as Actions from '../../../../../state/Actions';
import { routesName } from '../../../../../config/routesName';
import { export_balance_sheet } from "../../../../../api/urls";
import { CustomLink } from '../../../../custom/link/CustomLink';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';

import {
  setLoader,
  getDateFormat,
  multiple_date_range,
  getFinancialYearDate,
} from '../../../../../utils/Utils';

import {
  Box,
  Grid,
  Collapse
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';



const date_range = multiple_date_range;
const BalanceSheet = () => {
  const migration_date = getBusinessInfo()?.migration_date;

  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]


  const [results, setResults] = React.useState([]);
  const [isZero, setIsZero] = React.useState(false);
  const [toDate, setToDate] = React.useState(moment());
  const [isDetailed, setIsDetailed] = React.useState(false);
  const [dateRange, setDateRange] = React.useState(default_date_range);


  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange, toDate,])

  async function fetchData() {
    if (dateRange) {
      if (dateRange.dates.length) {
        try {
          Promise.all(dateRange.dates.map(async (data) => {
            let response = ApiActions(data.from_date, data.to_date, data.date_range, dispatch, Actions, setLoader)
            return response
          }
          )).then(response => {
            setResults(response)
          })
        } catch {
          throw Error("Promise failed");
        }
      } else {
        let response = ApiActions(null, toDate.format('YYYY-MM-DD'), `${toDate.format(getDateFormat())}`, dispatch, Actions, setLoader)
        return response
          .then(response => {
            setResults([response])
          })
      }
    }
  }

  return (
    <div>

      <ReportsSettingButtons
        scheduleData={{
          report_name: 'balance_sheet',
          report_title: 'Balance Sheet',

        }}
        accountMappingData={{
          refreshPage: () => fetchData(),
          mapping_type: 'balance_sheet',
        }}

        zeroData={{
          value: isZero,
          setValue: (value) => setIsZero(value),
        }}

        labelData={{
          value: isDetailed,
          labelName: 'Detailed',
          title: 'Export Preference',
          setValue: (value) => setIsDetailed(value),
        }}
        exportData={{ url: export_balance_sheet(), data: dateRange.dates.length ? { ...dateRange.dates[0], is_detailed: isDetailed, display_zero_balance_account: !isZero } : { to_date: toDate.format('YYYY-MM-DD'), is_detailed: isDetailed, display_zero_balance_account: !isZero } }}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'space-evenly',
            alignContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                dateText={'As of '}
                title={'Balance Sheet'}
                date={results?.length ? results?.length === 1 ? `${toDate.format(getDateFormat())}` : `${results[0].date_range}` : null}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={() => { }}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={null}
                setFromDate={() => { }}

                dateRange={dateRange}
                setDateRange={setDateRange}

                isAsOff={true}
              />
            </Grid>
          </Grid>
        </div>

        <CustomTableContainer>
          <Box sx={{ p: 2, pt: 0 }}>
            <CustomTable >
              <Header data={results} />
              <Body data={results} isZero={isZero} dates={dateRange?.dates?.length ? { ...dateRange.dates } : [{ from_date: moment(migration_date)?.format('YYYY-MM-DD'), to_date: toDate?.format('YYYY-MM-DD') }]} />
              <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo().currency.currency_code}</span></caption>
            </CustomTable>
          </Box>
        </CustomTableContainer>
      </CustomContainer>
    </div>
  )
}

export default BalanceSheet;

const Header = (props) => {
  const { data } = props;
  let custom_border = {
    padding: '16px',
    background: '#FFF',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  }
  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell sx={{ minWidth: 150, p: 0, border: 'none' }}>
          <div style={{ ...custom_border, marginLeft: '-16px', }} >
            <span>Particulars</span>
          </div>
        </CustomTableHeadCell>
        {data?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <CustomTableHeadCell sx={{ minWidth: 150, p: 0, border: 'none' }} align='right'>
                <div style={{ ...custom_border, marginRight: '-16px' }}>
                  <span style={{ whiteSpace: "nowrap", }}>{item.date_range}</span>
                </div>
              </CustomTableHeadCell>
            </React.Fragment>
          )
        })}
      </CustomTableRow>
    </CustomTableHead>
  )
}

const Body = (props) => {
  const { dates, data, isZero } = props
  const custom_components = {
    assets: { title: 'ASSETS', total: 'total_assets', totalTitle: `Total for Assets` },
    equities: { title: 'EQUITIES' },
    liabilities: { title: 'LIABILITIES', total: 'total_liabilities_and_equities', totalTitle: `Total for Liabilities and Equities` },

  }

  return (
    <CustomTableBody>
      {data?.map((item, index) => {
        if (!index) {
          return (
            Object.keys(item?.data?.components)?.map((key, index) => {
              return (
                <React.Fragment key={index}>
                  {custom_components[key] &&
                    <TableRows dates={dates} value_key={key} data={data} values={item.data.components[key]} title={custom_components[key].title} isZero={isZero} />
                  }
                  {key === custom_components['assets'].total &&
                    <TotalComponent dates={dates} value_key={key} data={data} values={item.data.components[key]} title={custom_components['assets'].totalTitle} />
                  }
                  {key === custom_components['liabilities'].total &&
                    <TotalComponent dates={dates} value_key={key} data={data} values={item.data.components[key]} title={custom_components['liabilities'].totalTitle} />
                  }
                </React.Fragment>
              )
            })
          )
        }
      })
      }
    </CustomTableBody>
  )
}

const TableRows = (props) => {
  const { dates, values, isZero, title, data = [], value_key } = props;

  const [open, setOpen] = React.useState([]);
  const [nonZeroAccounts, setNonZeroAccounts] = React.useState({});


  const addOrRemove = (array, item) => {
    const exists = array.includes(item)

    if (exists) {
      return array.filter((c) => { return c !== item })
    } else {
      const result = array
      result.push(item)
      return result
    }
  }

  let non_zero_accounts = {}
  React.useEffect(() => {
    if (isZero) {
      setOpen([]);
      setNonZeroAccounts({ ...non_zero_accounts })
    } else {
      setNonZeroAccounts({})
    }
  }, [isZero, values])

  return (
    <CustomTableRow>
      <CustomTableBodyCell sx={{ minWidth: 150, pl: 0, pr: 0 }}>
        <CustomTypography
          text={title}
          sx={{
            color: '#141414',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '27px',
            fontStyle: 'normal',
            textTransform: 'none',
            fontFamily: "Noto Sans",
          }}
        />
        {values.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <NestedRow dates={dates} {...item} open={open} setOpen={setOpen} addOrRemove={addOrRemove} isZero={isZero} nonZeroAccounts={nonZeroAccounts} />
            </React.Fragment>
          )
        })}
      </CustomTableBodyCell>

      {data.map((item, top_index) => {
        return (
          <React.Fragment key={top_index}>
            <CustomTableBodyCell align='right' sx={{ minWidth: 150, pl: 0, pr: 0 }}>
              <CustomTypography
                text={title}
                sx={{
                  color: '#141414',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '27px',
                  fontStyle: 'normal',
                  visibility: 'hidden',
                  textTransform: 'none',
                  fontFamily: "Noto Sans",
                }}
              />

              {item?.data?.components[value_key]?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <NestedTotal date={dates[top_index]} {...item} open={open} setOpen={setOpen} non_zero_accounts={non_zero_accounts} isZero={isZero} nonZeroAccounts={nonZeroAccounts} addOrRemove={addOrRemove} />
                  </React.Fragment>
                )
              })}
            </CustomTableBodyCell>
          </React.Fragment>
        )
      })}
    </CustomTableRow>
  )
}

const NestedRow = (props) => {
  const { isZero, nonZeroAccounts, open, setOpen, addOrRemove, component_details = [], component_name, has_account, marginLeft = 1, } = props;

  let is_has_account = component_name;
  let hide_icons = nonZeroAccounts[component_name] ? true : false

  return (
    component_name &&
    <Box sx={{ ml: marginLeft }}>
      {component_details?.length ?
        <React.Fragment>
          <CustomTypography
            id={'handle_' + component_name}
            dataTestId={'handle_' + component_name}
            text={
              <span style={{ cursor: isZero ? (hide_icons ? 'pointer' : '') : (is_has_account ? 'pointer' : ''), }} onClick={() => { if (isZero ? hide_icons : is_has_account) { setOpen([...addOrRemove(open, component_name)]) } }}>
                {component_name}
                {isZero ?
                  hide_icons &&
                  <Icon is_has_account={is_has_account} open={open.includes(component_name)} />
                  :
                  <Icon is_has_account={is_has_account} open={open.includes(component_name)} />
                }
              </span>}
            sx={{
              color: '#141414',
              fontSize: '12px',
              fontWeight: '700',
              lineHeight: '27px',
              fontStyle: 'normal',
              textTransform: 'none',
              fontFamily: "Noto Sans",
            }}
          />

          <Collapse in={open.includes(component_name)}>
            {component_details?.map((item, index) => {
              return (
                has_account ?
                  <Box key={index} style={{ minWidth: 400, }} sx={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#ffffff", }}>
                    {/* use the recursion and show the end accounts */}
                    {
                      isZero ?
                        nonZeroAccounts[component_name] && nonZeroAccounts[component_name].includes(item.business_account) &&
                        <NavigationComponent
                          account_name={item.business_account}
                          account_id={item.business_account_id}
                        />

                        :
                        <NavigationComponent
                          account_name={item.business_account}
                          account_id={item.business_account_id}
                        />
                    }
                  </Box>
                  :
                  <React.Fragment key={index}>
                    <NestedRow {...item} marginLeft={marginLeft + 1} open={open} setOpen={setOpen} addOrRemove={addOrRemove} isZero={isZero} nonZeroAccounts={nonZeroAccounts} />
                  </React.Fragment>
              )
            })}
            <TotalCollapseComponent marginLeft={marginLeft + 5} component_name={component_name} />
          </Collapse>
        </React.Fragment>
        :
        <CustomTypography
          id={'handle_' + component_name}
          dataTestId={'handle_' + component_name}
          text={component_name}
          sx={{
            color: '#141414',
            fontSize: '12px',
            fontWeight: '700',
            lineHeight: '27px',
            fontStyle: 'normal',
            textTransform: 'none',
            fontFamily: "Noto Sans",
          }}
        />
      }


    </Box>
  )
}

const NavigationComponent = ({ account_name, account_id }) => {
  return (
    <p style={{ paddingLeft: '10px', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500', whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: "hidden", }}>
      {/* <CustomLink
        id={account_name + '_link'}
        dataTestId={account_name + '_link'}
        style={{
          color: '#2464EB',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        to={routesName.balanceSheetTransaction.path + "?id=" + account_id}
      >{account_name}</CustomLink> */}
      <span>{account_name}</span>

    </p>
  )
}

const NavigationAmountComponent = ({ date, account_name, account_balance, account_id }) => {
  const businessInfo = getBusinessInfo();
  const migration_date = businessInfo?.migration_date;
  const fiscal_year = businessInfo?.fiscal_year?.month_range;


  // Helper function to determine the link target
  const getLinkTarget = (account_name) => {
    if (account_name === 'Previous Earning' || account_name === 'Current Year Earnings') {
      return routesName.profitLoss.path;
    }
    return `${routesName.balanceSheetTransaction.path}?id=${account_id}`;
  };

  // Helper function to generate state object based on conditions
  const generateState = (date) => {
    const { start_date, end_date } = getFinancialYearDate(fiscal_year, date?.to_date);

    if (!date) return null;

    if (account_name === 'Previous Earning') {
      return {
        name: 'Custom',
        dates: 0,
        from_date: moment(start_date).subtract(1, 'year').format('YYYY-MM-DD'),
        to_date: moment(end_date).subtract(1, 'year').format('YYYY-MM-DD'),
      };
    }

    if (account_name === 'Current Year Earnings') {
      return {
        name: 'Custom',
        dates: 0,
        from_date: start_date,
        to_date: date.to_date,
      };
    }

    return {
      dateRange: { name: 'Custom', dates: 0 },
      toDate: date.to_date,
      fromDate: moment(migration_date).format('YYYY-MM-DD'),
    };
  };

  const state = generateState(date);
  const linkTarget = getLinkTarget(account_name);
  const isLink = Boolean(account_id || account_name === 'Current Year Earnings');

  return (
    account_name === 'Current Year Earnings' ?
      <CustomLink
        state={state}
        to={linkTarget}
        id={`${account_name}_link`}
        dataTestId={`${account_name}_link`}
        style={{
          color: '#2464EB',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <CustomTypography
          text={<CurrencyFormatter amount={account_balance} />}
          sx={{
            color: 'inherit',
            fontSize: '12px',
            fontWeight: '700',
            lineHeight: '27px',
            fontStyle: 'normal',
            textTransform: 'none',
            fontFamily: "Noto Sans",
          }}
        />
      </CustomLink>

      :
      <p style={{ paddingLeft: '10px', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500', whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: "hidden", }}>
        {isLink ? (
          <CustomLink
            state={state}
            to={linkTarget}
            id={`${account_name}_link`}
            dataTestId={`${account_name}_link`}
            style={{
              color: '#2464EB',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <CurrencyFormatter amount={account_balance} />
          </CustomLink>
        ) : (
          <CurrencyFormatter amount={account_balance} />
        )}
      </p>
  );
};


const NestedTotal = (props) => {
  const { date, isZero, addOrRemove, non_zero_accounts, nonZeroAccounts, open, setOpen, component_details = [], component_name, component_balance, has_account, } = props;



  if (component_name === 'Current Year Earnings') {
    return (
      <NavigationAmountComponent
        date={date}
        account_id={''}
        account_name={component_name}
        account_balance={component_balance}
      />
    )
  }

  return (
    component_name &&
    <Box sx={{}}>
      <CustomTypography
        text={<CurrencyFormatter amount={component_balance} />}
        sx={{
          color: '#141414',
          fontSize: '12px',
          fontWeight: '700',
          lineHeight: '27px',
          fontStyle: 'normal',
          textTransform: 'none',
          fontFamily: "Noto Sans",
          visibility: !open.includes(component_name) ? 'visible' : 'hidden',
        }}
      />

      <Collapse in={open.includes(component_name)}>
        {component_details?.map((value, index) => {
          if (value.component_balance !== undefined || value.account_balance) {
            if (!non_zero_accounts[component_name]) {
              non_zero_accounts[component_name] = [];
            }
            let component_name_exists = non_zero_accounts[component_name].includes(component_name)
            let business_account_exists = non_zero_accounts[component_name].includes(value.business_account)
            if (component_name_exists || business_account_exists) {
              non_zero_accounts[component_name].filter((c) => { return c !== value.business_account })
            } else {
              non_zero_accounts[component_name].push(value.business_account);
            }

          }
          return (
            has_account ?
              <Box key={index} sx={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#ffffff", }}>
                {/* use the recursion and show the end accounts balance */}

                {isZero ?
                  nonZeroAccounts[component_name] && nonZeroAccounts[component_name].includes(value.business_account) &&

                  <NavigationAmountComponent
                    date={date}
                    account_name={value.business_account}
                    account_id={value.business_account_id}
                    account_balance={value.account_balance}
                  />
                  :
                  <NavigationAmountComponent
                    date={date}
                    account_name={value.business_account}
                    account_id={value.business_account_id}
                    account_balance={value.account_balance}
                  />
                }

              </Box>
              :
              <React.Fragment key={index}>
                <NestedTotal date={date} {...value} open={open} setOpen={setOpen} non_zero_accounts={non_zero_accounts} isZero={isZero} nonZeroAccounts={nonZeroAccounts} addOrRemove={addOrRemove} />
              </React.Fragment>
          )
        })}
        <TotalCollapseComponent component_balance={component_balance} />
      </Collapse>


    </Box>
  )
}

const TotalComponent = (props) => {
  const { title, data = [], value_key } = props;
  let custom_border = {
    padding: '16px',
    background: '#FFF7DC',
    borderTop: '1px solid #FFF0C0',
    borderBottom: '1px solid #FFF0C0'
  }
  return (
    <CustomTableRow >
      <CustomTableBodyCell sx={{ p: 0 }}>
        <div style={{ ...custom_border, marginLeft: '-16px' }}>
          <strong style={{ color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '700', whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: "hidden", }}>{title}</strong>
        </div>
      </CustomTableBodyCell>
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <CustomTableBodyCell align='right' sx={{ p: 0 }}>
              <div style={{ ...custom_border, marginRight: '-16px' }}>
                <strong style={{ color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '700', whiteSpace: "nowrap", }}>
                  <CurrencyFormatter amount={item.data.components[value_key]} />
                </strong>
              </div>
            </CustomTableBodyCell>
          </React.Fragment>
        )
      })}
    </CustomTableRow>
  )

}

const TotalCollapseComponent = (props) => {
  const { marginLeft = 2, component_name, component_balance } = props;
  return (
    <Box sx={{ ml: -marginLeft, mr: -2, mt: 1, borderTop: '2px solid #F5F5F5', borderBottom: '2px solid #F5F5F5' }}>
      <Box sx={{ p: 0.5, textAlign: component_name ? 'center' : '' }}>
        {component_name ?
          <CustomTypography
            text={'Total ' + component_name}
            sx={{
              pl: marginLeft,
              color: '#141414',
              fontSize: '12px',
              fontWeight: '700',
              lineHeight: '27px',
              fontStyle: 'normal',
              textTransform: 'none',
              fontFamily: "Noto Sans",
            }}
          />
          :
          <CustomTypography
            text={<CurrencyFormatter amount={component_balance} />}
            sx={{
              pr: 1.5,
              color: '#141414',
              fontSize: '12px',
              fontWeight: '700',
              lineHeight: '27px',
              fontStyle: 'normal',
              textTransform: 'none',
              fontFamily: "Noto Sans",
            }}
          />
        }

      </Box>
    </Box>
  )
}

const Icon = (props) => {
  const { open, is_has_account } = props;
  return (
    is_has_account ? open ?
      <ArrowDropUpIcon fontSize='small' sx={{ mb: -0.8, }} />
      :
      <ArrowDropDownIcon fontSize='small' sx={{ mb: -0.8, }} />
      : null
  )
}