import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../../../state/Actions';
import CustomTitle from '../../../common/CustomTitle';
import CommonSearch from '../../../common/CommonSearch';
import { routesName } from '../../../../config/routesName';
import ListItemActions from '../../../common/ListItemActions';
import CustomDialog from '../../../custom/dialog/CustomDialog';
import { NoDataComponent } from '../../../common/NoDataComponent';
import CustomPagination from '../../../custom/pagination/CustomPagination';
import { apiAction, apiBlobResponse, apiHandleDownload } from '../../../../api/api';
import { AnalyticsEvent, eventsNames } from '../../../../firebase/firebaseAnalytics';

import {
  export_received_invoice,
  delete_received_invoice,
  retrieve_received_invoice,
  get_list_of_received_invoice,
} from '../../../../api/urls';

import {
  ActionTextLinkBtn
} from '../../invoicing/common/CommonLinks';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../custom/container/CustomContainer';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../custom/table/CustomTable';

import {
  receivedInvoiceColumns,
} from './Helpers';

import {
  setLoader,
} from '../../../../utils/Utils';

import {
  Box,
  Grid,
} from '@mui/material';
import CustomDateRangePicker from '../../../custom/Datepicker/CustomDateRangePicker';
import DateRange from '../../../common/DateRange';


const Dropdown = DateRange;
const ReceivedInvoiceList = () => {
  const dispatch = Actions.getDispatch(React.useContext);

  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const [filters, setFilters] = React.useState({
    sort_by: 'invoice_date',
    sort_order: 'D',
  })

  const buildFilters = (filters) => {
    const filterParams = [
      filters.has_billed && `has_billed=${filters.has_billed}`,
      filters.payment_status && `payment_status=${filters.payment_status}`,
      filters.sender_business_legal_name && `sender_business_legal_name=${filters.sender_business_legal_name}`,
      filters.invoice_start_date && filters.invoice_end_date && `invoice_start_date=${filters.invoice_start_date}&invoice_end_date=${filters.invoice_end_date}`,
    ].filter(Boolean);

    const sortParams = `sort_by=${filters.sort_by}&sort_order=${filters.sort_order}`;

    return `&${sortParams}&${filterParams.join('&')}`;
  };

  const getInvoice = async () => {
    setLoader(dispatch, Actions, true);
    const additionalParams = buildFilters(filters);
    let res = await apiAction({
      method: 'get',
      url: get_list_of_received_invoice(page, additionalParams),
    })
    if (res?.success) {
      setResults(res?.result.result);

      setPagination({
        ...pagination,
        next: res?.result.next,
        count: res?.result.count,
        previous: res?.result.previous,
        number_of_pages: res?.result.number_of_pages,
      })
      setLoader(dispatch, Actions, false);
    } else {
      setLoader(dispatch, Actions, false);
    }
  }


  React.useEffect(() => {
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters]);


  const stateChangeHandler = (title, condition, maxWidth, url, deleteMessage) => {
    setState({
      url: url,
      open: true,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      deleteMessage: deleteMessage,
      onDeleteAction: () => { getInvoice() }
    })
  }

  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
      />
      <CustomTitleContainer>
        <CustomTitle title={'Received Invoices'} />
      </CustomTitleContainer>

      <CustomContainer maxWidth={"400"} sx={{ maxHeight: { xs: 'calc(100vh - 240px)', sm: '100%' } }} >
        <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '24px', display: '-webkit-flex', }}>
          <Filters filters={filters} setFilters={setFilters} setPage={setPage} />
        </div>
        <CustomTableContainer>
          <CustomTable>
            <TableHeader columns={receivedInvoiceColumns({ filters: filters, setFilters: setFilters })} />
            <TableBody columns={receivedInvoiceColumns({ filters: filters, setFilters: setFilters })} rows={results} stateChangeHandler={stateChangeHandler} />
          </CustomTable>

          <Box sx={{ pt: !results.length ? 10 : 0 }} >
            <NoDataComponent left='0%' top='0%' position={'relative'} data={results} />
          </Box>

        </CustomTableContainer>
      </CustomContainer>

      <CustomPagination
        page={page}
        count={pagination.number_of_pages}
        onChange={(event, newValue) => setPage(newValue)}
      />
    </div>
  )
}

export default ReceivedInvoiceList


const TableHeader = ({ columns }) => {
  return (
    <CustomTableHead>
      <CustomTableRow >
        {columns?.map((item) => (
          <CustomTableHeadCell align={item?.align} style={{ ...item?.style }}>{item?.label}</CustomTableHeadCell>
        ))}
        <CustomTableHeadCell style={{ width: 100 }} align='right'><span>Actions</span></CustomTableHeadCell>

      </CustomTableRow>
    </CustomTableHead>
  )
}

const TableBody = ({ columns, rows, stateChangeHandler }) => {
  let navigate = useNavigate()
  const dispatch = Actions.getDispatch(React.useContext);

  const getQuickActions = (item) => {
    const actions = [];
    actions.push(
      !item.has_billed && { name: 'View', onClick: () => { onSelectAction("view", item) } },
      { name: 'Print', onClick: () => { onSelectAction("print", item) } },
      { name: 'Export as PDF', onClick: () => { onSelectAction("download", item) } },
      {
        name: 'Delete',
        showDivider: true,
        onClick: () => { onSelectAction("delete", item) }
      }
    );
    return actions;


  }

  const onSelectAction = (action, item) => {
    setLoader(dispatch, Actions, true);
    if (action === "view") {
      navigate(routesName.receivedInvoiceView.path + "?id=" + item.id)
    }
    if (action === "print") {
      printInvoice(item)
      AnalyticsEvent(eventsNames.categories.RECEIVEDINVOICES, { action: eventsNames.actions.PRINT })
    }
    if (action === "download") {
      downloadInvoice(item)
      AnalyticsEvent(eventsNames.categories.RECEIVEDINVOICES, { action: eventsNames.actions.DOWNLOAD })
    }
    if (action === "convert") {
      setLoader(dispatch, Actions, false);
    }
    if (action === "delete") {
      stateChangeHandler('Delete Received Invoice', 'delete', 'sm', delete_received_invoice(item.id), `The received invoice will be deleted and can not be retrieved later.\n Are you sure about deleting it?`);
      setLoader(dispatch, Actions, false);
    }
  }

  const printInvoice = async (item) => {
    let data = await apiAction({
      method: 'get',
      url: retrieve_received_invoice(item.id),
    })
    if (data) {
      apiBlobResponse({
        url: export_received_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term, export_type: 'pdf' }, onSuccess: () => {
          setLoader(dispatch, Actions, false)
        }
      })
    }
  }

  const downloadInvoice = async (item) => {
    let data = await apiAction({
      method: 'get',
      url: retrieve_received_invoice(item.id),
    })
    if (data) {
      apiHandleDownload({
        url: export_received_invoice(), data: { ...data.result, terms_and_conditions_id: data.result.terms_and_conditions, payment_term_id: data.result.payment_term, export_type: 'pdf' }, filename: data.result.invoice_number, onSuccess: () => {
          setLoader(dispatch, Actions, false)
        }
      })
    }
  }

  return (
    <CustomTableBody>
      {rows?.map((rowItem) => (
        <CustomTableRow >
          {columns?.map((colItem) => (
            <CustomTableBodyCell align={colItem?.align} style={{ ...colItem?.style }}>{colItem?.onRender ? colItem?.onRender(rowItem) : rowItem[colItem.field]}</CustomTableBodyCell>
          ))}
          <CustomTableBodyCell style={{ width: 100 }} align='right'>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

              <ActionTextLinkBtn
                toActionText={rowItem.has_billed ? 'View' : 'Convert to Bill'}
                onClickActionText={() => {
                  onSelectAction(
                    rowItem.has_billed ? 'view' : 'convert',
                    rowItem
                  );
                }} />
              <ListItemActions
                actions={getQuickActions(rowItem)}
              />
            </Box>
          </CustomTableBodyCell>

        </CustomTableRow>
      ))}
    </CustomTableBody>
  )
}

const Filters = ({ setPage, setFilters, filters }) => {

  const onDateRangeChange = (dates) => {
    setPage(1);
    if (dates) {
      setFilters({ ...filters, invoice_start_date: dates[0].format("YYYY-MM-DD"), invoice_end_date: dates[1].format("YYYY-MM-DD"), dates: dates })
    } else {
      delete filters.invoice_start_date
      delete filters.invoice_end_date
      delete filters.dates
      setFilters({ ...filters })
    }

  }

  const paymentStatusList = [
    { name: 'Paid', value: 'paid' },
    { name: 'Unpaid', value: 'unpaid' },
    // { name: 'Overdue', value: 'overdue' },
    { name: 'Partially Paid', value: 'partially_paid' },
  ]

  const statusList = [
    { name: 'Billed', value: 'True' },
    { name: 'Unbilled', value: 'False' },
  ]
  return (
    <Grid xs={12} container sx={{  }} spacing={1}>
      <Grid item xs={6} sm={2.5}>
        <Dropdown
          item={{}}
          label="Status"
          placeholder='Select Status'

          id={'status_dropdown'}
          dataTestId={'status_dropdown'}

          results={statusList}
          value={statusList.find((item) => item.value === filters?.has_billed)}
          setValue={(event, value) => {
            if (value) {
              filters.has_billed = value?.value;
            } else {
              delete filters.has_billed;
            }
            setFilters((previous) => ({ ...previous, filters }))
          }}

        />
      </Grid>
      <Grid item xs={6} sm={2.5}>
        <Dropdown
          item={{}}
          label="Payment Status"
          placeholder='Select Payment Status'

          id={'payment_status_dropdown'}
          dataTestId={'payment_status_dropdown'}

          results={paymentStatusList}
          value={paymentStatusList.find((item) => item.value === filters?.payment_status)}
          setValue={(event, value) => {
            if (value) {
              filters.payment_status = value?.value;
            } else {
              delete filters.payment_status;
            }
            setFilters((previous) => ({ ...previous, filters }))
          }}

        />
      </Grid>
      <Grid item xs={12} sm={4.5} container>
        <CustomDateRangePicker onDateRangeChange={onDateRangeChange} />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <CommonSearch
          title={'Search'}
          fullWidth={true}
          titleVisibility={'hidden'}
          placeholder={'Enter name'}
          id={'input_search_sender_business_legal_name'}
          dataTestId={'input_search_sender_business_legal_name'}
          onSearchValue={filters?.sender_business_legal_name || ''}
          onSearch={(search_text) => {
            setPage(1);
            setFilters({ ...filters, sender_business_legal_name: search_text })
          }}
        />
      </Grid>
    </Grid>
  )
}