import React from 'react';
import moment from 'moment';
import { IconButton, } from '@mui/material';
import CustomInput from '../input/CustomInput';
import ClearIcon from "@mui/icons-material/Clear";
import * as Actions from "../../../state/Actions";
import { DateTimePicker } from "@mui/x-date-pickers";
import { stateChangeManager } from '../../../utils/Utils';
import { getBusinessInfo } from '../../../config/cookiesInfo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactComponent as CalendarMonthIcon } from '../../../assets/svg/CalendarMonthIcon.svg';

const CustomDatePicker = ({
  id,
  sx,
  value,
  isTime,
  maxDate,
  minDate,
  onChange,
  dataTestId,
  validation,
  no_minDate,
  placeholder,
  disabledDatePicker,
  disabledCloseIcon,
  inputFormat = "DD-MM-YYYY",
  dateTimeFormat = 'DD-MM-YYYY hh:mm a',
  ...props
}) => {
  const [error, setError] = React.useState(false);
  const dispatch = Actions.getDispatch(React.useContext);
  const [visibility, setVisibility] = React.useState(false);

  const migration_date = getBusinessInfo()?.migration_date;
  const minDateTime = minDate ? minDate : migration_date ? moment(migration_date) : undefined;

  const handleVisibility = (isVisible) => {
    if (value !== undefined && value !== null && value !== '') {
      setVisibility(isVisible);
    }
  }

  React.useEffect(() => {
    if (error) {
      stateChangeManager(dispatch, Actions, true, "error", "Please Enter Valid Date");
    }
  }, [error])

  const renderInput = (params, InputProps) => (
    <CustomInput
      id={id}
      dataTestId={dataTestId}

      {...params}
      readOnly={false}
      fullWidth={true}
      error={error ? error : validation}
      validation={error ? error : validation}
      isShowEndAdornment={true}
      disabled={disabledDatePicker}
      placeholderTextTransform={'upperCase'}
      onMouseEnter={() => { handleVisibility(true) }}

      onBlur={(event) => {
        if (event.target.value) {
          let dateValue = moment(event.target.value, inputFormat, true)
          if (dateValue.isValid()) {
            setError(false)
            onChange(dateValue)
          } else {
            setError(true)
            onChange(null)
          }
        } else {
          setError(false)
        }
      }}

      onMouseLeave={() => { handleVisibility(false) }}
      sx={{ ...sx, background: disabledDatePicker && '#F1F3F4' }}
      // placeholderTextTransform={placeholder ? 'capitalize' : 'upperCase'}
      endAdornment={
        <>
          {!disabledCloseIcon && !disabledDatePicker &&
            <IconButton
              size="small"
              onClick={() => { onChange(null); handleVisibility(false) }}
              sx={{ marginRight: -1.5, visibility: visibility ? 'visible' : 'hidden' }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          }
          {InputProps?.endAdornment}
        </>
      }
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} id={id} dataTestId={dataTestId}>
      {isTime ? (
        <DateTimePicker
          id={id}
          clearable
          value={value}
          dataTestId={dataTestId}
          InputAdornmentProps={{}}
          disableMaskedInput={false}
          disabled={disabledDatePicker}
          maxDateTime={maxDate || undefined}
          inputFormat={value ? dateTimeFormat : inputFormat}
          minDateTime={no_minDate ? undefined : minDateTime}
          components={{ OpenPickerIcon: CalendarMonthIcon }}
          renderInput={params => renderInput(params, params.InputProps)}

          onChange={(value) => {
            if (value?._isValid) {
              setError(false)
              onChange(value)
            }
          }}
        />
      ) : (
        <DesktopDatePicker
          id={id}
          clearable
          {...props}
          value={value}
          dataTestId={dataTestId}
          InputAdornmentProps={{}}
          inputFormat={inputFormat}
          disableMaskedInput={false}
          disabled={disabledDatePicker}
          maxDate={maxDate || undefined}
          components={{ OpenPickerIcon: CalendarMonthIcon }}
          renderInput={params => renderInput(params, params.InputProps)}
          minDate={no_minDate ? undefined : (minDate || (migration_date ? moment(migration_date) : undefined))}

          onChange={(value) => {
            if (value?._isValid) {
              setError(false)
              onChange(value)
            }
          }}
        />

      )}
    </LocalizationProvider>
  );
};

export default CustomDatePicker;