import React from 'react';
import TabManager from './TabManager';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import ManageDataPoint from '../dataPoint/ManageDataPoint';
import { DeleteComponent } from '../common/CommonComponents';
import DraggableTabs from '../../../../custom/tabs/DraggableTabs';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import { HtmlTooltip } from '../../../../custom/tooltip/CustomTooltip';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';

import {
    isNumber,
    stateChangeManager
} from '../../../../../utils/Utils';

import {
    get_insight_tab,
    list_of_insight_tab,
    update_insight_tab_order
} from '../../../../../api/urls';

import {
    CustomCard,
    CustomSkeleton
} from '../common/CustomComponents';

import {
    Box,
    Avatar,
    Popover,
    Divider,
    MenuItem,
    IconButton
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const ListInsightTab = ({ addNewSection, setAddNewSection, getTabData, tabData, setTabData, refreshDataPoint }) => {
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [result, setResult] = React.useState([]);
    const [isLoader, setIsLoader] = React.useState(true);
    const [selectedInsight, setSelectedInsight] = React.useState(0);

    React.useEffect(() => {
        const getListOfInsightTab = async () => {
            setIsLoader(true);
            const res = await apiAction({ url: list_of_insight_tab() });
            if (res?.success) {
                setResult(res.result);
                if (res.result[0]) {
                    getInsightTab(res.result[0].id);
                }
            }
            setIsLoader(false);
        };

        getListOfInsightTab();
    }, []);

    const getInsightTab = async (id) => {
        const res = await apiAction({ url: get_insight_tab(id) });
        if (res?.success) {
            getTabData(res.result);
            setTabData(res.result);
        }
    };

    const onDeleteTab = (id) => {
        const filteredArray = result.filter((val) => val.id !== id);
        setResult([...filteredArray]);
        setSelectedInsight(0);
        if (filteredArray.length > 0) {
            getInsightTab(filteredArray[0].id);
        }
    };

    const updateInsightTabOrder = async (data) => {
        const formData = {
            business_id: getBusinessInfo().id,
            tab_order_list: data.map((item) => item.id),
        };

        const res = await apiAction({
            method: 'post',
            navigate,
            dispatch,
            data: formData,
            url: update_insight_tab_order(),
        });

        if (res?.success) {
            const selectedTabIndex = data.findIndex((item) => item.id === result[selectedInsight].id);
            setResult(data);
            setSelectedInsight(selectedTabIndex);
            stateChangeManager(dispatch, Actions, true, 'success', res.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res.status);
        }
    };

    return (
        <div>
            <CustomCard sx={{ pt: 1 }}>
                {isLoader ? (
                    <Box sx={{ pl: 2, pr: 2, height: 55, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ width: '100%' }}>
                            <CustomSkeleton variant="text" height={50} />
                        </Box>
                        <Box sx={{ ml: 4, display: 'flex', justifyContent: 'space-between' }}>
                            <CustomSkeleton variant="circular" width={35} height={35} />
                            <CustomSkeleton variant="circular" sx={{ ml: 2 }} width={35} height={35} />
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <DraggableTabs
                            id="insight-tabs"
                            dataTestId="insight-tabs"
                            tabs={result}
                            value={selectedInsight}
                            sx={{ border: 'none' }}
                            setTabs={(tabs) => updateInsightTabOrder(tabs)}
                            onChange={(tab) => {
                                if (tab !== selectedInsight) {
                                    setSelectedInsight(tab);
                                    getInsightTab(result[tab].id);
                                }
                            }}
                        />

                        <Box sx={{ alignItems: 'center', fontSize: '24px', color: '#757575', marginTop: '-4px', opacity: 0.8, ml: 0.5, mr: 0.5, background: 'transparent' }}>|</Box>

                        <Box sx={{ flexGrow: 1, }}>
                            <TabActions
                                tabList={result}
                                setTabList={setResult}
                                refreshDataPoint={refreshDataPoint}
                                selectedTab={tabData}
                                selectedInsight={selectedInsight}
                                setSelectedInsight={(tab = result.length - 1) => {
                                    if (isNumber(tab)) {
                                        setSelectedInsight(tab);
                                        getInsightTab(result[tab]?.id);
                                    }
                                }}
                                setSelectedTab={(data) => {
                                    setTabData(data);
                                    getTabData(data);
                                }}
                                disabledDelete={result.length === 1}
                                onDeleteAction={onDeleteTab}
                                tab_id={result.length && result[selectedInsight]?.id}
                                addNewSection={addNewSection}
                                setAddNewSection={setAddNewSection}
                            />
                        </Box>
                    </Box>
                )}
            </CustomCard>
        </div>
    );
};

const TabActions = ({ addNewSection, setAddNewSection, tab_id, onDeleteAction, disabledDelete, tabList, setTabList, selectedTab, setSelectedTab, refreshDataPoint, selectedInsight, setSelectedInsight }) => {
    const [action, setAction] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [OpenDataPoint, setOpenDataPoint] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const actionList = [
        { name: 'Edit Tab', condition: 'edit_tab', onClickAction: setAction },
        { name: 'Add New Section', condition: 'add_new_section', onClickAction: setAction },
        { name: 'Manage Data Point', condition: 'manage_data_point', onClickAction: () => setOpenDataPoint(true) },
        { name: 'Delete Tab', showDivider: true, disabled: disabledDelete, condition: 'delete_tab', onClickAction: (value) => { setAction(value); setOpenDelete(true); } },
    ];

    React.useEffect(() => {
        if (addNewSection) {
            setAction('add_new_section');
        }
    }, [addNewSection]);

    return (
        <>
            {OpenDataPoint ? (
                <ManageDataPoint
                    open={OpenDataPoint}
                    setOpen={setOpenDataPoint}
                    onDeleteDataPointTabAction={() => setSelectedInsight(selectedInsight)}
                />
            ) : openDelete ? (
                <DeleteComponent
                    action={action}
                    openDelete={openDelete}
                    setOpenDelete={(value) => { setOpenDelete(value); setAction(false); }}
                    id={tab_id}
                    onDeleteAction={(id) => { onDeleteAction(id); setOpenDelete(false); setAction(false); }}
                />
            ) : (
                <TabManager
                    action={action}
                    setAction={setAction}
                    open={Boolean(action)}
                    setOpen={() => { setAction(null); setAddNewSection(false); }}
                    tab_id={tab_id}
                    tabList={tabList}
                    setTabList={setTabList}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    setSelectedInsight={setSelectedInsight}
                />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <HtmlTooltip title="Add New Tab" placement="top">
                        <IconButton aria-label="add" onClick={() => {
                            setAction('add_new_tab')
                            AnalyticsEvent(eventsNames.categories.INSIGHT, { action: eventsNames.actions.ADD, type: eventsNames.actions.insight.type.TAB });
                        }}>
                            <Avatar

                                sx={{
                                    height: '24px',
                                    width: '24px',
                                    bgcolor: '#2464eb',
                                }}
                            >
                                <AddIcon sx={{ fontSize: '18px', fontWeight: 700 }} />
                            </Avatar>
                        </IconButton>
                    </HtmlTooltip>
                </Box>
                <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                    <HtmlTooltip title="Refresh Data point" placement="top">
                        <IconButton aria-label="refresh" sx={{ ":hover": { color: '#2464eb' } }} onClick={refreshDataPoint}>
                            <CachedIcon fontSize="small" />
                        </IconButton>
                    </HtmlTooltip>
                    <HtmlTooltip title="Actions" placement="top">
                        <IconButton aria-label="actions" sx={{ ":hover": { color: '#2464eb' } }} onClick={handleClick}>
                            <MoreVertIcon fontSize="small" />
                        </IconButton>
                    </HtmlTooltip>
                </Box>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{ style: { minWidth: 100 } }}
            >
                {actionList?.map((action, index) => (
                    <React.Fragment key={index}>
                        {action.showDivider ? (
                            <>
                                <Divider />
                                <MenuItem
                                    id={`list_action_item_${index}`}
                                    dataTestId={`list_action_item_${index}`}
                                    disabled={action.disabled}
                                    sx={{ p: 1, color: 'red', fontWeight: 500, fontSize: 14, cursor: 'pointer', ':hover': { backgroundColor: '#f5f5f5' } }}
                                    onClick={() => { handleClose(); action.onClickAction(action.condition); }}
                                >
                                    {action.name}
                                </MenuItem>
                            </>
                        ) : (
                            <MenuItem
                                id={`list_action_item_${index}`}
                                dataTestId={`list_action_item_${index}`}
                                disabled={action.disabled}
                                sx={{ p: 1, color: '#141414', fontWeight: 500, fontSize: 14, cursor: 'pointer', ':hover': { backgroundColor: '#f5f5f5' } }}
                                onClick={() => { handleClose(); action.onClickAction(action.condition); }}
                            >
                                {action.name}
                            </MenuItem>
                        )}
                    </React.Fragment>
                ))}
            </Popover>
        </>
    );
};

export default ListInsightTab;
