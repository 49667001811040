import moment from 'moment/moment';
import React, { useEffect } from 'react';
import Input from '../../../../common/Input';
import Status from '../../../../common/Status';
import { apiAction } from '../../../../../api/api';
import DateRange from '../../../../common/DateRange';
import HTMLViewer from '../../../../common/HTMLViewer';
import * as Actions from '../../../../../state/Actions';
import CustomTitle from '../../../../common/CustomTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTabs from '../../../../custom/tabs/CustomTabs';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import CustomDialog from '../../../../custom/dialog/CustomDialog';
import CustomButton from '../../../../custom/button/CustomButton';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import AuditTrailHistory from '../../../../common/AuditTrailHistory';
import { BadgeWithTitle } from '../../../../custom/badge/CustomBadge';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import ViewPageActionButtons from '../../../../common/ViewPageActionButtons';
import CustomPagination from '../../../../custom/pagination/CustomPagination';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import NoTransactionComponent from '../../../../common/NoTransactionComponent';
import { eventsNames, AnalyticsEvent } from '../../../../../firebase/firebaseAnalytics';
import { ReactComponent as CalendarIcon } from '../../../../../assets/svg/calendar.svg';
import CurrencyFormatterWithExchangeRate from '../../common/CurrencyFormatterWithExchangeRate';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../bulkStatementUpload/mappings/Transactions';

import {
  InvoiceViewLink,
  EstimateViewLink,
  SalesOrderViewLink,
  CreditNoteViewLink,
  DeliveryChallanViewLink,
  PaymentReceivedViewLink,
  RecurringInvoiceViewLink,
} from '../../common/CommonLinks';

import {
  CustomContainer,
  CustomTitleContainer,
} from '../../../../custom/container/CustomContainer';

import {
  get_party,
  delete_party,
  get_list_of_payment,
  get_list_of_invoice,
  mark_party_inactive,
  get_list_of_estimate,
  send_party_statement,
  export_party_statement,
  get_list_of_sales_order,
  get_list_of_credit_note,
  get_credit_list_of_customer,
  get_party_transaction_count,
  get_customer_sales_overview,
  update_party_opening_balance,
  get_list_of_delivery_challan,
  get_list_of_recurring_invoice,
} from '../../../../../api/urls';

import {
  setLoader,
  getAddress,
  date_range,
  isFormValid,
  getDateFormat,
  getQueryParams,
  stateChangeManager,
  convertSlugToString,
  to_check_repeat_every,
  returnTransactionType,
  get_party_contact_name,
  breadCrumbsStateChangeManager,
  to_check_gst_registration_type,
} from '../../../../../utils/Utils';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';

import {
  Box,
  Chip,
  Grid,
  Avatar,
  Popover,
  Accordion,
  DialogContent,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';



const Dropdown = DateRange
const CustomerViewpage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [tab, setTab] = React.useState(0);
  const [sales, setSales] = React.useState();
  const [open, setOpen] = React.useState(false);
  const params = getQueryParams(location.search);
  const [results, setResults] = React.useState([]);
  const [customer, setCustomer] = React.useState();
  const dispatch = Actions.getDispatch(React.useContext);
  const [state, setState] = React.useState({
    id: '',
    title: '',
    open: false,
    condition: '',
    maxWidth: 'lg',
    fullWidth: true,
  })

  const stateChangeHandler = (title, condition, maxWidth, id, item, url) => {
    setState({
      id: id,
      url: url,
      open: true,
      item: item,
      title: title,
      fullWidth: true,
      maxWidth: maxWidth,
      condition: condition,
      navigate: () => navigate(routesName.invoicingSalesCustomer.path),
    })
  }

  const getCustomer = async () => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      url: get_party(params.id),
      data: { role: 'customer', business_id: getBusinessInfo().id },
    })
    if (res?.success) {
      setCustomer(res?.result);
      getApiResults(res?.result);
      setLoader(dispatch, Actions, false);
      breadCrumbsStateChangeManager(dispatch, res?.result.display_name);
    } else {
      setLoader(dispatch, Actions, false)
    }
  }
  React.useEffect(() => {

    if (params.id) {
      getCustomer()
    }
  }, []);

  React.useEffect(() => {
    const getCustomerSales = async () => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_customer_sales_overview(params.id),
        data: { role: 'customer', business_id: getBusinessInfo().id },
      })
      if (res?.success) {
        setSales(res?.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (params.id) {
      getCustomerSales()
    }
  }, []);


  const getApiResults = async (customer) => {
    let res = await apiAction({
      method: 'post',
      url: get_credit_list_of_customer(),
      data: { business_id: getBusinessInfo().id, customer_id: customer.id, },
    })
    if (res?.success) {
      setResults(res?.result)
    } else {
    }
  }


  const markPartyActiveInactive = async (id, isActive) => {
    let res = await apiAction({
      method: 'post',
      url: mark_party_inactive(id),
      data: { business_id: getBusinessInfo().id, is_inactive: isActive },
    })
    if (res?.success) {
      getCustomer();
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }
  return (
    <div>
      <CustomDialog
        state={state}
        setState={setState}
      />
      {
        customer && sales &&
        <div>
          <CustomizedDialogs
            open={open}
            setOpen={setOpen}
            results={results}
            customer={customer}
            title={`Credit details for ${customer.display_name}`}
          />
          <CustomTitleContainer>
            <Grid container spacing={0} style={{
              alignItems: 'center',
              justifyItems: 'center',
              alignContent: 'space-evenly',
              justifyContent: 'space-evenly',
            }}>
              <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'start', }}>
                <CustomTitle title={'Customers'} />
              </Grid>
              <Grid item xs={6} sx={{ display: '-webkit-flex', justifyContent: 'end', }}>
                <ViewPageActionButtons
                  isSent={false}
                  isPrint={false}
                  isDownload={false}
                  isEditable={!customer.is_inactive}
                  edit={() => {
                    navigate(routesName.invoicingSalesCustomerAdd.path + "?id=" + params.id, { replace: false })
                  }}
                  actionsList={
                    [
                      { name: customer.is_inactive ? 'Mark as Active' : 'Mark as Inactive', onClick: () => { markPartyActiveInactive(customer.id, !customer.is_inactive); AnalyticsEvent(eventsNames.categories.CUSTOMERS, { action: eventsNames.actions.CLICK, switch: customer.is_inactive ? eventsNames.actions.customer.switch.MARK_ACTIVE : eventsNames.actions.customer.switch.MARK_INACTIVE }); } },
                      { name: 'Delete', showDivider: true, onClick: () => { stateChangeHandler('Delete Customer', 'delete', 'sm', customer.id, customer, delete_party(customer.id)); AnalyticsEvent(eventsNames.categories.CUSTOMERS, { action: eventsNames.actions.DELETE }) } }
                    ]
                  }
                />

                {
                  !customer.is_inactive &&
                  <CustomButton
                    variant="contained"
                    btnLabel='Create Invoice'
                    id={'create_invoice_btn'}
                    dataTestId={'create_invoice_btn'}
                    sx={{ ml: 2, textTransform: 'none', }}
                    onClick={() => {
                      navigate(routesName.invoicingSalesInvoiceAdd.path + "?customer_id=" + params.id, { replace: false })
                      AnalyticsEvent(eventsNames.categories.CUSTOMERS, { action: eventsNames.actions.customer.action.CREATE_INVOICE })
                    }
                    }
                  />
                }
                <AuditTrailHistory
                  box_sx={{ height: '24px' }}
                  data={{ object_id: customer.id, object_type: 'customer' }}
                  AnalyticsEvent={() => { AnalyticsEvent(eventsNames.categories.CUSTOMERS, { action: eventsNames.actions.HISTORY }) }}
                />
              </Grid>
            </Grid>
          </CustomTitleContainer>

          <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
            <Box sx={{ mb: 2 }}>
              <BasicInfo customer={customer} markPartyActiveInactive={markPartyActiveInactive} />
              <SalesOverview
                data={sales && `${moment(sales.from_date).format(getDateFormat('MMM-YYYY'))} - ${moment(sales.to_date).format(getDateFormat('MMM-YYYY'))} | Last 12 months.`}
                sales_overview_data={[
                  { title: 'Total Sales', value: <CurrencyFormatter amount={sales.total_sales} currency={customer.currency.currency_code} />, onClick: false ? () => navigate(routesName.invoicingSalesInvoice.path, { state: { customer_id: customer.id, customer: customer, action: 'total_sales' } }) : null, },
                  { title: 'Total Payments Received', value: <CurrencyFormatter amount={sales.total_received} currency={customer.currency.currency_code} />, onClick: false ? () => navigate(routesName.invoicingPaymentsReceivedList.path, { state: { party_id: customer.id, party: customer, action: 'total_received' } }) : null, borderLeft: '2px solid #F1D881', borderRight: '2px solid #F1D881', },
                  { title: 'Total Unpaid', value: <CurrencyFormatter amount={sales.total_unpaid} currency={customer.currency.currency_code} />, onClick: false ? () => navigate(routesName.invoicingSalesInvoice.path, { state: { customer_id: customer.id, customer: customer, action: 'total_unpaid' } }) : null, borderRight: '2px solid #F1D881', },
                  { title: 'Unused credit', value: <CurrencyFormatter amount={customer.unused_credit} currency={customer.currency.currency_code} />, onClick: customer.unused_credit ? () => setOpen(true) : null, }
                ]}
              />
            </Box>
            <CustomTabs
              id={'tabs_'}
              dataTestId={'tabs_'}
              tabs={tabs}
              value={tab}
              onChange={(event, tab) => { setTab(tab) }}
            />
            {tabs[tab].condition === 'customer_information' && <CustomerInformation customer={customer} />}
            {tabs[tab].condition === 'transaction_list' && <TransactionList customer_id={Number(params.id)} customer_name={customer.display_name} />}
            {tabs[tab].condition === 'statement' && <Statement customer_id={Number(params.id)} customer_name={customer.display_name} />}

          </CustomContainer>
        </div>
      }
    </div>

  )
}

export default CustomerViewpage;

const tabs = [
  { name: 'Customer Information', condition: 'customer_information' },
  { name: 'Transaction List', condition: 'transaction_list' },
  { name: 'Statements', condition: 'statement' },
]

const BasicInfo = (props) => {
  const { customer } = props


  const billing_address = customer.billing_address ? getAddress(customer.billing_address) : null;
  const shipping_address = customer.shipping_address ? getAddress(customer.shipping_address) : null;

  const party_contact_data = customer.party_contact_data.find((item) => item.contact_type === "primary");

  return (
    <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
      <Box sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'row',
      }}>
        <Avatar />
        <Box sx={{ alignSelf: 'center', ml: 2.5 }}>

          <CustomTypography
            text={<span> {customer.display_name}{customer.is_inactive && <Chip sx={{ ml: 2, }} label={'Inactive'} />}</span>}
            sx={{
              mb: 0.5,
              color: '#000000',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={party_contact_data && <span><AlternateEmailRoundedIcon fontSize='small' sx={{ mb: -.8 }} /> {party_contact_data.email}</span>}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              display: 'inline',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={party_contact_data && party_contact_data.mobile_number ? <span> | <LocalPhoneRoundedIcon fontSize='small' sx={{ mb: -.8 }} />{party_contact_data.mobile_number}</span> : ''}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              display: 'inline',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          <CustomTypography
            text={customer.company_name ? <span><BusinessRoundedIcon fontSize='small' sx={{ mb: -.5 }} />{customer.company_name}</span> : ''}
            sx={{
              mt: 0.5,
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
          {/* <CustomTypography
            text={`${customer.company_name ? customer.company_name : '-'} | ${billing_address ? billing_address : shipping_address ? shipping_address : '-'}`}
            sx={{
              mb: 0.5,
              color: '#7A7A7A',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          /> */}
        </Box>
      </Box>
    </Box>
  )
};

const SalesOverview = (props) => {
  const { data, sales_overview_data } = props

  return (
    <Box sx={{ p: 2, border: '1px solid #FFF0C0', backgroundColor: "#FFF7DC" }}>
      <Grid container>
        {
          sales_overview_data.map((item, index) => {
            return (
              <Grid item xs={12} sm={3} key={index} sx={{
                p: 1.5,
                borderLeft: item.borderLeft,
                borderRight: item.borderRight,
              }}
              >
                <Box>
                  <CustomTypography
                    text={item.title}
                    sx={{
                      mb: 1,
                      color: '#7A7A7A',
                      fontSize: '14px',
                      fontWeight: '600',
                      lineHeight: '19px',
                      fontStyle: 'normal',
                      textTransform: 'none',
                      fontFamily: "Noto Sans",
                    }} />
                  <CustomTypography
                    id={'on_clicked_btn' + index}
                    dataTestId={'on_clicked_btn' + index}
                    text={item.value}
                    sx={{
                      mb: 1,
                      display: 'inline',
                      fontSize: '24px',
                      fontWeight: '700',
                      lineHeight: '28px',
                      fontStyle: 'normal',
                      textTransform: 'none',
                      fontFamily: "Noto Sans",
                      cursor: item.onClick ? 'pointer' : '',
                      color: item.onClick ? '#2464EB' : '#000000',
                      ":hover": { textDecoration: item.onClick ? 'underline' : '' }
                    }}
                    onClick={() => item.onClick ? item.onClick() : null}
                  />
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
      <CustomTypography
        text={data ? <span><CalendarIcon />{data}</span> : ''}
        sx={{
          mt: 1,
          color: '#A3A3A3',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '16px',
          fontStyle: 'normal',
          textTransform: 'none',
          fontFamily: "Noto Sans",
        }}
      />
    </Box>
  )
}

const AdjustOpeningBalance = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async () => {
    // let res = await apiAction({
    //   method: 'post',
    //   url: update_party_opening_balance(),
    //   data: {
    //     business_id: getBusinessInfo().id,
    //     party_id: Number(params.id),
    //     amount: opening_balance ? opening_balance : 0,
    //   }
    // })
    // // console.log('res', res)
    // if (res?.success) {
    //   setEdit(!edit)
    //   stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    // } else {
    //   stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    // }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <React.Fragment>
      {/* <CustomTypography
                    text={ 'Edit'}
                    id={'action_click'}
                    onClick={handleClick}
                    dataTestId={'action_click'}
                    sx={{
                      ml:2,
                      fontSize: '12px',
                      display: 'inline',
                      color: '#2464EB',
                      cursor: 'pointer',
                      display: 'inline',
                      fontWeight: '700',
                      lineHeight: '16px',
                      fontStyle: 'normal',
                      textTransform: "none",
                      fontFamily: "Noto Sans",
                      ":hover": { textDecoration: 'underline' },
                    }}
                  /> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Input
            autoFocus={true}
            id={'input_opening_balance'}
            dataTestId={'input_opening_balance'}
            isSearchableComponent={true}
            item={{
              type: 'number',
              required: true,
              fullWidth: true,
              title: '',
              placeholder: 'Enter Opening Balance',
              // value: opening_balance !== null ? opening_balance : '0.00',
              // onChange: (e) => onChange('opening_balance', limitDecimalPlaces(e.target.value)),
              // onBlur: (e) => onChange('opening_balance', limitDecimalPlaces(Number(e.target.value)))
            }}
            InputProps={{
              inputProps: { min: 0 }
            }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
                event.preventDefault();
              }
            }}
          />
          <CustomButton
            id={'save_btn'}
            btnLabel={'Save'}
            variant='contained'
            dataTestId={'save_btn'}
            onClick={() => { handleSubmit() }}
            sx={{ mt: 2, ml: 2, textTransform: 'none' }}
          />

        </Box>
      </Popover>
    </React.Fragment>
  );
}

const CustomerInformation = (props) => {
  const { customer } = props;

  const billing_address = customer.billing_address ? getAddress(customer.billing_address) : null;
  const shipping_address = customer.shipping_address ? getAddress(customer.shipping_address) : null;

  const party_contact_data = customer.party_contact_data.find((item) => item.contact_type === "primary");

  const customer_basic_info = [
    { title: 'Name', value: get_party_contact_name(party_contact_data) },
    { title: 'Phone', value: customer.phone_number },
    { title: 'PAN Number', value: customer.PAN  || customer.pan },
    { title: 'Customer Type', value: customer.party_type.capitalize() },
    { title: 'Fax', value: customer.fax_number },
    { title: 'Website', value: <span style={{ whiteSpace: 'pre-wrap' }}>{customer.website}</span> },
    { title: 'GST Registration Type', value: to_check_gst_registration_type(customer.gst_registration_type) },
    { title: 'GSTIN', value: customer.gstin, textTransform: 'uppercase' },
  ]

  const customer_details = [
    { title: 'Opening Balance', value: <span><CurrencyFormatter amount={customer.opening_balance} currency={customer.currency.currency_code} />{customer.opening_balance ? returnTransactionType(customer.opening_balance_transaction_type) : ''}</span> },
    { title: 'Billing Address', value: billing_address },
    { title: 'Shipping Address', value: shipping_address },
    { title: 'TDS Applicable', value: customer.is_tds_applicable ? 'Yes' : 'No' },
    { title: 'Terms', value: customer.payment_term ? customer.payment_term.name : '-' },
    { title: 'Payment Method', value: customer.preferred_payment_method, textTransform: 'capitalize' },
    { title: 'Other', value: customer.other_details },
    { title: 'Notes', value: customer.note },

  ]
  React.useEffect(() => {
    AnalyticsEvent(eventsNames.categories.CUSTOMERS, { action: eventsNames.actions.customer.action.INFORMATION });
  }, [])


  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={6}>
        <Box sx={{ p: 2 }}>
          <Grid container>
            {
              customer_basic_info.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TitleData item={item} />
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ borderLeft: '2px solid #F5F5F5', }}>
        <Box sx={{ p: 2 }}>
          <Grid container>
            {
              customer_details.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TitleData item={item} />
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

const TitleData = (props) => {
  const { item } = props
  return (
    <>
      <Grid item xs={6}>
        <CustomTypography
          text={item.title ? item.title : '-'}
          sx={{
            mb: 2,
            color: '#000000',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTypography
          text={item.value ? item.value : '-'}
          sx={{
            mb: 2,
            color: '#7A7A7A',
            fontSize: '14px',
            fontWeight: '400',
            overflow: 'scroll',
            lineHeight: '18px',
            fontStyle: 'normal',
            fontFamily: "Noto Sans",
            textTransform: item.textTransform,
          }}
        />
      </Grid>
    </>
  )
}

const TransactionList = (props) => {
  const { customer_id, customer_name } = props
  const dispatch = Actions.getDispatch(React.useContext);
  const [open, setOpen] = React.useState([]);
  const [count, setCount] = React.useState();
  const addOrRemove = (array, item) => {
    const exists = array.includes(item)

    if (exists) {
      return array.filter((c) => { return c !== item })
    } else {
      const result = array
      result.push(item)
      return result
    }
  }
  const setCountData = (key, value) => {
    setCount((previous) => ({
      ...previous,
      [key]: value
    }));
  }


  React.useEffect(() => {
    const getCountData = async (party_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'get',
        url: get_party_transaction_count(party_id)
      })
      if (res?.success) {
        setCount((previous) => ({ ...previous, ...res?.result }))
      } else {

      }
    }
    if (customer_id) {
      getCountData(customer_id)
    }
    // eslint-disable-next-line
  }, []);
  const transaction_list = [
    { component_name: 'Estimate', name: <BadgeWithTitle title={'Estimate'} count={count?.estimate_count} />, component: <EstimateTransactionList open={open.includes('Estimate')} setCountData={(value) => { setCountData('estimate_count', value) }} customer_id={customer_id} dispatch={dispatch} /> },
    { component_name: 'Sales Order', name: <BadgeWithTitle title={'Sales Order'} count={count?.sales_order_count} />, component: <SalesOrderTransactionList open={open.includes('Sales Order')} setCountData={(value) => { setCountData('sales_order_count', value) }} customer_id={customer_id} dispatch={dispatch} />, },
    { component_name: 'Delivery Challan', name: <BadgeWithTitle title={'Delivery Challan'} count={count?.delivery_challan_count} />, component: <DeliveryChallanTransactionList open={open.includes('Delivery Challan')} setCountData={(value) => { setCountData('delivery_challan_count', value) }} customer_id={customer_id} dispatch={dispatch} />, },
    { component_name: 'Invoice', name: <BadgeWithTitle title={'Invoice'} count={count?.invoice_count} />, component: <InvoiceTransactionList open={open.includes('Invoice')} setCountData={(value) => { setCountData('invoice_count', value) }} customer_id={customer_id} dispatch={dispatch} />, },
    { component_name: 'Recurring Invoice', name: <BadgeWithTitle title={'Recurring Invoice'} count={count?.recurring_invoice_count} />, component: <RecurringInvoiceTransactionList open={open.includes('Recurring Invoice')} setCountData={(value) => { setCountData('recurring_invoice_count', value) }} customer_id={customer_id} customer_name={customer_name} dispatch={dispatch} />, },
    { component_name: 'Customer Payment', name: <BadgeWithTitle title={'Customer Payment'} count={count?.payment_count} />, component: <CustomerPaymentTransactionList open={open.includes('Customer Payment')} setCountData={(value) => { setCountData('payment_count', value) }} customer_id={customer_id} customer_name={customer_name} dispatch={dispatch} />, },
    { component_name: 'Credit Note', name: <BadgeWithTitle title={'Credit Note'} count={count?.credit_note_count} />, component: <CreditNoteTransactionList open={open.includes('Credit Note')} setCountData={(value) => { setCountData('credit_note_count', value) }} customer_id={customer_id} customer_name={customer_name} dispatch={dispatch} /> },
  ]

  React.useEffect(() => {
    AnalyticsEvent(eventsNames.categories.CUSTOMERS, { action: eventsNames.actions.customer.action.TRANSACTION });
  }, [])
  return (
    transaction_list.map((item, index) => {
      return (
        <Box style={{ p: 2, border: '5px solid #F5F5F5' }} key={index}>
          <Accordion
            square
            elevation={0}
            disableGutters
            onChange={() => { setOpen([...addOrRemove(open, item.component_name)]) }}
            sx={{ '&:before': { display: 'none' } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CustomTypography
                text={item.name}
                sx={{
                  color: '#000000',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '18px',
                  fontStyle: 'normal',
                  fontFamily: "Noto Sans",
                }}
              />
            </AccordionSummary>
            <AccordionDetails>
              {item.component}
            </AccordionDetails>
          </Accordion>
        </Box>
      )
    })

  )
}


const InvoiceTransactionList = (props) => {
  const { customer_id, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getCustomerInvoice = async (customer_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_invoice(page),
        data: { role: 'customer', business_id: getBusinessInfo().id, customer_id: customer_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setInvoiceData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (customer_id && open) {
      getCustomerInvoice(customer_id)
    }
  }, [customer_id, page, open]);

  return (
    invoiceData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell> */}
                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Amount Due</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                invoiceData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.invoice_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.invoice_number}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}}  ><InvoiceViewLink id={item.id} title={item.invoice_number} /></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.due_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.due_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const CustomerPaymentTransactionList = (props) => {
  const { customer_id, open, dispatch, setCountData } = props

  const [page, setPage] = React.useState(1);
  const [paymentData, setPaymentData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getCustomerPayment = async (customer_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_payment(page),
        data: {
          payment_type: "receive", business_id: getBusinessInfo().id,
          party_id: customer_id,
          // party_display_name: customer_name,
        },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setPaymentData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (customer_id && open) {
      getCustomerPayment(customer_id)
    }
  }, [customer_id, page, open]);

  return (
    paymentData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Payment#</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Invoice</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Mode</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Total Amount</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Unused Amount</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                paymentData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} ><span>{moment(item.payment_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><PaymentReceivedViewLink id={item.id} title={item.id} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  >{item.payment_for_reference_number.map((value, index) => { return (<span key={index}>{index ? ', ' : ''}{value}</span>) })}</CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  >
                        <p style={{ margin: '0px', marginBottom: '5px' }}>{convertSlugToString(item.payment_method, '_').toUpperCase()}</p>
                        {
                          item.is_advance &&
                          <span style={{ fontSize: '10px', opacity: 0.6, }}>Advance Payment</span>
                        }
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.balance_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.balance_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const EstimateTransactionList = (props) => {
  const { customer_id, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [estimateData, setEstimateData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getCustomerEstimate = async (customer_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_estimate(page),
        data: { business_id: getBusinessInfo().id, customer_id: customer_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setEstimateData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (customer_id && open) {
      getCustomerEstimate(customer_id)
    }
  }, [customer_id, page, open]);

  return (
    estimateData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Estimate Number</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell> */}
                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                estimateData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.estimate_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.estimate_number}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}}  ><EstimateViewLink id={item.id} title={item.estimate_number} /></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const SalesOrderTransactionList = (props) => {
  const { customer_id, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [salesOrderData, setSalesOrderData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {

    const getCustomerSalesOrder = async (customer_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_sales_order(page),
        data: { role: 'customer', business_id: getBusinessInfo().id, customer_id: customer_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setSalesOrderData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (customer_id && open) {
      getCustomerSalesOrder(customer_id)
    }
  }, [customer_id, page, open]);

  return (
    salesOrderData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell> */}
                <CustomTableHeadCell align='right' ><span >Total</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                salesOrderData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.sales_order_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.sales_order_number}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}}  ><SalesOrderViewLink id={item.id} title={item.sales_order_number} /></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const RecurringInvoiceTransactionList = (props) => {
  const { customer_id, open, dispatch, setCountData } = props

  const [page, setPage] = React.useState(1);
  const [recurringInvoiceData, setRecurringInvoiceData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getCustomerRecurringInvoice = async (customer_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_recurring_invoice(page),
        data: { business_id: getBusinessInfo().id, customer_id: customer_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setRecurringInvoiceData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (customer_id && open) {
      getCustomerRecurringInvoice(customer_id)
    }
  }, [customer_id, page, open]);

  return (
    recurringInvoiceData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span>Status</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell><span>Customer Name</span></CustomTableHeadCell> */}
                <CustomTableHeadCell><span>Profile Name</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Frequency</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Last Invoice Date</span></CustomTableHeadCell>
                <CustomTableHeadCell><span>Next Invoice Date</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right'><span>Amount</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell align='right'><span>Actions</span></CustomTableHeadCell> */}
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                recurringInvoiceData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell align='center'><Status status={item.status} /></CustomTableBodyCell>
                      {/* <CustomTableBodyCell><span>{item.customer_display_name}</span></CustomTableBodyCell> */}
                      {/* <CustomTableBodyCell><span>{item.profile_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell><RecurringInvoiceViewLink id={item.periodic_task_id} title={item.profile_name} /></CustomTableBodyCell>
                      <CustomTableBodyCell><span>{to_check_repeat_every(item.repeat_frequency, item.repeat_every) ? to_check_repeat_every(item.repeat_frequency, item.repeat_every).name : `Every ${item.repeat_every} ${item.repeat_frequency.capitalize()}s`}</span></CustomTableBodyCell>
                      <CustomTableBodyCell><span>{item.last_invoice_date ? moment(item.last_invoice_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                      <CustomTableBodyCell><span>{item.status === 'Expired' ? '-' : item.next_invoice_date ? moment(item.next_invoice_date).format(getDateFormat()) : '-'}</span></CustomTableBodyCell>
                      <CustomTableBodyCell align='right'><span><CurrencyFormatter amount={item.amount} currency={item.currency_code} /></span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const CreditNoteTransactionList = (props) => {
  const { customer_id, open, dispatch, setCountData } = props

  const [page, setPage] = React.useState(1);
  const [estimateData, setEstimateData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getCustomerEstimate = async (customer_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_credit_note(page),
        data: { role: "customer", business_id: getBusinessInfo().id, customer_id: customer_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setEstimateData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (customer_id && open) {
      getCustomerEstimate(customer_id)
    }
    // eslint-disable-next-line
  }, [customer_id, page, open]);

  return (
    estimateData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center'><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Invoice#</span></CustomTableHeadCell>
                {/* <CustomTableHeadCell ><span >Customer</span></CustomTableHeadCell> */}
                <CustomTableHeadCell align='right' ><span >Amount</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Balance</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                estimateData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.credit_note_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.credit_note_number}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}}  ><CreditNoteViewLink id={item.id} title={item.credit_note_number} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{item.against_invoice_number}</span></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.customer_display_name}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.credit_remaining} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.credit_remaining} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const DeliveryChallanTransactionList = (props) => {
  const { customer_id, dispatch, setCountData, open } = props

  const [page, setPage] = React.useState(1);
  const [estimateData, setEstimateData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    next: undefined,
    count: '0',
    previous: undefined,
    number_of_pages: undefined,
  });

  React.useEffect(() => {
    const getCustomerEstimate = async (customer_id) => {
      // setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: get_list_of_delivery_challan(page),
        data: { business_id: getBusinessInfo().id, customer_id: customer_id },
      })
      if (res?.success) {
        setPagination({
          ...pagination,
          next: res?.result.next,
          count: res?.result.count,
          previous: res?.result.previous,
          number_of_pages: res?.result.number_of_pages,
        })
        setCountData(res?.result.count)
        setEstimateData(res?.result.result)
        setLoader(dispatch, Actions, false)
      } else {
        setLoader(dispatch, Actions, false)
      }
    }
    if (customer_id && open) {
      getCustomerEstimate(customer_id)
    }
    // eslint-disable-next-line
  }, [customer_id, page, open]);

  return (
    estimateData.length ?
      <React.Fragment>
        <CustomTableContainer >
          <CustomTable>
            <CustomTableHead>
              <CustomTableRow>
                <CustomTableHeadCell style={{ width: 100 }} align='center' ><span >Status</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Date</span></CustomTableHeadCell>
                <CustomTableHeadCell ><span >Number</span></CustomTableHeadCell>
                <CustomTableHeadCell align='right' ><span >Amount</span></CustomTableHeadCell>
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody>
              {
                estimateData.map((item, index) => {
                  return (
                    <CustomTableRow key={index}>
                      <CustomTableBodyCell sx={{}} align='center'><Status status={item.status} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}}  ><span>{moment(item.delivery_challan_date).format(getDateFormat())}</span></CustomTableBodyCell>
                      {/* <CustomTableBodyCell sx={{}}  ><span>{item.delivery_challan_number}</span></CustomTableBodyCell> */}
                      <CustomTableBodyCell sx={{}}  ><DeliveryChallanViewLink id={item.id} title={item.delivery_challan_number} /></CustomTableBodyCell>
                      <CustomTableBodyCell sx={{}} align='right'>
                        <span>{<CurrencyFormatter amount={item.total_amount} currency={item.currency_code} />}</span><br />
                        <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  )
                })
              }
            </CustomTableBody>
          </CustomTable>
        </CustomTableContainer>
        <CustomPagination
          page={page}
          count={pagination.number_of_pages}
          onChange={(event, newValue) => setPage(newValue)}
        />
      </React.Fragment>
      : <NoTransactionComponent />
  )
}

const Statement = (props) => {
  const { customer_id } = props

  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [dateRange, setDateRange] = React.useState(default_date_range);
  React.useEffect(() => {
    AnalyticsEvent(eventsNames.categories.CUSTOMERS, { action: eventsNames.actions.customer.action.STATEMENT });
  }, [])

  const [stateMent, setStateMent] = React.useState()
  const [body, setBody] = React.useState({
    filter_by: 'all',
    party_id: customer_id,
    to_date: default_date_range.dates.to_date,
    from_date: default_date_range.dates.from_date,
  })

  React.useEffect(() => {
    if (dateRange) {
      setBody({
        ...body,
        to_date: dateRange.dates.to_date,
        from_date: dateRange.dates.from_date,
      })
    }
  }, [dateRange]);

  // React.useEffect(() => {
  //   getCustomerStatement('html')
  // }, [body])

  const getCustomerStatement = async (export_type = 'html') => {
    let validation_data = [
      { key: "from_date", message: 'Please select the start date.' },
      { key: "to_date", message: 'Please select the end date.' },
    ]
    const { isValid, message } = isFormValid(body, validation_data);
    if (isValid) {
      setLoader(dispatch, Actions, true)
      let res = await apiAction({
        method: 'post',
        url: export_party_statement(),
        data: { business_id: getBusinessInfo().id, ...body, export_type: export_type },
      })
      if (res?.success) {
        setLoader(dispatch, Actions, false);
        if (export_type === 'html') { setStateMent(res?.result) }
        { export_type !== 'html' && stateChangeManager(dispatch, Actions, true, 'success', res?.status); }
      } else {
        setLoader(dispatch, Actions, false)
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  }

  const sendCustomerStatement = async () => {
    setLoader(dispatch, Actions, true)
    let res = await apiAction({
      method: 'post',
      url: send_party_statement(),
      data: { business_id: getBusinessInfo().id, ...body },
    })
    if (res?.success) {
      setLoader(dispatch, Actions, false)
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
    } else {
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      setLoader(dispatch, Actions, false)
    }
  }

  const onDateRangeChange = (dates) => {
    if (dates) {
      setBody({ ...body, from_date: dates[0].format("YYYY-MM-DD"), to_date: dates[1].format("YYYY-MM-DD") })
    } else {
      setBody({ ...body, to_date: null, from_date: null })
    }

  }
  return (
    <Box sx={{ p: 2, }}>
      <Box sx={{ borderBottom: '2px solid #F5F5F5' }}>
        <Grid container spacing={1} sx={{}}>
          <Grid item xs={12} sm={1.75} sx={{}}>
            <Dropdown
              label='Type'
              disableClearable={true}
              id={'statement_type_dropdown'}
              dataTestId={'statement_type_dropdown'}
              value={body.filter_by ? { name: body.filter_by.capitalize() } : null}
              setValue={(event, value) => { setBody({ ...body, filter_by: value.value }) }}
              results={[{ name: 'All', value: 'all' }, { name: 'Outstanding', value: 'outstanding' }]}
            />
          </Grid>
          <Grid item container xs={12} sm={5.25} spacing={1} sx={{}}>
            <CommonCustomDateRange
              setPage={() => { }}
              date_range={date_range()}

              toDate={null}
              setToDate={() => { }}

              fromDate={null}
              setFromDate={() => { }}

              dateRange={dateRange}
              setDateRange={setDateRange}

              isDateRange={true}
              onDateRangeChange={onDateRangeChange}
            />

          </Grid>
          {/* <Grid item xs={12} sm={1} sx={{ mt: 3, textAlign: 'end' }}>
            {stateMent &&
              <ViewPageActionButtons
                isSent={false}
                isPrint={false}
                isDownload={false}
                isEditable={false}
                actionsList={
                  [
                    { name: 'Send Statement', onClick: () => { sendCustomerStatement() }, id: 'send_statement_btn', dataTestId: 'send_statement_btn' },
                    { name: 'Export Statement as PDF', onClick: () => { getCustomerStatement('pdf') }, id: 'export_pdf_btn', dataTestId: 'export_pdf_btn', }
                  ]
                }
              />
            }
          </Grid> */}


          <Grid item xs={12} sm={5} sx={{ mt: 3, justifyContent: 'end', textAlign: 'end' }}>
            <CustomButton
              sx={{ mr: 1 }}
              variant="outlined"
              btnLabel='Create Statement'
              id={'create_statement_btn'}
              dataTestId={'create_statement_btn'}
              onClick={() => { getCustomerStatement('html') }}
            />
            {stateMent && body.from_date && body.to_date &&
              <>
                <CustomButton
                  sx={{ mr: 1 }}
                  variant="contained"
                  btnLabel='Send Statement'
                  id={'send_statement_btn'}
                  dataTestId={'send_statement_btn'}
                  onClick={() => { sendCustomerStatement() }}
                />
                <CustomButton
                  variant="contained"
                  id={'export_pdf_btn'}
                  btnLabel='Export as PDF'
                  dataTestId={'export_pdf_btn'}
                  onClick={() => { getCustomerStatement('pdf') }}
                />
                {/* <CustomGroupButton
                  options={[{ id: 'export_pdf_btn', dataTestId: 'export_pdf_btn', label: 'Export as PDF', condition: () => getCustomerStatement('pdf') },
                  ]}
                /> */}
              </>
            }
          </Grid>
        </Grid>
      </Box>
      <>
        {
          stateMent && <HTMLViewer html_string={stateMent.html_string} />
        }
      </>
    </Box>
  )
}


const CustomizedDialogs = (props) => {
  const { open, setOpen, title, customer, results } = props;

  return (
    <div>
      <BootstrapDialog
        open={open}
        fullWidth={true}
      >

        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          <CustomTypography
            text={title}
            sx={{
              color: '#000000',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '18px',
              fontStyle: 'normal',
              fontFamily: "Noto Sans",
            }}
          />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UnusedCredit customer={customer} results={results} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}

const UnusedCredit = (props) => {
  const { customer, results = [] } = props;

  return (
    <CustomTableContainer>
      <CustomTable>
        <CustomTableHead>
          <CustomTableRow >
            <CustomTableHeadCell align='left'>Credit Info</CustomTableHeadCell>
            <CustomTableHeadCell align='left'>Date Credited</CustomTableHeadCell>
            <CustomTableHeadCell align='right'>Amount</CustomTableHeadCell>
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {
            results.map((item, index) => {
              return (
                <CustomTableRow key={index}>
                  <CustomTableBodyCell sx={{}} align='left' size='small'>
                    {/* <p>{item.credit_note_number}</p> */}
                    {item.credit_type === 'excess_payment' ?
                      <React.Fragment>
                        <PaymentReceivedViewLink title={item.id} id={item.id} />
                        {/* <br/><span style={{ fontSize: '10px', opacity: 0.6, }}>{item.credit_note_number}</span> */}
                      </React.Fragment>
                      : <CreditNoteViewLink title={item.credit_note_number} id={item.id} />
                    }
                  </CustomTableBodyCell>
                  <CustomTableBodyCell sx={{}} align='left' size='small'><p>{item.credit_note_date ? moment(item.credit_note_date).format(getDateFormat()) : '-'}</p></CustomTableBodyCell>
                  <CustomTableBodyCell sx={{}} align='right' size='small'><p>{<CurrencyFormatter amount={item.credit_remaining} currency={customer && customer.currency.currency_code} />}</p></CustomTableBodyCell>
                </CustomTableRow>
              )
            }
            )}
          <CustomTableRow sx={{ background: '#FFF7DC', }}>
            <CustomTableBodyCell colSpan={3} sx={{ borderTop: '1px solid #FFF0C0', borderBottom: '1px solid #FFF0C0' }} align='right' size='small'><p>{<CurrencyFormatter amount={results.reduce((acc, item) => acc + item.credit_remaining, 0).toFixed(2)} currency={customer && customer.currency.currency_code} />}</p></CustomTableBodyCell>
          </CustomTableRow>
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  )
}