/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import { DialogContent, Switch } from '@mui/material';
import { apiAction } from '../../../../../../api/api';
import HighchartsReact from 'highcharts-react-official';
import { useIsElementVisible } from '../../common/Helper';
import * as Actions from '../../../../../../state/Actions';
import { GranularityComponent } from '../../common/CommonComponents';
import CustomTypography from '../../../../../custom/typography/CustomTypography';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../../bulkStatementUpload/mappings/Transactions';

import {
  setLoader,
  amountFormatter,
  getUniqueObjectsByKey,
  getSelectedGranularityData,
  formattedDataBasedGranularity,
  getUnitPreference,
} from '../../../../../../utils/Utils';

import {
  get_data_point,
  evaluate_data_point_range,
} from '../../../../../../api/urls';

const LineChart = ({ data, to_date, from_date, refreshDataPoint, openFull, setOpenFull }) => {
  const dispatch = Actions.getDispatch(React.useContext);

  const elementRef = React.useRef(null);
  const [results, setResults] = React.useState([]);
  const [isGrowthGraph, setIsGrowthGraph] = React.useState(false);
  const isElementVisible = useIsElementVisible(elementRef.current);
  const [granularity, setGranularity] = React.useState(getSelectedGranularityData('', from_date, to_date)?.selected);

  const fetchDataPoint = async (data_point_id) => {
    try {
      const value = await apiAction({ url: get_data_point(data_point_id) });
      if (!value?.success) {
        console.log('Error', value);
        return;
      }
      const result = value.result;
      const item = await evaluateDataPoint(data_point_id);
      if (item?.success) {
        setLoader(dispatch, Actions, false);
        result.lineChartData = formattedDataBasedGranularity(item.result, granularity);
      }
      setResults((prev) => getUniqueObjectsByKey([...prev, result]));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const evaluateDataPoint = async (data_point_id, isRefresh = false) => {
    try {
      const url = isRefresh ? `${evaluate_data_point_range(data_point_id, from_date, to_date, granularity)}&is_refresh=true` : evaluate_data_point_range(data_point_id, from_date, to_date, granularity);
      const value = await apiAction({ url });
      return value?.success ? value : null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const fetchAllDataPoints = (data_point_ids = []) => {
    data_point_ids.forEach((id) => {
      if (id) fetchDataPoint(id);
    });
  };

  const fetchAllEvaluateDataPoints = async (data_point_ids = [], isRefresh) => {
    try {
      const promises = data_point_ids.map(async (id) => {
        const resultItem = results.find((item) => item.id === id);
        if (resultItem) {
          const item = await evaluateDataPoint(id, isRefresh);
          setLoader(dispatch, Actions, false);
          resultItem.lineChartData = formattedDataBasedGranularity(item.result, granularity);
          return resultItem;
        }
      });
      const updatedResults = await Promise.all(promises);
      setResults(updatedResults.filter(Boolean));
      return updatedResults.filter(Boolean);// Filter out undefined values
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };

  React.useEffect(() => {
    if (data && isElementVisible && !results.length) fetchAllDataPoints(data.data_point_ids);
  }, [isElementVisible]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) {
      setResults([]);
      fetchAllDataPoints(data.data_point_ids);
    }
  }, [data]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) fetchAllEvaluateDataPoints(data.data_point_ids, true);
  }, [refreshDataPoint]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) {
      fetchAllEvaluateDataPoints(data.data_point_ids, false);
      setGranularity(getSelectedGranularityData('', from_date, to_date)?.selected);
    }
  }, [from_date, to_date]);

  React.useEffect(() => {
    if (data && isElementVisible && results.length) fetchAllEvaluateDataPoints(data.data_point_ids, false);
  }, [granularity]);


  return (
    <div ref={elementRef} style={{ height: '100%', overflow: 'hidden' }}>
      <FullViewModeLineChart
        isGrowthGraph={isGrowthGraph}
        setIsGrowthGraph={setIsGrowthGraph}

        open={openFull}
        title={data?.name}
        setOpen={setOpenFull}
        data={results.filter((item) => item.lineChartData)}

        to_date={to_date}
        from_date={from_date}
        granularity={granularity}
        setGranularity={setGranularity}
      />
      {results.length > 0 && (
        <>
          <CustomLineChart
            to_date={to_date}
            from_date={from_date}
            granularity={granularity}
            isGrowthGraph={isGrowthGraph}
            setGranularity={setGranularity}
            data={results.filter((item) => item.lineChartData)}
          />

          <div style={{ position: 'absolute', top: '52px', right: '1%', padding: '5px', borderRadius: '4px', background: '#fff', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px -10px' }}>
            <div style={{
              display: 'flex',
              fontSize: '12px',
              color: '#000000',
              fontWeight: '500',
              lineHeight: '18px',
              fontStyle: 'normal',
              alignItems: 'center',
              fontFamily: "Noto Sans"
            }}>
              <span style={{ cursor: 'default' }}>Growth rate</span>
              <Switch
                size='small'
                sx={{ ml: .3 }}
                id={'graph_switch'}
                dataTestId={'graph_switch'}
                inputProps={{ 'aria-label': 'controlled' }}

                checked={isGrowthGraph}
                onChange={(event) => { setIsGrowthGraph(!isGrowthGraph) }}

              />
            </div>
          </div>

          <div style={{ position: 'absolute', top: '12px', right: '30%' }}>
            <GranularityComponent
              to_date={to_date}
              from_date={from_date}
              granularity={granularity}
              setGranularity={setGranularity}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LineChart;

const CustomLineChart = ({ data, color, isGrowthGraph }) => {
  const [options, setOptions] = React.useState({});

  React.useEffect(() => {
    const seriesData = data?.map(item => ({
      name: item?.name,
      unit: item?.unit,
      color: color || '',
      data: item?.lineChartData?.map(entry => ({
        entry: entry,
        y: parseFloat(entry.result),
      })),
    }));

    let categories = data?.[0]?.lineChartData?.map(entry => entry.period);

    const chartOptions = {
      chart: {
        type: 'line',
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: null
        },
      },
      xAxis: {
        min: 0,
        maxPadding: 0,
        tickInterval: 1,
        endOnTick: false,
        startOnTick: false,
        categories: categories,
      },
      legend: {
        margin: 10,
        align: 'left',
        layout: 'horizontal',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          stickyTracking: false,
          connectNulls: false,
          point: {
            events: {
              click: function () { }
            }
          }
        },
      },
      tooltip: {
        snap: 0,
        formatter: function () {
          return `<p style="font-family: 'Noto Sans'; color:${this.color};">${moment(this.point.options.entry.to_date).format('DD MMM YYYY')}</p><br style="margin-bottom:'10px'"/><span style="font-family: 'Noto Sans';"><span><b>${this.series.name} :</b> </span><b>${getUnitPreference(this.series.userOptions.unit, this.y)}</b></span>`;
        }
      },
      series: seriesData,
    };

    setOptions(chartOptions);
  }, [data, color]);

  return (
    isGrowthGraph ?
      <MultipleGrowthChart data={data} color={color} />
      :
      <HighchartsReact
        options={options}
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%', width: '100%', zIndex: 1 } }}
      />
  );
};


export const FullViewModeLineChart = ({ isGrowthGraph, setIsGrowthGraph, color, open, setOpen, data, title, to_date, from_date, granularity, setGranularity }) => (
  <BootstrapDialog fullWidth open={open} maxWidth='lg' sx={{ position: 'relative' }}>
    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
      <CustomTypography
        text={title}
        sx={{
          color: '#000000',
          fontSize: '16px',
          fontWeight: '700',
          lineHeight: '18px',
          fontStyle: 'normal',
          fontFamily: "Noto Sans",
        }}
      />
    </BootstrapDialogTitle>
    <DialogContent dividers>
      <div style={{}}>
        <CustomLineChart
          data={data}
          color={color}
          to_date={to_date}
          from_date={from_date}
          granularity={granularity}
          isGrowthGraph={isGrowthGraph}
          setGranularity={setGranularity}
        />

        <div style={{ position: 'absolute', top: '74px', right: '1%', padding: '5px', borderRadius: '4px', background: '#fff', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px -10px' }}>
          <div style={{

            display: 'flex',
            fontSize: '12px',
            color: '#000000',
            fontWeight: '500',
            lineHeight: '18px',
            fontStyle: 'normal',
            alignItems: 'center',
            fontFamily: "Noto Sans"
          }}>
            <span style={{ cursor: 'default' }}>Growth rate</span>
            <Switch
              size='small'
              sx={{ ml: .3 }}
              id={'graph_switch'}
              dataTestId={'graph_switch'}
              inputProps={{ 'aria-label': 'controlled' }}

              checked={isGrowthGraph}
              onChange={(event) => { setIsGrowthGraph(!isGrowthGraph) }}

            />
          </div>
        </div>

        <div style={{ position: 'absolute', top: '22px', right: '10%' }}>
          <GranularityComponent
            to_date={to_date}
            from_date={from_date}
            granularity={granularity}
            setGranularity={setGranularity}
          />
        </div>
      </div>
    </DialogContent>
  </BootstrapDialog>
);


export const MultipleGrowthChart = ({ data, color }) => {
  const calculateGrowth = (lineChartData) => {
    if (!Array.isArray(lineChartData) || lineChartData.length === 0) return [];

    let previousGrowth = 0;

    return lineChartData.map((item, index) => {
      const currentMonthAmount = parseFloat(item.result) || 0;
      const previousMonthAmount = index > 0 ? parseFloat(lineChartData[index - 1]?.result) || 0 : 0;

      // Calculate the growth difference
      let growthDifference = 0;

      if (previousMonthAmount !== 0) {
        // Normal growth calculation
        growthDifference = ((currentMonthAmount - previousMonthAmount) / previousMonthAmount) * 100;
      } else {
        // If previousMonthAmount is 0 and current month is non-zero, set growth to 100%
        growthDifference = currentMonthAmount > 0 ? 100 : 0;
      }

      // Calculate cumulative growth
      const growth = previousGrowth + growthDifference;
      previousGrowth = growth;

      return {
        ...item,
        growth: isFinite(growth) ? growth.toFixed(2) : '0',
        growthDifference: isFinite(growthDifference) ? growthDifference.toFixed(2) : '0',
      };
    });


  };

  const formatTooltip = function () {
    const date = moment(this.points[0]?.point.options.entry.to_date).format('DD MMM YYYY');

    const generateTooltipRow = (point) => {
      const isActualValue = point.series.userOptions.dataType === 'Actual Value';
      const formattedValue = isActualValue
        ? getUnitPreference(point.series.userOptions.unit, point.y)
        : point.point.index
          ? `${point.point.options.entry.growthDifference}%`
          : '';

      return `
        <div style="font-family: 'Noto Sans'; margin: '0px';padding: '0px'">
          <b>${point.series.name}:</b> ${formattedValue}
        </div>`;
    };

    const tooltipRows = this.points.map(generateTooltipRow).join('');

    return `
      <p style="font-family: 'Noto Sans'; color: ${this.color};"><b>${date}</b></p>
      ${tooltipRows}`;
  };


  const createSeriesData = (dataItem) => {
    const lineChartData = calculateGrowth(dataItem?.lineChartData || []);

    return [
      {
        type: 'column',
        unit: dataItem.unit,
        dataType: 'Actual Value',
        name: `${dataItem?.name} - Actual Value`,
        data: lineChartData.map((entry) => ({ entry, y: parseFloat(entry.result) })),
      },
      {
        type: 'line',
        unit: dataItem.unit,
        dataType: 'Growth Rate',
        name: `${dataItem?.name} - Growth Rate`,
        yAxis: 1,
        data: lineChartData.map((entry) => ({ entry, y: parseFloat(entry.result) })),
      },
    ];
  };


  // Helper function to determine the direction icon based on the growth difference
  function getDirectionIcon(growthDifference) {

    if (growthDifference > 0) {
      return {
        color: 'green',
        directionIcon: '<span style="color: green;">\u25B2</span>'
      }

    } else if (growthDifference < 0) {
      return {
        color: 'red',
        directionIcon: '<span style="color: red;">\u25BC</span>'
      }

    }
    return {
      color: 'black',
      directionIcon: ''
    };
  }

  const chartOptions = React.useMemo(() => {
    if (!data || data.length === 0) return {};

    const categories = data[0]?.lineChartData?.map((entry) => entry.period) || [];
    const allSeriesData = data.flatMap(createSeriesData);

    return {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories,
        crosshair: true,
      },
      yAxis: [
        {
          title: {
            text: 'Actual Value',
          },
        },
        {
          title: {
            text: 'Growth Rate (%)',
          },
          labels: {
            enabled: false,
            format: '{value:.1f}%',
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        useHTML: true,
        backgroundColor: 'white',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        shadow: true,
        style: {
          color: '#333',
          padding: '10px',
          fontFamily: "'Noto Sans', sans-serif",
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        },
        formatter: formatTooltip,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            inside: true,
            verticalAlign: 'bottom',
            y: 100,
            formatter: function () {
              return getUnitPreference(this.series.userOptions.unit, this.y);
            },
          },
        },
        line: {
          dataLabels: {
            inside: false,
            enabled: true,
            formatter: function () {
              if (this.point.index === 0) return null;

              const growthDifference = this.point.entry?.growthDifference || 0;
              const { directionIcon, color } = getDirectionIcon(growthDifference);
              return `<span style="color: ${color};">${growthDifference}% ${directionIcon}</span>`;
            },
          },
        },

      },
      series: allSeriesData,
    };
  }, [data]);

  return (
    <HighchartsReact
      options={chartOptions}
      highcharts={Highcharts}
      containerProps={{ style: { height: '100%', width: '100%' } }}
    />
  );
};


const SingleGrowthChart = ({ data, color }) => {

  const calculateGrowth = (lineChartData) => {
    if (!Array.isArray(lineChartData) || lineChartData.length === 0) return [];

    return lineChartData.map((item, index) => {
      const currentMonthAmount = parseFloat(item.result);
      const previousMonthAmount = index === 0 ? null : parseFloat(lineChartData[index - 1]?.result);

      let growth = 0;

      // First month: growth is 0% if the result is 0
      if (index === 0) {
        growth = currentMonthAmount === 0 ? 0 : 100;
      }
      // Subsequent months: If the previous month result is 0, set growth to 100%
      else if (previousMonthAmount === 0 && currentMonthAmount !== 0) {
        growth = 100;
      }
      // Normal growth calculation
      else if (previousMonthAmount !== 0) {
        growth = ((currentMonthAmount - previousMonthAmount) / previousMonthAmount) * 100;
      }

      return {
        ...item,
        growth: isFinite(growth) ? growth.toFixed(2) : '0',
      };
    });
  };


  const tooltipFormatter = function () {
    const date = moment(this.points[0]?.point.options.entry.to_date).format(
      'DD MMM YYYY'
    );
    let tooltipContent = `<p style="font-family: 'Noto Sans'; color:${this.color};">${date}</p>`;
    this.points.forEach((point) => {
      const formattedValue =
        point.series.name === 'Actual Value'
          ? amountFormatter(point.y)
          : `${point.y.toFixed(1)}%`;
      tooltipContent += `
        <br>
        <span style="font-family: 'Noto Sans';">
          <span><b>${point.series.name}:</b></span> <b>${formattedValue}</b>
        </span>`;
    });
    return tooltipContent;
  };

  const chartOptions = React.useMemo(() => {
    if (!data) return {};

    const title = data?.[0]?.name;
    const lineChartData = calculateGrowth(data[0]?.lineChartData || []);
    const categories = data?.[0]?.lineChartData?.map((entry) => entry.period) || [];

    return {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: title,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        crosshair: true,
      },
      yAxis: [
        {
          title: {
            text: 'Actual Value',
          },
        },
        {
          title: {
            text: 'Growth Rate (%)',
          },
          labels: {
            format: '{value:.1f}%',
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
        formatter: tooltipFormatter,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return amountFormatter(this.y);
            },
          },
        },
        line: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.y.toFixed(1)}%`;
            },
          },
        },
      },
      series: [
        {
          type: 'column',
          name: 'Actual Value',
          // color: color || '#7cb5ec',
          data: lineChartData.map((entry) => ({
            y: parseFloat(entry.result),
            entry: entry,
          })),
        },
        {
          type: 'line',
          name: 'Growth Rate',
          // color: color || '#434348',
          yAxis: 1,
          data: lineChartData.map((entry) => ({
            y: parseFloat(entry.growth),
            entry: entry,
          })),
        },
      ],
    };
  }, [data, color]);

  return (
    <HighchartsReact
      options={chartOptions}
      highcharts={Highcharts}
      containerProps={{ style: { height: '100%', width: '100%', zIndex: 1 } }}
    />
  );
};



