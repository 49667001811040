import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { apiAction } from "../../../../../api/api";
import ReportsTitle from '../../common/ReportsTitle';
import * as Actions from '../../../../../state/Actions';
import { routesName } from '../../../../../config/routesName';
import { CustomLink } from '../../../../custom/link/CustomLink';
import ReportsSettingButtons from '../../ReportsSettingButtons';
import { getBusinessInfo } from '../../../../../config/cookiesInfo';
import CurrencyFormatter from '../../../../common/CurrencyFormatter';
import CommonCustomDateRange from '../../../../common/CommonCustomDateRange';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { CustomContainer } from '../../../../custom/container/CustomContainer';

import {
  get_cash_flow,
  export_cash_flow
} from '../../../../../api/urls';

import {
  setLoader,
  date_range,
  getDateFormat,
} from '../../../../../utils/Utils';


import {
  Box,
  Collapse,
  Grid,
} from '@mui/material';

import {
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableHead,
  CustomTableHeadCell,
  CustomTableBodyCell,
  CustomTableContainer,
} from '../../../../custom/table/CustomTable';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CashFlow = () => {
  let location = useLocation();

  const stateAction = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const { selectedDateRange } = stateAction;
  let filter_date_range = date_range().find((item) => item.name === selectedDateRange)
  const default_date_range = filter_date_range ? filter_date_range : date_range()[2]

  const [date, setDate] = React.useState();
  const [results, setResults] = React.useState([]);
  const [isZero, setIsZero] = React.useState(false);
  const [dateRange, setDateRange] = React.useState();
  const [toDate, setToDate] = React.useState(moment());
  const [fromDate, setFromDate] = React.useState(moment());
  const [isDetailed, setIsDetailed] = React.useState(false);

  React.useEffect(() => {
    if (dateRange) {
      if (dateRange.name !== "Custom") {
        getApiResults(dateRange.dates.from_date, dateRange.dates.to_date, dateRange.dates.date_range)
      } else {
        getApiResults(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), `${fromDate.format(getDateFormat())} - ${toDate.format(getDateFormat())}`)
      }
    }
    // eslint-disable-next-line
  }, [dateRange, toDate, fromDate]);

  const getApiResults = async (from_date, to_date, range_date) => {
    setLoader(dispatch, Actions, true)
    setDate(range_date)
    let res = await apiAction({
      method: 'post',
      url: get_cash_flow(),
      data: {
        business_id: getBusinessInfo().id,
        from_date: from_date,
        to_date: to_date
      },
    })
    // console.log('res',res)
    if (res?.success) {
      setResults(res?.result.components)
      setLoader(dispatch, Actions, false)
    } else {
    }
  }

  React.useEffect(() => {
    if (location.state) {
      let data = location.state;
      setToDate(moment(data.to_date));
      setFromDate(moment(data.from_date));
      setDateRange({ name: data.name, dates: data.dates });
    } else {
      setDateRange(default_date_range);
    }
    // eslint-disable-next-line
  }, []);

  const selectedDatesObject = { ...dateRange?.dates }
  const customDatesObject = { from_date: fromDate?.format('YYYY-MM-DD'), to_date: toDate?.format('YYYY-MM-DD') }

  return (
    <div>
      <ReportsSettingButtons

        scheduleData={{
          report_name: 'cash_flow',
          report_title: 'Cash Flow',

        }}

        zeroData={{
          value: isZero,
          setValue: (value) => setIsZero(value),
        }}

        labelData={{
          value: isDetailed,
          labelName: 'Detailed',
          title: 'Export Preference',
          setValue: (value) => setIsDetailed(value),
        }}

        exportData={{ url: export_cash_flow(), data: dateRange && dateRange.dates ? { ...dateRange.dates, is_detailed: isDetailed, display_zero_balance_account: !isZero } : { to_date: toDate.format('YYYY-MM-DD'), from_date: fromDate.format('YYYY-MM-DD'), is_detailed: isDetailed, display_zero_balance_account: !isZero } }}
      />
      <CustomContainer maxWidth={"400"} sx={{ height: 'calc(100vh - 134px)' }}>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Grid container spacing={0} sx={{ pb: 2 }} style={{
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'space-evenly',
            alignContent: 'space-evenly',
          }}>
            <Grid item xs={12} sm={4}>
              <ReportsTitle
                date={date}
                title={'Cash Flow'}
              />
            </Grid>
            <Grid container spacing={1} item xs={12} sm={8} sx={{ justifyContent: 'end', }}>
              <CommonCustomDateRange
                setPage={() => { }}
                date_range={date_range()}

                toDate={toDate}
                setToDate={setToDate}

                fromDate={fromDate}
                setFromDate={setFromDate}

                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            </Grid>
          </Grid>
        </div>
        <CustomTableContainer>

          <CustomTable>
            <CashFlowHeader />
            <CashFlowBody data={results} isZero={isZero} date={dateRange?.name === "Custom" ? { ...customDatesObject } : { ...selectedDatesObject }} />
            <caption style={{ fontFamily: 'Noto Sans', fontWeight: '500', fontSize: '10px', fontStyle: 'normal', lineHeight: '14px' }}>**Amount is displayed in your base currency <span style={{ backgroundColor: '#6ff795', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '2px', paddingTop: '1px' }}>{getBusinessInfo().currency.currency_code}</span></caption>
          </CustomTable>

        </CustomTableContainer>
      </CustomContainer>
    </div>
  )
}

const CashFlowHeader = () => {

  return (
    <CustomTableHead>
      <CustomTableRow >
        <CustomTableHeadCell sx={{ pr: 0, borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          <span>Account</span>
        </CustomTableHeadCell>
        <CustomTableHeadCell sx={{ pl: 0, borderBottom: '1px solid rgba(224, 224, 224, 1)' }} align='right'>
          <span>Total</span>
        </CustomTableHeadCell>
      </CustomTableRow>
    </CustomTableHead>
  )
}

const CashFlowBody = (props) => {
  const { date, isZero, data } = props

  const [open, setOpen] = React.useState([]);

  return (
    <CustomTableBody>
      {data.map((item, index) => {
        return (
          <TableRows
            date={date}
            key={index}
            open={open}
            index={index}
            isZero={isZero}
            setOpen={setOpen}
            component_name={item.component_name}
            component_balance={item.component_balance}
            component_details={item.component_details}
          />
        )
      })
      }
    </CustomTableBody>
  )
}

export default CashFlow;
const addOrRemove = (array, item) => {
  const exists = array.includes(item)

  if (exists) {
    return array.filter((c) => { return c !== item })
  } else {
    const result = array
    result.push(item)
    return result
  }
}

const TableRows = ({ date, open, setOpen, isZero, index, component_name, component_balance, component_details = [] }) => {

  const isSpecialComponent = component_name === 'Net Change in Cash (A + B + C)';
  const rowStyles = isSpecialComponent ? { background: '#FFF7DC', border: '1px solid #FFF0C0' } : {};

  return (
    <CustomTableRow key={index} sx={rowStyles}>

      <RenderComponentDetails
        open={open}
        date={date}
        isZero={isZero}
        setOpen={setOpen}
        details={component_details}
        component_name={component_name}
      />

      <RenderComponentBalances
        open={open}
        date={date}
        isZero={isZero}
        setOpen={setOpen}
        details={component_details}
        component_balance={component_balance}
      />


    </CustomTableRow>
  );
};

const RenderComponentDetails = ({ date, open, setOpen, details = [], isZero, component_name }) => {

  const toggleOpen = (componentName) => {
    setOpen([...addOrRemove(open, componentName)])
  };

  return (
    <CustomTableBodyCell sx={{ pr: 0 }}>
      <CellHeader label={component_name.replaceAll('*', ' ')} />
      <div style={{}} >
        {
          details.map((value, idx) => {
            const { component_name, component_balance, component_details = [] } = value;
            const isHasAccount = component_details?.length;

            return (
              (!isZero || component_balance !== 0) &&
              (
                <React.Fragment key={idx}>
                  <div style={{ marginTop: '8px', marginLeft: '10px', backgroundColor: idx % 2 === 0 ? "#f8f9fa" : "#ffffff", }} key={idx}>
                    <CustomTypography
                      id={'handle_' + component_name}
                      dataTestId={'handle_' + component_name}
                      onClick={() => { if (isHasAccount) toggleOpen(component_name); }}
                      text={
                        <span>
                          {component_name.replaceAll('*', ' ')}
                          <Icon is_has_account={isHasAccount} open={open.includes(component_name)} />
                        </span>
                      }
                      sx={{
                        opacity: 1,
                        color: '#000000',
                        fontSize: '12px',
                        lineHeight: '20px',
                        fontWeight: '500',
                        paddingLeft: '5px',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        display: 'inline-block',
                        fontFamily: "Noto Sans",
                        letterSpacing: '0.01071em',
                        cursor: isHasAccount ? 'pointer' : '',
                      }}
                    />
                  </div>

                  <Collapse in={open.includes(component_name)} sx={{ mt: .5 }}>
                    {component_details?.map((item, index) => {
                      return (
                        (!isZero || item.account_balance !== 0) && (
                          <Box key={index} style={{ minWidth: 400, }} sx={{ ml: 2.5, mt: 1, backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#ffffff", }}>
                            <NavigationComponent
                              date={date}
                              account_name={item.business_account}
                              account_id={item.business_account_id}
                            />
                          </Box>
                        ))
                    })}
                  </Collapse>
                </React.Fragment>
              )
            );
          })
        }
      </div>
    </CustomTableBodyCell>

  );
};


const RenderComponentBalances = ({ date, open, component_balance, details = [], isZero }) => {

  return (
    <CustomTableBodyCell align='right' sx={{ pl: 0, }}>
      <CellHeader label={<CurrencyFormatter amount={component_balance} />} />
      <div style={{}}>
        {
          details.map((value, idx) => {
            const { component_name, component_balance, component_details = [] } = value;
            return (
              (!isZero || component_balance !== 0) && (
                <React.Fragment key={idx}>
                  <div style={{ marginTop: '8px', backgroundColor: idx % 2 === 0 ? "#f8f9fa" : "#ffffff", }} key={idx}>
                    <CustomTypography
                      id={'component_balance_' + component_name}
                      dataTestId={'component_balance_' + component_name}
                      text={<CurrencyFormatter amount={value.component_balance} />}
                      sx={{
                        opacity: 0.9,
                        color: '#000000',
                        fontSize: '12px',
                        lineHeight: '20px',
                        fontWeight: '500',
                        fontStyle: 'normal',
                        textTransform: 'none',
                        fontFamily: "Noto Sans",
                        display: 'inline-block',
                        letterSpacing: '0.01071em',
                      }}
                    />
                  </div>

                  <Collapse in={open.includes(component_name)} sx={{ mt: .5 }}>
                    {component_details?.map((item, index) => {
                      return (
                        (!isZero || item.account_balance !== 0) && (
                          <Box key={index} style={{ minWidth: 400, }} sx={{ mt: 1, backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#ffffff", }}>
                            <NavigationAmountComponent
                              date={date}
                              account_balance={item.account_balance}
                              account_name={item.business_account}
                              account_id={item.business_account_id}
                            />
                          </Box>
                        ))
                    })}
                  </Collapse>
                </React.Fragment>
              )
            )
          })
        }
      </div>
    </CustomTableBodyCell>


  )
};

const CellHeader = ({ label }) => {
  return (
    <strong style={{ color: '#000000', fontSize: '13px', lineHeight: '16px', fontWeight: '700' }}>
      {label}
    </strong>
  )
}

const NavigationComponent = ({ account_name, account_id }) => {
  return (
    <p style={{ margin: '0px', paddingLeft: '5px', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500', whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: "hidden", }}>
      {/* <CustomLink
        id={account_name + '_link'}
        dataTestId={account_name + '_link'}
        style={{
          color: '#2464EB',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        to={routesName.balanceSheetTransaction.path + "?id=" + account_id}
      >{account_name}</CustomLink> */}
      <span style={{ opacity: 0.8, }}>{account_name}</span>

    </p>
  )
}

const NavigationAmountComponent = ({ date, account_name, account_balance, account_id }) => {

  return (
    <p style={{ margin: '0px', display: 'block', color: '#000000', fontSize: '12px', lineHeight: '16px', fontWeight: '500', whiteSpace: "nowrap", }}>
      {account_id ?
        <CustomLink
          id={account_name + '_link'}
          dataTestId={account_name + '_link'}
          style={{
            color: '#2464EB',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          state={date ? { dateRange: { name: 'Custom', dates: 0 }, toDate: date.to_date, fromDate: date.from_date } : null}
          to={routesName.cashFlowTransaction.path + "?id=" + account_id}
        >{<CurrencyFormatter amount={account_balance} />}</CustomLink>
        : <CurrencyFormatter amount={account_balance} />
      }

    </p>
  )
}

const Icon = (props) => {
  const { open, is_has_account } = props;
  return (
    is_has_account ? open ?
      <ArrowDropUpIcon fontSize='small' sx={{ mb: -0.8, }} />
      :
      <ArrowDropDownIcon fontSize='small' sx={{ mb: -0.8, }} />
      : null
  )
}

