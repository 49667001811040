import moment from 'moment';
import * as React from 'react';
import Input from '../../../common/Input';
import { apiAction } from '../../../../api/api';
import DateRange from '../../../common/DateRange';
import DatePicker from '../../../common/DatePicker';
import NotFound from '../../../../layouts/NotFound';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { useLocation, useNavigate } from 'react-router-dom';
import { MetaData } from '../../../custom/tooltip/CustomTooltip';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { getSessionStorage, getToken, getUserDetails, removeSessionStorage } from '../../../../config/cookiesInfo';
import { getBusinessPreferenceData, getUserPreferenceData, updateUserPreferencesData } from '../../setting/settings/Preferences/common/getPreferences';

import {
    setLoader,
    isFormValid,
    getJsDateFormat,
    stateChangeManager,
    getFinancialYearDate,
    get_party_contact_name,
} from '../../../../utils/Utils';

import {
    get_countries,
    get_industries,
    retrieve_business,
    get_list_of_state,
    get_list_of_currency,
    business_registration,
    get_list_of_time_zone,
    get_list_of_date_format,
    get_list_of_fiscal_year,
    receive_invoice_in_business,
} from '../../../../api/urls';

import {
    setLoginStatus,
    setBusinessInfo,
} from '../../../../config/cookiesInfo';

import {
    Box,
    Grid,
    Button,
    Container,
    createTheme,
    ThemeProvider,
} from '@mui/material';
import CustomDefaultDropdown from '../../../custom/dropdown/CustomDefaultDropdown';


const theme = createTheme();

const Dropdown = DateRange;

const CreateCompany = ({ onClose, marginTop, onSuccess, isReceiveInvoiceInBusiness }) => {
    const location = useLocation();
    const sessionStorage = getSessionStorage('receivedInvoice');


    const user = getUserDetails();
    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);
    const [isFormSubmited, setFormSubmited] = React.useState(false);

    const initial_item_data = {
        logo: null,
        legal_name: null,
        industry_id: null,


        city: null,
        state_id: null,
        zip_code: null,
        address_line1: null,
        address_line2: null,

        country_id: null,

        currency_id: null,

        financial_year_last_day: null,

        number_of_employees: null,

        pan_number: null,
        phone_number: null,
        gstin_number: null,
        company_registration_number: null,
        is_business_registered: false,

        time_zone_id: null,
        date_format_id: null,
        fiscal_year_id: null,

        business_contact: null,
        migration_date: null,
    };

    const [data, setData] = React.useState({ ...initial_item_data });

    const handleSubmit = async () => {
        setFormSubmited(true);

        let validation_data = [
            { key: 'legal_name', message: 'Please enter organization name' },
            { key: 'industry_id', message: 'Please select industry' },
            { key: 'country_id', message: 'Please select country' },
            { key: 'state_id', message: 'Please select state' },
            { key: 'fiscal_year_id', message: 'Please select fiscal year' },
            { key: 'time_zone_id', message: 'Please select time zone' },
            { key: 'date_format_id', message: 'Please select date format' },
            { key: 'currency_id', message: 'Please select currency' },
            { key: 'financial_year_last_day', message: 'Please select financial year last date' },
            { key: 'migration_date', message: 'Please select migration date' },
        ]
        const { isValid, message } = isFormValid(data, validation_data)

        if (isValid) {
            AnalyticsEvent(eventsNames.categories.BUSINESS, { action: eventsNames.actions.CREATE });
            setLoader(dispatch, Actions, true)
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                data: { ...data },
                url: business_registration(),
            })

            if (res?.success) {
                if (isReceiveInvoiceInBusiness) {
                    onSuccess(res?.result);
                } else if (sessionStorage?.link_uid) {
                    addDocumentInBusiness(res?.result.id, sessionStorage?.link_uid)
                }
                else {
                    setLoginStatus('true');
                    if (onClose) { onClose() }
                    setBusinessInfo(res?.result);
                    setLoader(dispatch, Actions, false);
                    navigate(routesName.dashboard.path);
                    getUserPreferenceData(navigate, dispatch);
                    getBusinessPreferenceData(res?.result.id, navigate, dispatch);
                }

                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                setLoader(dispatch, Actions, false)
                stateChangeManager(dispatch, Actions, true, 'error', res?.status);
            }
        } else {
            setLoader(dispatch, Actions, false)
            stateChangeManager(dispatch, Actions, true, 'error', message);
        }
    };


    const addDocumentInBusiness = async (business_id, link_uid) => {
        try {
            const response = await apiAction({ method: 'post', url: receive_invoice_in_business(), data: { business_id: business_id, link_uid: link_uid } });
            if (response?.success) {
                let invoice_id = response?.result?.id;
                getBusinessInfo(business_id, invoice_id);
                updateUserPreferencesData(business_id, navigate, dispatch);
                if (response?.status) {
                    stateChangeManager(dispatch, Actions, true, 'success', response?.status);
                }
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', response?.status);

            }
        } catch (error) {
            console.error('Failed to fetch businesses:', error);
        }

    }

    const getBusinessInfo = async (id, invoice_id) => {
        const businessRes = await apiAction({
            method: 'get',
            url: retrieve_business(id),
        });

        if (businessRes?.success) {
            setLoginStatus('true');
            setBusinessInfo(businessRes?.result);
            removeSessionStorage('receivedInvoice');
            getUserPreferenceData(navigate, dispatch);
            getBusinessPreferenceData(businessRes?.result?.id);
            navigate(routesName.receivedInvoiceView.path + "?id=" + invoice_id, { replace: true });
            setLoader(dispatch, Actions, false);
        }
    }

    return (
        getToken() ?
            <ThemeProvider theme={theme}>

                <Container component="main" maxWidth='sm' sx={{
                    mt: 2,
                    // pb: 2,
                    pt: 2,
                    ":hover": { boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)' },
                    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                }}>
                    <CustomTypography
                        text={`Hello ${get_party_contact_name(user) ? get_party_contact_name(user) : user?.name} !`}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '18px',
                            fontWeight: '700',
                            textAlign: 'start',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            fontFamily: "Noto Sans",
                            textTransform: 'capitalize',
                            marginTop: marginTop ? marginTop : 4,
                        }}
                    />
                    <Box
                        sx={{
                            mt: 3,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <BusinessInfo
                            data={data}
                            setData={setData}
                            isFormSubmited={isFormSubmited}
                        />

                        <Button
                            id={'get_start_btn'}
                            dataTestId={'get_start_btn'}
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 6, mb: 8, textTransform: 'capitalize' }}
                        >
                            Get Started
                        </Button>
                    </Box>
                </Container>
            </ThemeProvider>
            :
            <NotFound />
    );
}
export default CreateCompany;

const BusinessInfo = (props) => {
    const { data, setData, isFormSubmited } = props;

    let navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const onChange = (key_name, value) => {
        setData((previous) => ({ ...previous, ...data, [key_name]: value }))
    }
    const [type, setType] = React.useState(data.is_business_registered ? 'yes' : 'no');

    const [countries, setCountries] = React.useState([])
    React.useEffect(() => {
        const getCountries = async () => {
            let res = await apiAction({
                method: 'get',
                navigate: navigate,
                dispatch: dispatch,
                url: get_countries(),
            })
            if (res?.success) {
                setCountries(res?.result);
            } else {

            }
        }
        getCountries()
        // eslint-disable-next-line
    }, []);

    const [currencies, setCurrencies] = React.useState([])
    React.useEffect(() => {
        const getCurrencies = async () => {
            let res = await apiAction({
                method: 'get',
                navigate: navigate,
                dispatch: dispatch,
                url: get_list_of_currency(),
            })
            if (res?.success) {
                let results = res?.result.map((item) => {
                    item['helperText'] = `${item?.name} (${item?.symbol})`;
                    return item;
                }
                )
                setCurrencies(results);
            } else {

            }
        }
        getCurrencies()
        // eslint-disable-next-line
    }, []);

    const [industries, setIndustries] = React.useState([]);
    React.useEffect(() => {
        const getIndustries = async () => {
            let res = await apiAction({
                method: 'get',
                navigate: navigate,
                dispatch: dispatch,
                url: get_industries(),
            })
            if (res?.success) {
                setIndustries(res?.result)
            } else {

            }
        }
        getIndustries()
        // eslint-disable-next-line
    }, []);

    const [states, setStates] = React.useState([]);
    React.useEffect(() => {
        const getStates = async (id) => {
            let res = await apiAction({
                method: 'post',
                navigate: navigate,
                dispatch: dispatch,
                url: get_list_of_state(),
                data: { country_id: id }
            })
            if (res?.success) {
                setStates(res?.result.sort((a, b) => -b.name.localeCompare(a.name)))
            } else {

            }
        }
        if (data.country_id) {
            getStates(data.country_id)
        }
        // eslint-disable-next-line
    }, [data.country_id]);

    const [fiscalYear, setFiscalYear] = React.useState([]);
    React.useEffect(() => {
        const getFiscalYear = async (id) => {
            let res = await apiAction({
                method: 'get',
                navigate: navigate,
                dispatch: dispatch,
                url: get_list_of_fiscal_year(id),
            })
            if (res?.success) {
                setFiscalYear(res?.result)
                let fiscal_year = res?.result.find((item) => item.id === data.fiscal_year_id)
                if (fiscal_year) {
                    financialYearLastDay(fiscal_year.month_range)
                }

            } else {

            }
        }
        if (data.country_id) {
            getFiscalYear(data.country_id)
        }
        // eslint-disable-next-line
    }, [data.country_id]);

    const [dateFormat, setDateFormat] = React.useState([]);
    React.useEffect(() => {
        const getDateFormat = async (id) => {
            let res = await apiAction({
                method: 'get',
                navigate: navigate,
                dispatch: dispatch,
                url: get_list_of_date_format(id),
            })
            if (res?.success) {
                setDateFormat(res?.result)
                let selected_country = countries.find((item) => item.id === id)
                if (selected_country.name === "India") {
                    let date_formate = res?.result.find((item) => item.date_format === 'dd-MM-yyyy')
                    data.date_format_id = date_formate ? date_formate.id : null;
                }
                setData((prev) => ({ ...prev, ...data }))
                setDateFormat(res?.result.map((item) => { return { ...item, date_format: `${item.date_format}  -  [ ${moment().format(getJsDateFormat(item.date_format))} ]` } }))
            } else {

            }
        }
        if (data.country_id) {
            getDateFormat(data.country_id)
        }
        // eslint-disable-next-line
    }, [data.country_id]);

    const [timeZone, setTimeZone] = React.useState([]);
    React.useEffect(() => {
        const getTimeZone = async (id) => {
            let res = await apiAction({
                method: 'get',
                navigate: navigate,
                dispatch: dispatch,
                url: get_list_of_time_zone(id),
            })
            if (res?.success) {
                setTimeZone(res?.result);
            } else {

            }
        }
        if (data.country_id) {
            getTimeZone(data.country_id)
        }
        // eslint-disable-next-line
    }, [data.country_id]);

    const financialYearLastDay = (month_range) => {
        if (month_range) {
            let { start_date, end_date } = getFinancialYearDate(month_range)
            data.migration_date = start_date;
            data.financial_year_last_day = end_date;
        } else {
            data.financial_year_last_day = null
        }
        setData((prev) => ({ ...prev, ...data }))

    }

    return (
        <Box sx={{ pt: 1 }}>
            <Grid container spacing={3}>

                {/* //Organization Name// */}
                <Grid item xs={12}>
                    <Input
                        autoFocus={true}
                        id={'input_legal_name'}
                        dataTestId={'input_legal_name'}
                        item={{
                            type: 'text',
                            fullWidth: true,
                            placeholder: 'Enter Organization Name',
                            validation: isFormSubmited && !data.legal_name,
                            value: data.legal_name ? data.legal_name : '',
                            title: <LabelWithAsteriskMark label={'Organization Name'} />,
                            onChange: (e) => { onChange('legal_name', e.target.value !== '' ? e.target.value : null) }
                        }}
                    />
                </Grid>

                {/* //Select Industry// */}
                <Grid item xs={12}>
                    <Dropdown
                        id={'industry_dropdown'}
                        dataTestId={'industry_dropdown'}
                        results={industries}
                        placeholder='Select the Industry'
                        validation={isFormSubmited && !data.industry_id}
                        label={<LabelWithAsteriskMark label={'Select Industry'} />}
                        value={data.industry_id ? industries.find((item) => item.id === data.industry_id) : null}
                        setValue={(event, selected) => { onChange('industry_id', selected ? selected.id : null) }}
                    />
                </Grid>

                {/* //Select Country// */}
                <Grid item xs={6} >
                    <Dropdown
                        id={'country_dropdown'}
                        dataTestId={'country_dropdown'}
                        results={countries}
                        placeholder='Select the Country'
                        validation={isFormSubmited && !data.country_id}
                        label={<LabelWithAsteriskMark label={'Select Country'} />}
                        value={data.country_id ? countries.find((item) => item.id === data.country_id) : null}
                        setValue={(event, selected) => {
                            if (selected) {
                                data.country_id = selected.id;
                                data.currency_id = selected.currency_id;
                                data.fiscal_year_id = selected.fiscal_year;
                                data.date_format_id = selected.date_format;
                                data.fiscal_year_id = selected.fiscal_year;
                                data.time_zone_id = selected.timezone_list[0];
                                data.migration_date = getFinancialYearDate()?.start_date;
                            } else {
                                data.country_id = null;
                                data.currency_id = null;
                                data.time_zone_id = null;
                                data.fiscal_year_id = null;
                                data.date_format_id = null;
                                data.fiscal_year_id = null;
                                data.migration_date = null;
                                data.financial_year_last_day = null;
                            }
                            setData((prev) => ({ ...prev, ...data }))
                        }}
                    />
                </Grid>

                {/* //Select State// */}
                <Grid item xs={6}>
                    <Dropdown
                        id={'state_dropdown'}
                        dataTestId={'state_dropdown'}
                        results={states}
                        placeholder='Select the State'
                        validation={isFormSubmited && !data.state_id}
                        label={<LabelWithAsteriskMark label={'Select State'} />}
                        value={data.state_id ? states.find((item) => item.id === data.state_id) : null}
                        setValue={(event, selected) => { setData({ ...data, state_id: selected ? selected.id : null }) }}
                    />
                </Grid>

                {/* //Address Line 1// */}
                {/* <Grid item xs={12} sx={{}}>
                    <Input
                        item={{
                            type: 'text',
                            required: true,
                            fullWidth: true,
                            title: 'Address Line 1',
                            placeholder: 'Enter Address Line 1',
                            value: data.address_line1 ? data.address_line1 : '',
                            onChange: (e) => { onChange('address_line1', e.target.value !== '' ? e.target.value : null) }
                        }}
                    />
                </Grid> */}

                {/* //Address Line 2// */}
                {/* <Grid item xs={12} sx={{}}>
                    <Input
                        item={{
                            type: 'text',
                            required: true,
                            fullWidth: true,
                            title: 'Address Line 2',
                            placeholder: 'Enter Address Line 2',
                            value: data.address_line2 ? data.address_line2 : '',
                            onChange: (e) => { onChange('address_line2', e.target.value !== '' ? e.target.value : null) }
                        }}
                    />
                </Grid> */}


                {/* //City/Town// */}
                {/* <Grid item xs={4} sx={{}}>
                    <Input
                        item={{
                            type: 'text',
                            fullWidth: true,
                            title: 'City/Town',
                            placeholder: 'Enter City/Town',
                            value: data.city ? data.city : '',
                            onChange: (e) => { onChange('city', e.target.value !== '' ? e.target.value : null) }
                        }}
                    />
                </Grid> */}

                {/* //Pin Code// */}
                {/* <Grid item xs={4} sx={{}}>
                    <Input
                        item={{
                            type: 'number',
                            fullWidth: true,
                            title: 'Pin Code',
                            placeholder: 'Enter Pin Code',
                            value: data.zip_code ? data.zip_code : '',
                            onChange: (e) => { onChange('zip_code', isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : null) }
                        }}

                        onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === 'E') {
                                event.preventDefault();
                            }
                        }}
                    />
                </Grid> */}

                {/* //Fiscal Year// */}
                <Grid item xs={6}>
                    <Dropdown
                        id={'month_range_dropdown'}
                        dataTestId={'month_range_dropdown'}
                        results={fiscalYear}
                        optionLabel={'month_range'}
                        placeholder='Select the Fiscal Year'
                        validation={isFormSubmited && !data.fiscal_year_id}
                        label={<LabelWithAsteriskMark label={'Fiscal Year'} />}
                        value={data.fiscal_year_id ? fiscalYear.find((item) => item.id === data.fiscal_year_id) : null}
                        setValue={(event, selected) => {
                            if (selected) {
                                financialYearLastDay(selected.month_range)
                            } else {
                                financialYearLastDay(null)
                            }
                            onChange('fiscal_year_id', selected ? selected.id : null)
                        }}
                    />
                </Grid>

                {/* //Time zone// */}
                <Grid item xs={6}>
                    <Dropdown
                        id={'time_zone_dropdown'}
                        dataTestId={'time_zone_dropdown'}
                        results={timeZone}
                        optionLabel={'time_zone'}
                        placeholder='Select the Time Zone'
                        validation={isFormSubmited && !data.time_zone_id}
                        label={<LabelWithAsteriskMark label={'Time Zone'} />}
                        value={data.time_zone_id ? timeZone.find((item) => item.id === data.time_zone_id) : null}
                        setValue={(event, selected) => { onChange('time_zone_id', selected ? selected.id : null) }}
                    />
                </Grid>

                {/* //Date Format// */}
                <Grid item xs={6}>
                    <Dropdown
                        id={'date_format_dropdown'}
                        dataTestId={'date_format_dropdown'}
                        results={dateFormat}
                        optionLabel={'date_format'}
                        placeholder='Select the Date Format'
                        validation={isFormSubmited && !data.date_format_id}
                        label={<LabelWithAsteriskMark label={'Date Format'} />}
                        value={data.date_format_id ? dateFormat.find((item) => item.id === data.date_format_id) : null}
                        setValue={(event, selected) => { onChange('date_format_id', selected ? selected.id : null) }}
                    />
                </Grid>

                {/* //Currency// */}
                <Grid item xs={6}>
                    <CustomTypography
                        text={<LabelWithAsteriskMark label={'Select Currency'} />}
                        sx={{
                            mb: 1,
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '18px',
                            fontStyle: 'normal',
                            textTransform: "none",
                            fontFamily: "Noto Sans",
                            textTransform: 'capitalize',
                        }}
                    />
                    <CustomDefaultDropdown
                        maxHeight={250}
                        fullWidth={true}
                        results={currencies}
                        labelKey='currency_code'
                        id={'currency_dropdown'}
                        disabledCloseIcon={false}
                        placeholder='Select Currency'
                        valueLabelKey={'currency_code'}
                        dataTestId={'currency_dropdown'}
                        validation={isFormSubmited && !data.currency_id}
                        value={data.currency_id ? currencies.find((item) => item.id === data.currency_id) : null}
                        setValue={(selectedCurrency) => {
                            setData((prev) => ({ ...prev, ...data, currency_id: selectedCurrency ? selectedCurrency.id : null }))
                        }}
                    />
                </Grid>

                {/* //Financial Year Last Date// */}
                <Grid item xs={6}>
                    <DatePicker
                        disabledCloseIcon={true}
                        disabledDatePicker={true}
                        id={'financial_year_last_day_date_select'}
                        dataTestId={'financial_year_last_day_date_select'}
                        validation={isFormSubmited && !data.financial_year_last_day}
                        title={<LabelWithAsteriskMark label={'Financial Year Last Date'} />}
                        date={data.financial_year_last_day ? moment(data.financial_year_last_day) : null}
                        setDate={(date) => { onChange('financial_year_last_day', date ? date.format('YYYY-MM-DD') : null) }}
                    />
                </Grid>

                {/* //Migration Date// */}
                <Grid item xs={6}>
                    <DatePicker
                        no_minDate={true}
                        maxDate={moment()}
                        id={'migration_date_select'}
                        dataTestId={'migration_date_select'}
                        validation={isFormSubmited && !data.migration_date}
                        date={data.migration_date ? moment(data.migration_date) : null}
                        setDate={(date) => { onChange('migration_date', date ? date.format('YYYY-MM-DD') : null) }}
                        title={<span style={{ display: 'flex', alignItems: 'center' }}><LabelWithAsteriskMark label={'Migration Date'} /><MetaData placement='top' sx={{}} meta_data={`The date on which you generated the Trial Balance report in your previous accounting software while migrating to finycs.`} /></span>}
                    />
                </Grid>

                {/* //Phone Number// */}
                {/* <Grid item xs={6}>
                    <Input
                        item={{
                            type: 'number',
                            fullWidth: true,
                            title: 'Phone Number',
                            inputProps: { maxLength: 10 },
                            placeholder: 'Enter Phone Number',
                            value: data.phone_number ? data.phone_number : '',
                            onChange: (e) => { onChange("phone_number", isNumber(e.target.value) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : null) }
                        }}
                        onKeyPress={(event) => {
                            if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e' || event?.key === 'E') {
                                event.preventDefault();
                            }
                        }}
                    />
                </Grid> */}

                {/* //PAN Number// */}
                {/* <Grid item xs={6}>
                    <Input
                        item={{
                            type: 'text',
                            fullWidth: true,
                            title: 'PAN Number',
                            inputProps: { maxLength: 10 },
                            placeholder: 'Enter PAN Number',
                            // validation: isFormSubmited && (pan && !isValidPan(pan)),
                            value: data.pan_number ? data.pan_number.toUpperCase() : '',
                            onChange: (e) => { onChange('pan_number', e.target.value !== '' ? e.target.value.replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "").toUpperCase() : null) },
                        }}
                    />
                </Grid> */}

                {/* //Is GST Registered ?// */}
                {/* <Grid item xs={6}>
                    <Box sx={{ mt: 2.5 }}>
                        <Grid container spacing={0}
                            style={{
                                alignItems: 'center',
                                justifyItems: 'center',
                                alignContent: 'space-evenly',
                            }}
                        >
                            <Grid item sx={{ pr: 2, textAlign: 'left' }}>
                                <CustomTypography
                                    text={'Is GST Registered ?'}
                                    sx={{
                                        color: '#000000',
                                        display: 'inline',
                                        fontSize: '14px',
                                        textAlign: 'start',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{ pl: 2, textAlign: 'left' }}>
                                <RadioGroup row sx={{ marginBottom: 0 }}>
                                    <FormControlLabel value={'yes'}
                                        label={<span style={{
                                            color: '#000000',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            display: 'inline',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            fontWeight: type === 'yes' ? '600' : '400',
                                        }}>YES</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'yes'}
                                            onChange={(e) => { setType(e.target.value); onChange('is_business_registered', e.target.value === 'yes');; }}
                                        />}
                                    />
                                    <FormControlLabel value={'no'}
                                        label={<span style={{
                                            color: '#000000',
                                            fontSize: '14px',
                                            display: 'inline',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            fontFamily: "Noto Sans",
                                            fontWeight: type === 'no' ? '600' : '400',
                                        }}>NO</span>} control={<Radio sx={{ color: '#8F8F8F' }} checked={type === 'no'}
                                            onChange={(e) => { setType(e.target.value); onChange('is_business_registered', e.target.value === 'yes'); }}
                                        />} />
                                </RadioGroup>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid> */}

                {/* //GSTIN// */}
                {/* {data.is_business_registered ?
                    <Grid item xs={6}>
                        <Input
                            item={{
                                type: 'text',
                                title: 'GSTIN',
                                fullWidth: true,
                                inputProps: { maxLength: 15 },
                                placeholder: 'For e.g. 29APPCK7465F1Z1',
                                value: data.gstin_number ? data.gstin_number.toUpperCase() : '',
                                // validation: isFormSubmited && (!gstin || (gstin && !validator.isLength(String(gstin), { min: 15, max: 15 }))),
                                onChange: (e) => onChange("gstin_number", e.target.value !== '' ? e.target.value.replace(/[`~$&+,:;=?_[\]@#|{}'<>.^*()%!-/]/, "").toUpperCase() : null),
                            }}
                        />
                    </Grid>
                    : null
                } */}


            </Grid>
        </Box>
    )
}