import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { ReactComponent as OverduePaymentIcon } from '../assets/svg/overduePayment.svg';

import {
    Box,
    Grid,
} from '@mui/material';

const NewOverDuePayment = ({ navigateToSignup, textContent }) => {

    const offerText = textContent?.other_section_data?.finycs_free_text?.text;

    // Data for features section
    const featuresData = {
        title: <span>{textContent?.title.slice(0, 23)}<br />{textContent?.title.slice(24, textContent?.title.length)}</span>,
        description: (
            <div>
                <p>
                    {textContent?.subtitle?.slice(0, 67)} <br />
                    {textContent?.subtitle.slice(67, textContent?.subtitle.length)}
                </p>
                <Box sx={styles.featuresList}>
                    {textContent?.bulletins?.map((text, index) => (
                        <Box key={index} sx={styles.listItem}>
                            <FeatureCount count={index + 1} />
                            <CustomTypography
                                text={text?.text}
                                sx={styles?.listItemText}
                            />
                        </Box>
                    ))}
                </Box>
            </div>
        ),
    };

    return (
        <Custom.Container>
            <Box sx={styles.sectionContainer}>
                <Box sx={{ p: { xs: 4, sm: 0 } }}>
                    <Box container sx={{
                        pb: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottom: '1px solid #E0E0E0',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', sm: 'row' }
                        }}>
                            <FeaturesContent title={featuresData.title} description={featuresData.description} />
                            <FeaturesImage />
                        </Box>
                    </Box>
                    <Box sx={styles.promoBox}>
                        <Box>
                            <CustomTypography
                                text={<span>{offerText.slice(0, 32)}<span style={{ color: '#EF6F0D' }}>{offerText?.slice(32, offerText.length)}</span></span>}
                                sx={styles.promoText}
                            />
                        </Box>
                        <ActionButton text="Signup now for Free" variant="gradient" navigateToSignup={navigateToSignup} />
                        {/* <Box sx={styles.actionButtons}>
                            <ActionButton text="Book Free Demo" variant="plain" />
                        </Box> */}
                    </Box>
                </Box>
            </Box>
        </Custom.Container>
    );
};

export default NewOverDuePayment;

// Reusable button component for actions
const ActionButton = ({ text, variant, navigateToSignup }) => {
    const buttonStyle = variant === 'gradient' ? styles.gradientButton : styles.plainButton;
    return (
        <Box onClick={() => { navigateToSignup() }} sx={{ ...styles.buttonBase, ...buttonStyle, cursor: 'pointer' }}>
            {text}
        </Box>
    );
};

// Component for displaying the features image
const FeaturesImage = () => (
    <Box
        order={{ xs: 1, sm: 2 }}
        sx={{ ...styles.imageContainer }}>
        <Box sx={styles.imageBox}>
            <OverduePaymentIcon style={styles.imageStyle} />
        </Box>
    </Box>
);

// Component for displaying the count number for features
const FeatureCount = ({ count }) => (
    <Box sx={styles.countBox}>
        {count}
    </Box>
);

// Component for the features content (title and description)
const FeaturesContent = ({ title, description }) => (
    <Box
        sx={styles.contentContainer}
        order={{ xs: 2, sm: 1 }}>
        <Box>
            <CustomTypography text={title} sx={styles.title} />
            <CustomTypography text={description} sx={styles.description} />
        </Box>
    </Box>
);

// Styles used in the component
const styles = {
    sectionContainer: {
        pb: { xs: 2, sm: 8 },
        pt: { xs: 1, sm: 8 },
        height: '100%',
        display: 'flex',
        borderRadius: '32px',
        alignItems: 'center',
        background: '#FAFAFA',
        justifyContent: 'center',
    },
    contentContainer: {
        gap: '20px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    imageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        mr: { xs: -10, sm: -30 },
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    imageBox: {
        width: '100%',
        maxWidth: '560px',
        maxHeight: '500px',
    },
    imageStyle: {
        width: '100%',
        height: 'auto',
    },
    countBox: {
        mr: 2,
        color: '#FFFFFF',
        display: 'flex',
        borderRadius: 10,
        textAlign: 'center',
        alignItems: 'center',
        background: '#2464EB',
        justifyContent: 'center',
        width: { xs: '28px', sm: '35px' },
        height: { xs: '28px', sm: '35px' },
        fontSize: { xs: '12px', sm: '14px' },
    },
    title: {
        fontWeight: 700,
        color: '#141414',
        fontSize: '40px',
        lineHeight: '48px',
        letterSpacing: '0px',
    },
    description: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#666666',
        lineHeight: '32px',
        letterSpacing: '0.2px',
    },
    featuresList: {
        padding: 0,
        fontWeight: 600,
    },
    listItem: {
        alignItems: 'center',
        paddingTop: { xs: '15px', sm: '10px' },
        display: { xs: '-webkit-box', sm: 'flex' },
    },
    listItemText: {
        padding: 0,
        fontWeight: 600,
        color: '#161C2D',
        letterSpacing: '0px',
        listStyleType: 'none',
        lineHeight: { xs: '22px', sm: '30px' },
        fontSize: { xs: '16px', sm: '20px' },
        textWrap: "balance"
    },
    promoBox: {
        mt: 3,
        gap: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    promoText: {
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0.2px',
        fontSize: { xs: '20px', sm: '24px' },
        textAlign: { xs: 'center', sm: 'start' },
    },
    actionButtons: {
        width: '100%',
        display: 'flex',
        marginBottom: 2,
        borderRadius: 10,
        maxWidth: '460px',
        alignItems: 'center',
        backgroundColor: '#EFEFEF',
        height: { xs: '60px', sm: '72px' },
        justifyContent: 'space-between',
    },
    buttonBase: {
        margin: 1,
        width: '100%',
        height: '56px',
        display: 'flex',
        maxWidth: '290px',
        cursor: 'pointer',
        borderRadius: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: { xs: '18px', sm: '22px' },
    },
    gradientButton: {
        color: '#ffffff',
        background: 'linear-gradient(to bottom, #96B8FF, #0952EB)',
    },
    plainButton: {
        color: '#000000',
        backgroundColor: '#EFEFEF',
    },
};
