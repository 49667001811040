import React, { useState, useEffect, useRef } from 'react';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { Box } from '@mui/material';
import userImage from '../../../../../assets/image/profile.png';

const NewReviews = ({ textContent }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [visibleReviews, setVisibleReviews] = useState([]);
    const [numVisible, setNumVisible] = useState(1);
    const containerRef = useRef(null);
    const reviewsRef = useRef([]);

    const reviewContent = textContent?.cards
    const handleNextClick = () => {
        if (activeIndex + numVisible < reviewContent.length) {
            setActiveIndex(prevIndex => prevIndex + 1);
        }
    };

    const handlePrevClick = () => {
        if (activeIndex > 0) {
            setActiveIndex(prevIndex => prevIndex - 1);
        }
    };

    useEffect(() => {
        const calculateVisibleReviews = () => {
            if (!containerRef.current) return;

            const containerWidth = containerRef.current.offsetWidth;
            const cardWidth = 391;
            const gap = 30;

            const calculatedNumVisible = Math.max(1, Math.floor((containerWidth + gap) / (cardWidth + gap)));
            setNumVisible(calculatedNumVisible);
            setVisibleReviews(reviewContent.slice(activeIndex, activeIndex + calculatedNumVisible));
        };

        calculateVisibleReviews();

        const handleResize = () => {
            calculateVisibleReviews();
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [activeIndex]);

    const isLeftArrowDisabled = activeIndex === 0;
    const isRightArrowDisabled = activeIndex + numVisible >= reviewContent.length;

    // console.log("textContent", textContent)

    return (
        <Custom.Container sx={{ background: '#F9F9F9', mt: 2, mb: 3, width: "90%" }}>
            <Box sx={styles.container}  >
                <Box sx={{ p: 2, pt: 3, position: 'relative', pb: 5 }} >
                    <Title text="Real-Time Metrics Tracking / From Reactive to Proactive" sx={styles.heading} />
                    <Title
                        text={<span>From uncovering seasonal patterns to spotting long-term opportunities, <br />Finycs transforms your data into clear insights that empower smarter, faster decisions.</span>}
                        sx={styles.subHeading}
                    />
                    <SliderArrows
                        onNextClick={handleNextClick}
                        onPrevClick={handlePrevClick}
                        isLeftDisabled={isLeftArrowDisabled}
                        isRightDisabled={isRightArrowDisabled}
                    />
                </Box>
                <Box
                    ref={containerRef}
                    className="swiper-wrapper review-container"
                    sx={{
                        p: 2,
                        display: 'flex',
                        gap: '30px',
                        width: '100%',
                    }}
                >
                    {visibleReviews.map((content, index) => (
                        <Box
                            key={index}
                            className="c-integrations-item"
                            sx={{
                                width: {
                                    xs: 'calc(100% - 100px)',
                                    sm: '391px'
                                },
                                alignItems: "center",
                                minWidth: {
                                    xs: '100px',
                                    sm: '391px',
                                },
                                flexShrink: 0,
                            }}
                            ref={(el) => (reviewsRef.current[index] = el)}
                        >
                            <Box className="c-integrations-item__inner">
                                <Box className="c-integrations-item__top">
                                    <Box component="img" sx={styles.userImage} src={userImage} alt="User" />
                                    <Box className="c-integrations-item__name">{content?.title}</Box>
                                </Box>
                                <Box className="c-integrations-item__info">{content?.text}</Box>
                                {/* <Box className="c-integrations-item__terms">{content.title}</Box> */}
                                {/* <Box className="c-integrations-item__button">{"Read More"}</Box> */}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Custom.Container>
    );
};

const SliderArrows = ({ onNextClick, onPrevClick, isLeftDisabled, isRightDisabled }) => {
    return (
        <Box className="toggle-arrows-container" sx={{ right: { sm: 0, xs: "17px" } }}>
            <Box
                className={`left-arrow ${isLeftDisabled ? 'disabled' : ''}`}
                onClick={isLeftDisabled ? undefined : onPrevClick}
                sx={{
                    opacity: isLeftDisabled ? 0.5 : 1,
                    cursor: isLeftDisabled ? 'not-allowed' : 'pointer',
                }}
            >
                <img
                    decoding="async"
                    src="https://landingi.com/wp-content/themes/landingi/img/icons/arrow-left.svg"
                    data-src="https://landingi.com/wp-content/themes/landingi/img/icons/arrow-left.svg"
                    width="16"
                    height="16"
                    alt=""
                />
            </Box>
            <Box
                className={`right-arrow ${isRightDisabled ? 'disabled' : ''}`}
                onClick={isRightDisabled ? undefined : onNextClick}
                sx={{
                    opacity: isRightDisabled ? 0.5 : 1,
                    cursor: isRightDisabled ? 'not-allowed' : 'pointer',
                }}
            >
                <img
                    decoding="async"
                    src="https://landingi.com/wp-content/themes/landingi/img/icons/arrow-left.svg"
                    data-src="https://landingi.com/wp-content/themes/landingi/img/icons/arrow-left.svg"
                    width="16"
                    height="16"
                    alt=""
                />
            </Box>
        </Box>
    );
};

const Title = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.typography, ...sx }} variant="h2" />
);

export default NewReviews;

const styles = {
    container: {
        pt: 4,
        pb: 8,
        height: '100%',
        borderRadius: '32px',
        position: 'relative',
    },
    heading: {
        textAlign: 'center',
        letterSpacing: '1.28px',
        fontSize: '1.5rem',
        fontWieght: 300,
        lineHeight: 1.7,
    },
    subHeading: {
        pt: 2,
        mb: 3,
        textAlign: 'center',
        letterSpacing: '0.64px',
        fontSize: '1.2rem',
        fontWieght: 100,
        lineHeight: 1.7,
    },
    userImage: {
        objectFit: 'cover',
        borderRadius: '50%',
        width: { xs: '35px', sm: '50px' },
        height: { xs: '35px', sm: '50px' },
    },
    reviewItemContainer: {
        width: '100%',
        display: 'flex',
        maxWidth: '1200px',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        background: '#FFF',
    },
};