import { CONSTANTS } from "./eventsConst";
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebaseConfig";
import { ENVIRONMENT } from "../config/config";

export const eventsNames = CONSTANTS;
export const AnalyticsEvent = (category, params) => {
    // console.log('===>AnalyticsEvent',category, params)
    // if(params){
    //     params["email"] = getUserDetails() ? getUserDetails().email : "-"
    //     params["host"] = HOST
    // }

    if (ENVIRONMENT === 'PRODUCTION') {
        logEvent(analytics, category, params)
    }
}