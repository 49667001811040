import * as React from 'react';
import validator from 'validator';
import Input from '../../common/Input';
import * as Actions from '../../../state/Actions';
import { routesName } from '../../../config/routesName';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiAction, apiAction_social } from '../../../api/api';
import CustomTypography from '../../custom/typography/CustomTypography';
import ModalUpdateUserDetails from './onBoarding/ModalUpdateUserDetails';
import { AuthPagesLeftSideUI } from '../home/insight/common/CommonComponents';
import { getBusinessPreferenceData, getUserPreferenceData, updateUserPreferencesData } from '../setting/settings/Preferences/common/getPreferences';


import {
  retrieve_user,
  retrieve_business,
  get_list_of_business,
  user_register_through_invite,
} from '../../../api/urls';

import {
  parseJwt,
  setLoader,
  isFormValid,
  verifyPassword,
  getQueryParams,
  stateChangeManager,
} from '../../../utils/Utils';

import {
  setToken,
  clearCookie,
  setLoginStatus,
  setUserDetails,
  setBusinessInfo,
} from '../../../config/cookiesInfo';

import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const sign_up_initialData = {
  email: null,
  token: null,
  new_password: null,
  confirm_password: null,
}


const CreateInvitedUser = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const params = getQueryParams(location.search);
  const dispatch = Actions.getDispatch(React.useContext);
  const [parseJwtData, setParseJwtData] = React.useState(false);
  const [isMissingData, setIsMissingData] = React.useState(false);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [data, setData] = React.useState({ ...sign_up_initialData });

  const [isShow, setIsShow] = React.useState({
    is_password: false,
    is_confirmed_password: false
  })


  React.useEffect(() => {
    if (params.token) {
      setParseJwtData(parseJwt(params.token))
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (parseJwtData) {
      setData({
        ...data,
        ...parseJwtData,
        token: params.token,
        email: parseJwtData.email,
      })
    }
    // eslint-disable-next-line
  }, [parseJwtData]);

  const handleSubmit = async (event) => {
    clearCookie(false);
    event.preventDefault();
    setFormSubmitted(true);
    let validation_data = [
      { key: "new_password", message: 'New password field can not be empty' },
      { key: "confirm_password", message: 'Confirm password field can not be empty' },
      { key: '', validation: (!data.email || !validator.isEmail(data.email)), message: 'Please enter valid email.' },
      { key: "", validation: data.new_password !== data.confirm_password, message: 'New password and confirm password does not match' },
      { key: "", validation: !verifyPassword(data.new_password), message: 'Use 8 or more characters with a mix of letters, finycs & symbols.' },

    ]

    const { isValid, message } = isFormValid(data, validation_data)
    if (isValid) {
      setLoader(dispatch, Actions, true)
      let res = await apiAction_social({
        method: 'post',
        url: user_register_through_invite(),
        data: { ...data, password: data.new_password, name: data.first_name + ' ' + data.last_name },
      })
      if (res?.success) {
        setToken(res.data.access_token);
        setUserDetails(res?.data?.user);
        setLoader(dispatch, Actions, false);

        handleMissingInfoRedirect({ is_mobile_number_empty: res?.is_mobile_number_empty, is_email_empty: res?.is_email_empty });

      } else {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
      }
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'error', message);
    }
  };

  const onAccept = async () => {
    let res = await apiAction_social({
      method: 'post',
      url: user_register_through_invite(),
      data: { ...data, password: data.new_password },
    })
    if (res?.success) {
      clearCookie(false);
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'success', res?.status);
      navigate("/auth" + routesName.signIn.path)
    } else {
      setLoader(dispatch, Actions, false);
      stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    }
  }

  const onPasswordVisibility = (data) => {
    if (data === 'is_password') {
      isShow.is_password = !isShow.is_password;
    } else {
      isShow.is_confirmed_password = !isShow.is_confirmed_password;
    }
    setIsShow({ ...isShow })
  }

  const handleMissingInfoRedirect = async ({ is_email_empty, is_mobile_number_empty }) => {
        setLoader(dispatch, Actions, false);

    if (is_mobile_number_empty) {
      setIsMissingData('is_mobile_number_empty');
    } else if (is_email_empty) {
      setIsMissingData('is_email_empty');
    } else if (!(is_mobile_number_empty && is_email_empty)) {
      fetchBusinessList();
    }
  };

  const fetchBusinessList = async () => {
    const res = await apiAction({ method: 'get', url: get_list_of_business() });
    if (res?.success) {
      const businessList = res?.result;
      if (businessList?.length) {
        const res = await getUserPreferenceData(navigate, dispatch);
        if (res?.success) {
          const userInfo = res?.result;
          fetchUserProfile(userInfo?.business_id, businessList);
        }

      } else {
        redirectToBusinessCreation();
      }

    } else {
      setLoader(dispatch, Actions, false);
    }
  }

  const fetchUserProfile = async (businessId, businessList) => {
    const res = await apiAction({ method: 'get', url: retrieve_user() });

    if (res?.success) {
      setUserDetails(res.result);
      fetchBusinessDetails(businessId, businessList);
    }
  };

  const fetchBusinessDetails = async (businessId, businessList) => {
    if (businessList.length) {
      const selectedBusinessId = businessId || businessList?.[0]?.id;
      const businessRes = await apiAction({
        method: 'get',
        url: retrieve_business(selectedBusinessId),
      });

      if (businessRes?.success) {
        if (!businessId) {
          updateUserPreferencesData(selectedBusinessId, navigate, dispatch);
      }

        setLoginStatus('true');
        setBusinessInfo(businessRes?.result);
        getBusinessPreferenceData(businessRes?.result?.id);

        navigate(routesName.dashboard.path);
        setLoader(dispatch, Actions, false);
      }
    } else {
      redirectToBusinessCreation();
      setLoader(dispatch, Actions, false);
    }
  };

  const redirectToBusinessCreation = () => {
    setLoader(dispatch, Actions, false);
    navigate(`/auth${routesName.businessCreate.path}`);
  };


  const handleBusinessRedirect = () => {
    fetchBusinessList();
  };

  if (isMissingData) {
    return (
      <ModalUpdateUserDetails
        open={isMissingData}
        setOpen={() => {
          setIsMissingData(false);
          handleBusinessRedirect();
        }}
      />
    );
  }
  return (

    <Box sx={{
      display: 'flex',
      flexDirection: "row",
      height: '100vh',
      alignItems: "center",
    }}>
      <AuthPagesLeftSideUI />
      {/* Right Section */}

      <Box
        sx={{
          width: "100%",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fafafa',
          p: 2
        }}
      >
        <Box
          className='m_login_container'
          sx={{
            p: 4,
            flexGrow: 1,
            maxHeight: "676px",
            maxWidth: "424px",
            backgroundColor: '#f0f0f0',
            borderRadius: '12px',
          }}
        >
          <Box
            style={{ display: "flex", alignItems: "center", paddingBottom: "24px", cursor: "pointer", width: "fit-content" }}
            onClick={(e) => { navigate("/auth" + routesName.signIn.path, { replace: true }); e.preventDefault() }}
          >
            <ArrowBackIosIcon sx={{ width: "0.5em", height: "0.5em" }} />
            <CustomTypography
              text={'Back'}
              sx={{
                color: '#000000',
                fontSize: '16px',
                textAlign: 'start',
                fontWeight: '700',
                fontStyle: 'normal',
                lineHeight: '40.86px',
                fontFamily: "Noto Sans",
                pl: "4px"
              }}
            />
          </Box>
          {
            parseJwtData?.is_user_exists ? (
              <AlreadyHaveAccount data={data} onAccept={onAccept} />
            )
              :
              <Box component="form" onSubmit={handleSubmit} noValidate>
                {
                  params.token &&
                  <Box>
                    <CustomTypography
                      text={<span>You've been invited by {data.business_name} <br />to use the finycs.</span>}
                      sx={{
                        color: '#000000',
                        fontSize: '20px',
                        textAlign: 'start',
                        fontWeight: '700',
                        fontStyle: 'normal',
                        lineHeight: '40.86px',
                        fontFamily: "Noto Sans",
                      }}
                    />
                    <Box sx={{ mt: 3, }}>
                      <Input
                        id='input_email'
                        dataTestId='input_email'
                        item={{
                          type: 'text',
                          disabled: true,
                          title: 'Email',
                          required: true,
                          fullWidth: true,
                          placeholder: 'Enter Email',
                          value: data.email ? data.email : '',
                          validation: isFormSubmitted && !data.email,
                          onChange: (e) => setData({ ...data, email: e.target.value })
                        }}
                      />
                    </Box>

                    <Box sx={{ mt: 3, }}>
                      <Input
                        id='input_new_password'
                        dataTestId='input_new_password'
                        item={{
                          required: true,
                          fullWidth: true,
                          title: 'Password',
                          placeholder: 'Enter Password',
                          type: isShow.is_password ? 'text' : 'password',
                          value: data.new_password ? data.new_password : '',
                          validation: isFormSubmitted && !verifyPassword(data.new_password),
                          onChange: (e) => setData({ ...data, new_password: e.target.value })
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                            <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                              onClick={() => { onPasswordVisibility('is_password') }} id='show_password_icon' dataTestId='show_password_icon'>
                              {
                                isShow.is_password ?
                                  <VisibilityIcon fontSize='small' />
                                  :
                                  <VisibilityOffIcon fontSize='small' />
                              }
                            </IconButton>
                          </InputAdornment>

                        }}
                      />
                    </Box>
                    <Box sx={{ mt: 2, }}>
                      <Input
                        id='input_confirm_password'
                        dataTestId='input_confirm_password'
                        item={{
                          required: true,
                          fullWidth: true,
                          title: 'Confirm Password',
                          placeholder: 'Enter Confirm Password',
                          type: isShow.is_confirmed_password ? 'text' : 'password',
                          value: data.confirm_password ? data.confirm_password : '',
                          validation: isFormSubmitted && !verifyPassword(data.confirm_password),
                          onChange: (e) => setData({ ...data, confirm_password: e.target.value }),
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                            <IconButton edge="end" size='small' sx={{ ml: .5, color: '#2464EB', cursor: 'pointer' }}
                              onClick={() => { onPasswordVisibility('is_confirmed_password') }} id='show_confirmed_password_icon' dataTestId='show_confirmed_password_icon'>
                              {
                                isShow.is_confirmed_password ?
                                  <VisibilityIcon fontSize='small' />
                                  :
                                  <VisibilityOffIcon fontSize='small' />
                              }
                            </IconButton>
                          </InputAdornment>

                        }}
                      />
                    </Box>
                  </Box>
                }


                <Button
                  fullWidth
                  type="submit"
                  id='clicked_btn'
                  variant="contained"
                  dataTestId='clicked_btn'
                  sx={{ mt: 4, mb: 2, textTransform: 'none' }}>{`Continue`}</Button>
              </Box>
          }

        </Box>
      </Box>
    </Box>

  )
}

export default CreateInvitedUser;

const AlreadyHaveAccount = (props) => {
  const { data, onAccept } = props;
  const navigate = useNavigate();

  return (
    <Box>
      <CustomTypography
        text={'Finycs Invitation'}
        sx={{
          mb: 3,
          color: '#000000',
          fontSize: '30px',
          fontWeight: '700',
          textAlign: 'center',
          fontStyle: 'normal',
          lineHeight: '40.86px',
          fontFamily: "Noto Sans",
        }}
      />
      <CustomTypography
        text={<span>
          You have been invited to join <strong style={{ color: '#000000' }}>{data.business_name}</strong> as a collaborator.
          Accept this invitation to access our business on your finycs account and collaborate.
        </span>}
        sx={{
          color: '#777775',
          fontSize: '20px',
          textAlign: 'start',
          fontWeight: '700',
          fontStyle: 'normal',
          lineHeight: '40.86px',
          fontFamily: "Noto Sans",
        }}
      />

      <Box sx={{ display: 'flex', justifyContent: "center", textAlign: 'center' }}>
        <Button

          id='clicked_btn'
          variant="contained"
          dataTestId='clicked_btn'
          onClick={() => onAccept()}
          sx={{ mt: 4, mb: 2, textTransform: 'none' }}>{`Accept`}</Button>
        <Button
          variant="outlined"
          id='clicked_btn_cancel'
          dataTestId='clicked_btn_cancel'
          onClick={() => { window.close() }}
          sx={{ ml: 2, mt: 4, mb: 2, textTransform: 'none' }}>{`Cancel`}</Button>
      </Box>
    </Box>
  )
}