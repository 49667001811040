import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

// const HTMLViewer = (props) => {
//     const { html_string } = props
//     const [scale, setScale] = React.useState(window.innerWidth / 1200);

//     React.useEffect(() => {
//         const handleResize = () => {
//             setScale(window.innerWidth / 1200);
//         };
//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     // Check for mobile view
//     const isMobile = useMediaQuery('(max-width:600px)');
//     return (
//         <Box sx={{
//             width: '100%',
//             height: '100%',
//             position: 'relative',
//             background: '#FFFFFF',
//             borderTop: '5px solid #F5F5F5',
//         }}>
//             <div className='page' style={{
//                 zoom: scale,
//                 width: '100%',
//                 padding: '5px',
//                 paddingTop: '30px',
//                 position: 'relative',
//                 background: '#FFFFFF',
//                 minHeight: isMobile ? `${(window.innerHeight) * (297 / 210)}px` : '100%',
//             }}>
//                 <div style={{ position: 'relative', marginBottom: '60px', }} dangerouslySetInnerHTML={{ __html: html_string }} />
//                 {/* <div className="powered-by" style={{}}>
//                             <p>Powered by</p>
//                             <img src={images.appLogo.src} alt="Finycs Logo" className="powered-by__logo" />
//                             <p>finycs</p>
//                         </div> */}

//             </div>
//             {/* {ReactHtmlParser(html_string)} */}
//         </Box>
//     )
// }

const HTMLViewer = (props) => {
    const { html_string } = props
    const ref = React.useRef();
    const [height, setHeight] = React.useState("0px");
    const onLoad = () => {
        setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    };
    return (
        <Box sx={{ p: 6, pt: 4 }}>
            <iframe
                ref={ref}
                title='html'
                width="100%"
                height={height}
                onLoad={onLoad}
                scrolling={"no"}
                frameBorder={'0'}
                srcDoc={html_string}
                style={{
                    width: "100%",
                    overflow: "auto",
                }}
            >
            </iframe>
        </Box>
    )
}
export default HTMLViewer;