
import * as React from 'react';
import CustomInput from '../input/CustomInput';
import { HtmlTooltip } from '../tooltip/CustomTooltip';
import CustomTypography from '../typography/CustomTypography';
import {
    Box,
    MenuItem,
    Autocomplete
} from '@mui/material';

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomDefaultDropdown = (props) => {
    const {
        id,
        sx,
        url,
        item,
        value,
        hasMore,
        nextUrl,
        labelKey,
        setValue,
        autoFocus,
        isTooltip,
        fullWidth,
        dataTestId,
        InputProps,
        renderInput,
        fetchOptions,
        MenuItemStyle,
        inputDisabled,
        maxHeight = 300,
        valueLabelKey,
        results = [{}],
        size = "small",
        forcePopupIcon,
        tooltipLabelKey,
        placeholder = '',
        disabledCloseIcon,
        fetchTermLimit = 2,
        validation = false,
        isShowEndAdornment = false,
        noResult = 'No results found',
    } = props

    const handleChange = (value) => {
        setValue(value);
    };

    const onChangeInputHandler = (newValue) => {
        if (newValue && fetchOptions) {
            if (newValue.length % fetchTermLimit === 0) {
                let apiBody = { 'description': newValue }
                fetchOptions(url, apiBody);
            }
        }
    }

    return (
        <Autocomplete
            id={id}
            size={size}
            options={results}
            autoHighlight={true}
            fullWidth={fullWidth}
            dataTestId={dataTestId}
            disabled={inputDisabled}
            placeholder={placeholder}
            value={value ? value : null}
            popupIcon={<ExpandMoreIcon />}
            forcePopupIcon={forcePopupIcon}
            disableClearable={disabledCloseIcon}
            clearIcon={<ClearIcon fontSize='small' />}
            getOptionValue={(option) => option[valueLabelKey]}
            sx={{ ...sx, background: inputDisabled ? '#F1F3F4' : '' }}
            onChange={(event, newValue) => { handleChange(newValue); }}
            getOptionLabel={(option) => value ? option[valueLabelKey] : isTooltip ? option[tooltipLabelKey] : option[labelKey]}

            renderInput={renderInput ? renderInput : (params) => <CustomInput id={id} dataTestId={dataTestId} sx={{ ...sx, background: inputDisabled ? '#F1F3F4' : '' }} isSearchableComponent={true} isShowEndAdornment={isShowEndAdornment} validation={validation} InputProps={InputProps} fullWidth  {...params} {...item} autoFocus={autoFocus} placeholder={placeholder} value={value ? value : ''} onChange={(e) => { onChangeInputHandler(e.target.value) }} />}

            renderOption={(props, item) => (
                isTooltip ?
                    <React.Fragment key={item[labelKey]}>
                        <HtmlTooltip title={item[tooltipLabelKey]} placement="right" >
                            <MenuItem key={item[labelKey]} {...props} sx={{ ...MenuItemStyle, borderBottom: '1px solid #F5F5F5' }}>
                                <Box sx={{ width: '100%' }}>
                                    <CustomTypography
                                        text={item[labelKey]}
                                        sx={{
                                            pt: 1,
                                            pb: 1,
                                            color: '#141414',
                                            fontWeight: 700,
                                            fontSize: '12px',
                                            lineHeight: '16px',
                                            fontStyle: 'normal',
                                            fontFamily: 'Noto Sans',
                                        }}
                                    />
                                    <CustomTypography
                                        text={item.helperText ? item.helperText : ''}
                                        sx={{
                                            color: '#7A7A7A',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            lineHeight: '18px',
                                            fontStyle: 'normal',
                                            whiteSpace: 'pre-wrap',
                                            fontFamily: 'Noto Sans',
                                        }}
                                    />
                                </Box>
                            </MenuItem>
                        </HtmlTooltip>
                    </React.Fragment>
                    :
                    <MenuItem key={item[labelKey]} {...props} sx={{ ...MenuItemStyle, borderBottom: '1px solid #F5F5F5' }}>
                        <Box sx={{ width: '100%' }}>
                            <CustomTypography
                                text={item[labelKey]}
                                sx={{
                                    pt: 1,
                                    pb: 1,
                                    color: '#141414',
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    fontStyle: 'normal',
                                    fontFamily: 'Noto Sans',
                                }}
                            />
                            <CustomTypography
                                text={item.helperText ? item.helperText : ''}
                                sx={{
                                    color: '#7A7A7A',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    fontStyle: 'normal',
                                    whiteSpace: 'pre-wrap',
                                    fontFamily: 'Noto Sans',
                                }}
                            />
                        </Box>
                    </MenuItem>
            )}

            ListboxProps={{
                onScroll: (event) => {
                    const listboxNode = event.target;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight && hasMore) {
                        fetchOptions(nextUrl);
                    } else if (listboxNode.scrollTop === 0 && hasMore) {
                        fetchOptions(nextUrl);
                    }
                },
                style: { maxHeight: maxHeight, overflowY: 'auto' }
            }}

            renderNoOptions={() => (
                <CustomTypography
                    text={noResult}
                    sx={{
                        mt: 2,
                        mb: 2,
                        color: '#141414',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '16px',
                        textAlign: 'center',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                    }}
                />
            )}
        />
    )
}

export default CustomDefaultDropdown