import React from "react";
import { Close } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { routesName } from '../../../../config/routesName';
import CustomButton from "../../../custom/button/CustomButton";
import {
    Box,
    Divider,
    Drawer,
    styled,
    IconButton,
} from "@mui/material";

function NewDrawerComponent(props) {
    const { openDrawer, setOpenDrawer } = props

    const onCloseHandler = () => {
        setOpenDrawer(false)
    }

    return (
        <Drawer
            anchor='right'
            open={openDrawer}
            onClose={onCloseHandler}
            variant={openDrawer ? "persistent" : 'temporary'}
            PaperProps={{
                sx: {
                    backgroundColor: '#FFFFFF',
                    width: {
                        xs: 'calc(100vw - 210px)',
                    },
                    overflow: "hidden"
                }
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "flex-end", background: "#FAFAFA" }}>
                <DrawerHeader sx={{ pb: "21px", }} >
                    <IconButton onClick={onCloseHandler} sx={{ ":hover": { bgcolor: '#e0e0e0', } }}>
                        <Close sx={{ color: '#2464EB', fontSize: 30, }} fontSize='small' />
                    </IconButton>
                </DrawerHeader>
            </Box>
            <Divider />
            <DrawerHeader>
                <Buttons />
            </DrawerHeader>
        </Drawer>
    );
}

const Buttons = () => {
    const navigate = useNavigate();

    return (
        <Box >
            <CustomButton
                fullWidth={false}
                sx={{ mt: 3, mb: 3, }}
                btnLabel='Login'
                variant="outlined"
                id={'landing_page_sign_in'}
                dataTestId={'landing_page_sign_in'}
                onClick={() => { navigate("/auth" + routesName.signIn.path) }}
            />
            <CustomButton
                sx={{}}
                btnLabel='Start free trail'
                variant="contained"
                id={'landing_page_sign_up'}
                dataTestId={'landing_page_sign_up'}
                onClick={() => { navigate("/auth" + routesName.signUp.path) }}
            />
        </Box>
    )
}

export const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));
export default NewDrawerComponent;


