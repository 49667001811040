import React from "react";
import { Breadcrumbs } from '@mui/material';
import { vendor } from "../../../../utils/Constant";
import * as Actions from '../../../../state/Actions';
import { routesName } from "../../../../config/routesName";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CustomTypography from "../../typography/CustomTypography";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getQueryParams, convertSlugToString, breadCrumbsStateChangeManager, isNumber } from "../../../../utils/Utils";

const CustomBreadCrumbs = () => {

  const state = Actions.getState(React.useContext);
  const dispatch = Actions.getDispatch(React.useContext);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  let url = params['*']
  let length = url ? url.split('/').length - 1 : ''
  const baseUrl = url ? '/' + url.split('/')[0] : ''

  const onBreadCrumbClick = (item, index) => {

    if (convertSlugToString(item, '-') === "mappings") {
      navigate(routesName.mappings.path)
    } else if (convertSlugToString(item, '-') === "mapping") {

      if (location.pathname.includes(vendor)) {
        navigate(routesName.invoicingPurchaseVendorImportMapping.path)
      } else if (location.pathname.includes("customer")) {
        navigate(routesName.invoicingSalesCustomersImportMapping.path)
      } else if (location.pathname.includes("items")) {
        navigate(routesName.invoicingItemsMapping.path)
      } else {
        navigate(routesName.openingBalanceMapping.path)
      }
    } else if (convertSlugToString(item, '-') === "file upload") {

      if (location.pathname.includes(vendor)) {
        navigate(routesName.invoicingPurchaseVendorImportFile.path)
      } else if (location.pathname.includes("customer")) {
        navigate(routesName.invoicingSalesCustomersImportFile.path)
      } else if (location.pathname.includes("items")) {
        navigate(routesName.invoicingItemsFileUpload.path)
      } else {
        navigate(routesName.openingBalanceImport.path)
      }

    } else if (convertSlugToString(item, '-') === "trial balance") {
      navigate(routesName.trialBalance.path)
    } else if (convertSlugToString(item, '-') === "general ledger") {
      navigate(routesName.generalLedgerList.path)
    } else if (convertSlugToString(item, '-') === "TDS summary") {
      navigate(routesName.tdsSummaryReports.path)
    } else if (item === "sales-by-sales-person") {
      navigate(routesName.salesBySalesPerson.path)
    } else if (item === "profit-loss") {
      navigate(routesName.profitLoss.path)
    } else if (item === "horizontal-profit-loss") {
      navigate(routesName.horizontalProfitLoss.path)
    } else if (item === "balance-sheet") {
      navigate(routesName.balanceSheet.path)
    } else if (item === "horizontal-balance-sheet") {
      navigate(routesName.horizontalBalanceSheet.path)
    } else if (item === "cash-flow") {
      navigate(routesName.cashFlow.path)
    } else {
      navigate(!(length === index) ? baseUrl + '/' + item : '')
    }
  }

  // To reset the Bread crumbs
  React.useEffect(() => {
    breadCrumbsStateChangeManager(dispatch, null);
  }, [location]);

  return (
    <Breadcrumbs
      sx={{ pl: 2, display: { xs: 'none', sm: 'block' } }}
      separator={<NavigateNextIcon fontSize="small" sx={{ color: '#000000' }} />}
      aria-label="breadcrumb">

      {
        url && url.split('/').map((item, index) => {

          // console.log('====>', Object.keys(queryParams).length !== 0 && (item === 'view' || item === 'add') && state.end_breadcrumbs, state.end_breadcrumbs)
          return (
            <div key={index} >
              {
                index ?
                  index === length ?
                    <CustomTypography
                      id={'breadcrumb_' + index}
                      dataTestId={'breadcrumb_' + index}
                      text={Object.keys(queryParams).length !== 0 && (item === 'view' || item === 'add') && state.end_breadcrumbs ? state.end_breadcrumbs : isNumber(item) ? state.end_breadcrumbs : convertSlugToString(item, '-')}
                      sx={{
                        color: '#666666',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '50px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                        textTransform: 'capitalize',

                        maxWidth: '150px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    />
                    :
                    <CustomTypography
                      id={'breadcrumb_' + index}
                      dataTestId={'breadcrumb_' + index}
                      text={convertSlugToString(item, '-')}
                      sx={{
                        color: '#2464EB',
                        fontSize: '14px',
                        cursor: 'pointer',
                        fontWeight: '500',
                        lineHeight: '50px',
                        fontStyle: 'normal',
                        fontFamily: 'Noto Sans',
                        textTransform: 'capitalize',
                        ":hover": { textDecoration: 'underline' },

                        maxWidth: '150px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      onClick={() => onBreadCrumbClick(item, index)}

                    />
                  : <CustomTypography
                    id={'home_' + index}
                    dataTestId={'home_' + index}
                    text={'Home'}
                    sx={{
                      color: '#2464EB',
                      fontSize: '14px',
                      fontWeight: '500',
                      cursor: 'pointer',
                      lineHeight: '50px',
                      fontStyle: 'normal',
                      fontFamily: 'Noto Sans',
                      textTransform: 'capitalize',
                      ":hover": { textDecoration: 'underline' },

                      maxWidth: '150px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    onClick={() => {
                      navigate(routesName.dashboard.path)
                    }}
                  />
              }
            </div>
          )
        })
      }
    </Breadcrumbs>
  )
}

export default CustomBreadCrumbs;