import fileDownload from 'js-file-download';
import { getToken, clearCookie } from "../config/cookiesInfo";
import { getFilenameFromContentDisposition, notifyErrorMessage, } from '../utils/Utils';
import { isAPISuccess, isBusinessUnauthorized, isUnAuthorized, isUrlNotFound, isInternalServerError, isBadRequest } from '../utils/Utils';

function convertStringsToNumbers(obj) {
  // Iterate through each key in the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // If the value is an object, recurse into it
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        convertStringsToNumbers(obj[key]);
      }
      // If the value is a string that represents a number, convert it to a number
      else if (typeof obj[key] === '0' && !isNaN(obj[key])) {
        obj[key] = Number(obj[key]);
      }
    }
  }
  return obj;
}

export async function apiAction({ url, method = 'get', data = null, onError = () => { } }) {

  let options = {
    method: method,
    cache: "no-store",
    body: JSON.stringify(convertStringsToNumbers(data)),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${getToken()}`
    }
  }
  if (method.toLowerCase() === "get") {
    delete options["body"]
  }
  try {
    let response = await fetch(url, options)

    let status = response.status
    if (isAPISuccess(status)) {
      let data = await response.json()
      if (isBusinessUnauthorized(data.status)) {
        clearCookie();
        // notifyErrorMessage(data.status);
        return { success: false, status: data.status };
      } else {
        return data
      }
    } else if (isUnAuthorized(status)) {
      clearCookie();
      // notifyErrorMessage(`Your session has expired`)
      return { success: false, status: 'Something went wrong' };
    } else if (isUrlNotFound(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false, status: 'Something went wrong' };
    } else if (isInternalServerError(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false, status: 'Something went wrong' };
    } else if (isBadRequest(status)) {
      // notifyErrorMessage(`Please check your internet connection`)
      return { success: false, status: 'Something went wrong' };
    }
  } catch (error) {
    console.log('api_Action :', error)
    // window.alert('Failed to fetch')
    // window.confirm("Error \nFailed to fetch");
    onError(error)
  }
}

export async function apiAction_social({ url, method = 'get', data = null, onError = () => { } }) {
  let options = {
    method: method,
    cache: "no-store",
    body: JSON.stringify(convertStringsToNumbers(data)),
    headers: {
      "Content-Type": "application/json",
    }
  }
  if (method.toLowerCase() === "get") {
    delete options["body"]
  }
  try {
    let response = await fetch(url, options)

    let status = response.status
    if (isAPISuccess(status)) {
      let data = await response.json()

      if (isBusinessUnauthorized(data.status)) {
        clearCookie();
        // notifyErrorMessage(data.status);
        return { success: false };

      } else {
        return data
      }
    } else if (isUnAuthorized(status)) {
      clearCookie();
      // notifyErrorMessage(`Your session has expired`)
      return { success: false };
    } else if (isUrlNotFound(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false };
    } else if (isInternalServerError(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false };
    } else if (isBadRequest(status)) {
      // notifyErrorMessage(`Please check your internet connection`)
      return { success: false };
    }
  } catch (error) {
    console.log('api_Action :', error)
    onError(error)
  }
}

export async function apiActionFormData({ url, method = 'post', data }) {

  var formData = new FormData();
  for (var key in convertStringsToNumbers(data)) {
    if (data[key] && data[key].constructor === [].constructor) {
      // eslint-disable-next-line 
      data[key].map(function (id) {
        formData.append(key, id)
        return undefined
      })
    } else {
      formData.append(key, data[key])
    }
  }

  let options = {
    body: formData,
    cache: "no-store",
    method: method,
    headers: {
      "Authorization": `Token ${getToken()}`
    }
  }

  try {
    let response = await fetch(url, options);
    let status = response.status
    if (isAPISuccess(status)) {
      let data = await response.json()

      if (isBusinessUnauthorized(data.status)) {
        clearCookie();
        // notifyErrorMessage(data.status);
        return { success: false };

      } else {
        return data
      }
    } else if (isUnAuthorized(status)) {
      clearCookie();
      // notifyErrorMessage(`Your session has expired`)
      return { success: false, status: data.status };
    } else if (isUrlNotFound(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false, status: 'Something went wrong' };
    } else if (isInternalServerError(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false, status: 'Something went wrong' };
    } else if (isBadRequest(status)) {
      // notifyErrorMessage(`Please check your internet connection`)
      return { success: false, status: 'Something went wrong' };
    }
  } catch (error) {
    console.log("error response", url, error.message);

  }
}

export async function apiFormData({ url, method = 'post', data, file_key = "attachment", file_name = 'signature' }) {

  var formData = new FormData();
  if (data[file_key]) {
    if (data[file_key] && typeof (data[file_key]) !== "string") {
      formData.append(file_key, data[file_key])
    }
  }
  if (data[file_name]) {
    if (data[file_name] && typeof (data[file_name]) !== "string") {
      formData.append(file_name, data[file_name])
    }
  }
  data[file_key] = (typeof (data[file_key]) === "string" && data[file_key]) ? data[file_key] : null
  data[file_name] = (typeof (data[file_name]) === "string" && data[file_name]) ? data[file_name] : null
  formData.append("data", JSON.stringify(convertStringsToNumbers(data)))
  formData.append("business_id", data['business_id'])


  let options = {
    body: formData,
    cache: "no-store",
    method: method,
    headers: {
      "Authorization": `Token ${getToken()}`
    }
  }

  try {
    let response = await fetch(url, options);

    let status = response.status
    if (isAPISuccess(status)) {
      let data = await response.json()

      if (isBusinessUnauthorized(data.status)) {
        clearCookie();
        // notifyErrorMessage(data.status);
        return { success: false, status: 'Unauthorized business access' };

      } else {
        return data
      }
    } else if (isUnAuthorized(status)) {
      clearCookie();
      // notifyErrorMessage(`Your session has expired`)
      return { success: false, status: 'Something went wrong' };
    } else if (isUrlNotFound(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false };
    } else if (isInternalServerError(status)) {
      // notifyErrorMessage(`Something went wrong`)
      return { success: false, status: 'Something went wrong' };
    } else if (isBadRequest(status)) {
      // notifyErrorMessage(`Please check your internet connection`)
      return { success: false, status: 'Something went wrong' };
    }
  } catch (error) {
    console.log("error response", url, error.message);

  }
}

export async function apiBlobResponse({ url, method = 'POST', data, onSuccess }) {
  data['export_type'] = 'pdf';
  data['payment_term_id'] = data.payment_term;
  data['terms_and_conditions_id'] = data.terms_and_conditions;
  data['place_of_supply_id'] = data.place_of_supply ? data.place_of_supply.id : null;
  data['source_of_supply_id'] = data.source_of_supply ? data.source_of_supply.id : null;
  fetch(url, {
    method: method,
    cache: "no-store",
    body: JSON.stringify(convertStringsToNumbers(data)),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${getToken()}`
    }
  })
    .then((response) => response.blob())
    .then((res) => {

      const file = res
      const fileURL = URL.createObjectURL(file);
      onSuccess()
      window.open(fileURL);

    })
    .catch((err) => {
      console.log(err.message);
    });
}


export async function apiHandleDownload({ url, method = 'POST', data, filename, formate = '.pdf', onSuccess }) {
  data['export_type'] = 'pdf';
  data['payment_term_id'] = data.payment_term;
  data['terms_and_conditions_id'] = data.terms_and_conditions;
  data['place_of_supply_id'] = data.place_of_supply ? data.place_of_supply.id : null;

  let options = {
    method: method,
    cache: "no-store",
    body: JSON.stringify(convertStringsToNumbers(data)),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${getToken()}`
    }
  }
  if (method.toLowerCase() === "get") {
    delete options["body"]
  }

  fetch(url, options)
    .then((response) => response.blob())
    .then((res) => {

      const file = res
      fileDownload(file, `${filename}` + formate)
      onSuccess()

    })
    .catch((err) => {
      console.log(err.message);
    });
}


export const apiFetchDocument = async ({ url, data, method = 'POST', exportType = 'pdf' }) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Token ${getToken()}`
    },
    body: JSON.stringify({ export_type: exportType, ...data }),
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }
  // Check the Content-Type of the response
  const contentType = response.headers.get('Content-Type') || '';

  if (contentType.includes('application/json')) {
    const result = await response.json();
    return { ...result };
  }

  if (contentType.includes('application/pdf') || exportType === 'pdf') {
    const contentDisposition = response.headers.get('content-disposition');
    const filename = getFilenameFromContentDisposition(contentDisposition) || 'sampleFile.pdf';
    const blob = await response.blob();
    return { blob, filename, success: true };
  }

  // Handle unexpected content types
  throw new Error(`Unsupported Content-Type: ${contentType}`);
};
