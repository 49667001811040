import React from 'react';
import Sections from './Sections';
import { isEqual } from '../common/Helper';
import ManageSection from './ManageSection';
import { useNavigate } from 'react-router-dom';
import { apiAction } from '../../../../../api/api';
import * as Actions from '../../../../../state/Actions';
import * as SectionTypes from './sectionTypes/SectionTypes';
import { stateChangeManager } from '../../../../../utils/Utils';
import { update_insight_section } from '../../../../../api/urls';
import CustomTypography from '../../../../custom/typography/CustomTypography';

import {
    CustomCard,
    CustomSkeleton,
} from '../common/CustomComponents';

import {
    CommonHeader,
    DeleteComponent,
} from '../common/CommonComponents';

import {
    Box,
    Divider,
} from '@mui/material';

const SectionCard = (props) => {
    const { getTitle, item, to_date, from_date, onDeleteSection, refreshDataPoint } = props

    const navigate = useNavigate();
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [action, setAction] = React.useState('');
    const [openFull, setOpenFull] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);


    React.useEffect(() => {
        setData(item);
    }, [item])

    const handleDataPointDrop = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedDataPoints = Array.from(data.data_point_ids);
        const [draggedItem] = updatedDataPoints.splice(result.source.index, 1);
        updatedDataPoints.splice(result.destination.index, 0, draggedItem);


        const hasChanged = !isEqual(data.data_point_ids, updatedDataPoints);
        console.log('Data point ids have changed:', hasChanged);
        if (hasChanged) {
            onUpdateSection(updatedDataPoints);

        }

    };

    const onUpdateSection = async (data_point_ids) => {
        let res = await apiAction({
            method: 'post',
            navigate: navigate,
            dispatch: dispatch,
            url: update_insight_section(),
            data: { ...data, section_id: data.id, data_point_ids: data_point_ids },

        });
        if (res?.success) {
            setData({ ...res?.result, id: data.id });
            stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', res?.status);
        }
    }

    return (
        <Box sx={{ height: '100%' }}>
            <DeleteComponent
                action={action}

                openDelete={openDelete}
                setOpenDelete={(value) => { setOpenDelete(value); setAction('') }}

                id={data ? data.id : null}
                onDeleteAction={(id) => { onDeleteSection(id); setOpenDelete(false); setAction('') }}
            />
            <ManageSection
                open={open}
                data={data}
                action={action}
                setOpen={setOpen}
                setData={(data) => { setData((previous) => ({ ...previous, ...data })) }}
            />
            <CustomCard sx={{ minHeight: 'calc(100vh - 310px)',position:'relative' }}>
                {data ?
                    <CommonHeader
                        is_add={false}
                        is_sorting={false}
                        title={getTitle(data?.name)}
                        onFullHandler={() => { setOpenFull(true) }}
                        is_full={data?.section_type !== 'list_view'}
                        onEditHandler={() => { setOpen(true); setAction('manage') }}
                        onAddHandler={() => { setOpen(true); setAction('add_data_point') }}
                        onDeleteHandler={() => { setOpenDelete(true); setAction('delete_section') }}
                    />
                    :
                    <Box sx={{ height: 35, p: 1, pl: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                        <CustomSkeleton variant="text" sx={{ mr: 2, height: 40, width: '100%' }} />
                        <Box sx={{ display: 'flex' }}>
                            <CustomSkeleton variant="circular" sx={{ ml: 1 }} width={30} height={30} />
                            <CustomSkeleton variant="circular" sx={{ ml: 1 }} width={30} height={30} />
                            <CustomSkeleton variant="circular" sx={{ ml: 1 }} width={30} height={30} />
                        </Box>
                    </Box>
                }
                <Divider
                    orientation='horizontal'
                    sx={{
                        height: '1px',
                        border: 'none',
                        bgcolor: "#F5F5F5"
                    }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    {Boolean(data?.data_point_ids?.length) ?
                        <Box sx={{ flex: 1, overflowY: 'scroll', maxHeight: 'calc(100vh - 370px)' }}>
                            <SectionType
                                mb={0}
                                data={data}
                                setData={setData}
                                to_date={to_date}
                                openFull={openFull}
                                from_date={from_date}
                                setOpenFull={setOpenFull}
                                type={data?.section_type}
                                refreshDataPoint={refreshDataPoint}
                                handleDataPointDrop={handleDataPointDrop}
                            />
                        </Box>
                        :
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            height='calc(100vh - 330px)'
                            onClick={() => { setOpen(true); setAction('add_data_point') }}
                            sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#e8f3ff' } }}
                        >
                            <CustomTypography
                                text={'+ Add Data Point'}
                                sx={{
                                    color: '#2464EB',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    fontWeight: '700',
                                    textAlign: 'center',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    fontFamily: 'Noto Sans',

                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                                onClick={() => { setOpen(true); setAction('add_data_point') }}
                            />
                        </Box>
                    }
                    {Boolean(data?.data_point_ids?.length) && data?.section_type !== 'pie_chart' &&
                        <CustomTypography
                            text={'+ Add Data Point'}
                            sx={{
                                pt: 1,
                                textAlign: 'center',

                                color: '#2464EB',
                                fontSize: '12px',
                                cursor: 'pointer',
                                fontWeight: '700',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                fontFamily: 'Noto Sans',

                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                            onClick={() => { setOpen(true); setAction('add_data_point') }}
                        />
                    }
                </Box>

            </CustomCard>
        </Box>
    )
}

export default SectionCard


const SectionType = (props) => {
    const { type, data, setData, to_date, from_date, refreshDataPoint, handleDataPointDrop, openFull, setOpenFull } = props;
    return (
        type === 'list_view' ?
            <SectionTypes.List
                mb={0}
                data={data}
                setData={setData}
                to_date={to_date}
                from_date={from_date}
                refreshDataPoint={refreshDataPoint}
                handleDataPointDrop={handleDataPointDrop}
            />
            : type === 'pie_chart' ?
                <SectionTypes.PieChart
                    mb={0}
                    data={data}
                    setData={setData}
                    to_date={to_date}
                    from_date={from_date}
                    openFull={openFull}
                    setOpenFull={setOpenFull}
                    refreshDataPoint={refreshDataPoint}
                    handleDataPointDrop={handleDataPointDrop}
                />
                : type === 'line_chart' ?
                    <SectionTypes.LineChart
                        mb={0}
                        data={data}
                        setData={setData}
                        to_date={to_date}
                        from_date={from_date}
                        openFull={openFull}
                        setOpenFull={setOpenFull}
                        refreshDataPoint={refreshDataPoint}
                        handleDataPointDrop={handleDataPointDrop}
                    />
                    : type === 'scatter_chart' ?
                        <SectionTypes.ScatterChart
                            mb={0}
                            data={data}
                            setData={setData}
                            to_date={to_date}
                            from_date={from_date}
                            openFull={openFull}
                            setOpenFull={setOpenFull}
                            refreshDataPoint={refreshDataPoint}
                            handleDataPointDrop={handleDataPointDrop}
                        />
                        : type === 'bar_graph' ?
                            <SectionTypes.BarChart
                                mb={0}
                                data={data}
                                setData={setData}
                                to_date={to_date}
                                from_date={from_date}
                                openFull={openFull}
                                setOpenFull={setOpenFull}
                                refreshDataPoint={refreshDataPoint}
                                handleDataPointDrop={handleDataPointDrop}
                            />
                            : ''
    )
}
