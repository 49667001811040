import React from "react"
import moment from "moment"
import Status from "../../../common/Status"
import { getDateFormat } from "../../../../utils/Utils"
import CurrencyFormatter from "../../../common/CurrencyFormatter"
import { HeadingWithSortable } from "../../../common/SortableHeading"
import { ReceivedInvoiceViewLink } from "../../invoicing/common/CommonLinks"
import CurrencyFormatterWithExchangeRate from "../../invoicing/common/CurrencyFormatterWithExchangeRate"

export const receivedInvoiceColumns = ({ filters, setFilters }) => [
    {
        align: 'center',
        label: 'Status',
        style: { width: 100 },
        field: 'has_billed',
        onRender: (item) => (
            <div>
                <Status status={item.has_billed ? "closed" : "unbilled"} />
            </div>
        ),
    },
    {
        align: 'left',
        style: { width: 100 },
        field: 'invoice_date',
        label: <HeadingWithSortable heading={'Date'} sortableKey={'invoice_date'} filters={filters} setFilters={setFilters} />,
        onRender: (item) => (
            <div>
                <span style={{ whiteSpace: 'nowrap' }}>{moment(item.invoice_date).format(getDateFormat())}</span>
            </div>
        ),
    },
    {
        align: 'center',
        style: { width: 100 },
        field: 'payment_status',
        label: <HeadingWithSortable heading={'Payment Status'} sortableKey={'payment_status'} filters={filters} setFilters={setFilters} />,
        onRender: (item) => (
            <div>
                <Status status={item.payment_status} />
            </div>
        ),
    },
    {
        align: 'left',
        style: { width: 120 },
        field: 'invoice_number',
        label: <HeadingWithSortable heading={'Number'} sortableKey={'invoice_number'} filters={filters} setFilters={setFilters} />,
        onRender: (item) => (
            <div>
                <ReceivedInvoiceViewLink title={item.invoice_number} id={item.id} />
            </div>
        ),
    },
    {
        align: 'left',
        style: { width: 210 },
        field: 'sender_business_legal_name',
        label: <HeadingWithSortable heading={'Name'} sortableKey={'sender_business_legal_name'} filters={filters} setFilters={setFilters} />,
        onRender: (item) => (
            <div>
                {item.sender_business_legal_name}
            </div>
        ),
    },
    {
        align: 'right',
        style: { width: 150 },
        field: 'total_amount',
        label: <HeadingWithSortable heading={'Total'} sortableKey={'total_amount'} filters={filters} setFilters={setFilters} />,
        onRender: (item) => (
            <div>
                <CurrencyFormatter amount={item.total_amount} currency={item.currency_code} /><br />
                <CurrencyFormatterWithExchangeRate currency_code={item.currency_code} amount={item.total_amount} exchange_rate={item.exchange_rate} />
            </div>
        ),
    },
]