import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export const MaterialUISwitch = styled((props)=><Switch {...props}/>)(({ theme,...props }) => ({
    width: 40,
    padding: 0,
    height: 21.8,
    borderRadius: 4,
    border:'1px solid #bbbcbd',
    "& .MuiSwitch-switchBase": {
        margin: 1,
        padding:0,
        transform: "translateX(0%)",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(calc(100% - 4px))",
            "& .MuiSwitch-thumb:before": {
                height: "100%",
                width: "100%",
                display: "flex",
                content: "'%'",
                boxShadow:'none',
                alignItems: "center",
                justifyContent: "center",
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: '#f1f3f4',
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: 20,
        height: 18,
        borderRadius: 4,
        boxShadow:'none',
        backgroundColor: '#2464eb',
        "&::before": {
            height: "100%",
            width: "100%",
            display: "flex",
            boxShadow:'none',
            alignItems: "center",
            content: `"${props?.r}"`,
            justifyContent: "center",
        },
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        borderRadius: 4,
        backgroundColor: '#f1f3f4',
    },
}));

export const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#2550AA',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));