import React from "react";
import moment from "moment";
import { Grid } from "@mui/material";
import DatePicker from "../../common/DatePicker";

export default function CustomDateRangePicker({ onDateRangeChange, disabledCloseIcon = false, dates }) {
    const [endDate, setEndDate] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);

    const [endDateOpen, setEndDateOpen] = React.useState(false);
    const [startDateOpen, setStartDateOpen] = React.useState(false);

    const wrapperRef = React.useRef(null);

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setStartDateOpen(false);

        if (date && endDate && date > endDate) {
            setEndDate(null);
            setEndDateOpen(true);
        } else if (date && endDate) {
            onDateRangeChange([date, endDate]);
        } else if (!endDate && date) {
            setEndDateOpen(true);
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setEndDateOpen(false);
        if (date && startDate && date < startDate) {
            setStartDate(null);
            setStartDateOpen(true);
        } else if (date && startDate) {
            onDateRangeChange([startDate, date]);
        } else if (!startDate && date) {
            setStartDateOpen(true);
        }
    };

    const resetDateRange = () => {
        setStartDate(null);
        setEndDate(null);
        setStartDateOpen(false);
        setEndDateOpen(false);
        onDateRangeChange(null);
    };

    React.useEffect(() => {
        if (dates) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        } else {
            resetDateRange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates]);

    React.useEffect(() => {
        if (!startDate && !endDate) {
            resetDateRange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            const inputElement = event.target.closest(".MuiInputBase-root");

            if (inputElement) {
                // Close both date pickers
                setStartDateOpen(false);
                setEndDateOpen(false);

                // Focus on the clicked input element
                inputElement.querySelector("input")?.focus();
            } else if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                !event.target.closest(".MuiCalendarPicker-root")
            ) {
                // Close date pickers when clicking outside
                setStartDateOpen(false);
                setEndDateOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    return (
        <div ref={wrapperRef}>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={5.5}>
                    <DatePicker
                        id="start_date_select"
                        dataTestId="start_date_select"
                        title="Start Date"
                        placeholder="From"
                        open={startDateOpen}

                        setDate={handleStartDateChange}
                        disabledCloseIcon={disabledCloseIcon}
                        maxDate={endDate ? moment(endDate) : null}
                        date={startDate ? moment(startDate) : null}
                        onClose={() => startDate && setStartDateOpen(false)}

                        onOpen={() => {
                            setEndDateOpen(false);
                            setStartDateOpen(true);
                        }}

                    />
                </Grid>
                <Grid item xs={6} sm={5.5}>
                    <DatePicker
                        id="end_date_select"
                        dataTestId="end_date_select"
                        title="End Date"
                        placeholder="To"
                        open={endDateOpen}

                        setDate={handleEndDateChange}
                        disabledCloseIcon={disabledCloseIcon}
                        date={endDate ? moment(endDate) : null}
                        minDate={startDate ? moment(startDate) : null}
                        onClose={() => endDate && setEndDateOpen(false)}

                        onOpen={() => {
                            setStartDateOpen(false);
                            setEndDateOpen(true);
                        }}

                    />
                </Grid>
            </Grid>
        </div>
    );
}
