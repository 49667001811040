import React from 'react';
import validator from 'validator';
import { PdfViewer } from './PdfViewer';
import { apiAction } from '../../api/api';
import fileDownload from 'js-file-download';
import * as Actions from '../../state/Actions';
import Input from '../../components/common/Input';
import { routesName } from '../../config/routesName';
import DownloadIcon from '@mui/icons-material/Download';
import { AppLogo } from '../../components/common/AppLogo';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomButton from '../../components/custom/button/CustomButton';
import CustomTypography from '../../components/custom/typography/CustomTypography';
import { Resend } from '../../components/pages/userManagement/onBoarding/VerifyOtp';
import * as Custom from '../../components/pages/landing/marketingComponents/Custom/Custom';
import { PasswordInput } from '../../components/pages/userManagement/onBoarding/SigninForm';
import { BootstrapDialog } from '../../components/pages/bulkStatementUpload/mappings/Transactions';

import {
    setPreviewInfo,
    getPreviewInfo,
    isAuthenticated,
    removePreviewInfo,
    setSessionStorage,
    removeSessionStorage,
} from '../../config/cookiesInfo';

import {
    send_otp,
    verify_otp,
    view_sms_document,
    check_user_existence,
    check_approve_action_for_document_link,
} from '../../api/urls';

import {
    getQueryParams,
    stateChangeManager,
    isValidPhoneNumber,
    getFilenameFromContentDisposition
} from '../../utils/Utils';

import {
    Fab,
    Box,
    AppBar,
    Toolbar,
    Divider,
    IconButton,
    DialogContent,
    InputAdornment
} from '@mui/material';

import { Edit } from '@mui/icons-material';


const fetchDocument = async ({
    link_uid,
    data = {},
    exportType,
    method = 'post',
    url = view_sms_document(),
}) => {
    let options = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ link_uid, export_type: exportType, ...data }),
    }
    if (method.toLowerCase() === "get") {
        delete options["body"]
    }
    const response = await fetch(url, { ...options });

    if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    // Check the Content-Type of the response
    const contentType = response.headers.get('Content-Type') || '';

    if (contentType.includes('application/json')) {
        const result = await response.json();
        return { ...result };
    }

    if (contentType.includes('application/pdf') || exportType === 'pdf') {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = getFilenameFromContentDisposition(contentDisposition) || 'sampleFile.pdf';
        const blob = await response.blob();
        return { blob, filename, success: true };
    }

    // Handle unexpected content types
    throw new Error(`Unsupported Content-Type: ${contentType}`);
};

const PreviewSMS = () => {
    const location = useLocation();
    const previewInfo = getPreviewInfo();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [data, setData] = React.useState({});
    const [modalOpen, setModalOpen] = React.useState(false);
    const [pdfBlobUrl, setPdfBlobUrl] = React.useState(null);

    React.useEffect(() => {
        if (params.t) {
            if (previewInfo && previewInfo?.link_uid === params.t) {
                fetchDocumentData(previewInfo);
            } else {
                removePreviewInfo();
                fetchDocumentData();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.t]);

    const fetchDocumentData = (data = {}) => {
        setData(data);
        removeSessionStorage('receivedInvoice');
        fetchDocument({
            method: 'post',
            exportType: 'pdf',
            link_uid: params.t,
            url: view_sms_document(),
            data: { ...data, for_download: false, },
        })
            .then(handleDocumentResponse)
            .catch(handleDocumentError);
    };

    const handleDocumentResponse = (response) => {
        if (response?.success) {
            setModalOpen(false);
            const pdfUrl = URL.createObjectURL(response.blob);
            setPdfBlobUrl(pdfUrl);
        } else {
            handleDocumentError(response);
        }
    };

    const handleDocumentError = (response) => {
        if (Object.keys(data).length) {
            removePreviewInfo();
            stateChangeManager(dispatch, Actions, true, 'error', response.status);
        }
        setModalOpen(response?.secure_by);
        setData((previous) => ({ ...previous, secure_by: response?.secure_by }))
    };

    const handleDownload = async () => {
        if (params.t) {
            try {
                const pdfDocument = await fetchDocument(
                    {
                        method: 'post',
                        exportType: 'pdf',
                        link_uid: params.t,
                        url: view_sms_document(),
                        data: { ...data, for_download: true, },
                    }
                );
                if (pdfDocument.success) {
                    fileDownload(pdfDocument.blob, pdfDocument.filename);
                }
            } catch (error) {
                console.error('Error downloading PDF:', error);
            }
        }
    };

    const handleSave = async (data) => {
        setPreviewInfo({ ...data, link_uid: params.t, secure_by: modalOpen });
        await fetchDocumentData(data);
    };

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative', background: '#fafafa' }}>
            <ModalToViewDocument open={modalOpen} onSave={handleSave} />
            {!modalOpen && pdfBlobUrl && (
                <Custom.Container sx={{}}>
                    <Header handleDownload={handleDownload} data={data} />
                    <Box sx={{ m: 2, mt: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            maxWidth: '960px',
                        }}>
                            <PdfViewer pdfFile={pdfBlobUrl} />
                        </div>
                    </Box>
                </Custom.Container>
            )}
        </Box>
    );
};

export default PreviewSMS;

const ModalToViewDocument = ({ open, onSave }) => {
    return (
        <BootstrapDialog fullWidth open={open} maxWidth="sm">
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box component="form" sx={{ pt: 3, pb: 4, flexGrow: 1, maxWidth: { xs: '100%', sm: 400 } }}>
                    <CustomTypography
                        text="View your digital document"
                        sx={{ mb: 4, lineHeight: '36px', fontSize: '30px', fontWeight: 700, color: '#000', textAlign: 'center' }}
                    />
                    {open === 'otp' && <OtpValidation onSave={onSave} />}
                    {open === 'password' && <PasswordValidation onSave={onSave} />}
                    {open === 'input_validation' && <MobileNumberValidation onSave={onSave} />}
                </Box>
            </DialogContent>
        </BootstrapDialog>
    );
};

const MobileNumberValidation = ({ onSave }) => {
    const [disabled, setDisabled] = React.useState(true);
    const [inputValue, setInputValue] = React.useState('');

    const validateData = (input) => {
        setDisabled(true);
        let validationResult;
        if (validator.isEmail(input)) {
            validationResult = { email: input };
        } else if (isValidPhoneNumber(input)) {
            validationResult = { mobile_number: input };
        }
        if (validationResult) setDisabled(false);
        return validationResult;
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Input
                autoFocus
                id="input_mobile_number_or_email"
                dataTestId="input_mobile_number_or_email"
                item={{
                    type: 'text',
                    required: true,
                    fullWidth: true,
                    value: inputValue,
                    title: 'Email/Mobile Number',
                    placeholder: 'Enter Your Email Address or Mobile number',
                    onChange: (e) => {
                        validateData(e.target.value);
                        setInputValue(e.target.value);
                    },
                }}
            />
            <CustomButton
                fullWidth
                type="submit"
                btnLabel="Next"
                variant="contained"
                disabled={disabled}
                sx={{ mt: 4, mb: 3 }}
                onClick={() => onSave(validateData(inputValue))}
            />
        </Box>
    );
};

const PasswordValidation = ({ onSave }) => {
    const [password, setPassword] = React.useState('');
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    return (
        <Box sx={{ mt: 2 }}>
            <PasswordInput
                label="Password"
                password={password}
                isPasswordVisible={isPasswordVisible}
                handleInputChange={(e) => setPassword(e.target.value)}
                togglePasswordVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            <CustomButton
                fullWidth
                type="submit"
                btnLabel="Next"
                variant="contained"
                disabled={!password}
                sx={{ mt: 4, mb: 3 }}
                onClick={() => onSave({ password })}
            />
        </Box>
    );
};

const OtpValidation = ({ onSave }) => {
    const location = useLocation();
    const params = getQueryParams(location.search);
    const dispatch = Actions.getDispatch(React.useContext);

    const [type, setType] = React.useState(true);
    const [disabled, setDisabled] = React.useState(true);
    const [isResend, setIsResend] = React.useState(false);
    const [nextStep, setNextStep] = React.useState(false);
    const [sessionID, setSessionID] = React.useState(null);
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const [otp, setOtp] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');

    const validateData = (input) => {
        setType(null);
        setDisabled(true);
        let validationResult;
        if (validator.isEmail(input)) {
            setType('email');
            validationResult = { email: input };
        } else if (isValidPhoneNumber(input)) {
            setType('mobile_number');
            validationResult = { mobile_number: input };
        }
        if (validationResult) setDisabled(false);
        return validationResult;
    };

    const handleResendOtp = async () => {
        const validationResult = validateData(inputValue);
        if (validationResult) {
            const response = await apiAction({
                method: 'post',
                url: send_otp(),
                data: { link_uid: params.t, otp_for: 'view_template', ...validationResult },
            });
            if (response.success) {
                setNextStep(true);
                setIsResend(!isResend);
                setSessionID(response.session_id);
                stateChangeManager(dispatch, Actions, true, 'success', response.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', response.status);
            }
        }
    };

    const verifyOTP = async ({ otp, type }) => {
        const validationResult = validateData(inputValue);

        if (otp) {
            const res = await apiAction({
                method: "POST",
                url: verify_otp(),
                data: {
                    otp: otp,
                    otp_through: type,
                    session_id: sessionID,


                },
            });
            if (res?.success) {
                onSave({ session_id: sessionID, ...validationResult, })
                stateChangeManager(dispatch, Actions, true, 'success', res?.status);
            } else {
                stateChangeManager(dispatch, Actions, true, 'error', `${res?.status} for ${type === 'email' ? 'Email' : 'Mobile Number '}`);
            }
        } else {
            stateChangeManager(dispatch, Actions, true, 'error', `Please enter the otp`);
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Input
                autoFocus
                id="input_mobile_number_or_email"
                dataTestId="input_mobile_number_or_email"
                item={{
                    type: 'text',
                    required: true,
                    fullWidth: true,
                    disabled: nextStep,
                    value: inputValue,
                    title: 'Email/Mobile Number',
                    placeholder: 'Enter Your Email Address or Mobile number',
                    onChange: (e) => { setInputValue(e.target.value); validateData(e.target.value) },
                }}
                InputProps={{
                    endAdornment: nextStep && (
                        <InputAdornment position="end">
                            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
                            <IconButton
                                edge="end"
                                aria-label="Edit mode"
                                onClick={() => { setNextStep(false); setOtp('') }}
                            >
                                {<Edit fontSize='small' sx={{ color: '#2464EB' }} />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {nextStep && (
                <>
                    <Divider sx={{ mb: 3 }} />
                    <PasswordInput
                        label="OTP"
                        password={otp}
                        isPasswordVisible={isPasswordVisible}
                        handleInputChange={(e) => setOtp(e.target.value)}
                        togglePasswordVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
                    />

                    <Resend
                        resendLabel="Resend"
                        sx={{ mt: 4, mb: 3 }}
                        onClick={handleResendOtp}
                    />
                </>
            )}
            <CustomButton
                fullWidth
                type="submit"
                id="sign_in_btn"
                variant="contained"
                disabled={disabled}
                sx={{ mt: 4, mb: 3 }}
                btnLabel={nextStep ? 'Next' : 'Get OTP'}
                onClick={() => { nextStep ? verifyOTP({ otp, type }) : handleResendOtp() }}
            />
        </Box>
    );
};

const Header = ({ handleDownload, data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = getQueryParams(location.search);
    const [forApprove, setForApprove] = React.useState(false);

    const fetchDocumentData = () => {
        fetchDocument({
            method: 'post',
            url: check_approve_action_for_document_link(),
            data: { link_uid: params.t, },
        })
            .then((response) => {
                if (response?.success) {
                    setForApprove(response?.for_approve)
                }
            })
            .catch((error) => {
                console.error('Error fetching document:', error);
            });
    };

    React.useEffect(() => {
        if (params.t) {
            // fetchDocumentData();
        }
    }, [params.t]);

    return (
        <AppBar sx={{ bgcolor: "#FFFFFF", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' }} elevation={0}>
            <Toolbar disableGutters sx={{ pl: 1, pr: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <Box sx={{ display: 'flex' }} onClick={() => { navigate(routesName.landingPage.path); }}>
                    <CustomLogo />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                        <Fab size="small" aria-label="download" onClick={handleDownload}>
                            <DownloadIcon fontSize="small" />
                        </Fab>
                    </Box>
                    {/* {forApprove && (
                        <Box sx={{ ml: 2, }}>
                            <ApproveBtn data={data} />
                        </Box>
                    )} */}
                </Box>
            </Toolbar>
        </AppBar>
    )
}

const CustomLogo = () => {

    return (
        <Box style={{ cursor: 'pointer' }} onClick={() => { }}>
            <AppLogo />
        </Box>
    )
}

const ApproveBtn = ({ data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const previewInfo = getPreviewInfo();
    const params = getQueryParams(location.search);
    let receivedInvoiceData = { ...previewInfo, is_Authenticated: isAuthenticated(), link_uid: params.t };

    const navigateToLogin = () => { navigate("/auth" + routesName.signIn.path, { state: {} }) }
    const navigateToSignup = () => { navigate("/auth" + routesName.signUp.path, { state: {} }) }

    const fetchDocumentData = () => {
        fetchDocument({
            method: 'post',
            url: check_user_existence(),
            data: { ...data },
        })
            .then((response) => {
                if (response?.success) {
                    if (response?.is_exists) {
                        navigateToLogin();
                    } else {
                        navigateToSignup();
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching document:', error);
            });
    };

    return (
        <CustomButton
            fullWidth
            btnLabel="Approve"
            variant="contained"
            onClick={() => {
                setSessionStorage('receivedInvoice', { ...receivedInvoiceData })

                previewInfo && previewInfo.secure_by !== 'password' ?
                    fetchDocumentData()
                    :
                    navigateToLogin();

            }}
        />
    );
};


