import React from 'react';
import validator from 'validator';
import SocialLogin from '../SocialLogin';
import { AgreeTerms } from './SigninForm';
import Input from '../../../common/Input';
import CustomButton from '../../../custom/button/CustomButton';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { LabelWithAsteriskMark } from '../../../common/CommonLabel';
import CustomTypography from '../../../custom/typography/CustomTypography';

import { apiAction } from '../../../../api/api';
import { send_otp } from '../../../../api/urls';
import * as Actions from '../../../../state/Actions';
import { routesName } from '../../../../config/routesName';
import { eventsNames, AnalyticsEvent } from '../../../../firebase/firebaseAnalytics';
import { AuthPagesLeftSideUI } from '../../home/insight/common/CommonComponents';

import {
    setLoader,
    isFormValid,
    isValidPhoneNumber,
    stateChangeManager,
} from '../../../../utils/Utils';

import {
    Box,
    Grid,
    Divider,
} from '@mui/material';
import { getSessionStorage } from '../../../../config/cookiesInfo';


const SignupForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = Actions.getDispatch(React.useContext);
    const sessionStorage = getSessionStorage('receivedInvoice');

    const sign_up_initialData = {
        otp_for: 'sign_up',
        email: sessionStorage?.email || location.state?.email || '',
        mobile_number: sessionStorage?.mobile_number || location.state?.mobile_number || '',
    };

    const [agree, setAgree] = React.useState(true);
    const [data, setData] = React.useState({ ...sign_up_initialData });
    const [isFormSubmitted, setFormSubmitted] = React.useState(false);

    React.useState(() => {
        if (sessionStorage && sessionStorage?.link_uid) {
            setData((previous) => ({
                ...previous,
                link_uid: sessionStorage?.link_uid,
                email: sessionStorage?.email || '',
                mobile_number: sessionStorage?.mobile_number || '',
            }));
        } else if (location.state) {
            setData((previous) => ({
                ...previous,
                email: location.state?.email || '',
                mobile_number: location.state?.mobile_number || '',
            }));
        }

    }, [sessionStorage, location.state]);



    const handleInputChange = (key, value) => {
        setData((prevData) => ({ ...prevData, [key]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormSubmitted(true);

        const validationData = getValidationData();
        const { isValid, message } = isFormValid(data, validationData);

        if (isValid) {
            setLoader(dispatch, Actions, true);
            const res = await submitSignUpData();

            if (res?.success) {
                onSuccess(res);
            } else {
                onFailure(res);
            }
        } else {
            onError(message);
        }
    };

    const getValidationData = () => [
        { key: 'email', message: 'Please enter a email address' },
        {
            key: '',
            validation: !validator.isEmail(data.email),
            message: 'Please enter a valid email address',
        },
        { key: 'mobile_number', message: 'Please enter a mobile number' },
        {
            key: '',
            validation: !isValidPhoneNumber(data.mobile_number),
            message: 'Please enter a valid mobile number',
        },
        {
            key: '',
            validation: !agree,
            message: 'Please accept the terms and conditions',
        },
    ];

    const submitSignUpData = () =>
        apiAction({
            method: 'post',
            url: send_otp(),
            data: { ...data, is_privacy_policy_and_terms_accepted: agree },
        });

    const onSuccess = (res) => {
        setFormSubmitted(false);
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'success', res?.status);
        navigate("/auth" + routesName.verifyOtp.path, {
            state: { ...location.state, ...data, session_id: res?.session_id, },
        });
    };

    const onFailure = (res) => {
        setFormSubmitted(false);
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', res?.status);
    };

    const onError = (message) => {
        setLoader(dispatch, Actions, false);
        stateChangeManager(dispatch, Actions, true, 'error', message);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            height: '100vh',
            alignItems: "center",
        }}>
            <AuthPagesLeftSideUI />

            <Box
                sx={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#fafafa',
                    p: 2
                }}
            >
                <Box className='m_login_container'
                    sx={{
                        p: 4,
                        flexGrow: 1,
                        maxHeight: "676px",
                        maxWidth: "424px",
                        backgroundColor: '#f0f0f0',
                        borderRadius: '12px',
                    }}
                >
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: "center", flexDirection: 'column', }}>

                        <Box noValidate component="form" onSubmit={handleSubmit}>
                            <CustomTypography
                                text="Sign Up"
                                sx={{
                                    mb: 4,
                                    color: '#000000',
                                    fontSize: '20px',
                                    textAlign: 'start',
                                    fontWeight: '700',
                                    lineHeight: '40.86px',
                                    fontFamily: 'Noto Sans',
                                }}
                            />

                            {/* Email Input */}
                            <Box sx={{ mt: 3 }}>
                                <Input
                                    sx={{ backgroundColor: "#f6f6f6" }}
                                    id="input_email_address"
                                    dataTestId="input_email_address"
                                    autoFocus
                                    item={{
                                        type: 'text',
                                        required: true,
                                        fullWidth: true,
                                        value: data.email,
                                        placeholder: 'Enter Your Email Address',
                                        title: <LabelWithAsteriskMark
                                            label="Email Address"
                                            style={{ lineHeight: "24px", fontSize: "13px" }}
                                        />,
                                        onChange: (e) => handleInputChange('email', e.target.value),
                                        validation: isFormSubmitted && !validator.isEmail(data.email),
                                    }}
                                    inputHeight={"27px"}
                                />
                            </Box>

                            {/* Phone Number Input */}
                            <Box sx={{ mt: 3 }}>
                                <Input
                                    sx={{ backgroundColor: "#f6f6f6" }}
                                    id="input_mobile_number"
                                    dataTestId="input_mobile_number"
                                    item={{
                                        type: 'number',
                                        required: true,
                                        fullWidth: true,
                                        value: data.mobile_number,
                                        placeholder: 'Enter Your Mobile Number',
                                        title: <LabelWithAsteriskMark label="Mobile Number" />,
                                        onChange: (e) => handleInputChange('mobile_number', e.target.value),
                                        validation: isFormSubmitted && !isValidPhoneNumber(data.mobile_number),
                                    }}
                                    inputHeight={"27px"}
                                />
                            </Box>

                            {/* Terms and Conditions */}
                            <AgreeTerms agree={agree} setAgree={setAgree} />

                            {/* Sign Up Button */}
                            <CustomButton
                                fullWidth
                                type="submit"
                                id="next_btn"
                                btnLabel="Next"
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, mb: 3, }}
                                dataTestId="next_btn"
                                disabled={!data.email || !data.mobile_number}
                            />

                            <Divider>
                                <CustomTypography
                                    text={'OR'}
                                    sx={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '18px',
                                        fontStyle: 'normal',
                                        fontFamily: "Noto Sans",
                                        textTransform: 'capitalize',
                                    }}
                                />
                            </Divider>

                            <SocialLogin
                                btnText='SIGN UP WITH GOOGLE'
                                AnalyticsEvent={() => { AnalyticsEvent(eventsNames.categories.USER_AUTHENTICATION, { action: eventsNames.actions.SIGN_UP, method: eventsNames.actions.userAuthentication.method.GOOGLE }); }}
                            />

                            <Grid container spacing={2} sx={{ pt: 3, fontSize: "14px", justifyContent: "center" }}>
                                <Grid item sx={{ textAlign: 'end' }}>
                                    <b style={{ fontSize: 14, paddingRight: "3px" }}>Already have an account?</b>
                                    <Link style={{ color: '#2465EB' }} id='sign_in_link' dataTestId='sign_in_link' state={{ ...location.state }} to={{ pathname: "/auth" + routesName.signIn.path }}> Sign in</Link>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>

    );
};

export default SignupForm;
