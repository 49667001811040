import { HOST, APP_NAME } from "../config/config"
import { getBusinessInfo } from "../config/cookiesInfo"


export const capture_marketing_page_metadata = () => {
    return HOST + APP_NAME + `/capture_marketing_page_metadata/`
}

export const get_webpage_context = (type) => {
    return HOST + APP_NAME + `/get_webpage_context/?page=${type}`
}

//////////////////////////////////////Support ///////////////////////////////////////////////////////
export const category_list = () => {
    return HOST + APP_NAME + `/category_list/?business_id=${getBusinessInfo()?.id}`
}
export const raise_ticket = () => {
    return HOST + APP_NAME + `/raise_ticket/`
}

//////////////////////////////////////OTP based Signup/Signin ///////////////////////////////////////////////////////
export const send_otp = () => {
    return HOST + APP_NAME + `/send_otp/`
}
export const signup_with_otp = () => {
    return HOST + APP_NAME + `/signup_with_otp/`
}

export const verify_otp = () => {
    return HOST + APP_NAME + `/verify_otp/`
}
//////////////////////////////////////Delete Account ///////////////////////////////////////////////////////
export const send_otp_for_account_deletion = () => {
    return HOST + APP_NAME + `/send_otp_for_account_deletion/`
}

export const verify_otp_for_account_deletion = (otp) => {
    return HOST + APP_NAME + `/verify_otp_for_account_deletion/?otp=${otp}`
}

export const get_customer_businesses = () => {
    return HOST + APP_NAME + `/get_customer_businesses/`
}

export const delete_customer_account = () => {
    return HOST + APP_NAME + `/delete_customer_account`
}

////////////////////////////////////// mark as sent///////////////////////////////////////////////////////
export const mark_invoice_sent = (id) => {
    return HOST + APP_NAME + `/mark_invoice_sent/${id}/`
}

export const mark_estimate_sent = (id) => {
    return HOST + APP_NAME + `/mark_estimate_sent/${id}/`
}

export const mark_sales_order_sent = (id) => {
    return HOST + APP_NAME + `/mark_sales_order_sent/${id}/`
}

export const mark_credit_note_sent = (id) => {
    return HOST + APP_NAME + `/mark_credit_note_sent/${id}/`
}

export const mark_purchase_order_sent = (id) => {
    return HOST + APP_NAME + `/mark_purchase_order_sent/${id}/`
}

export const mark_debit_note_sent = (id) => {
    return HOST + APP_NAME + `/mark_debit_note_sent/${id}/`
}

//////////////////////////////////////UserManagement///////////////////////////////////////////////////////
export const book_demo = () => {
    return HOST + APP_NAME + `/book-demo/`
}
export const terms_and_conditions = () => {
    return HOST + APP_NAME + `/terms_and_conditions/`
}
export const privacy_policy = () => {
    return HOST + APP_NAME + `/privacy_policy/`
}
export const userSignIn = () => {
    return HOST + APP_NAME + `/login/`
}
export const get_google_login = () => {
    return HOST + APP_NAME + `/google_login/`
}
export const google_login = () => {
    return HOST + APP_NAME + `/dj-rest-auth/google/`
}
export const google_connect = () => {
    return HOST + APP_NAME + `/dj-rest-auth/google/connect/`
}
export const social_user_check = () => {
    return HOST + APP_NAME + `/social_user_check/`
}
export const userSignUp = () => {
    return HOST + APP_NAME + `/signup/`
}
export const send_verify_registration_mail_view = () => {
    return HOST + APP_NAME + `/send_verify_registration_mail_view/`
}
export const send_forgot_password_mail_view = () => {
    return HOST + APP_NAME + `/send_forgot_password_mail_view/`
}
export const update_password_with_otp = () => {
    return HOST + APP_NAME + `/update_password_with_otp/`
}
export const update_password = () => {
    return HOST + APP_NAME + `/update_password/`
}
export const change_login_password_view = () => {
    return HOST + APP_NAME + `/change_login_password_view/`
}

export const retrieve_user = () => {
    return HOST + APP_NAME + `/retrieve_user/`
}
export const update_user_details = () => {
    return HOST + APP_NAME + `/update_user_details/`
}

export const get_country_related_data = (country_id) => {
    return HOST + APP_NAME + `/get_country_related_data/?country_id=${country_id}`
}


export const get_list_of_business = () => {
    return HOST + APP_NAME + `/get_list_of_business/`
}
export const retrieve_business = (id) => {
    return HOST + APP_NAME + `/retrieve_business/${id}/`
}



export const date_format_list = () => {
    return HOST + APP_NAME + `/date_format_list/?business_id=${getBusinessInfo().id}`
}
export const get_sample_datetime_parsed_result = (datetime_string, datetime_format) => {
    if (datetime_format) {
        return HOST + APP_NAME + `/get_sample_datetime_parsed_result/?datetime_string=${datetime_string}&datetime_format=${datetime_format}`
    } else {
        return HOST + APP_NAME + `/get_sample_datetime_parsed_result/?datetime_string=${datetime_string}`
    }
}

export const retrieve_sales_person = (id) => {
    return HOST + APP_NAME + `/retrieve_sales_person/${id}/`
}

////////////////////////////////////////open_Apis/////////////////////////////////////////////////

export const preview_document = () => {
    return HOST + APP_NAME + `/preview_document/`
}
export const send_document = () => {
    return HOST + APP_NAME + `/send_document/`
}

export const get_list_of_currency = () => {
    return HOST + APP_NAME + `/get_list_of_currency/`
}

export const get_countries = () => {
    return HOST + APP_NAME + `/get_countries/`
}
export const get_industries = () => {
    return HOST + APP_NAME + `/get_industries/`
}
export const business_registration = () => {
    return HOST + APP_NAME + `/business_registration/`
}
export const update_business_details = () => {
    return HOST + APP_NAME + `/update_business_details/`
}

export const get_list_of_state = () => {
    return HOST + APP_NAME + `/get_list_of_state/`
}

export const get_list_of_fiscal_year = (country_id) => {
    return HOST + APP_NAME + `/get_list_of_fiscal_year/?country_id=${country_id}`
}
export const get_list_of_time_zone = (country_id) => {
    return HOST + APP_NAME + `/get_list_of_time_zone/?country_id=${country_id}`
}
export const get_list_of_date_format = (country_id) => {
    return HOST + APP_NAME + `/get_list_of_date_format/?country_id=${country_id}`
}
export const get_history_of_object = (business_id, object_type, object_id) => {
    return HOST + APP_NAME + `/get_history_of_object/?business_id=${business_id}&object_type=${object_type}&object_id=${object_id}`
}

export const get_list_of_tds_tcs_details = (business_id, tax_type, name) => {
    return HOST + APP_NAME + `/get_list_of_tds_tcs_details/?business_id=${business_id}&tax_type=${tax_type}`
}
export const search_list_of_tds_tcs_details = (business_id, tax_type, name) => {
    return HOST + APP_NAME + `/get_list_of_tds_tcs_details/?business_id=${business_id}&tax_type=${tax_type}&name=${name}`
}

///////////////////////////////////User Management//////////////////////////////////////////////////////
export const send_invitation_mail = () => {
    return HOST + APP_NAME + `/send_invitation_mail/`
}
export const user_register_through_invite = () => {
    return HOST + APP_NAME + `/user_register_through_invite/`
}
export const list_users_in_business = () => {
    return HOST + APP_NAME + `/list_users_in_business/?business_id=${getBusinessInfo().id}`
}
export const list_of_user_invite = (is_expired, is_accepted) => {
    return HOST + APP_NAME + `/list_of_user_invite/?business_id=${getBusinessInfo().id}&is_expired=${'False'}&is_accepted=${'False'}`
}

export const resend_user_invitation = () => {
    return HOST + APP_NAME + `/resend_user_invitation/`
}

export const delete_user_invite = (id) => {
    return HOST + APP_NAME + `/delete_user_invite/${id}`
}

///////////////////////////////////Send Document Through SMS//////////////////////////////////////////////////////
export const preview_sms_document = (id) => {
    return HOST + APP_NAME + `/preview_sms_document/`
}

export const send_document_through_sms = (id) => {
    return HOST + APP_NAME + `/send_document_through_sms/`
}

export const view_sms_document = (id) => {
    return HOST + APP_NAME + `/view_sms_document/`
}



///////////////////////////////////Accounting_journalEntry//////////////////////////////////////////////////////


export const get_source_of_journal_entry = (journal_entry_id, source_type) => {
    return HOST + APP_NAME + `/get_source_of_journal_entry/?business_id=${getBusinessInfo().id}&journal_entry_id=${journal_entry_id}&source_type=${source_type}`
}
export const get_list_of_journal_entries = (page) => {
    return HOST + APP_NAME + `/get_list_of_journal_entries/?page=${page}`
}

export const list_journal_entry_for_contra_entry_reference = (page) => {
    return HOST + APP_NAME + `/list_journal_entry_for_contra_entry_reference/?page=${page}`
}

export const export_list_of_journal_entries = () => {
    return HOST + APP_NAME + `/export_list_of_journal_entries/`
}

export const retrieve_journal_entry = (id) => {
    return HOST + APP_NAME + `/retrieve_journal_entry/${id}/`
}
export const create_journal_entry = () => {
    return HOST + APP_NAME + `/create_journal_entry/`
}
export const update_journal_entry = (id) => {
    return HOST + APP_NAME + `/update_journal_entry/${id}/`
}
export const delete_journal_entry = (id) => {
    return HOST + APP_NAME + `/delete_journal_entry/${id}/`
}

/////////////////////////////////////////Chart of Accounts////////////////////////////////////////////////////////
export const get_chart_of_account = () => {
    return HOST + APP_NAME + `/get_chart_of_account/`
}

export const get_chart_of_account_nested = () => {
    return HOST + APP_NAME + `/get_chart_of_account_nested/`
}
export const get_chart_of_account_nested_new = (page) => {
    return HOST + APP_NAME + `/get_chart_of_account_new/?page=${page}`
}
export const get_chart_of_account_nested_nodes = (page) => {
    return HOST + APP_NAME + `/get_chart_of_account_nodes/?page=${page}`
}
export const create_default_chart_of_accounts = () => {
    return HOST + APP_NAME + `/create_default_chart_of_accounts/`
}
export const get_list_of_account = () => {
    return HOST + APP_NAME + `/get_list_of_account/`
}

export const create_custom_account = () => {
    return HOST + APP_NAME + `/create_custom_account/`
}
export const update_account = (id) => {
    return HOST + APP_NAME + `/update_account/${id}/`
}
export const get_list_of_parent_account = (id) => {
    return HOST + APP_NAME + `/get_list_of_parent_account/?business_id=${id}`
}
export const get_list_of_parent_account_new = (page) => {
    return HOST + APP_NAME + `/get_list_of_parent_account_new/?business_id=${getBusinessInfo().id}&page=${page}`
}
export const get_next_parent_account_number = () => {
    return HOST + APP_NAME + `/get_next_parent_account_number/`
}

export const create_custom_parent_account = () => {
    return HOST + APP_NAME + `/create_custom_parent_account/`
}
export const delete_parent_account = (id) => {
    return HOST + APP_NAME + `/delete_parent_account/${id}/`
}
export const retrieve_parent_account = (id) => {
    return HOST + APP_NAME + `/retrieve_parent_account/${id}/`
}
export const update_custom_parent_account = (id) => {
    return HOST + APP_NAME + `/update_custom_parent_account/${id}/`
}
export const create_bulk_custom_account = () => {
    return HOST + APP_NAME + `/create_bulk_custom_account/`
}

/////////////////////////////////////////GSTR Filing////////////////////////////////////////////////////////
export const get_gstr1_report = () => {
    return HOST + APP_NAME + `/get_gstr1_report/`
}
export const get_gstr3b_report = () => {
    return HOST + APP_NAME + `/get_gstr3b_report/`
}

/////////////////////////////////////////Reminder ////////////////////////////////////////////////////////
export const get_reminder_channels = () => {
    return HOST + APP_NAME + `/get_reminder_channels/?business_id=${getBusinessInfo()?.id}`
}
export const list_invoice_reminder = (business_id) => {
    return HOST + APP_NAME + `/list_invoice_reminder/?business_id=${business_id}`
}
export const list_bill_reminder = (business_id) => {
    return HOST + APP_NAME + `/list_bill_reminder/?business_id=${business_id}`
}

export const update_invoice_reminder = (id) => {
    return HOST + APP_NAME + `/update_invoice_reminder/${id}/`
}
export const update_bill_reminder = (id) => {
    return HOST + APP_NAME + `/update_bill_reminder/${id}/`
}

export const delete_invoice_reminder = (id) => {
    return HOST + APP_NAME + `/delete_invoice_reminder/${id}/`
}
export const delete_bill_reminder = (id) => {
    return HOST + APP_NAME + `/delete_bill_reminder/${id}/`
}
export const create_invoice_reminder = () => {
    return HOST + APP_NAME + `/create_invoice_reminder/`
}
export const create_bill_reminder = () => {
    return HOST + APP_NAME + `/create_bill_reminder/`
}
/////////////////////////////////////////General Ledger////////////////////////////////////////////////////////
export const getGeneralLedger = () => {
    return HOST + APP_NAME + `/get_list_of_general_ledger/`
}

export const getSingleGeneralLedger = (id, page) => {
    return HOST + APP_NAME + `/get_list_of_general_ledger/${id}/?page=${page}`
}
/////////////////////////////////////////reports////////////////////////////////////////////////////////

export const get_report_map = (report_type) => {
    return HOST + APP_NAME + `/get_report_map/?business_id=${getBusinessInfo().id}&report_type=${report_type}`
}
export const update_report_map = (report_type) => {
    return HOST + APP_NAME + `/update_report_map/`
}

export const schedule_report_periodic_task = () => {
    return HOST + APP_NAME + `/schedule_report_periodic_task/`
}

export const get_schedule_report_periodic_task = (report_name) => {
    return HOST + APP_NAME + `/get_schedule_report_periodic_task/?business_id=${getBusinessInfo().id}&report_name=${report_name}`
}



export const get_ratio_details = (ratio_type, period_range) => {
    return HOST + APP_NAME + `/get_ratio_details/?business_id=${getBusinessInfo().id}&ratio=${ratio_type}&period_range=${period_range}`
}
export const get_ratio_summary = (ratio_type, period_range) => {
    return HOST + APP_NAME + `/get_ratio_summary/?business_id=${getBusinessInfo().id}&ratio=${ratio_type}&period_range=${period_range}`
}

export const get_Profit_loss = () => {
    return HOST + APP_NAME + `/get_profit_and_loss_statement/`
}
export const expense_details_report = (page) => {
    return HOST + APP_NAME + `/expense_details_report/?page=${page}`
}
export const export_profit_and_loss = () => {
    return HOST + APP_NAME + `/export_profit_and_loss/`
}
export const get_profit_and_loss_horizontal_statement = () => {
    return HOST + APP_NAME + `/get_profit_and_loss_horizontal_statement/`
}
export const export_horizontal_profit_and_loss = () => {
    return HOST + APP_NAME + `/export_horizontal_profit_and_loss/`
}

export const get_balance_sheet_statement = () => {
    return HOST + APP_NAME + `/get_balance_sheet_statement/`
}
export const get_balance_sheet_horizontal_statement = () => {
    return HOST + APP_NAME + `/get_balance_sheet_horizontal_statement/`
}
export const export_horizontal_balance_sheet_statement = () => {
    return HOST + APP_NAME + `/export_horizontal_balance_sheet_statement/`
}

export const export_balance_sheet = () => {
    return HOST + APP_NAME + `/export_balance_sheet/`
}

export const get_cash_flow = () => {
    return HOST + APP_NAME + `/get_cash_flow_statement/`
}
export const export_cash_flow = () => {
    return HOST + APP_NAME + `/export_cash_flow/`
}

export const export_movement_of_equity = () => {
    return HOST + APP_NAME + `/export_movement_of_equity/`
}

export const get_trial_balance = () => {
    return HOST + APP_NAME + `/get_trial_balance/`
}
export const export_trial_balance = () => {
    return HOST + APP_NAME + `/export_trial_balance/`
}

export const sales_by_customer_report = (page) => {
    return HOST + APP_NAME + `/sales_by_customer_report/?page=${page}`
}
export const sales_by_item = (page) => {
    return HOST + APP_NAME + `/sales_by_item/?page=${page}`
}
export const sales_by_sales_person_report = (page) => {
    return HOST + APP_NAME + `/sales_by_sales_person_report/?page=${page}`
}
export const income_by_customers_report = (page) => {
    return HOST + APP_NAME + `/income_by_customers_report/?page=${page}`
}
export const invoice_details_report = (page) => {
    return HOST + APP_NAME + `/invoice_details_report/?page=${page}`
}
export const sales_order_details_report = (page) => {
    return HOST + APP_NAME + `/sales_order_details_report/?page=${page}`
}
export const recurring_invoice_details_report = (page) => {
    return HOST + APP_NAME + `/recurring_invoice_details_report/?page=${page}`
}
export const estimate_details_report = (page) => {
    return HOST + APP_NAME + `/estimate_details_report/?page=${page}`
}
export const delivery_challan_details_report = (page) => {
    return HOST + APP_NAME + `/delivery_challan_details_report/?page=${page}`
}

export const activity_logs_and_audit_trail = (page) => {
    return HOST + APP_NAME + `/activity_logs_and_audit_trail/?page=${page}`
}
export const bill_details_report = (page) => {
    return HOST + APP_NAME + `/bill_details_report/?page=${page}`
}
export const purchase_order_details_report = (page) => {
    return HOST + APP_NAME + `/purchase_order_details_report/?page=${page}`
}
export const purchases_by_vendors_report = (page) => {
    return HOST + APP_NAME + `/purchases_by_vendors_report/?page=${page}`
}
export const purchases_by_item = (page) => {
    return HOST + APP_NAME + `/purchases_by_item/?page=${page}`
}

export const tax_summary = (page) => {
    return HOST + APP_NAME + `/tax_summary/?page=${page}`
}

export const get_account_transactions_report = (page) => {
    return HOST + APP_NAME + `/get_account_transactions_report/?page=${page}`
}
export const dashboard_receivable_payable_aging_report = () => {
    return HOST + APP_NAME + `/dashboard_receivable_payable_aging_report/`
}
export const customer_vendor_balances_report = (page) => {
    return HOST + APP_NAME + `/customer_vendor_balances_report/?page=${page}`
}
export const time_to_get_paid_report = (page) => {
    return HOST + APP_NAME + `/time_to_get_paid_report/?page=${page}`
}


export const account_receivable_summary_report_file = () => {
    return HOST + APP_NAME + `/account_receivable_summary_report_file/`
}
export const account_receivable_summary_report = () => {
    return HOST + APP_NAME + `/account_receivable_summary_report/`
}

export const account_payable_summary_report_file = () => {
    return HOST + APP_NAME + `/account_payable_summary_report_file/`
}
export const account_payable_summary_report = () => {
    return HOST + APP_NAME + `/account_payable_summary_report/`
}

export const account_receivable_detailed_report = () => {
    return HOST + APP_NAME + `/account_receivable_detailed_report/`
}
export const account_receivable_detail_report_file = () => {
    return HOST + APP_NAME + `/account_receivable_detail_report_file/`
}

export const account_payable_detailed_report = () => {
    return HOST + APP_NAME + `/account_payable_detailed_report/`
}
export const account_payable_detail_report_file = () => {
    return HOST + APP_NAME + `/account_payable_detail_report_file/`
}

export const payments_received_payments_made_report = (page) => {
    return HOST + APP_NAME + `/payments_received_payments_made_report/?page=${page}`
}
export const credit_note_details_report = (page) => {
    return HOST + APP_NAME + `/credit_note_details_report/?page=${page}`
}
export const debit_note_details_report = (page) => {
    return HOST + APP_NAME + `/debit_note_details_report/?page=${page}`
}
export const refund_history_customer_vendor = (page) => {
    return HOST + APP_NAME + `/refund_history_customer_vendor/?page=${page}`
}

export const get_summary_for_tds = (business_id, from_date, to_date, page) => {
    return HOST + APP_NAME + `/get_summary_for_tds/?page=${page}&business_id=${business_id}&from_date=${from_date}&to_date=${to_date}`
}
export const get_details_for_tds = (business_id, from_date, to_date, section_id, page,) => {
    return HOST + APP_NAME + `/get_details_for_tds/?page=${page}&business_id=${business_id}&from_date=${from_date}&to_date=${to_date}&section_id=${section_id}`
}
export const form_27eq_details = (business_id, from_date, to_date, page) => {
    return HOST + APP_NAME + `/form_27eq_details/?page=${page}&business_id=${business_id}&from_date=${from_date}&to_date=${to_date}`
}

export const item_stock_report = (page) => {
    return HOST + APP_NAME + `/item_stock_report/?page=${page}`
}

/////////////////////////////////////////Downloads////////////////////////////////////////////////////////

export const get_list_of_exported_files = (page) => {
    return HOST + APP_NAME + `/get_list_of_exported_files/?page=${page}`
}
export const delete_exported_file = (id) => {
    return HOST + APP_NAME + `/delete_exported_file/${id}/`
}
export const send_downloaded_file = (id) => {
    return HOST + APP_NAME + `/send_downloaded_file/`
}
export const share_downloaded_file = (id) => {
    return HOST + APP_NAME + `/share_downloaded_file/`
}

export const import_party_sample = () => {
    return HOST + APP_NAME + `/import_party_sample/?business_id=${getBusinessInfo().id}`
}

export const import_item_sample = () => {
    return HOST + APP_NAME + `/import_item_sample/?business_id=${getBusinessInfo().id}`
}

export const generate_stock_item_code = () => {
    return HOST + APP_NAME + `/generate_stock_item_code/?business_id=${getBusinessInfo().id}`
}

////////////////////////////////////// Download Data Related API's ////////////////////////////////////////////

export const generate_party_backup_file = () => {
    return HOST + APP_NAME + `/generate_party_backup_file/`
}

export const generate_estimates_backup_file = () => {
    return HOST + APP_NAME + `/generate_estimates_backup_file/`
}

export const generate_sales_order_backup_file = () => {
    return HOST + APP_NAME + `/generate_sales_order_backup_file/`
}

export const generate_delivery_challan_backup_file = () => {
    return HOST + APP_NAME + `/generate_delivery_challan_backup_file/`
}

export const generate_invoice_backup_file = () => {
    return HOST + APP_NAME + `/generate_invoice_backup_file/`
}

export const generate_payment_backup_file = () => {
    return HOST + APP_NAME + `/generate_payment_backup_file/`
}

export const generate_credit_note_backup_file = () => {
    return HOST + APP_NAME + `/generate_credit_note_backup_file/`
}

export const generate_expense_backup_file = () => {
    return HOST + APP_NAME + `/generate_expense_backup_file/`
}

export const generate_purchase_order_backup_file = () => {
    return HOST + APP_NAME + `/generate_purchase_order_backup_file/`
}

export const generate_bill_backup_file = () => {
    return HOST + APP_NAME + `/generate_bill_backup_file/`
}

export const generate_debit_note_backup_file = () => {
    return HOST + APP_NAME + `/generate_debit_note_backup_file/`
}

export const generate_journal_entry_backup_file = () => {
    return HOST + APP_NAME + `/generate_journal_entry_backup_file/`
}

export const generate_chart_of_account_backup_file = () => {
    return HOST + APP_NAME + `/generate_chart_of_account_backup_file/`
}

export const generate_complete_backup_file = () => {
    return HOST + APP_NAME + `/generate_complete_backup_file/`
}

/////////////////////////////////////////Mappings////////////////////////////////////////////////////////

export const create_dynamic_mapping = () => {
    return HOST + APP_NAME + `/create_dynamic_mapping/`
}
export const validate_sourced_transaction = () => {
    return HOST + APP_NAME + `/validate_sourced_transaction/`
}


export const list_dynamic_mapping = () => {
    return HOST + APP_NAME + `/list_dynamic_mapping/`
}

export const get_sourced_transactions_fields = () => {
    return HOST + APP_NAME + '/get_sourced_transactions_fields/'
}

export const get_field_for_opening_balance = () => {
    return HOST + APP_NAME + '/get_field_for_opening_balance/'
}

export const transaction_bulk_upload = () => {
    return HOST + APP_NAME + '/transaction_bulk_upload/'
}

export const update_dynamic_mapping = (id) => {
    return HOST + APP_NAME + `/update_dynamic_mapping/${id}/`
}

export const list_sourced_transactions = (page, page_size = 10) => {
    return HOST + APP_NAME + `/list_sourced_transactions/?page=${page}&page_size=${page_size}`
}

export const get_transaction_detail = () => {
    return HOST + APP_NAME + `/get_transaction_detail/`
}

export const delete_sourced_transaction = (id) => {
    return HOST + APP_NAME + `/delete_sourced_transaction/${id}/`
}

export const assign_journalentry = (id) => {
    return HOST + APP_NAME + `/assign_journalentry_to_sourced_transaction/`
}

export const bulk_create_journal_entry_from_sourced_transaction = () => {
    return HOST + APP_NAME + `/bulk_create_journal_entry_from_sourced_transaction/`
}

export const create_sourced_transaction_preference = () => {
    return HOST + APP_NAME + `/create_sourced_transaction_preference/`
}

export const update_sourced_transaction_preference = (preference_id) => {
    return HOST + APP_NAME + `/update_sourced_transaction_preference/` + preference_id + `/`
}

export const list_sourced_transaction_preference = (preference_id) => {
    return HOST + APP_NAME + `/list_sourced_transaction_preference/?business_id=` + getBusinessInfo().id
}

export const delete_sourced_transaction_preference = (preference_id) => {
    return HOST + APP_NAME + `/delete_sourced_transaction_preference/` + preference_id + `/`
}


////////////////////////////////////////////////////////Invoicing////////////////////////////////////////////////////////////////////

export const list_payment_term = () => {
    return HOST + APP_NAME + `/list_payment_terms/`
}

export const create_sales_person = () => {
    return HOST + APP_NAME + `/create_sales_person/`
}

export const get_list_of_sales_person = () => {
    return HOST + APP_NAME + `/get_list_of_sales_person/`
}


export const list_units = () => {
    return HOST + APP_NAME + `/list_units/`
}

export const create_payment_term = () => {
    return HOST + APP_NAME + `/create_payment_term/`
}

export const get_list_of_tax = () => {
    return HOST + APP_NAME + `/get_list_of_tax/`
}

export const create_tax = () => {
    return HOST + APP_NAME + `/create_tax/`
}

export const get_data_for_manual_reminder = () => {
    return HOST + APP_NAME + `/get_data_for_manual_reminder/`
}

export const send_manual_invoice_reminder = () => {
    return HOST + APP_NAME + `/send_manual_invoice_reminder/`
}

/////////////////////////////////////customer///////////////////////////////////////////////////////

export const mark_party_inactive = (id) => {
    return HOST + APP_NAME + `/mark_party_inactive/${id}/`
}

export const export_party = () => {
    return HOST + APP_NAME + `/export_party/`
}
export const create_party = () => {
    return HOST + APP_NAME + `/create_party/`
}
export const list_party = (page) => {

    return page ? HOST + APP_NAME + `/list_party/?page=${page}` : HOST + APP_NAME + `/list_party/`
}

export const get_party = (id) => {
    return HOST + APP_NAME + `/get_party/${id}/`
}
export const get_customer_sales_overview = (id) => {
    return HOST + APP_NAME + `/get_customer_sales_overview/${id}/`
}

export const update_party = (id) => {
    return HOST + APP_NAME + `/update_party/${id}/`
}
export const update_party_opening_balance = () => {
    return HOST + APP_NAME + `/update_party_opening_balance/`
}

export const delete_party = (id) => {
    return HOST + APP_NAME + `/delete_party/${id}/`
}

export const create_address = () => {
    return HOST + APP_NAME + `/create_address/`
}

export const export_party_statement = () => {
    return HOST + APP_NAME + `/export_party_statement/`
}
export const send_party_statement = () => {
    return HOST + APP_NAME + `/send_party_statement/`
}

export const import_party = () => {
    return HOST + APP_NAME + `/import_party/`
}

export const import_item = () => {
    return HOST + APP_NAME + `/import_item/`
}
export const get_party_transaction_count = (party_id) => {
    return HOST + APP_NAME + `/get_party_transaction_count/?business_id=${getBusinessInfo().id}&party_id=${party_id}`
}



/////////////////////////////////////vendor///////////////////////////////////////////////////////
export const list_vendor = (page) => {
    return HOST + APP_NAME + `/list_party/?page=${page}`
}

export const get_vendor = (id) => {
    return HOST + APP_NAME + `/get_party/${id}/`
}

export const update_vendor = (id) => {
    return HOST + APP_NAME + `/update_party/${id}/`
}

export const create_vendor = () => {
    return HOST + APP_NAME + `/create_party/`
}

export const delete_vendor = (id) => {
    return HOST + APP_NAME + `/delete_party/${id}/`
}

export const get_vendor_purchase_overview = (id) => {
    return HOST + APP_NAME + `/get_vendor_purchase_overview/${id}/`
}


///////////////////////////////////// item ///////////////////////////////////////////////////////
export const create_item = () => {
    return HOST + APP_NAME + `/create_item/`
}
export const export_item = () => {
    return HOST + APP_NAME + `/export_item/`
}
export const update_item = (id) => {
    return HOST + APP_NAME + `/update_item/${id}/`
}

export const get_item = (id) => {
    return HOST + APP_NAME + `/get_item/${id}/`
}

export const list_items = (page) => {
    return HOST + APP_NAME + `/list_items/?page=${page}`
}

export const generate_bulk_barcode = (page) => {
    return HOST + APP_NAME + `/generate_bulk_barcode/`
}

export const get_barcode = (selectedData) => {
    let { item_id, title_line_1, title_line_2, note_line_1, note_line_2, note_line_3 } = selectedData;

    return HOST + APP_NAME + `/get_barcode/?business_id=${getBusinessInfo().id}&item_id=${item_id}&title_line_1=${title_line_1}&title_line_2=${title_line_2}&note_line_1=${note_line_1}&note_line_2=${note_line_2}&note_line_3=${note_line_3}`
}

export const get_item_dropdown_fields = (item_id) => {
    return HOST + APP_NAME + `/get_item_dropdown_fields/?business_id=${getBusinessInfo().id}&item_id=${item_id}`
}

export const delete_item = (id) => {
    return HOST + APP_NAME + `/delete_item/${id}/`
}

export const adjust_item_stock = (id) => {
    return HOST + APP_NAME + `/adjust_item_stock/${id}/`
}

export const get_stock_history = (id, business_id) => {
    return HOST + APP_NAME + `/get_stock_history/${id}/?business_id=${business_id}`
}

export const get_list_of_hsn_sac_code = (business_id, search_text, item_type, codeLength) => {
    return HOST + APP_NAME + `/get_list_of_hsn_sac_code/?business_id=${business_id}&search_text=${search_text}${item_type ? '&item_type=' + item_type : ''}${codeLength ? '&list_of_hsn_sac_code_len=' + codeLength : ''}`
}
export const get_item_stock_info = (item_id, business_id) => {
    return HOST + APP_NAME + `/get_item_stock_info/${item_id}/?business_id=${getBusinessInfo().id}`
}
export const get_item_transaction_count = (item_id) => {
    return HOST + APP_NAME + `/get_item_transaction_count/?business_id=${getBusinessInfo().id}&item_id=${item_id}`
}


////////////////////////////////////////////Invoice////////////////////////////////////////////////


export const get_create_invoice_meta_data = () => {
    return HOST + APP_NAME + `/get_create_invoice_meta_data/`
}

export const export_invoice = () => {
    return HOST + APP_NAME + `/export_invoice/`
}
export const get_list_invoice_statestics = () => {
    return HOST + APP_NAME + `/get_list_invoice_statestics/`
}

export const get_invoice_status_count = () => {
    return HOST + APP_NAME + `/get_invoice_status_count/`
}

export const get_list_of_terms_and_conditions = () => {
    return HOST + APP_NAME + `/get_list_of_terms_and_conditions/`
}

export const create_terms_and_conditions = () => {
    return HOST + APP_NAME + `/create_terms_and_conditions/`
}
export const update_terms_and_conditions = (id) => {
    return HOST + APP_NAME + `/update_terms_and_conditions/${id}/`
}

export const get_list_of_invoice = (page) => {
    return HOST + APP_NAME + `/get_list_of_invoice/?page=${page}`
}

export const create_invoice = () => {
    return HOST + APP_NAME + `/create_invoice/`
}

export const update_invoice = (id) => {
    return HOST + APP_NAME + `/update_invoice/${id}/`
}

export const delete_invoice = (id) => {
    return HOST + APP_NAME + `/delete_invoice/${id}/`
}

export const retrieve_invoice = (id) => {
    return HOST + APP_NAME + `/retrieve_invoice/${id}/`
}

export const send_invoice_mail = () => {
    return HOST + APP_NAME + `/send_invoice_mail/`
}

////////////////////////////////////////////Bills////////////////////////////////////////////////

export const get_list_bill_statestics = () => {
    return HOST + APP_NAME + `/get_list_bill_statestics/`
}

export const get_bill_status_count = () => {
    return HOST + APP_NAME + `/get_bill_status_count/`
}

export const get_create_bill_meta_data = () => {
    return HOST + APP_NAME + `/get_create_bill_meta_data/`
}

export const get_list_of_bill = (page) => {
    return HOST + APP_NAME + `/get_list_of_bill/?page=${page}`
}
export const delete_bill = (id) => {
    return HOST + APP_NAME + `/delete_bill/${id}/`
}

export const create_bill = () => {
    return HOST + APP_NAME + `/create_bill/`
}
export const retrieve_bill = (id) => {
    return HOST + APP_NAME + `/retrieve_bill/${id}/`
}

export const update_bill = (id) => {
    return HOST + APP_NAME + `/update_bill/${id}/`
}

export const export_bill = () => {
    return HOST + APP_NAME + `/export_bill/`
}
////////////////////////////////////////////Sales order////////////////////////////////////////////////
export const get_create_sales_order_meta_data = () => {
    return HOST + APP_NAME + `/get_create_sales_order_meta_data/`
}

export const create_sales_order = () => {
    return HOST + APP_NAME + `/create_sales_order/`
}

export const preview_sales_order = () => {
    return HOST + APP_NAME + `/preview_sales_order/`
}

export const update_sales_order = (id) => {
    return HOST + APP_NAME + `/update_sales_order/${id}/`
}

export const delete_sales_order = (id) => {
    return HOST + APP_NAME + `/delete_sales_order/${id}/`
}

export const get_list_of_sales_order = (page) => {
    return HOST + APP_NAME + `/get_list_of_sales_order/?page=${page}`
}

export const retrieve_sales_order = (id) => {
    return HOST + APP_NAME + `/retrieve_sales_order/${id}/`
}

export const send_sales_order_mail = () => {
    return HOST + APP_NAME + `/send_sales_order_mail/`
}
////////////////////////////////////////////purchase order////////////////////////////////////////////////
export const get_create_purchase_order_meta_data = () => {
    return HOST + APP_NAME + `/get_create_purchase_order_meta_data/`
}

export const create_purchase_order = () => {
    return HOST + APP_NAME + `/create_purchase_order/`
}
export const update_purchase_order = (id) => {
    return HOST + APP_NAME + `/update_purchase_order/${id}/`
}

export const delete_purchase_order = (id) => {
    return HOST + APP_NAME + `/delete_purchase_order/${id}/`
}

export const get_list_of_purchase_order = (page) => {
    return HOST + APP_NAME + `/get_list_of_purchase_order/?page=${page}`
}

export const retrieve_purchase_order = (id) => {
    return HOST + APP_NAME + `/retrieve_purchase_order/${id}/`
}

export const preview_purchase_order = () => {
    return HOST + APP_NAME + `/preview_purchase_order/`
}

export const send_purchase_order_mail = () => {
    return HOST + APP_NAME + `/send_purchase_order_mail/`
}


////////////////////////////////////////////Recurring Invoice////////////////////////////////////////////////
export const create_recurring_invoice = () => {
    return HOST + APP_NAME + `/create_recurring_invoice/`
}

export const get_list_of_recurring_invoice = (page) => {
    return HOST + APP_NAME + `/get_list_of_recurring_invoice/?page=${page}`
}

export const retrieve_recurring_invoice = (id) => {
    return HOST + APP_NAME + `/retrieve_recurring_invoice/${id}/`
}

export const update_recurring_invoice = (id) => {
    return HOST + APP_NAME + `/update_recurring_invoice/${id}/`
}

export const delete_recurring_invoice = (id) => {
    return HOST + APP_NAME + `/delete_recurring_invoice/${id}/`
}
////////////////////////////////////////////Recurring Expense////////////////////////////////////////////////
export const create_recurring_expense = () => {
    return HOST + APP_NAME + `/create_recurring_expense/`
}

export const get_list_of_recurring_expense = (page) => {
    return HOST + APP_NAME + `/get_list_of_recurring_expense/?page=${page}`
}

export const retrieve_recurring_expense = (id) => {
    return HOST + APP_NAME + `/retrieve_recurring_expense/${id}/?business_id=${getBusinessInfo().id}`
}

export const update_recurring_expense = (id) => {
    return HOST + APP_NAME + `/update_recurring_expense/${id}/`
}

export const delete_recurring_expense = (id) => {
    return HOST + APP_NAME + `/delete_recurring_expense/${id}/`
}


////////////////////////////////////////////Payments////////////////////////////////////////////////

export const create_payment = () => {
    return HOST + APP_NAME + `/create_payment/`
}
export const get_list_of_payment = (page) => {
    return HOST + APP_NAME + `/get_list_of_payment/?page=${page}`
}
export const delete_payment = (id) => {
    return HOST + APP_NAME + `/delete_payment/${id}/`
}
export const retrieve_payment = (id) => {
    return HOST + APP_NAME + `/retrieve_payment/${id}/`
}
export const export_payment_receipt = () => {
    return HOST + APP_NAME + `/export_payment_receipt/`
}

export const update_payment = () => {
    return HOST + APP_NAME + `/update_payment/`
}

export const send_payment_receipt_mail = () => {
    return HOST + APP_NAME + `/send_payment_receipt_mail/`
}

////////////////////////////////////////////Dashboard////////////////////////////////////////////////

export const dashboard_total_payable = () => {
    return HOST + APP_NAME + `/dashboard_total_payable/`
}
export const dashboard_total_receivable = () => {
    return HOST + APP_NAME + `/dashboard_total_receivable/`
}

export const dashboard_cash_flow_graph_data = () => {
    return HOST + APP_NAME + `/dashboard_cash_flow_graph_data/`
}
export const dashboard_income_expense_graph_data = () => {
    return HOST + APP_NAME + `/dashboard_income_expense_graph_data/`
}
export const dashboard_top_expense_data = () => {
    return HOST + APP_NAME + `/dashboard_top_expense_data/`
}
export const dashboard_top_expense_data_inner = () => {
    return HOST + APP_NAME + `/dashboard_top_expense_data_inner/`
}
export const dashboard_top_customer_data = () => {
    return HOST + APP_NAME + `/dashboard_top_customer_data/`
}
export const dashboard_top_sales_data = () => {
    return HOST + APP_NAME + `/dashboard_top_sales_data/`
}

export const dashboard_todays_statestics = () => {
    return HOST + APP_NAME + `/dashboard_todays_statestics/`
}

////////////////////////////////////////////Opening Balance Adjustments////////////////////////////////////////////////

export const chart_of_account_for_opening_balance = () => {
    return HOST + APP_NAME + `/chart_of_account_for_opening_balance/`
}
export const manual_opening_balance_adjustment = () => {
    return HOST + APP_NAME + `/manual_opening_balance_adjustment/`
}
export const validate_opening_balance_file_data = () => {
    return HOST + APP_NAME + `/validate_opening_balance_file_data/`
}

export const validate_party_import_file = () => {
    return HOST + APP_NAME + `/validate_party_import_file/`
}

export const validate_file_for_import_item = () => {
    return HOST + APP_NAME + `/validate_file_for_import_item/`
}

export const import_opening_balance = () => {
    return HOST + APP_NAME + `/import_opening_balance/`
}

export const validate_migration_date = () => {
    return HOST + APP_NAME + `/validate_migration_date/`
}

export const validate_migration_date_for_account_creation = () => {
    return HOST + APP_NAME + `/validate_migration_date_for_account_creation/`
}

export const delete_opening_balance = (id) => {
    return HOST + APP_NAME + `/delete_opening_balance/${id}/`
}

////////////////////////////////////////////Estimate////////////////////////////////////////////////


export const get_create_estimate_meta_data = () => {
    return HOST + APP_NAME + `/get_create_estimate_meta_data/`
}
export const get_list_of_estimate = (page) => {
    return HOST + APP_NAME + `/get_list_of_estimate/?page=${page}`
}
export const create_estimate = () => {
    return HOST + APP_NAME + `/create_estimate/`
}
export const retrieve_estimate = (id) => {
    return HOST + APP_NAME + `/retrieve_estimate/${id}/`
}
export const update_estimate = (id) => {
    return HOST + APP_NAME + `/update_estimate/${id}/`
}
export const delete_estimate = (id) => {
    return HOST + APP_NAME + `/delete_estimate/${id}/`
}
export const preview_estimate = () => {
    return HOST + APP_NAME + `/preview_estimate/`
}
export const send_estimate_mail = () => {
    return HOST + APP_NAME + `/send_estimate_mail/`
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const get_fields_for_mapping = (mapping_for) => {
    return HOST + APP_NAME + `/get_fields_for_mapping/?mapping_for=${mapping_for}`
}

////////////////////////////////////////////Credit Notes////////////////////////////////////////////////

export const get_create_credit_note_meta_data = () => {
    return HOST + APP_NAME + `/get_create_credit_note_meta_data/`
}
export const create_credit_note = () => {
    return HOST + APP_NAME + `/create_credit_note/`
}
export const export_credit_note = () => {
    return HOST + APP_NAME + `/export_credit_note/`
}
export const get_list_of_credit_note = (page) => {
    return HOST + APP_NAME + `/get_list_of_credit_note/?page=${page}`
}
export const update_credit_note = (id) => {
    return HOST + APP_NAME + `/update_credit_note/${id}/`
}
export const retrieve_credit_note = (id) => {
    return HOST + APP_NAME + `/retrieve_credit_note/${id}/`
}
export const delete_credit_note = (id) => {
    return HOST + APP_NAME + `/delete_credit_note/${id}/`
}
export const send_credit_note_mail = () => {
    return HOST + APP_NAME + `/send_credit_note_mail/`
}
export const delete_credits_applied_to_invoice = (id) => {
    return HOST + APP_NAME + `/delete_credits_applied_to_invoice/${id}/`
}


////////////////////////////////////////////Debit Notes////////////////////////////////////////////////

export const get_create_debit_note_meta_data = () => {
    return HOST + APP_NAME + `/get_create_debit_note_meta_data/`
}
export const create_debit_note = () => {
    return HOST + APP_NAME + `/create_debit_note/`
}
export const export_debit_note = () => {
    return HOST + APP_NAME + `/export_debit_note/`
}
export const get_list_of_debit_note = (page) => {
    return HOST + APP_NAME + `/get_list_of_debit_note/?page=${page}`
}
export const update_debit_note = (id) => {
    return HOST + APP_NAME + `/update_debit_note/${id}/`
}
export const retrieve_debit_note = (id) => {
    return HOST + APP_NAME + `/retrieve_debit_note/${id}/`
}
export const delete_debit_note = (id) => {
    return HOST + APP_NAME + `/delete_debit_note/${id}/`
}
export const send_debit_note_mail = () => {
    return HOST + APP_NAME + `/send_debit_note_mail/`
}
export const delete_credits_applied_to_bill = (id) => {
    return HOST + APP_NAME + `/delete_credits_applied_to_bill/${id}/`
}
//////////////////////////////////////////////////////Refund Payment//////////////////////////////////////////////////////
export const create_refund_payment = () => {
    return HOST + APP_NAME + `/create_refund_payment/`
}
export const delete_refund_payment = (id) => {
    return HOST + APP_NAME + `/delete_refund_payment/${id}/`
}
export const get_list_of_refund_payment = (page) => {
    return HOST + APP_NAME + `/get_list_of_refund_payment/?page=${page}`
}

//////////////////////////////////////////////////////Credited Invoice//////////////////////////////////////////////////////

export const list_of_credited_invoices = () => {
    return HOST + APP_NAME + `/list_of_credited_invoices/`
}
export const apply_credit_as_invoice_payment = () => {
    return HOST + APP_NAME + `/apply_credit_as_invoice_payment/`
}
export const get_credit_list_of_customer = () => {
    return HOST + APP_NAME + `/get_credit_list_of_customer/`
}


//////////////////////////////////////////////////////Credited Bills//////////////////////////////////////////////////////

export const list_of_credited_bills = () => {
    return HOST + APP_NAME + `/list_of_credited_bills/`
}
export const apply_credit_as_bill_payment = () => {
    return HOST + APP_NAME + `/apply_credit_as_bill_payment/`
}
export const get_credit_list_of_vendor = () => {
    return HOST + APP_NAME + `/get_credit_list_of_vendor/`
}

////////////////////////////////////////////////////// Delivery Challans//////////////////////////////////////////////////////
export const get_list_of_delivery_challan = (page) => {
    return HOST + APP_NAME + `/get_list_of_delivery_challan/?page=${page}`
}
export const get_create_delivery_challan_meta_data = () => {
    return HOST + APP_NAME + `/get_create_delivery_challan_meta_data/`
}
export const create_delivery_challan = () => {
    return HOST + APP_NAME + `/create_delivery_challan/`
}
export const update_delivery_challan = (id) => {
    return HOST + APP_NAME + `/update_delivery_challan/${id}/`
}
export const retrieve_delivery_challan = (id) => {
    return HOST + APP_NAME + `/retrieve_delivery_challan/${id}/`
}
export const delete_delivery_challan = (id) => {
    return HOST + APP_NAME + `/delete_delivery_challan/${id}/`
}
export const export_delivery_challan = () => {
    return HOST + APP_NAME + `/export_delivery_challan/`
}


////////////////////////////////////////////////////// Record Expense//////////////////////////////////////////////////////
export const create_expense = () => {
    return HOST + APP_NAME + `/create_expense/`
}
export const list_expense = (page) => {
    return HOST + APP_NAME + `/list_expense/?page=${page}`
}
export const retrieve_expense = (id) => {
    return HOST + APP_NAME + `/retrieve_expense/${id}/`
}

export const update_expense = (id) => {
    return HOST + APP_NAME + `/update_expense/${id}/`
}

export const delete_expense = (id) => {
    return HOST + APP_NAME + `/delete_expense/${id}/`
}

export const export_expense = () => {
    return HOST + APP_NAME + `/export_expense/`
}

//////////////////////////////////////////////////////////////////////////////// Preferences ////////////////////////////////////////////////

export const get_user_preference = () => {
    return HOST + APP_NAME + `/get_user_preference/`
}
export const update_user_preference = () => {
    return HOST + APP_NAME + `/update_user_preference/`
}

export const get_business_preference = (business_id) => {
    return HOST + APP_NAME + `/get_business_preference/?business_id=${business_id}`
}
export const update_business_preference = () => {
    return HOST + APP_NAME + `/update_business_preference/`
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const create_insight_tab = () => {
    return HOST + APP_NAME + `/create_insight_tab/`
}

export const update_insight_tab = () => {
    return HOST + APP_NAME + `/update_insight_tab/`
}

export const list_of_insight_tab = () => {
    return HOST + APP_NAME + `/list_of_insight_tab/?business_id=${getBusinessInfo().id}`
}

export const get_insight_tab = (tab_id) => {
    return HOST + APP_NAME + `/get_insight_tab/?business_id=${getBusinessInfo().id}&tab_id=${tab_id}`
}

export const create_insight_section = () => {
    return HOST + APP_NAME + `/create_insight_section/`
}

export const list_of_insight_section = (tab_id) => {
    return HOST + APP_NAME + `/list_of_insight_section/?business_id=${getBusinessInfo().id}&tab_id=${tab_id}`
}

export const list_of_data_point = (business_id, searchTerm) => {
    return HOST + APP_NAME + `/list_of_data_point/?business_id=${business_id}&name=${searchTerm}`
}

export const create_data_point = () => {
    return HOST + APP_NAME + `/create_data_point/`
}

export const update_data_point = () => {
    return HOST + APP_NAME + `/update_data_point/`
}

export const get_data_point = (data_point_id) => {
    return HOST + APP_NAME + `/get_data_point/?business_id=${getBusinessInfo().id}&data_point_id=${data_point_id}`
}

export const evaluate_data_point = (data_point_id, from_date, to_date) => {
    return HOST + APP_NAME + `/evaluate_data_point/?business_id=${getBusinessInfo().id}&data_point_id=${data_point_id}&from_date=${from_date}&to_date=${to_date}`
}
export const evaluate_data_point_range = (data_point_id, from_date, to_date, granularity) => {
    let granularityValues = { d: 'day', w: 'week', m: 'month', y: 'year' }
    return HOST + APP_NAME + `/evaluate_data_point_range/?business_id=${getBusinessInfo()?.id}&data_point_id=${data_point_id}&from_date=${from_date}&to_date=${to_date}&granularity=${granularityValues[granularity]}`
}

export const evaluate_dependant_data_point = (data_point_id, from_date, to_date) => {
    return HOST + APP_NAME + `/evaluate_dependant_data_point?business_id=${getBusinessInfo().id}&data_point_id=${data_point_id}&from_date=${from_date}&to_date=${to_date}`
}

export const update_insight_section = () => {
    return HOST + APP_NAME + `/update_insight_section/`
}

export const update_insight_section_order = () => {
    return HOST + APP_NAME + `/update_insight_section_order/`
}

export const update_insight_tab_order = () => {
    return HOST + APP_NAME + `/update_insight_tab_order/`
}


export const delete_insight_tab = () => {
    return HOST + APP_NAME + `/delete_insight_tab/`
}

export const delete_insight_section = () => {
    return HOST + APP_NAME + `/delete_insight_section/`
}

export const delete_data_point = () => {
    return HOST + APP_NAME + `/delete_data_point/`
}

/////////////////////////////////////////////Received Invoice //////////////////////////////


export const get_list_of_received_invoice = (page, additionalParams) => {
    return HOST + APP_NAME + `/get_list_of_received_invoice/?page=${page}&business_id=${getBusinessInfo()?.id}${additionalParams}`
}

export const retrieve_received_invoice = (id) => {
    return HOST + APP_NAME + `/retrieve_received_invoice/${id}/?business_id=${getBusinessInfo()?.id}`
}

export const export_received_invoice = (id) => {
    return HOST + APP_NAME + `/export_received_invoice/`
}

export const receive_invoice_in_business = () => {
    return HOST + APP_NAME + `/receive_invoice_in_business/`
}

export const delete_received_invoice = (id) => {
    return HOST + APP_NAME + `/delete_received_invoice/${id}/`
}


/////////////////////////////////////////////conversion user //////////////////////////////

export const check_user_existence = () => {
    return HOST + APP_NAME + `/check_user_existence/`
}

export const check_approve_action_for_document_link = () => {
    return HOST + APP_NAME + `/check_approve_action_for_document_link/`
}



