import React from 'react';
import * as Custom from '../Custom/Custom';
import CustomTypography from '../../../../custom/typography/CustomTypography';
import { ReactComponent as KeyFeaturesIcon } from '../assets/svg/Group 59565.svg';
import { ReactComponent as AccuracyIcon } from '../../../../../assets/svg/Accuracy.svg';
import { ReactComponent as TimeSavingIcon } from '../../../../../assets/svg/Timesaving.svg';
import { ReactComponent as AffordablePricingIcon } from '../../../../../assets/svg/Affordablepricing.svg';


import {
    Box,
    Grid,
} from '@mui/material';


const KeyFeatures = () => {
    return (
        <Custom.Container sx={{ background: '#F9F9F9', mb: 3,mt : "-105px" }}>
            <Box sx={styles.container}>
                <Title text="Key Features" sx={{ textAlign: 'center' }} />
                <Description text={
                    <span>
                        Finycs makes managing your accounting easy with automated entries,<br/>
                        real-time tracking, and clear financial insights—all in one place.
                    </span>
                } />
                <Box sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                }}>

                    <Grid container sx={{maxWidth: '1200px'}}>
                        <FeaturesContent />
                        <FeaturesGraphic />
                    </Grid>
                </Box>
            </Box>
        </Custom.Container>
    );
};


export default KeyFeatures;


const FeaturesContent = () => (
    <Grid
        item
        sm={6}
        xs={12}
        sx={{
            p: 2,
            gap: '20px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
        }}
        order={{ xs: 2, sm: 2 }}
    >
        {cardData.map((item, index) => (
            <FeatureCard
                key={index}
                icon={item.icon}
                title={item.title}
                message={item.message}
            />
        ))}
    </Grid>
);

const FeatureCard = ({ icon: Icon, title, message }) => (
    <Box sx={styles.cardBox}>

        <Box sx={styles.cardIconWrapper}>
            <Icon />
        </Box>

        <Box sx={{ pl: 2, maxWidth: { xs: '260px', sm: '100%' } }}>
            <CustomTypography text={title} sx={styles.cardContent} />
            <CustomTypography text={message} sx={styles.cardMessage} />
        </Box>
    </Box>
);

const FeaturesGraphic = () => (
    <Grid
        item
        sm={6}
        xs={12}
        order={{ xs: 1, sm: 1 }}
        sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <Box sx={styles.graphicBox}>
            <KeyFeaturesIcon style={{ width: '100%', height: 'auto' }} />
        </Box>
    </Grid>
);

// Reusable Text component
const Title = ({ text, sx }) => (
    <CustomTypography text={text} sx={{ ...styles.typography, ...sx }} />
);

const Description = ({ text }) => (
    <CustomTypography
        text={text}
        sx={styles.description}
    />
);

const cardData = [
    {
        icon: AccuracyIcon,
        title: 'Effortless Accounting',
        message: 'Manage all your accounting operations with ease- anytime, anywhere.',
    },
    {
        icon: AffordablePricingIcon,
        title: 'Smart Invoicing',
        message: 'Create, send, and manage invoices with just a few clicks.',
    },
    {
        icon: TimeSavingIcon,
        title: 'Real-time Analytics',
        message: 'Gain valuable financial insights instantly to make informed decisions.',
    },
];


// Define reusable styles
const styles = {
    container: {
        mt: { xs: '60px', sm: 20 }, p: 2, pt: 4, pb: 8, background: '#FAFAFA', height: '100%', borderRadius: '32px'
    },
    typography: {
        fontWeight: 700, color: '#141414', fontSize: '40px', lineHeight: '66px', letterSpacing: '0px',
    },
    description: {
        mt: 3, mb: 6, fontSize: '20px', fontWeight: 400, color: '#666666', lineHeight: '32px', textAlign: 'center', letterSpacing: '0.2px',
    },
    cardBox: {
        display: { xs: '-webkit-box', sm: 'flex' }, width: '100%', padding: '20px', maxWidth: '500px', borderRadius: '8px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
    cardIconWrapper: {
        width: '48px', height: '48px', display: 'flex', borderRadius: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EBF1FF'
    },
    cardContent: {
        mt: 2, fontWeight: 700, color: '#141414', fontSize: '20px', lineHeight: 'auto', letterSpacing: '0px',
    },
    cardMessage: {
        mt: 2, fontSize: '14px', fontWeight: 400, color: '#666666', lineHeight: '20px', letterSpacing: '0.2px',
    },
    graphicBox: {
        width: '100%', height: 'auto', maxWidth: '422px', maxHeight: '400px',
    }
};

